import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import StudentTopBar from './Components/StudentTopBar'
import CapeeshWebView from '../Private/StudentCapeeshWeb/CapeeshWebView'
import useScreenSize from '../../Logic/useScreenSize'
import { history } from '_state/helpers'
import StudentAppOnboardingPage from './StudentAppOnboardingPage'
import StudentProfile from './StudentProfile'
import { CapeeshColors } from '../../assets/ColorPalette'
const useStyles = makeStyles((theme) => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        gap: 0,
        backgroundColor: CapeeshColors.EggShell2,
        backgroundImage: 'radial-gradient(circle at 1px 1px, rgb(225, 225, 221) 1px, transparent 0)',
        backgroundSize: '12px 12px',
    },
    centeredCapeeshWebContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'calc(100vh - 60px)',
        width: '100%',
    },
    hideCapeeshWebContainer: {
        display: 'none',
    },
}))

import { StudentMonoPageSubPages, switchToStudentSubPage } from 'Logic/Student/StudentQueryParamParser'

const StudentDashboard = (props) => {
    const classes = useStyles()

    const screenSize = useScreenSize()

    const [previewHeight, setPreviewHeight] = useState(screenSize.height * 0.85)
    const [previewWidth, setPreviewWidth] = useState(screenSize.height * 0.85 * 0.3)
    const [showPreviewBorder, setShowPreviewBorder] = useState(true)
    const [fitsToScreenEdges, setFitsToScreenEdges] = useState(false)
    const widthHeightMultiplier = 0.62

    const [currentSubPage, setCurrentSubPage] = useState(StudentMonoPageSubPages.PlayCapeeshApp)

    const maxHeight = 1000
    const maxWidth = maxHeight * widthHeightMultiplier

    const parseLocationSearchParameters = () => {
        const queryParamsSearch = props.location.search

        console.log('location changed', props.location.search)

        // pages to show on the same screen
        const showAppOnboardingFromParams = new URLSearchParams(queryParamsSearch).get('showAppOnboarding')
        const showStudentProfile = new URLSearchParams(queryParamsSearch).get('showStudentProfile')

        let showingSubPages = false

        if (showAppOnboardingFromParams === 'true') {
            setCurrentSubPage(StudentMonoPageSubPages.AppOnboarding)
            showingSubPages = true
        }
        if (showStudentProfile === 'true') {
            setCurrentSubPage(StudentMonoPageSubPages.Profile)
            showingSubPages = true
        }

        if (!showingSubPages) {
            setCurrentSubPage(StudentMonoPageSubPages.PlayCapeeshApp)
        }
    }

    useEffect(() => {
        parseLocationSearchParameters()
    }, [props.location.search])

    useEffect(() => {
        parseLocationSearchParameters()
    }, [])

    const recalculateCapeeshViewSize = () => {
        const topBarHeight = 60
        const magicCapeeshWidthLimiter = 0
        const magicCapeeshHeightLimiter = 5
        let width = previewWidth
        let height = previewHeight

        let isPortrait = true
        if (screenSize.height * 1.5 <= screenSize.width) {
            isPortrait = false
        }
        let isMobileView = false
        if (screenSize.width < maxWidth) {
            isMobileView = true
        }

        if (screenSize.width <= maxWidth) {
            // less than max
            if (isPortrait) {
                width = screenSize.width
                height = screenSize.height - topBarHeight
                setFitsToScreenEdges(true)
            } else {
                height = screenSize.height - topBarHeight
                width = height * 0.3
            }
        } else {
            height = screenSize.height * 0.85
            width = height * widthHeightMultiplier
            setFitsToScreenEdges(false)
        }

        // sanity check
        if (width > screenSize.width - magicCapeeshWidthLimiter) {
            width = screenSize.width - magicCapeeshWidthLimiter
        }
        if (height > screenSize.height - magicCapeeshHeightLimiter - topBarHeight) {
            height = screenSize.height - magicCapeeshHeightLimiter - topBarHeight
        }
        if (isMobileView) {
            setShowPreviewBorder(false)
        } else {
            setShowPreviewBorder(true)
        }

        if (height !== previewHeight) {
            setPreviewHeight(height)
        }
        if (width !== previewWidth) {
            setPreviewWidth(width)
        }
    }

    useEffect(() => {
        recalculateCapeeshViewSize()
    }, [])

    useEffect(() => {
        recalculateCapeeshViewSize()
    }, [screenSize])

    const handleGoBakToCapeeshApp = () => {
        switchToStudentSubPage(StudentMonoPageSubPages.PlayCapeeshApp)
    }

    return (
        <div className={classes.main}>
            <StudentTopBar dispatch={props.dispatch} />
            <div
                hidden={currentSubPage !== StudentMonoPageSubPages.PlayCapeeshApp}
                className={`${fitsToScreenEdges ? '' : classes.centeredCapeeshWebContainer} ${currentSubPage !== StudentMonoPageSubPages.PlayCapeeshApp ? classes.hideCapeeshWebContainer : ''}`}>
                <CapeeshWebView
                    student={props.student}
                    previewHeight={previewHeight}
                    previewWidth={previewWidth}
                    showBorder={showPreviewBorder}
                />
            </div>

            {currentSubPage === StudentMonoPageSubPages.AppOnboarding && (
                <StudentAppOnboardingPage onGoBakToCapeeshApp={handleGoBakToCapeeshApp} />
            )}
            {currentSubPage === StudentMonoPageSubPages.Profile && (
                <StudentProfile onGoBakToCapeeshApp={handleGoBakToCapeeshApp} />
            )}
        </div>
    )
}

StudentDashboard.propTypes = {}

const mapStateToProps = (state) => {
    const { student, studentAuth } = state
    return {
        student: student,
        studentAuth: studentAuth,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(StudentDashboard)
