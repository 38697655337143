import React, { useState } from 'react'
import { lessonActions } from '../../../../_state/actions'
import { connect } from 'react-redux'
import {
    Button,
    Checkbox,
    FormControl,
    Grid,
    LinearProgress,
    makeStyles,
    MenuItem,
    Select,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip,
    Typography,
} from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import { AccountCircle, CheckCircleOutline, PanoramaFishEye } from '@material-ui/icons'
import CapeeshStyledModal from '../../../../Components/CapeeshStyledModal'
import { getFlagAssetForLanguageId } from '../../../../Logic/FlagUnicodeLogic'
import CapeeshResponsiveListDialog from '../../../../Components/CapeeshResponsiveListDialog/CapeeshResponsiveListDialog'
import {
    TopicTranslationConfigurationOptions,
    TranslationApprovalType,
    TranslationImportExportVersion,
} from '../../../../Logic/TopicTranslationConstants'
import { HdrAuto } from '@mui/icons-material'
import { CapeeshLanguage } from '../../../../Logic/CapeeshConstants'

const useStyles = makeStyles((theme) => ({}))

const CourseSupportedLanguagePane = (props) => {
    const classes = useStyles()

    const [order, setOrder] = useState('desc')
    const [orderBy, setOrderBy] = useState('count')
    const [thenOrderBy, setThenOrderBy] = useState('value')
    const [l1IdToAdd, setL1IdToAdd] = useState(null)

    const [l1IdToImportTranslationsFor, setL1IdToImportTranslationsFor] = useState(null)

    const [openTopicTranslationFileSelector, setOpenTopicTranslationFileSelector] = useState(null)
    const [openTopicExportConfigurationSelector, setOpenTopicExportConfigurationSelector] = useState(null)

    const [translationExportLanguageId, setTranslationExportLanguageId] = useState(null)
    const [translationExportLanguageName, setTranslationExportLanguageName] = useState('')

    const [translationVersion, setTranslationVersion] = useState(TranslationImportExportVersion.V2)

    const [translationExportConfigurationOptions, setTranslationExportConfigurationOptions] = useState([
        {
            id: TopicTranslationConfigurationOptions.RemoveCrossLessonDuplicates,
            name: 'Remove cross lesson duplicates',
        },
        // TODO: add when available from the backend.
        // {id: 4, name: 'Try to export with english as from translation language'},
    ])
    const [translationExportPrecheckedOptions, setTranslationExportPrecheckedOptions] = useState([
        {
            id: TopicTranslationConfigurationOptions.RemoveCrossLessonDuplicates,
            name: 'Remove cross lesson duplicates',
        },
    ])

    let alreadySupportedLanguages = props.topicSupportedLanguages?.map(function (x) {
        return x.l1Id
    })

    let languageSupportColumns = [
        {
            id: 'l1Name',
            isDefaultLanguage: 'isDefaultLanguage',
            disablePadding: true,
            label: <FormattedMessage id="create.lesson.supportlanguages.pane.name" defaultMessage="Language" />,
        },
        {
            id: 'l1Id',
            disablePadding: true,
            label: <FormattedMessage id="create.lesson.supportlanguages.pane.l1id" defaultMessage="L1Id" />,
        },
        {
            id: 'published',
            isBadge: true,
            disablePadding: true,
            label: (
                <FormattedMessage id="create.lesson.supportlanguages.pane.needsrepublish" defaultMessage="Published" />
            ),
        },
        {
            id: 'lessonsAvailableForUsers',
            isBadge: true,
            disablePadding: true,
            label: (
                <FormattedMessage
                    id="create.lesson.supportlanguages.pane.lessonsAvailable"
                    defaultMessage="Lessons Available"
                />
            ),
        },
        {
            id: 'translationApprovalType',
            isTranslationBadge: true,
            disablePadding: true,
            label: (
                <FormattedMessage
                    id="create.lesson.supportlanguages.pane.translationtype"
                    defaultMessage="Translation Type"
                />
            ),
        },

        {
            id: 'l1IdExportV2',
            numeric: false,
            isExportTranslationButtonV2: true,
            disablePadding: true,
            isButton: true,
            label: (
                <FormattedMessage
                    id="create.lesson.supportlanguages.pane.exporttranslation"
                    defaultMessage="Export V2."
                />
            ),
        },

        {
            id: 'l1IdImport',
            numeric: false,
            isImportTranslationButtonV2: true,
            disablePadding: true,
            isButton: true,
            label: (
                <FormattedMessage
                    id="create.lesson.supportlanguages.pane.importtranslation.v2"
                    defaultMessage="Import V2."
                />
            ),
        },
        {
            id: 'l1IdImportAutoTranslationV1',
            numeric: false,
            isl1IdImportAutoTranslationV1: true,
            disablePadding: true,
            isButton: true,
            label: (
                <FormattedMessage
                    id="create.lesson.supportlanguages.pane.importtranslation.v1"
                    defaultMessage="Import Machine Translation V1."
                />
            ),
        },
    ]

    if (props.user.organizationRole === 'teacher') {
        languageSupportColumns = [
            {
                id: 'l1Name',
                isDefaultLanguage: 'isDefaultLanguage',
                disablePadding: true,
                label: <FormattedMessage id="create.lesson.supportlanguages.pane.name" defaultMessage="Language" />,
            },
            {
                id: 'lessonsAvailableForUsers',
                isBadge: true,
                disablePadding: true,
                label: (
                    <FormattedMessage
                        id="create.lesson.supportlanguages.pane.available.in.app"
                        defaultMessage="Available in app"
                    />
                ),
            },
        ]

        if (alreadySupportedLanguages.findIndex((x) => x === CapeeshLanguage.Dari) <= 0) {
            alreadySupportedLanguages.push(CapeeshLanguage.Dari)
        }
        if (alreadySupportedLanguages.findIndex((x) => x === CapeeshLanguage.Tigrinya <= 0)) {
            alreadySupportedLanguages.push(CapeeshLanguage.Tigrinya)
        }
        if (alreadySupportedLanguages.findIndex((x) => x === CapeeshLanguage.Nepali <= 0)) {
            alreadySupportedLanguages.push(CapeeshLanguage.Nepali)
        }
        if (alreadySupportedLanguages.findIndex((x) => x === CapeeshLanguage.KurdishKurmanji <= 0)) {
            alreadySupportedLanguages.push(CapeeshLanguage.KurdishKurmanji)
        }
        if (alreadySupportedLanguages.findIndex((x) => x === CapeeshLanguage.KurdishSorani <= 0)) {
            alreadySupportedLanguages.push(CapeeshLanguage.KurdishSorani)
        }
    }

    const handleExportClickV2 = (row, column) => {
        const { metadata } = props
        setTranslationExportLanguageId(row.l1Id)
        setTranslationVersion(2)
        var l1Language = metadata.metadata?.languages?.find((x) => x.id === row.l1Id)
        if (l1Language) {
            setTranslationExportLanguageName(l1Language.name)
        }
        setOpenTopicExportConfigurationSelector(true)
    }

    const handleExportClickedConfigurationSelected = (event) => {
        let allTranslations = false
        let deltaOnlyTranslations = false
        let removeCrossLessonDuplicates = false

        if (event.length > 0) {
            event.forEach((checkedOption) => {
                if (checkedOption === TopicTranslationConfigurationOptions.AllTranslations) {
                    allTranslations = true
                } else if (checkedOption === TopicTranslationConfigurationOptions.DeltaTranslationsOnly) {
                    deltaOnlyTranslations = true
                } else if (checkedOption === TopicTranslationConfigurationOptions.RemoveCrossLessonDuplicates) {
                    removeCrossLessonDuplicates = true
                }
            })
        }

        props.onExportTranslationForL1Id(
            translationExportLanguageId,
            deltaOnlyTranslations,
            removeCrossLessonDuplicates,
            translationVersion
        )
        setOpenTopicExportConfigurationSelector(false)
        setTranslationExportLanguageId(null)
        setTranslationExportLanguageName('')
    }

    const handleImportConfirmed = (l1Id, version) => {
        setOpenTopicTranslationFileSelector(false)

        if (l1Id) {
            props.onImportTranslationForL1Id(l1Id, version)
        }
        setL1IdToImportTranslationsFor(null)
    }

    const handleImportMachineTranslationV1 = (row, column) => {
        setL1IdToImportTranslationsFor(row.l1Id)
        setTranslationVersion(TranslationImportExportVersion.MachineTranslationV1)
        setOpenTopicTranslationFileSelector(true)
    }

    const handleImportV2Click = (row, column) => {
        setL1IdToImportTranslationsFor(row.l1Id)
        setTranslationVersion(2)
        setOpenTopicTranslationFileSelector(true)
    }

    const onDeleteClick = (row, column) => {
        props.onDeleteL1Id(row.l1Id)
    }

    const onChangeAddL1Value = (event) => {
        setL1IdToAdd(event.target.value)
    }

    const onAddSelectedL1Language = (event) => {
        if (l1IdToAdd) {
            props.onAddL1Id(l1IdToAdd)
            setL1IdToAdd(null)
        }
    }

    const renderCellValue = (row, column) => {
        if (column.isExportTranslationButtonV2) {
            if (row.isDefaultLanguage) {
                return
            }

            return (
                <Button variant="outlined" onClick={(event) => handleExportClickV2(row, column)} color="primary">
                    {'Export V2'}
                </Button>
            )
        }

        if (column.isImportTranslationButtonV2) {
            if (row.isDefaultLanguage) {
                return
            }
            return (
                <Button variant="outlined" onClick={(event) => handleImportV2Click(row, column)} color="primary">
                    {'Import V2'}
                </Button>
            )
        }

        if (column.isDeleteSupportedLanguage) {
            if (row.isDefaultLanguage) {
                return
            }
            if (row.deleted) {
                return 'deleted'
            }
            return (
                <Button variant="outlined" onClick={(event) => onDeleteClick(row, column)} color="primary">
                    {'Mark as deleted'}
                </Button>
            )
        }

        if (column.isl1IdImportAutoTranslationV1) {
            if (row.isDefaultLanguage) {
                return
            }
            return (
                <Button
                    variant="outlined"
                    onClick={(event) => handleImportMachineTranslationV1(row, column)}
                    color="primary">
                    {'Machine Translated Import V1'}
                </Button>
            )
        }

        if (column.isTranslationBadge) {
            if (row.isDefaultLanguage) {
                return
            }

            if (!row.lessonsAvailableForUsers) {
                return
            }

            if (row.translationApprovalType === TranslationApprovalType.VerifiedByHuman) {
                return <AccountCircle style={{ color: 'green' }} />
            } else if (row.translationApprovalType === TranslationApprovalType.MachineTranslation) {
                return <HdrAuto style={{ color: 'orange' }} />
            } else {
                return
            }
        }

        if (column.isBadge) {
            return row[column.id] === true ? (
                <CheckCircleOutline style={{ color: 'green' }} />
            ) : (
                <PanoramaFishEye style={{ color: 'red' }} />
            )
        }

        if (column.isDefaultLanguage) {
            return row.isDefaultLanguage ? row[column.id] + ' (default)' : row[column.id]
        }
        return '' + row[column.id]
    }

    function handleCancel() {
        setOpenTopicTranslationFileSelector(false)
        setL1IdToImportTranslationsFor(null)
    }

    function handleTranslationExportConfigurationCancel() {
        setOpenTopicExportConfigurationSelector(false)
        setTranslationExportLanguageId(null)
        setTranslationExportLanguageName(null)
    }

    return (
        <Grid container spacing={3}>
            {openTopicTranslationFileSelector && (
                <CapeeshStyledModal
                    open={openTopicTranslationFileSelector}
                    textTitle={'Do you want to upload new translations?'}
                    textLine1={
                        'The existing translations for this scenario will be overwritten, do you want to continue?'
                    }
                    onButton1={() => handleCancel()}
                    onButton2={() => handleImportConfirmed(l1IdToImportTranslationsFor, translationVersion)}
                    button1Variant={'outlined'}
                    button1Text={'Cancel'}
                    button2Text={'Yes'}
                    onClose={() => handleCancel()}
                />
            )}

            <CapeeshResponsiveListDialog
                open={openTopicExportConfigurationSelector}
                dialogTitle={translationExportLanguageName}
                dialogContentText="Select how you wish the translations to be exported."
                handleButton1={() => handleTranslationExportConfigurationCancel()}
                handleButton2={handleExportClickedConfigurationSelected}
                button1Variant={'outlined'}
                button1Text="Cancel"
                button2Text="Export translations"
                list={translationExportConfigurationOptions}
                originList={translationExportPrecheckedOptions}
            />

            <Grid item xs={12} sm={12} className={classes.tablescroller}>
                <TableHead>
                    <TableRow>
                        {languageSupportColumns?.map((column) => {
                            return (
                                <TableCell
                                    key={column.id}
                                    align={column.numeric ? 'right' : 'left'}
                                    padding={column.disablePadding ? 'none' : 'default'}
                                    sortDirection={orderBy === column.id ? order : false}>
                                    <Tooltip
                                        title="Sort"
                                        placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                                        enterDelay={300}>
                                        <TableSortLabel active={orderBy === column.id} direction={order}>
                                            {column.label}
                                        </TableSortLabel>
                                    </Tooltip>
                                </TableCell>
                            )
                        })}
                    </TableRow>
                </TableHead>

                <TableBody>
                    {props.topicSupportedLanguages
                        ?.sort((a, b) => a.l1Name.localeCompare(b.l1Name))
                        .map((row, rowIndex) => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.l1Id}>
                                    {languageSupportColumns?.map((column, index) => (
                                        <TableCell
                                            key={index}
                                            component="th"
                                            scope="row"
                                            padding="none"
                                            style={{
                                                width: props.user.organizationRole === 'teacher' ? '400px' : '140px',
                                            }}>
                                            {renderCellValue(row, column)}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            )
                        })}
                </TableBody>
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
                <Typography
                    variant="body1"
                    style={{
                        //fontSize: 36,
                        color: '#8E8E93',
                        fontFamily: 'Lato',
                        marginBottom: '10px',
                    }}>
                    <FormattedMessage
                        id={'create.topic.lesson.order.language.modal.add.language.desc'}
                        defaultMessage={'Add an l1 Language'}
                    />
                </Typography>

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        gap: '16px',
                        alignItems: 'center',
                    }}>
                    <FormControl className={classes.languageForm}>
                        <Select
                            onChange={onChangeAddL1Value}
                            inputProps={{
                                name: 'language',
                                id: 'language-simple',
                            }}
                            style={{ minWidth: '150px' }}
                            autoWidth>
                            {props.metadata?.metadata?.languages
                                ?.filter((x) => !alreadySupportedLanguages.includes(x.id))
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map((language, index) => (
                                    <MenuItem key={language.id} value={language.id}>
                                        <img src={getFlagAssetForLanguageId(language.id)} width="16" />
                                        {'\u00A0\u00A0'}
                                        {language.friendlyName}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                    <Button
                        className={classes.button}
                        color="primary"
                        variant="contained"
                        onClick={onAddSelectedL1Language}>
                        <FormattedMessage
                            id={'create.topic.lesson.order.language.modal.add.language'}
                            defaultMessage={'Add language'}
                        />
                    </Button>
                </div>
            </Grid>
        </Grid>
    )
}

function mapStateToProps(state) {
    const { organization, lesson, metadata, user } = state
    return {
        organization,
        lesson,
        metadata,
        user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseSupportedLanguagePane)
