import React, { useEffect, useState } from 'react'
import {
    Avatar,
    Button,
    Card,
    CardContent,
    Typography,
    Grid,
    CircularProgress,
    Menu,
    MenuItem,
} from '@material-ui/core'
import { Settings } from '@material-ui/icons'
import { connect } from 'react-redux'
import moment from 'moment'
import Divider from '@material-ui/core/Divider'
import { makeGetRequest } from '../../../_state/helpers/api'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { configWithAuth } from '../../../_state/helpers'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles((theme) => ({
    card: {
        cursor: 'pointer',
        transition: 'all .25s ease',
        '&:hover': {
            boxShadow: '0 5px 15px rgba(0,0,0,0.25)',
        },
    },
    cardContent: {
        position: 'relative',
    },
    avatar: {
        backgroundColor: '#dfdfdf',
        color: '#262626',
        width: '100%',
        height: '100%',
    },
    avatarProgress: {
        width: '60px',
        height: '60px',
        margin: '5px auto',
        position: 'relative',
        overflow: 'visible',
    },
    progressBar: {
        // color: green[500],
        position: 'absolute',
        zIndex: 1,
    },
    cardActionArea: {
        position: 'absolute',
        top: '0px',
        right: '0px',
        padding: '10px',
        width: '-webkit-fill-available',
    },
    cardAction: {
        color: '#cdcdcd',
        width: '40px',
        height: '40px',
        minWidth: '40px',
        minHeight: '40px',
        float: 'right',
        transition: 'all .25s ease',
        '&:hover': {
            color: '#262626',
            background: 'rgba(0, 0, 0, 0.0)',
        },
    },
    button: {
        margin: theme.spacing(1),
    },
    menuItemDivider: {
        margin: '15px 0px',
    },
}))

const StudentListCard = (props) => {
    const {
        user,
        handleEdit,
        handleRemove,
        handleDelete,
        metadata,
        organization,
        showUserUsageStatistics,
        showDeleteFromClassroom,
        onSetOrEditNickName,
    } = props
    const classes = useStyles()

    const [userPicture, setUserPicture] = useState(null)
    const [anchorSettingsMenu, setAnchorSettingsMenu] = useState(null)

    useEffect(() => {
        if (user.hasProfilePicture) {
            makeGetRequest(
                `admin/stats/organization/${organization.selectedOrganization}/user/${user.id}/picture/base64`,
                configWithAuth()
            )
                .then((result) => {
                    setUserPicture(result.data)
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }, [])

    const handleClickedSettingsMenu = (event) => {
        event.stopPropagation()
        event.preventDefault()

        setAnchorSettingsMenu(event.currentTarget)
    }

    const handleCloseMenus = (event) => {
        event.stopPropagation()
        event.preventDefault()

        setAnchorSettingsMenu(null)
    }

    const getCapitalLetter = (text) => {
        const capitalLetters = text.replace(/(?![A-Z])./g, '')

        return capitalLetters.length > 0 ? capitalLetters : text.substring(0, 1)
    }

    const formatSeconds = (seconds) => {
        const min = seconds / 60
        const hours = min / 60
        const rhours = Math.floor(hours)
        const minutes = (hours - rhours) * 60
        const rminutes = Math.round(minutes)

        let secondString = seconds + ' seconds'
        if (seconds === 1) {
            secondString = seconds + ' seconds'
        }

        if (seconds < 60) return secondString + '.'

        if (rhours === 0 && rminutes === 0) return 'No activity'

        let minutesString = rminutes + ' minutes'
        if (rminutes === 1) {
            minutesString = rminutes + ' minute'
        }
        if (rhours === 0) {
            return minutesString + '.'
        }

        let rhoursString = rhours + ' hours'
        if (rhours === 1) {
            rhoursString = rhours + ' hour'
        }

        return rhoursString + ' and ' + minutesString + '.'
    }

    const userHasSignedUp = user.completedOnboardingAt && user.completedOnboardingAt != '0001-01-01T00:00:00+00:00'

    const getUserNameSection = (user) => {
        let userName = user.nickName ? user.nickName : ''
        if (userName && user.name && user.name !== user.email) {
            userName = userName + ' / ' + user.name
        } else if (user.name && user.name !== user.email) {
            userName = user.name
        }
        userName = userName.trim()

        if (userName) {
            return (
                <React.Fragment>
                    <Typography variant="caption">
                        <FormattedMessage
                            id="dashboard.student.list.card.name.and.app.name"
                            defaultMessage="Name / App name"
                        />
                    </Typography>
                    <Typography variant="subtitle2">{`${userName} (${user.email})`}</Typography>
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <Typography variant="caption">
                        <FormattedMessage id="dashboard.student.list.card.username" defaultMessage="Username" />
                    </Typography>
                    <Typography variant="subtitle2">{`${user.email}`}</Typography>
                </React.Fragment>
            )
        }
    }
    const handleSetOrEditNickName = (event, userId) => {
        event.stopPropagation()
        event.preventDefault()
        handleCloseMenus(event)
        onSetOrEditNickName(userId)
    }
    return (
        <Card onClick={() => handleEdit(user.id)} className={classes.card}>
            <CardContent className={classes.cardContent}>
                <div className={classes.cardActionArea}>
                    <Menu
                        id="add-menu"
                        anchorEl={anchorSettingsMenu}
                        open={Boolean(anchorSettingsMenu)}
                        onClose={handleCloseMenus}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
                        {showDeleteFromClassroom && (
                            <MenuItem onClick={(event) => handleRemove(event, user.id)}>
                                <FormattedMessage
                                    id="dashboard.student.list.card.removefromclassroom"
                                    defaultMessage="Remove from classroom"
                                />
                            </MenuItem>
                        )}
                        {showDeleteFromClassroom && <Divider className={classes.menuItemDivider} />}
                        <MenuItem onClick={(event) => handleDelete(event, user.id)}>
                            <FormattedMessage
                                id="dashboard.student.list.card.removefromallclassrooms"
                                defaultMessage="Remove from all classrooms"
                            />
                        </MenuItem>
                        <MenuItem onClick={(event) => handleSetOrEditNickName(event, user.id)}>
                            <FormattedMessage
                                id="dashboard.student.list.card.set.edit.nick.name"
                                defaultMessage="Set / Edit dashboard name"
                            />
                        </MenuItem>
                    </Menu>
                    <Button
                        id="settings-button"
                        className={classes.cardAction}
                        disableRipple={true}
                        disableFocusRipple={true}
                        onClick={handleClickedSettingsMenu}>
                        <Settings />
                    </Button>
                </div>

                <Grid container justify="center" alignContent="center">
                    <Grid item xs={12} sm={2}>
                        <Grid container>
                            <Grid item xs={12}>
                                <div className={classes.avatarProgress}>
                                    <CircularProgress
                                        className={classes.progressBar}
                                        thickness={3}
                                        size="100%"
                                        variant="static"
                                        value={user.percentageInLevel}
                                    />
                                    <Avatar className={classes.avatar} src={userPicture}>
                                        {getCapitalLetter(user.name)}
                                    </Avatar>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography align="center" variant="subtitle2">
                                    {
                                        <FormattedMessage id="dashboard.student.list.card.level" defaultMessage="Level">
                                            {(leveltext) => leveltext + ': ' + user.level}
                                        </FormattedMessage>
                                    }
                                </Typography>
                                <Typography
                                    align="center"
                                    variant="caption"
                                    style={{ display: 'flex', justifyContent: 'center' }}>
                                    {`${user.experience} LP`.toUpperCase()}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={5}>
                        <Grid container>
                            <Grid item xs={12}>
                                {getUserNameSection(user)}
                            </Grid>

                            {
                                <Grid item xs={12}>
                                    <Typography variant="caption">
                                        <FormattedMessage
                                            id="dashboard.student.list.card.signedup"
                                            defaultMessage="Onboarded"
                                        />
                                    </Typography>
                                    <Typography variant="subtitle2">
                                        {showUserUsageStatistics ? (
                                            userHasSignedUp ? (
                                                user ? (
                                                    moment(user.completedOnboardingAt).format('MMMM Do YYYY')
                                                ) : (
                                                    ''
                                                ) // moment(user.signUpDate).format('MMMM Do YYYY, h:mm:ss a')
                                            ) : (
                                                <FormattedMessage
                                                    id="dashboard.student.list.card.not.onboarded.yet"
                                                    defaultMessage="Not onboarded yet"
                                                />
                                            )
                                        ) : userHasSignedUp ? (
                                            <FormattedMessage
                                                id="dashboard.student.list.card.onboarded"
                                                defaultMessage="Has Onboarded"
                                            />
                                        ) : (
                                            <FormattedMessage
                                                id="dashboard.student.list.card.notonboarded"
                                                defaultMessage="Not onboarded yet"
                                            />
                                        )}
                                    </Typography>
                                </Grid>
                            }
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={5}>
                        <Grid container>
                            {showUserUsageStatistics && (
                                <Grid item xs={12}>
                                    <Typography variant="caption">
                                        <FormattedMessage
                                            id="dashboard.student.list.card.lastactive"
                                            defaultMessage="Last active"
                                        />
                                    </Typography>
                                    <Typography variant="subtitle2">
                                        {userHasSignedUp && user.lastActive ? (
                                            moment(user.lastActive).fromNow()
                                        ) : (
                                            <FormattedMessage
                                                id="dashboard.student.list.card.notsignedup"
                                                defaultMessage="Not signed up"
                                            />
                                        )}
                                    </Typography>
                                </Grid>
                            )}
                            {props.showAppUsage && showUserUsageStatistics && (
                                <Grid item xs={12}>
                                    <Typography variant="caption">
                                        <FormattedMessage
                                            id="dashboard.student.list.card.appusage"
                                            defaultMessage="App usage"
                                        />
                                    </Typography>
                                    <Typography variant="subtitle2">
                                        {userHasSignedUp ? (
                                            formatSeconds(user.appUseSeconds)
                                        ) : (
                                            <FormattedMessage
                                                id="dashboard.student.list.card.notsignedup"
                                                defaultMessage="Not signed up"
                                            />
                                        )}
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

StudentListCard.propTypes = {}

const mapToProps = (state) => {
    return {
        metadata: state.metadata,
        organization: state.organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapToProps, mapDispatchToProps)(StudentListCard)
