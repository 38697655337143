import { Card, CardContent, Grid, makeStyles, Typography } from '@material-ui/core'
import { connect } from 'react-redux'
import IconButton from '@material-ui/core/IconButton'

import Switch from '@material-ui/core/Switch'

import React, { useEffect, useState } from 'react'
import { makeGetRequest } from '../../../_state/helpers/api'
import { configWithAuth } from '../../../_state/helpers'
import { convertDataURIToBinary } from '../../../_state/helpers/base64Logic'
import FallbackImageLesson from '../../../assets/images/courseGroup/FallbackImageLesson.jpeg'
import { CapeeshColors } from '../../../assets/ColorPalette'

import PlayCircleIcon from '@mui/icons-material/PlayCircle'
import { TopicLessonType } from '../../../Views/Private/TopicConstants'
import LimitedTypography from '../../LimitedTypography'
import { cap } from 'lodash/fp/_falseOptions'
import checkmarkImageGray from '../../../assets/images/Lessonstatusribbons.png'
import checkmarkImage from '../../../assets/images/Lessonstatusribbons-1.png'
import Button from '@mui/material/Button'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles((theme) => ({
    scenarioCard: {
        background: 'white',
        paddingBottom: '20px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
    },
    editLessonButton: {
        height: '40px',
        width: '170px',
        align: 'right',
        textAlign: 'center',
    },
    scenarioContent: {
        marginTop: '10px',
        background: CapeeshColors.OffWhite,
    },
    publishedLanguagesText: {
        boxShadow: 'none',
        flex: 1,
        fontSize: 14,
        color: 'black',
        fontFamily: 'Lato',
        textAlign: 'left',
        marginTop: '10px',
    },
    publishedInfoText: {
        boxShadow: 'none',
        flex: 1,
        fontSize: 14,
        color: 'black',
        fontFamily: 'Lato',
        textAlign: 'right',
        marginTop: '10px',
    },
    imageContainer: {
        marginTop: '10px',
        position: 'relative',
        overflow: 'hidden',
        height: '80px',
        width: '100px',
    },

    roundedTextInfo: {
        borderRadius: '10px',
        color: 'white',
        paddingLeft: '10px',
        paddingRight: '10px',
        height: '30px',
        display: 'flex',
        justifyContent: 'center',
    },

    previewButtonWithText: {
        borderRadius: '10px',
        paddingLeft: '10px',
        paddingRight: '10px',
        height: '30px',
        display: 'flex',
        justifyContent: 'right',
    },
    mainText: {
        fontSize: '16px',
        color: '#35323C',
        marginBottom: '5px',
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '140%',
    },
    examBox: {
        backgroundColor: CapeeshColors.OrangeBright,
        borderRadius: 5,
        display: 'flex',
        justifyContent: 'center',
        marginLeft: 8,
    },
    examText: {
        color: '#fff',
        fontFamily: 'Rubik',
        fontSize: 10,
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '140%',
        letterSpacing: 0.25,
        paddingBottom: 2,
        paddingTop: 2,
        paddingLeft: 10,
        paddingRight: 10,
    },
    passIndicatorText: {
        color: '#35323C',
        textAlign: 'right',
        fontFamily: 'Rubik',
        fontSize: 10,
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '140%',
        marginRight: 5,
    },
    playLessonButton: {
        cursor: 'pointer',
        background: CapeeshColors.Primary600,
        borderRadius: 8,
        width: 143,
        height: 40,
        marginTop: 16,
        marginRight: 8,
        justifyContent: 'center',
        display: 'flex',
        '&:hover': {
            background: CapeeshColors.PinkBright,
        },
    },
    previewLessonText: {
        color: 'white',
        fontSize: 14,
        marginTop: 10,
        fontFamily: 'Rubik',
        fontWeight: '400',
        wordWrap: 'break-word',
        paddingLeft: 16,
        paddingRight: 16,
    },
}))

const CourseViewTopicLessonCard = ({
    lessonOrderItem,
    index,
    isExam,
    passed,
    showPassed,
    onPreviewLesson,
    organization,
    showUserPerformanceData = false,
    userCorrectAnswers = 0,
    userAnswers = 0,
    normalizedHighScore = 0,
    stars = 0,
    userTimeToCompleteString,
}) => {
    const classes = useStyles()

    const [imageUrl, setImageUrl] = useState('')
    const [imageBlobUrl, setImageBlobUrl] = useState('')

    const [imageBorderStyle, setImageBorderStyle] = useState('4px solid ' + CapeeshColors.OrangeBright)

    useEffect(() => {
        if (lessonOrderItem?.imageUrl && lessonOrderItem.imageUrl !== imageUrl) {
            setImageUrl(lessonOrderItem.imageUrl)
        }
    }, [])

    const getPercetageStringNumber = (normalizedHighScore) => {
        const percentageNum = normalizedHighScore * 100
        return percentageNum.toFixed(1)
    }

    useEffect(() => {
        if (!imageUrl) {
            return
        }

        if (imageUrl && !imageBlobUrl) {
            let imageReferenceId = imageUrl.replace('image/file/', '')
            makeGetRequest(
                'admin/organization/' +
                    organization.selectedOrganization +
                    '/image/file/reference/' +
                    imageReferenceId +
                    '/base64',
                configWithAuth()
            )
                .then((result) => {
                    let type = null
                    const typeImage = result.data.split(';')[0].split(':')[1]
                    switch (typeImage) {
                        case 'image/jpeg':
                            type = 'jpeg'
                            break
                        case 'image/png':
                            type = 'png'
                            break
                        default:
                            type = 'unknown'
                            break
                    }
                    var binary = convertDataURIToBinary(result.data)
                    var blob = new Blob([binary], { type: type })
                    var blobUrl = URL.createObjectURL(blob)
                    setImageBlobUrl(blobUrl)
                })
                .catch((error) => {
                    console.log('error', error)
                })
        }
    }, [imageUrl])

    return (
        <div className={classes.scenarioContent}>
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', flex: 1 }}>
                <div>
                    <div
                        className={classes.imageContainer}
                        style={{
                            borderRadius: '10px',
                            borderLeft: imageBorderStyle,
                            borderBottom: imageBorderStyle,
                        }}>
                        {imageUrl && imageBlobUrl && (
                            <img
                                src={imageBlobUrl}
                                style={{
                                    maxWidth: '150%',
                                    maxHeight: '150%',
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translateY(-50%) translateX(-50%)',
                                }}
                            />
                        )}
                        {imageUrl && !imageBlobUrl && <Typography style={{ marginTop: '70px' }}></Typography>}
                        {!imageUrl && (
                            <img
                                src={FallbackImageLesson}
                                style={{
                                    maxWidth: '150%',
                                    maxHeight: '150%',
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translateY(-50%) translateX(-50%)',
                                }}
                            />
                        )}
                    </div>
                </div>
                <div style={{ width: '100%' }}>
                    <div style={{ marginLeft: '15px' }}>
                        <div>
                            <div style={{ marginTop: '5px' }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        flexDirection: 'row',
                                        flex: 1,
                                        width: '100%',
                                        alignItems: 'center',
                                    }}>
                                    <div
                                        style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                                        <LimitedTypography
                                            limit={30}
                                            text={lessonOrderItem.name}
                                            customStyle={{
                                                fontSize: '16px',
                                                color: '#35323C',
                                                marginBottom: '5px',
                                                fontFamily: 'Rubik',
                                                fontStyle: 'normal',
                                                fontWeight: 600,
                                                lineHeight: '140%',
                                            }}></LimitedTypography>
                                        <div>
                                            {isExam && (
                                                <div className={classes.examBox}>
                                                    <Typography className={classes.examText}>
                                                        <FormattedMessage
                                                            id="course.modal.topic.lesson.card.exam"
                                                            defaultMessage="Exam"
                                                        />
                                                    </Typography>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-end',
                                            justifyContent: 'flex-end',
                                            flex: 1,
                                            marginTop: -16,
                                        }}>
                                        {!passed && showPassed && (
                                            <div style={{ display: 'flex' }}>
                                                <Typography className={classes.passIndicatorText}>
                                                    <FormattedMessage
                                                        id="course.modal.topic.lesson.card.notpassed"
                                                        defaultMessage="Not passed"
                                                    />
                                                </Typography>
                                                <img src={checkmarkImageGray} style={{ height: '12px' }} />
                                            </div>
                                        )}
                                        {passed && showPassed && (
                                            <div style={{ display: 'flex' }}>
                                                <Typography className={classes.passIndicatorText}>
                                                    <FormattedMessage
                                                        id="course.modal.topic.lesson.card.passed"
                                                        defaultMessage="Passed"
                                                    />
                                                </Typography>
                                                <img src={checkmarkImage} style={{ height: '12px' }} />
                                            </div>
                                        )}

                                        {showUserPerformanceData && (
                                            <div
                                                style={{ display: 'flex', flexDirection: 'column', paddingTop: '5px' }}>
                                                {/*<Typography className={classes.passIndicatorText}>*/}
                                                {/*    <FormattedMessage*/}
                                                {/*        id="course.modal.topic.lesson.answers"*/}
                                                {/*        defaultMessage="{userCorrectAnswers} / {userAnswers} correct answers"*/}
                                                {/*        values={{*/}
                                                {/*            userCorrectAnswers: userCorrectAnswers,*/}
                                                {/*            userAnswers: userAnswers,*/}
                                                {/*        }}*/}
                                                {/*    />*/}
                                                {/*</Typography>*/}
                                                <Typography
                                                    className={classes.passIndicatorText}
                                                    style={{ marginTop: '5px' }}>
                                                    <FormattedMessage
                                                        id="course.modal.topic.lesson.percentage"
                                                        defaultMessage="Percentage {percentage}%"
                                                        values={{
                                                            percentage: getPercetageStringNumber(normalizedHighScore),
                                                        }}
                                                    />
                                                </Typography>
                                                <Typography
                                                    className={classes.passIndicatorText}
                                                    style={{ marginTop: '5px' }}>
                                                    <FormattedMessage
                                                        id="course.modal.topic.lesson.time.to.complete"
                                                        defaultMessage="Time spent: {timeToCompleteString}"
                                                        values={{
                                                            timeToCompleteString: userTimeToCompleteString,
                                                        }}
                                                    />
                                                </Typography>
                                                <Typography
                                                    className={classes.passIndicatorText}
                                                    style={{ marginTop: '5px' }}>
                                                    <FormattedMessage
                                                        id="course.modal.topic.lesson.stars"
                                                        defaultMessage="Stars: {stars}"
                                                        values={{
                                                            stars: stars,
                                                        }}
                                                    />
                                                </Typography>
                                            </div>
                                        )}

                                        {onPreviewLesson && (
                                            <div
                                                onClick={() => onPreviewLesson(lessonOrderItem.customLessonId)}
                                                color={CapeeshColors.PinkBright}
                                                className={classes.playLessonButton}>
                                                <Typography className={classes.previewLessonText}>
                                                    <FormattedMessage
                                                        id="course.modal.topic.lesson.card.previewlessonbuttontext"
                                                        defaultMessage="Preview lesson"
                                                    />
                                                </Typography>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {lessonOrderItem.topicLessonType === TopicLessonType.Scenario && (
                                    <LimitedTypography
                                        customStyle={{
                                            color: '#6E6C71',
                                            marginTop: '5px',
                                            marginBottom: '20px',
                                            fontSize: '14px',
                                            fontFamily: 'Rubik',
                                            fontStyle: 'normal',
                                            fontWeight: 400,
                                            lineHeight: '140%',
                                        }}
                                        limit={100}
                                        text={lessonOrderItem.description}></LimitedTypography>
                                )}
                            </div>
                        </div>
                        <Grid item xs={12} style={{ marginRight: '15px' }}>
                            {lessonOrderItem.topicLessonType !== TopicLessonType.Scenario && (
                                <Typography variant="body1" color="primary">
                                    <FormattedMessage
                                        id="course.modal.topic.lesson.card.unsupportedlesson"
                                        defaultMessage="Unsupported Lesson"
                                    />
                                </Typography>
                            )}
                        </Grid>
                    </div>
                </div>
            </div>
        </div>
    )
}

CourseViewTopicLessonCard.propTypes = {}

const mapStateToProps = (state) => {
    return {
        organization: state.organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseViewTopicLessonCard)
