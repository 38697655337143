import { Button, FormControl, Grid, InputLabel, makeStyles, MenuItem, Select, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { TextField } from '@mui/material'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import Dialog from '@mui/material/Dialog'

const useStyles = makeStyles((theme) => ({
    textField: {
        minWidth: '200px',
        marginLeft: '10px',
        marginRight: '10px',
        marginBottom: '20px',
    },
}))

const CreateNewSubPartComponent = (props) => {
    // expected props
    // props.onCreateCourseGroup (name, description, l2Id, l1Id)

    const classes = useStyles()

    const [subPartName, setSubPartName] = useState('')

    return (
        <Dialog
            open={props.open}
            onClose={() => props.onCancel()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{'Create a new section of the course'}</DialogTitle>
            <DialogContent>
                <Grid container spacing={12}>
                    <DialogContentText id="alert-dialog-description" style={{ marginBottom: '10px' }}>
                        If you wish, you can split your course up into sections. This is useful if a course has many
                        topics.
                    </DialogContentText>
                    <Grid item xs={12}>
                        <Typography
                            style={{ width: '200px', textAlign: 'left', marginLeft: '1px', textTransform: 'none' }}>
                            Course section
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            multiline
                            defaultValue={subPartName}
                            onChange={(event) => setSubPartName(event.target.value)}
                            label={'Choose a name for the section of the course'}
                            style={{ borderRadius: '10px', paddingRight: '5px' }}
                            InputProps={{ disableUnderline: true, shrink: 'false' }}
                            className={classes.textField}
                            variant="filled"
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.onCancel()} autoFocus>
                    {' '}
                    Cancel{' '}
                </Button>
                <Button
                    className={classes.leftSideButtons}
                    variant={'contained'}
                    color={'primary'}
                    disabled={subPartName === ''}
                    onClick={() => props.onCreateSubPart(subPartName)}>
                    <FormattedMessage id="create.create.course.group.button.title" defaultMessage="Create" />
                </Button>
            </DialogActions>
        </Dialog>
    )
}

function mapStateToProps(state) {
    const { metadata } = state
    return {
        metadata,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewSubPartComponent)
