import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import { EditOutlined } from '@mui/icons-material'

import { makeStyles } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import { makeGetRequest } from '../../../../_state/helpers/api'
import { configWithAuth } from '../../../../_state/helpers'
import { AddOutlined, LanguageOutlined } from '@material-ui/icons'
import { Button } from '@mui/material'
import { CapeeshColors } from '../../../../assets/ColorPalette'
import IconButton from '@material-ui/core/IconButton'
import WestIcon from '@mui/icons-material/West'
import { CapeeshSizes } from '../../../../assets/SizePresets'
import { courseGroupActions, organizationActions } from '../../../../_state/actions'

const useStyles = makeStyles((theme) => ({
    main: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        width: '100%',
        minHeight: '180px',
    },
    sideBar: {
        width: CapeeshSizes.CreatorSideBar,
        padding: '32px',
    },
    sideBarText: {
        fontFamily: 'Rubik',
        fontsize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        color: CapeeshColors.DeepBlue,
    },
    topicInfoContainer: {
        display: 'flex',
        flexDirection: 'row',
        gap: '32px',
    },
    topicImage: {
        width: '320px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'left',
    },
    topicImageContent: {
        width: '320px',
        position: 'relative',
    },
    topicDataContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        justifyContent: 'center',
    },
    topicTopHeader: {
        fontFamily: 'Rubik',
        fontsize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
    },
    topicName: {
        fontFamily: 'Rubik',
        fontsize: '32px',
        fontStyle: 'normal',
        fontWeight: 500,
    },
    topicDescription: {
        fontFamily: 'Rubik',
        fontsize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
    },
    newLessonButton: {
        color: 'white',
        background: CapeeshColors.Primary600,
        height: '40px',
        marginTop: '16px',
    },
    languagesButton: {
        height: '40px',
        marginTop: '16px',
        background: CapeeshColors.Gray100,
    },
    floatingEditButton: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        height: '40px',
        marginTop: '16px',
        background: CapeeshColors.Gray100,
    },
}))

const CourseGroupHeader = ({
    courseGroup,
    organization,
    onCreateTopic,
    onBackToCourses,
    courseGroupId,
    showActionButtons,
    onEditCourse,
    setImageBlob,
    numberOfClassroomsUsingCourseGroup,
    onShareCourse,
}) => {
    const classes = useStyles()

    const [currentCourseImageFileReferenceId, setCurrentCourseGroupImageFileReferenceId] = useState('')
    const [courseGroupImageData, setCourseGroupImageData] = useState(false)

    const [courseCourseGroupImageData, setCourseCourseGroupImageData] = useState(null)

    const fetchImage = () => {
        if (!courseGroup) {
            return
        }

        if (courseGroup.id === courseGroupId) {
            if (courseGroup.imageUrl) {
                var fileReferenceId = courseGroup.imageUrl.split('image/file/')[1]
                if (fileReferenceId === currentCourseImageFileReferenceId) {
                    return
                }
                makeGetRequest(
                    `admin/organization/${organization.selectedOrganization}/image/file/reference/${fileReferenceId}/base64`,
                    configWithAuth()
                )
                    .then((result) => {
                        setCourseCourseGroupImageData(result.data)
                        setImageBlob(result.data)
                        setCurrentCourseGroupImageFileReferenceId(fileReferenceId)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
        }
    }
    useEffect(() => {
        fetchImage()
    }, [])
    useEffect(() => {
        if (
            courseGroup &&
            courseGroup.id === courseGroupId &&
            courseGroup.fileReferenceId !== currentCourseImageFileReferenceId
        ) {
            fetchImage()
        }
    }, [courseGroup])

    return (
        <div className={classes.main}>
            <div className={classes.sideBar}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        cursor: 'pointer',
                    }}
                    onClick={(event) => onBackToCourses(event)}>
                    <IconButton
                        sx={{
                            fontSize: '24px',
                            display: 'center',
                            alignItems: 'left',
                            marginLeft: '4px',
                            justifyContent: 'center',
                            height: '30px',
                            color: 'gray',
                        }}>
                        <WestIcon sx={{ fontSize: '24px' }} />
                    </IconButton>
                    <Typography className={classes.sideBarText}>
                        <FormattedMessage
                            id="create.course.group.button.back.to.courses"
                            defaultMessage={'Go to courses'}
                        />
                    </Typography>
                </div>
            </div>
            <div className={classes.topicInfoContainer}>
                <div className={classes.topicImage}>
                    <div className={classes.topicImageContent}>
                        {courseCourseGroupImageData ? (
                            <div style={{ marginTop: '20px' }} key={courseCourseGroupImageData}>
                                <img
                                    style={{
                                        borderRadius: '10px',
                                    }}
                                    src={courseCourseGroupImageData}
                                    width={'320px'}></img>
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: '21%',
                                        left: '12%',
                                        transform: 'translateY(-21%) translateX(-12%)',
                                        zIndex: 1,
                                    }}>
                                    <Button
                                        className={classes.languagesButton}
                                        style={{
                                            textTransform: 'none',
                                            color: CapeeshColors.Gray900,
                                            background: CapeeshColors.Gray100,
                                            opacity: '0.8',
                                        }}
                                        variant={'contained'}
                                        onClick={() => onEditCourse()}>
                                        <EditOutlined
                                            sx={{
                                                marginRight: '8px',
                                                fontSize: '15px',
                                            }}
                                        />
                                        <FormattedMessage
                                            id="create.course.group.button.edit.course.title"
                                            defaultMessage={'Edit course'}
                                        />
                                    </Button>
                                </div>
                            </div>
                        ) : (
                            <div
                                style={{
                                    height: '190px',
                                    width: '320px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundColor: 'white',
                                    borderRadius: '10px',
                                }}>
                                {courseGroup && !courseGroup.imageUrl && (
                                    <Typography
                                        variant="h4"
                                        style={{
                                            fontFamily: 'Lato',
                                            fontStyle: 'normal',
                                            fontWeight: 400,
                                            fontSize: '16px',
                                            lineHeight: '23px',
                                            background: 'white',
                                            marginTop: '20px',
                                            padding: '20px',
                                        }}>
                                        <FormattedMessage
                                            id="create.course.group.no.image.added"
                                            defaultMessage={'No image has been added to the course.'}
                                        />
                                    </Typography>
                                )}

                                <div
                                    style={{
                                        position: 'absolute',
                                        top: '21%',
                                        left: '12%',
                                        transform: 'translateY(-21%) translateX(-12%)',
                                        zIndex: 1,
                                    }}>
                                    <Button
                                        className={classes.languagesButton}
                                        style={{
                                            textTransform: 'none',
                                            color: CapeeshColors.Gray900,
                                            background: CapeeshColors.Gray100,
                                            opacity: '0.8',
                                        }}
                                        variant={'contained'}
                                        onClick={() => onEditCourse()}>
                                        <EditOutlined
                                            sx={{
                                                marginRight: '8px',
                                                fontSize: '15px',
                                            }}
                                        />
                                        <FormattedMessage
                                            id="create.course.group.button.edit.course.title"
                                            defaultMessage={'Edit course'}
                                        />
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className={classes.topicDataContainer}>
                    <Typography className={classes.topicTopHeader}>
                        <FormattedMessage id="globalwords.course" defaultMessage={'Course'} />
                    </Typography>
                    <Typography className={classes.topicName} variant={'h3'}>
                        {courseGroup?.name}
                    </Typography>
                    <Typography style={{ flex: 1 }} className={classes.topicDescription}>
                        {courseGroup?.description}
                    </Typography>
                    {showActionButtons && (
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'left', gap: '16px' }}>
                            <Button
                                className={classes.newLessonButton}
                                style={{
                                    textTransform: 'none',
                                }}
                                variant={'contained'}
                                onClick={() => onCreateTopic()}>
                                <AddOutlined
                                    sx={{
                                        marginRight: '15px',
                                        fontSize: '15px',
                                        color: 'white',
                                    }}
                                />
                                <FormattedMessage
                                    id="create.course.header.new.topic.title"
                                    defaultMessage={'Create new topic'}
                                />
                            </Button>

                            <Button
                                className={classes.newLessonButton}
                                style={{
                                    textTransform: 'none',
                                }}
                                variant={'contained'}
                                onClick={() => onShareCourse()}>
                                <FormattedMessage
                                    id="create.course.header.available in"
                                    defaultMessage={'Available in {numberOfClassroomsUsingCourseGroup} classrooms'}
                                    values={{ numberOfClassroomsUsingCourseGroup: numberOfClassroomsUsingCourseGroup }}
                                />
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

CourseGroupHeader.propTypes = {}

const mapStateToProps = (state) => {
    return {
        organization: state.organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseGroupHeader)
