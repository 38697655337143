import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { TextField, Button, Icon, Paper, Grid, Typography, makeStyles } from '@material-ui/core'
import { authActions } from '_state/actions'
import CapeeshStyledModal from 'Components/CapeeshStyledModal'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import StepContent from '@material-ui/core/StepContent'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '90%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 300,
    },
}))

const ForgotPassword = ({
    forgotPasswordPin,
    updateForgotPassword,
    startOver,
    clearErrors,
    notifyError,
    errorMessage,
    resetStep,
    loginLink,
}) => {
    const classes = useStyles()

    const [emailAddress, setEmailAddress] = React.useState('')
    const [pin, setPin] = React.useState('')
    const [password, setPassword] = React.useState('')

    const steps = [
        <FormattedMessage
            id="dashboard.forgotpasswordpage.step1enterEmail"
            defaultMessage="Enter your email address"
        />,
        <FormattedMessage
            id="dashboard.forgotpasswordpage.step2enterPin"
            defaultMessage="Enter PIN and choose new password"
        />,
    ]

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <FormattedMessage
                        id="dashboard.forgotpasswordpage.typeinemail"
                        defaultMessage="To reset your password, please enter your email address."
                    />
                )
            case 1:
                return (
                    <FormattedMessage
                        id="dashboard.forgotpasswordpage.wehavesentpinemailmsg"
                        defaultMessage="We have sent you an email containing a PIN code. Please
                  enter the PIN code below and choose a new password."
                    />
                )
            default:
                return 'Unknown step'
        }
    }

    const handleNext = () => {
        switch (resetStep) {
            case 0:
                forgotPasswordPin(emailAddress)
                break
            case 1:
                updateForgotPassword(emailAddress, pin, password)
                break
            default:
                console.log('password authaction error occurred. Reach undefined step.')
        }
    }

    const handleBack = () => {
        startOver()
    }

    const handleButtonAction = () => {
        clearErrors()
    }

    return (
        <div className={classes.root}>
            <CapeeshStyledModal
                open={notifyError}
                textTitle={
                    <FormattedMessage
                        id="dashboard.forgotpasswordpage.oopssomethingwrong"
                        defaultMessage="Oops, something went wrong."
                    />
                }
                textLine1={errorMessage}
                onClose={() => handleButtonAction()}
                onButton1={() => handleButtonAction()}
                button1Text={<FormattedMessage id="globalwords.ok" defaultMessage="Ok" />}
            />
            <Stepper activeStep={resetStep} orientation="vertical">
                {steps.map((label, index) => {
                    return (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                            <StepContent>
                                <Typography>{getStepContent(index)}</Typography>
                                <Grid container direction="column">
                                    {resetStep === 0 && (
                                        <TextField
                                            id="emailAddress"
                                            label={
                                                <FormattedMessage
                                                    id="dashboard.forgotpasswordpage.enteremailaddress"
                                                    defaultMessage="Enter your email address"
                                                />
                                            }
                                            placeholder="john.smith@yourcompany.com"
                                            className={classes.textField}
                                            value={emailAddress}
                                            onChange={(event) => setEmailAddress(event.target.value)}
                                        />
                                    )}
                                    {resetStep === 1 && (
                                        <React.Fragment>
                                            <TextField
                                                id="pin"
                                                label={
                                                    <FormattedMessage
                                                        id="dashboard.forgotpasswordpage.enterpinfromemail"
                                                        defaultMessage="Enter the PIN code from email"
                                                    />
                                                }
                                                placeholder=""
                                                className={classes.textField}
                                                value={pin}
                                                onChange={(event) => setPin(event.target.value)}
                                                autoComplete="nothanks"
                                            />
                                            <TextField
                                                id="password"
                                                label={
                                                    <FormattedMessage
                                                        id="dashboard.forgotpasswordpage.enternewpassword"
                                                        defaultMessage="Enter a new password"
                                                    />
                                                }
                                                className={classes.textField}
                                                type="password"
                                                margin="normal"
                                                value={password}
                                                onChange={(event) => setPassword(event.target.value)}
                                                autoComplete="nothanks"
                                            />
                                            {password.length <= 7 && (
                                                <Typography>
                                                    <FormattedMessage
                                                        id="dashboard.forgotpasswordpage.passwordlengthmsg"
                                                        defaultMessage="Password must be at least 8 characters in length"
                                                    />
                                                </Typography>
                                            )}
                                        </React.Fragment>
                                    )}
                                    <div>
                                        {resetStep !== 0 && (
                                            <Button onClick={handleBack} className={classes.button}>
                                                <FormattedMessage id="globalwords.back" defaultMessage="Back" />
                                            </Button>
                                        )}
                                        <Button
                                            disabled={!(resetStep !== 1 || password.length > 7)}
                                            variant="contained"
                                            color="primary"
                                            onClick={handleNext}
                                            className={classes.button}>
                                            {resetStep === steps.length - 1 ? (
                                                <FormattedMessage id="globalwords.finish" defaultMessage="Finish" />
                                            ) : (
                                                <FormattedMessage id="globalwords.next" defaultMessage="Next" />
                                            )}
                                        </Button>
                                    </div>
                                </Grid>
                            </StepContent>
                        </Step>
                    )
                })}
            </Stepper>
            {resetStep === steps.length && (
                <Paper square elevation={0} className={classes.resetContainer}>
                    <Typography>Your password has successfully been changed. Please click Login below</Typography>
                    <Button
                        color="primary"
                        variant="contained"
                        className={classes.button}
                        component={Link}
                        to={loginLink}>
                        <FormattedMessage id="globalwords.login" defaultMessage="Login" />
                    </Button>
                </Paper>
            )}
        </div>
    )
}

function mapStateToProps(state) {
    return {}
}

export default connect(mapStateToProps)(ForgotPassword)
