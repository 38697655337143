import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import { Grid, makeStyles } from '@material-ui/core'
import { Box } from '@mui/material'
import TextField from '@mui/material/TextField'
import {
    AutoCourseCreateDialogStepType,
    AutoCourseCreatorProcessStages,
} from '../../../../Logic/AutoCourseCreationConstants'
import ACCScrollingTexts from '../AutoCourseCreateLanguage/Components/ACCScrollingTexts'
import { ReactComponent as FullLogoLanguage } from '../Images/capeesh_create_logo.svg'
import { CapeeshColors } from '../../../../assets/ColorPalette'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'

const useStyles = makeStyles((theme) => ({
    topTextStyle: {
        color: 'white',
        textAlign: 'center',
        fontSize: 24,
        fontFamily: 'Rubik',
        fontWeight: '300',
        alignSelf: 'center',
        marginBottom: 16,
        width: 664,
    },
    infoTextFirstPage: {
        color: 'white',
        textAlign: 'center',
        fontFamily: 'Rubik',
        fontSize: 14,
        fontStyle: 'italic',
        fontWeight: 400,
    },
    inputFieldStyle: {
        borderRadius: '16px 16px 0px 0',
        borderTop: '2px solid rgba(255, 255, 255, 0.32)',
        borderRight: '2px solid rgba(255, 255, 255, 0.32)',
        borderLeft: '2px solid rgba(255, 255, 255, 0.32)',
        background: 'rgba(255, 255, 255, 0.12)',
        padding: '15px',
        textAlign: 'center',
        width: '634px',
    },
    helpTextBox: {
        width: '605px',
        padding: '15px',
        textAlign: 'center',
        borderRadius: '0px 0px 8px 8px',
        background: 'rgba(255, 255, 255, 0.24)',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    },
    secondaryText: {
        width: 664,
        textAlign: 'center',
        color: 'rgba(255, 255, 255, 0.80)',
        fontSize: 14,
        fontFamily: 'Rubik',
        fontWeight: '400',
        wordWrap: 'break-word',
        marginBottom: '32px',
    },
}))

const AutoCourseCreateIntroScreen = (props) => {
    // props.handleSetAuthorInputMetaData
    // props.authorMetadataDto

    const classes = useStyles()

    const intl = useIntl()

    const messages = defineMessages({
        courseTeachPlaceholder: {
            id: 'create.language.ai.language.what.should.the.course.teach.placeholder',
            defaultMessage: 'This course should teach...',
        },
    })

    const texts = [
        <FormattedMessage
            id={'create.knowledge.ai.promp.example1'}
            defaultMessage={'E.g. Teach health and safety for a construction site.'}
        />,
        <FormattedMessage
            id={'create.knowledge.ai.promp.example2'}
            defaultMessage={'E.g. Teach how to use an operating system in simple terms.'}
        />,
        <FormattedMessage
            id={'create.knowledge.ai.promp.example3'}
            defaultMessage={'E.g. Teach how to use basic functions in a sheets program.'}
        />,
    ]

    const [currentIndex, setCurrentIndex] = useState(0)

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length)
        }, 7000)

        return () => clearInterval(timer)
    }, [texts.length])

    return (
        <div style={{ justifyContent: 'center', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <Grid item xs={12} style={{ position: 'relative', zIndex: 1 }}>
                <div style={{ display: 'flex', justifyContent: 'center', zIndex: 1 }}>
                    <FullLogoLanguage style={{ width: '380px', zIndex: 1 }}></FullLogoLanguage>
                </div>
            </Grid>
            <Grid item xs={12} style={{ position: 'relative', zIndex: 1 }}>
                <Grid
                    container
                    justify="center"
                    style={{ justifyContent: 'center', alignItems: 'center', alignSelf: 'center', marginBottom: 32 }}>
                    <Typography
                        style={{
                            color: '#FFF',
                            textAlign: 'center',
                            justifySelf: 'center',
                            fontFamily: 'Rubik',
                            width: '530px',
                            fontSize: 26,
                            fontStyle: 'normal',
                            fontWeight: 300,
                            lineHeight: '100%',
                        }}>
                        <FormattedMessage id={'create.knowledge.ai.title'} defaultMessage={'Knowledge'} />
                    </Typography>
                </Grid>
            </Grid>
            <Box className={classes.topTextStyle}>
                <FormattedMessage
                    id={'create.course.topic.new.lesson.knowledge.ai.desc1'}
                    defaultMessage={
                        'Ideal for teaching extended content like rules, instructions, or explanations — perfect for job training! Language support ensures everyone understands and learns the language indirectly.'
                    }
                />
            </Box>
            <Typography className={classes.secondaryText}>
                <FormattedMessage
                    id={'create.course.topic.new.lesson.knowledge.ai.desc2'}
                    defaultMessage={
                        'Helps you create voice-drive interactive role plays to practice workplace language and scenarios.'
                    }
                />
            </Typography>
            <div
                style={{
                    fontFamily: 'Rubik',
                    fontSize: 32,
                    fontStyle: 'normal',
                    fontWeight: 400,
                    color: 'white',
                    textAlign: 'center',
                    marginBottom: 16,
                    width: 664,
                }}>
                <FormattedMessage
                    id={'create.knowledge.ai.describe.desc'}
                    defaultMessage={'Describe briefly what the lessons should teach and who they are for.'}
                />
            </div>
            <Grid item xs={12} style={{ marginBottom: '10px' }}>
                <Grid container style={{ justifyContent: 'center' }}>
                    <Grid item xs={12} sm={12} md={12} style={{ textAlign: 'center' }}>
                        <TextField
                            disabled={
                                props.autoCourseCreation.processingState !==
                                AutoCourseCreatorProcessStages.StartSequence
                            }
                            multiline
                            className={classes.inputFieldStyle}
                            sx={{
                                '& .MuiInputBase-input::placeholder': {
                                    color: 'white',
                                },
                                '& .MuiInputBase-input': {
                                    color: 'white',
                                    marginLeft: '16px',
                                    marginTop: '8px',
                                },
                            }}
                            autoFocus
                            id="name"
                            variant="standard"
                            value={props.authorMetadataDto?.authorWhatText}
                            onChange={(e) =>
                                props.handleSetAuthorInputMetaData(AutoCourseCreateDialogStepType.What, e.target.value)
                            }
                            rows={5}
                            placeholder={intl.formatMessage(messages.courseTeachPlaceholder)}
                            InputProps={{
                                disableUnderline: true,
                            }}
                        />
                    </Grid>
                    <div className={classes.helpTextBox}>
                        <Box>
                            <ACCScrollingTexts text={texts[currentIndex]} textStyle={classes.infoTextFirstPage} />
                        </Box>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

AutoCourseCreateIntroScreen.propTypes = {}

const mapStateToProps = (state) => {
    return {
        organization: state.organization,
        autoCourseCreation: state.autoCourseCreation,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(AutoCourseCreateIntroScreen)
