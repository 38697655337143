import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import { ReactComponent as BackgroundImage } from '../../../assets/images/background.svg'
import { ReactComponent as MentorImage } from '../../../assets/images/Linn_Export.svg'
import { ReactComponent as BottomRowImage } from '../../../assets/images/navBackground.svg'
import { ReactComponent as BottomRowHatIcon } from '../../../assets/images/iconGraduation.svg'
import { ReactComponent as BottomRowSettingsIcon } from '../../../assets/images/iconSettings.svg'
import { ReactComponent as LevelImage } from '../../../assets/images/level.svg'
import { CapeeshColors } from '../../../assets/ColorPalette'
import placeholderImage from '../../../assets/images/placeholder_image.png'
import topPlaceholderImage from '../../../assets/images/orgDefaultImage.png'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        overflow: 'hidden',
    },
    orgImageContainer: {
        display: 'flex',
        height: 175,
        width: '100%',
        backgroundColor: 'black',
    },
    imageBackgroundContainer: {
        position: 'relative',
        zIndex: 1,
    },
    backgroundImage: {
        height: 650,
        width: 450,
        position: 'absolute',
        top: -50,
        left: -35,
    },
    mentorContainer: {
        height: 75,
        width: 75,
        position: 'absolute',
        left: 282,
        top: -25,
    },
    mainTextContainer: {
        position: 'absolute',
        zIndex: 1,
        paddingLeft: 16,
        width: 250,
        fontSize: 24,
        fontFamily: 'Nunito',
        fontWeight: 'semibold',
    },
    bottomRowImage: {
        position: 'absolute',
        width: 450,
        height: 115,
        left: -5,
        top: 460,
    },
    bottomRowSettingsIcon: {
        position: 'absolute',
        width: 30,
        height: 30,
        left: 260,
        top: 500,
    },
    bottomRowHatIcon: {
        position: 'absolute',
        width: 50,
        height: 50,
        left: 75,
        top: 475,
    },
    levelImage: {
        position: 'absolute',
        width: 100,
        height: 50,
        left: 16,
        top: -165,
    },
    coursesTextContainer: {
        position: 'absolute',
        width: 50,
        left: 70,
        top: 525,
        color: CapeeshColors.BlueBright,
    },
    courseCardContainer: {
        position: 'absolute',
        width: 250,
        height: 350,
        left: 15,
        top: 75,
        backgroundColor: 'white',
        borderRadius: 40,
        overflow: 'hidden',
    },
    courseCardImage: {
        width: '100%',
        height: 150,
        display: 'block',
        flexDirection: 'column',
        WebkitMaskImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 1) 70%, rgba(0, 0, 0, 0))',
        maskImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 1) 70%, rgba(0, 0, 0, 0))',
        WebkitMaskComposite: 'destination-in',
        maskComposite: 'intersect',
    },
    orgImage: {
        width: '100%',
        height: '100%',
    },
    courseCardImageContainer: {
        position: 'relative',
        display: 'inline-block',
    },
    nameText: {
        fontSize: 24,
        fontFamily: 'Nunito',
        fontWeight: 'semibold',
        padding: 8,
    },
    descriptionText: {
        fontSize: 18,
        fontFamily: 'Nunito',
        fontWeight: 'regular',
        padding: 8,
    },
}))

const CourseCreationPreviewMockup = (props) => {
    const classes = useStyles()

    return (
        <div className={classes.mainContainer}>
            <div className={classes.orgImageContainer}>
                <img src={topPlaceholderImage} className={classes.orgImage} />
            </div>
            <div className={classes.imageBackgroundContainer}>
                <BackgroundImage className={classes.backgroundImage} />
                <MentorImage className={classes.mentorContainer} />
                <div className={classes.mainTextContainer}>
                    <FormattedMessage
                        id={'create.course.new.course.preview.title'}
                        defaultMessage={'What would you like to study today?'}
                    />
                </div>
                <BottomRowImage className={classes.bottomRowImage} />
                <BottomRowSettingsIcon className={classes.bottomRowSettingsIcon} />
                <BottomRowHatIcon className={classes.bottomRowHatIcon} />
                <LevelImage className={classes.levelImage} />
                <div className={classes.coursesTextContainer}>Courses</div>
                <div className={classes.courseCardContainer}>
                    <div className={classes.courseCardImageContainer}>
                        <img src={props.imageSrc?.imageUrl ?? placeholderImage} className={classes.courseCardImage} />
                    </div>
                    <div className={classes.nameText}>{props.mainText}</div>
                    <div className={classes.descriptionText}>{props.description}</div>
                </div>
            </div>
        </div>
    )
}

CourseCreationPreviewMockup.propTypes = {}

const mapStateToProps = (state) => {
    return {
        organization: state.organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseCreationPreviewMockup)
