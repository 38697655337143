import { studentConstants } from '../actions'

const initialState = {
    fetchingUserInfo: false,
    userInfo: null,
    userDashboardLanguageId: 1,
}

export function student(state = initialState, action) {
    switch (action.type) {
        case studentConstants.STUDENT_GET_USER_INFO.REQUEST:
            return {
                ...state,
                fetchingUserInfo: true,
            }
        case studentConstants.STUDENT_GET_USER_INFO.SUCCESS:
            return {
                ...state,
                fetchingUserInfo: false,
                userInfo: action.data,
            }
        case studentConstants.STUDENT_GET_USER_INFO.FAILURE:
            return {
                ...state,
                fetchingUserInfo: false,
            }

        case studentConstants.STUDENT_SET_USER_DASHBOARD_LANGUAGE.REQUEST:
            return {
                ...state,
                userDashboardLanguageId: action.payload.languageId,
            }

        default:
            return state
    }
}
