import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import Dialog from '@mui/material/Dialog'
import xImage from '../../../../assets/images/Navigation.png'
import ComponentStepper from '../../Creator/ComponentStepper'
import ChooserComponent from './ChooserComponent'
import MultipleStudentAddComponent from '../../../../Components/MultipleStudentAddModal/MultipleStudentAddComponent'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: 32,
    },
    closeIcon: {
        width: 24,
        height: 24,
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        alignSelf: 'flex-end',
        marginRight: 16,
        marginTop: 16,
        display: 'flex',
        cursor: 'pointer',
    },
}))

const AddStudentsToClassroomModal = ({ open, onClose, classrooms, onAddStudentsToClassroom }) => {
    const classes = useStyles()

    const [selectedClassroom, setSelectedClassroom] = useState(null)

    const [addStudentRows, setAddStudentRows] = React.useState([])
    const [hasAnyValidEmails, setHasAnyValidEmails] = React.useState(false)

    const listOfClassrooms = classrooms.map((item) => {
        return { text: item.name, item: item }
    })

    const onChooseClassroom = (classroom) => {
        setSelectedClassroom(classroom)
    }

    const handleStudentRowsAreUpdated = (studentRows) => {
        setAddStudentRows(studentRows)
        setHasAnyValidEmails(studentRows.filter((x) => x.validEmail).length > 0)
    }

    const editCourseStepperComponents = [
        {
            component: (
                <MultipleStudentAddComponent
                    onStudentRowsChanged={handleStudentRowsAreUpdated}
                    onHasAnyValidEmails={(value) => setHasAnyValidEmails(value)}
                    showTitle={true}
                    title={
                        <FormattedMessage
                            id="dashboard.classroom.students.invitemodal.title"
                            defaultMessage="Invite new students"
                        />
                    }
                    description={
                        <FormattedMessage
                            id="dashboard.classroom.students.invitemodal.description"
                            defaultMessage="Enter emails for user to invite. Use tab key to navigate to the next field. You can paste in space or comma separated lists (eg. from a Excel column). The name field will only be shown in the dashboard and not shared with the user."
                        />
                    }
                    initialStudentRows={addStudentRows}
                />
            ),
            stepCondition: hasAnyValidEmails,
        },
        {
            component: (
                <ChooserComponent
                    listToChooseFrom={listOfClassrooms}
                    onSetChosenOption={onChooseClassroom}
                    defaultOption={selectedClassroom}
                    showTitle={true}
                    title={
                        <FormattedMessage
                            id="dashboard.classroom.students.choose.classroom.title"
                            defaultMessage="Choose a classroom"
                        />
                    }
                    description={
                        <FormattedMessage
                            id="dashboard.classroom.students.choose.classroom.description"
                            defaultMessage="Users in Capeesh must be a part of a classroom to make sure they get assigned the courses in that classroom."
                        />
                    }
                />
            ),
            stepCondition: selectedClassroom,
        },
    ]

    const handleAddStudentsToClassroom = () => {
        if (!selectedClassroom) {
            return
        }
        if (addStudentRows.length === 0) {
            return
        }

        onAddStudentsToClassroom(addStudentRows, selectedClassroom.id)
    }

    const handleCancel = () => {
        setSelectedClassroom(null)
        onClose()
    }

    return (
        <Dialog open={open} maxWidth={'md'} onClose={handleCancel}>
            <div className={classes.mainContainer}>
                <img onClick={() => handleCancel()} src={xImage} className={classes.closeIcon} />
                <ComponentStepper
                    stepperComponents={editCourseStepperComponents}
                    onFinishStepper={handleAddStudentsToClassroom}
                    onCancel={handleCancel}
                />
            </div>
        </Dialog>
    )
}

AddStudentsToClassroomModal.propTypes = {}

const mapStateToProps = (state) => {
    return {
        organization: state.organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(AddStudentsToClassroomModal)
