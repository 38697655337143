import { defineMessages } from 'react-intl'

export const i18nMessages = defineMessages({
    createLesson: {
        id: 'globalwords.create.lesson',
        defaultMessage: 'Create lesson',
    },
    recreateLesson: {
        id: 'globalwords.recreate.lesson',
        defaultMessage: 'Recreate Lesson',
    },

    createQuizDialogeMessage: {
        id: 'create.knowledge.ai.lesson.plan.lesson.create.quiz.question',
        defaultMessage: 'Write a question and the answer. Also add at least one incorrect answer.',
    },
    addNewQuizQuestion: {
        id: 'create.knowledge.ai.lesson.plan.lesson.add.new.quiz.question',
        defaultMessage: 'Add a new quiz question',
    },
    question: {
        id: 'globalwords.question',
        defaultMessage: 'Question',
    },
    correctAnswer: {
        id: 'globalwords.correctAnswer',
        defaultMessage: 'Correct answer',
    },
    incorrectAnswerNumber: {
        id: 'globalwords.incorrect.answer.number',
        defaultMessage: 'Incorrect answer {number}',
    },
    writeLessonPlot: {
        id: 'create.knowledge.ai.lesson.write.lesson.plot',
        defineMessage: 'Write the lesson plot',
    },
    writeLessonPlotDesc: {
        id: 'create.knowledge.ai.lesson.write.lesson.plot.desc',
        defaultMessage:
            'Write the plot that the lesson will be based on. Give as much detail as you wish on what should happen in the story.',
    },
    allImagesFail: {
        id: 'create.knowledge.ai.image.generation.fail.message',
        defaultMessage: 'Failed to generate all images.',
    },
    someImagesFail: {
        id: 'create.knowledge.ai.some.image.generation.fail.message',
        defaultMessage: 'Failed to generate some images.',
    },
    dashboardGraphsYLabelHours: {
        id: 'dashboard.graphs.ylabel.hours',
        defaultMessage: 'Hours',
    },
    dashboardGraphsYLabelSessions: {
        id: 'dashboard.graphs.ylabel.sessions',
        defaultMessage: 'Sessions',
    },
    dashboardGraphsYLabelStars: {
        id: 'dashboard.graphs.ylabel.stars',
        defaultMessage: 'Stars',
    },
    dashboardGraphsYLabelLessons: {
        id: 'dashboard.graphs.ylabel.lessons',
        defaultMessage: 'Lessons',
    },
    dashboardGraphsYLabelAnswers: {
        id: 'dashboard.graphs.ylabel.answers',
        defaultMessage: 'Answers',
    },
})

export default { i18nMessages }
