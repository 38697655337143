import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import OrganizationOverviewSidebar from './Components/OrganizationOverviewSidebar'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Card, CardContent, CardHeader, Grid, Typography } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import { organizationActions } from '../../../_state/actions'
import { InviteRolesToDisplayName } from '../../../Logic/OrganizationAssetConstants'
import EmailInvite from '../../../Components/EmailInvite'
import Table from '@mui/material/Table'
import { CapeeshColors } from '../../../assets/ColorPalette'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import DeleteIcon from '@mui/icons-material/Delete'
import CapeeshStyledModal from '../../../Components/CapeeshStyledModal'

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '300px',
        marginLeft: 'calc(15% + 25px)',
        marginRight: '25px',
    },

    card: {
        minHeight: '250px',
        marginTop: '25px',
        marginRight: '40px',
    },

    userBody: {
        marginTop: '10px',
    },
    inviteButton: {
        float: 'right',
        clear: 'both',
        marginTop: '15px',
        marginRight: '15px',
    },

    tableHeaderCell: {
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        fontFamily: 'Rubik',
        textAlign: 'left',
    },

    tableHeaderCellSmallText: {
        fontSize: '10px',
        fontStyle: 'normal',
        fontWeight: 400,
        fontFamily: 'Rubik',
        textAlign: 'left',
    },

    tableRowCellName: {
        color: CapeeshColors.Primary600,
        fontSize: '14px',
        fontStyle: 'normal',
        fontFamily: 'Rubik',
        fontWeight: 500,
        textTransform: 'none',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        width: '190px',
    },
}))

const OrganizationAdmins = (props) => {
    const classes = useStyles()
    const [currentOrganizationDto, setCurrentOrganizationDto] = useState(null)

    const [currentOrganizationAdminUsers, setCurrentOrganizationAdminUsers] = useState([])

    const [canAddOrganizationAdministrator, setCanAddOrganizationAdministrator] = useState(false)
    const [canDeleteOrganizationAdministrator, setCanDeleteOrganizationAdministrator] = useState(false)

    const [showInviteAdministrator, setShowInviteAdministrator] = useState(false)

    // delete users
    const [toDeleteUser, setToDeleteUser] = useState(null)
    const [showDeleteUserConfirmation, setShowDeleteUserConfirmation] = useState(false)

    const isEmailOfUserSameAsAdmin = (email) => {
        return props.user.email === email
    }

    useEffect(() => {
        const { location } = props

        const organizationId =
            location.state && location.state.organizationId
                ? location.state.organizationId
                : props.organization.selectedOrganization

        setCurrentOrganizationAdminUsers([])

        if (props.user.organizationRole === 'admin' || props.user.organizationRole === 'teacher') {
            setCanAddOrganizationAdministrator(true)
            setCanDeleteOrganizationAdministrator(true)
        } else if (props.user.organizationRole === 'reporting' && !props.user.classroomOnlyReportingUser) {
            setCanAddOrganizationAdministrator(true)
            setCanDeleteOrganizationAdministrator(true)
        } else {
            setCanAddOrganizationAdministrator(false)
            setCanDeleteOrganizationAdministrator(false)
        }

        props.dispatch(organizationActions.getMyOrganizations())
    }, [])

    useEffect(() => {
        let adminUsers = []

        if (props.organization.organizationUsers) {
            props.organization.organizationUsers
                .filter(
                    (x) =>
                        x.roles.includes('Admin') ||
                        x.roles.includes('Teacher') ||
                        x.roles.includes('Reporting') ||
                        x.roles.includes('QA')
                )
                .map((user, index) => {
                    if (user.email.includes('capeesh.com')) {
                        // skip showing capeesh users if in the list to customers
                    } else if (user.roles.includes('Admin')) {
                        adminUsers.push({
                            id: user.id,
                            email: user.email,
                            name: user.name,
                            role: InviteRolesToDisplayName['Admin'],
                            hasCreate: true,
                            hasDashboard: true,
                            hasCreateClassrooms: true,
                            hasAddStudents: true,
                            hasAddAdministrators: true,
                            hasAdministerCourses: true,
                            hasClassroomStatistics: true,
                            hasAllClassrooms: true,
                        })
                    } else if (user.roles.includes('Teacher')) {
                        adminUsers.push({
                            id: user.id,
                            email: user.email,
                            name: user.name,
                            role: InviteRolesToDisplayName['Teacher'],
                            hasCreate: true,
                            hasDashboard: true,
                            hasCreateClassrooms: true,
                            hasAddStudents: true,
                            hasAddAdministrators: false,
                            hasAdministerCourses: true,
                            hasClassroomStatistics: true,
                            hasAllClassrooms: false,
                        })
                    } else if (user.roles.includes('Reporting')) {
                        if (user.classroomOnlyReportingUser) {
                            adminUsers.push({
                                id: user.id,
                                email: user.email,
                                name: user.name,
                                role: 'Classroom Administrator',
                                hasCreate: false,
                                hasDashboard: true,
                                hasCreateClassrooms: true,
                                hasAddStudents: true,
                                hasAddAdministrators: false,
                                hasAdministerCourses: true,
                                hasClassroomStatistics: true,
                                hasAllClassrooms: false,
                            })
                        } else {
                            adminUsers.push({
                                id: user.id,
                                email: user.email,
                                name: user.name,
                                role: InviteRolesToDisplayName['Reporting'],
                                hasCreate: false,
                                hasDashboard: true,
                                hasCreateClassrooms: true,
                                hasAddStudents: true,
                                hasAddAdministrators: true,
                                hasAdministerCourses: true,
                                hasClassroomStatistics: true,
                                hasAllClassrooms: true,
                            })
                        }
                    } else {
                        adminUsers.push({
                            id: user.id,
                            email: user.email,
                            name: user.name,
                            role: user.roles.join(', '),
                            hasCreate: false,
                            hasDashboard: false,
                            hasCreateClassrooms: false,
                            hasAddStudents: false,
                            hasAddAdministrators: false,
                            hasAdministerCourses: false,
                            hasClassroomStatistics: false,
                            hasAllClassrooms: false,
                        })
                    }
                })
        }
        setCurrentOrganizationAdminUsers(adminUsers)
    }, props.organization.organizationUsers)

    const handleInviteAdministrators = () => {}

    const handleDeleteOrganizationUser = (userToDelete) => {
        if (userToDelete?.id) {
            setToDeleteUser(userToDelete)
            setShowDeleteUserConfirmation(true)
        }
    }

    const handleDeleteOrganizationUserCanceled = () => {
        setToDeleteUser(null)
        setShowDeleteUserConfirmation(false)
    }
    const handleDeleteOrganizationUserConfirmed = () => {
        setShowDeleteUserConfirmation(false)

        if (toDeleteUser?.id) {
            props.dispatch(
                organizationActions.deleteOrganizationUser(
                    props.organization.selectedOrganization,
                    toDeleteUser.id,
                    null
                )
            )
        }
    }

    return (
        <div className={classes.root}>
            <OrganizationOverviewSidebar path={props.computedMatch?.path} />

            {showDeleteUserConfirmation && (
                <CapeeshStyledModal
                    open={showDeleteUserConfirmation}
                    textTitle={
                        <FormattedMessage id="globalwords.deleteconfirmation" defaultMessage="Delete confirmation" />
                    }
                    textLine1={
                        'Do you really want delete the "' +
                        toDeleteUser.email +
                        '"? The user will loose access to Capeesh Dashboard and Capeesh Create and App for this organization.'
                    }
                    onButton2={() => handleDeleteOrganizationUserConfirmed()}
                    onButton1={() => handleDeleteOrganizationUserCanceled()}
                    button1Text={<FormattedMessage id="globalwords.cancel" defaultMessage="Cancel" />}
                    button1Variant={'outlined'}
                    button2Text={<FormattedMessage id="globalwords.yes" defaultMessage="Yes" />}
                    onClose={() => handleDeleteOrganizationUserCanceled()}
                />
            )}

            {
                <EmailInvite
                    inviteEmailsOpen={showInviteAdministrator}
                    title={
                        <FormattedMessage
                            id="dashboard.administrators.inviteadmin.title"
                            defaultMessage="Invite new administrators"
                        />
                    }
                    description={
                        <FormattedMessage
                            id="dashboard.administrators.inviteadmin.description"
                            defaultMessage="Enter emails for user to invite. Use comma to separate and add more than one."
                        />
                    }
                    handleInviteEmailsClose={() => setShowInviteAdministrator(false)}
                    availableRoles={['Reporting']}
                />
            }

            <Grid container>
                <Grid item xs={6}>
                    <Typography variant="h4" style={{ marginTop: '25px' }}>
                        <FormattedMessage
                            id="dashboard.administrators..orgAdminsHeader"
                            defaultMessage="Organization Admins"
                        />
                    </Typography>
                </Grid>

                <Grid
                    item
                    xs={6}
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'baseline',
                    }}>
                    {canAddOrganizationAdministrator && (
                        <Button
                            color={'primary'}
                            className={classes.inviteButton}
                            variant={'contained'}
                            onClick={() => setShowInviteAdministrator(true)}>
                            <FormattedMessage
                                id="dashboard.administrators.inviteadministrator.button.text"
                                defaultMessage="Invite administrators"
                            />
                        </Button>
                    )}
                </Grid>
                <Grid xs={12}>
                    <Typography variant="body2" className={classes.userBody}>
                        <FormattedMessage
                            id="dashboard.administrators.orgAdminsSubHeader"
                            defaultMessage="These are users with administrator access to your organization"
                        />
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <TableContainer
                        sx={{
                            marginTop: '30px',
                            minWidth: 400,
                            backgroundColor: CapeeshColors.Gray5,
                            borderRadius: '16px',
                        }}>
                        <Table
                            style={{ marginTop: '20px', marginBottom: '20px' }}
                            size="small"
                            aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell width="20%">
                                        <Typography className={classes.tableHeaderCell}>
                                            <FormattedMessage id="globalwords.email" defaultMessage="Email" />
                                        </Typography>
                                    </TableCell>
                                    <TableCell width="20%">
                                        <Typography className={classes.tableHeaderCellSmallText}>
                                            <FormattedMessage id="globalwords.role" defaultMessage="Role" />
                                        </Typography>
                                    </TableCell>
                                    <TableCell width="7%" align="right">
                                        <Typography className={classes.tableHeaderCellSmallText}>
                                            <FormattedMessage
                                                id="dashboard.administrators.classroom.table.create"
                                                defaultMessage="Create"
                                            />
                                        </Typography>
                                    </TableCell>
                                    <TableCell width="7%" align="right">
                                        <Typography className={classes.tableHeaderCellSmallText}>
                                            <FormattedMessage
                                                id="dashboard.administrators.classroom.table.dashboard"
                                                defaultMessage="Dashboard"
                                            />
                                        </Typography>
                                    </TableCell>
                                    <TableCell width="7%" align="right">
                                        <Typography className={classes.tableHeaderCellSmallText}>
                                            <FormattedMessage
                                                id="dashboard.administrators.classroom.table.addclassrooms"
                                                defaultMessage="Add Classrooms"
                                            />
                                        </Typography>
                                    </TableCell>
                                    <TableCell width="7%" align="right">
                                        <Typography className={classes.tableHeaderCellSmallText}>
                                            <FormattedMessage
                                                id="dashboard.administrators.classroom.table.addStudents"
                                                defaultMessage="Add students"
                                            />
                                        </Typography>
                                    </TableCell>
                                    <TableCell width="7%" align="right">
                                        <Typography className={classes.tableHeaderCellSmallText}>
                                            <FormattedMessage
                                                id="dashboard.administrators.classroom.table.addadministrators"
                                                defaultMessage="Add administrators"
                                            />
                                        </Typography>
                                    </TableCell>
                                    <TableCell width="7%" align="right">
                                        <Typography className={classes.tableHeaderCellSmallText}>
                                            <FormattedMessage
                                                id="dashboard.administrators.classroom.table.administercourses"
                                                defaultMessage="Administer courses"
                                            />
                                        </Typography>
                                    </TableCell>
                                    <TableCell width="7%" align="right">
                                        <Typography className={classes.tableHeaderCellSmallText}>
                                            <FormattedMessage
                                                id="dashboard.administrators.classroom.table.all.classrooms"
                                                defaultMessage="All classrooms"
                                            />
                                        </Typography>
                                    </TableCell>
                                    {canDeleteOrganizationAdministrator && (
                                        <TableCell width="10%" align="right">
                                            <Typography className={classes.tableHeaderCellSmallText}>
                                                <FormattedMessage
                                                    id="dashboard.administrators.classroom.table.delete"
                                                    defaultMessage="Delete"
                                                />
                                            </Typography>
                                        </TableCell>
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {currentOrganizationAdminUsers.map((adminUser) => (
                                    <TableRow
                                        key={adminUser.id}
                                        // onClick={() => handleGoToSingleClassroom(userGroup.id)}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell width="20%" component="th" scope="row">
                                            <Typography className={classes.tableRowCellName}>
                                                {adminUser.email}
                                            </Typography>
                                        </TableCell>
                                        <TableCell width="20%" align="right">
                                            <Typography
                                                style={{
                                                    textAlign: 'left',
                                                    fontSize: '14px',
                                                    fontStyle: 'normal',
                                                    fontWeight: 400,
                                                    fontFamily: 'Rubik',
                                                }}>
                                                {adminUser.role}
                                            </Typography>
                                        </TableCell>
                                        <TableCell width="7%" align="right">
                                            <CheckCircleIcon
                                                style={{
                                                    color: adminUser.hasCreate
                                                        ? CapeeshColors.Green
                                                        : CapeeshColors.Gray2,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell width="7%" align="right">
                                            <CheckCircleIcon
                                                style={{
                                                    color: adminUser.hasDashboard
                                                        ? CapeeshColors.Green
                                                        : CapeeshColors.Gray2,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell width="7%" align="right">
                                            <CheckCircleIcon
                                                style={{
                                                    color: adminUser.hasCreateClassrooms
                                                        ? CapeeshColors.Green
                                                        : CapeeshColors.Gray2,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell width="7%" align="right">
                                            <CheckCircleIcon
                                                style={{
                                                    color: adminUser.hasAddStudents
                                                        ? CapeeshColors.Green
                                                        : CapeeshColors.Gray2,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell width="7%" align="right">
                                            <CheckCircleIcon
                                                style={{
                                                    color: adminUser.hasAddAdministrators
                                                        ? CapeeshColors.Green
                                                        : CapeeshColors.Gray2,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell width="7%" align="right">
                                            <CheckCircleIcon
                                                style={{
                                                    color: adminUser.hasAdministerCourses
                                                        ? CapeeshColors.Green
                                                        : CapeeshColors.Gray2,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell width="7%" align="right">
                                            <CheckCircleIcon
                                                style={{
                                                    color: adminUser.hasAllClassrooms
                                                        ? CapeeshColors.Green
                                                        : CapeeshColors.Gray2,
                                                }}
                                            />
                                        </TableCell>
                                        {canDeleteOrganizationAdministrator && (
                                            <TableCell width="7%" align="right">
                                                <DeleteIcon
                                                    style={
                                                        isEmailOfUserSameAsAdmin(adminUser.email)
                                                            ? { opacity: 0.25 }
                                                            : {}
                                                    }
                                                    onClick={() => {
                                                        if (isEmailOfUserSameAsAdmin(adminUser.email)) return
                                                        handleDeleteOrganizationUser(adminUser)
                                                    }}
                                                />
                                            </TableCell>
                                        )}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid container style={{ marginTop: '70px' }}>
                    <Grid item xs={12}>
                        <Typography variant="h4">
                            <FormattedMessage
                                id="dashboard.administrators.roledescription.title"
                                defaultMessage="Administrator types"
                            />
                        </Typography>
                        <Typography variant="body2" className={classes.userBody}>
                            <FormattedMessage
                                id="dashboard.administrators.roledescription.description"
                                defaultMessage="Under follows a overview of the different types of admin users supported by Capeesh and seen as Role in the table above."
                            />
                        </Typography>
                    </Grid>

                    <Grid item xs={4}>
                        <Card className={classes.card}>
                            <CardHeader
                                title=<FormattedMessage
                                    id="dashboard.administrators.roledescription.administrator.title"
                                    defaultMessage="Administrator"
                                />
                            />
                            <CardContent>
                                <Typography variant="subtitle1">
                                    <FormattedMessage
                                        id="dashboard.administrators.roledescription.administrator.subtitle"
                                        defaultMessage="Customer administrators for the whole organization"
                                    />
                                </Typography>
                                <Typography variant="subtitle1" className={classes.userBody}>
                                    <FormattedMessage
                                        id="dashboard.administrators.roledescription.administrator.descriptions"
                                        defaultMessage="This user can see and edit organization specific information like: administrators, statistics, billing information. In addition they can Create classrooms and assign Classroom Administrators, Courses and Students to classrooms."
                                    />
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={4}>
                        <Card className={classes.card}>
                            <CardHeader
                                title=<FormattedMessage
                                    id="dashboard.administrators.roledescription.classroomadministrator.title"
                                    defaultMessage="Classroom Administrator"
                                />
                            />
                            <CardContent>
                                <Typography variant="subtitle1">
                                    <FormattedMessage
                                        id="dashboard.administrators.roledescription.classroomadministrator.subtitle"
                                        defaultMessage="Customer administrator role for one or more single Classrooms"
                                    />
                                </Typography>
                                <Typography variant="subtitle1" className={classes.userBody}>
                                    <FormattedMessage
                                        id="dashboard.administrators.roledescription.classroomadministrator.description"
                                        defaultMessage="This user can see 1 or more Classrooms. They can add students, change the Courses for classrooms and see statistics from the Students in those classrooms."
                                    />
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={4}>
                        <Card className={classes.card}>
                            <CardHeader
                                title=<FormattedMessage
                                    id="dashboard.administrators.roledescription.creator.title"
                                    defaultMessage="Creator"
                                />
                            />
                            <CardContent>
                                <Typography variant="subtitle1">
                                    <FormattedMessage
                                        id="dashboard.administrators.roledescription.creator.subtitle"
                                        defaultMessage="Customer role for course Creation"
                                    />
                                </Typography>
                                <Typography variant="subtitle1" className={classes.userBody}>
                                    <FormattedMessage
                                        id="dashboard.administrators.roledescription.creator.description"
                                        defaultMessage="These users get access to Capeesh Create to create courses. The user also has access to create classrooms to test out the courses."
                                    />
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

function mapStateToProps(state) {
    const { organization, user } = state
    return {
        organization,
        user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationAdmins)
