import React from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import { Button } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

const useStyles = makeStyles((theme) => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        justifyContent: 'flex-start',
        height: 'calc(100vh - 60px)',
    },
}))

const StudentAppOnboardingPage = ({ onGoBakToCapeeshApp }) => {
    const classes = useStyles()

    return (
        <div className={classes.main}>
            <div style={{ display: 'flex', alignItems: 'left', paddingLeft: '16px', paddingTop: '16px' }}>
                <Button
                    style={{ width: '250px', height: '40px', textTransform: 'none' }}
                    variant={'contained'}
                    onClick={onGoBakToCapeeshApp}>
                    <ArrowBackIcon width={25} height={25} style={{ paddingRight: '8px' }} />
                    Back to Capeesh App
                </Button>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>information here</div>
        </div>
    )
}

StudentAppOnboardingPage.propTypes = {}

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(StudentAppOnboardingPage)
