import { userConstants } from '../actions'
import { authConstants } from '../actions'

const initialState = {
    info: null,
    fetchingUserStats: false,
    userStats: null,
    userActivity: [],
    fetchingUserActivity: false,
    fetchingUserQuizProgress: false,
    userSessionsDaily: [],
    fetchingUserSessionsDaily: false,
    userSessions: [],
    fetchingUserSessions: false,
    userDashboardLanguageId: 1,

    userIdToCourseGroupMapper: {},
    userIdToCourseGroupProgressionMapper: {},
    userIdToLessonProgressionsMapper: {},
    updatingUserAttributes: false,
}

export function user(state = {}, action) {
    switch (action.type) {
        case userConstants.GETUSERINFO.REQUEST:
            return {
                ...state,
                isLoading: true,
            }
        case userConstants.GETUSERINFO.SUCCESS:
            return {
                ...state,
                info: action.data,
            }
        case userConstants.ASSIGN_ROLE.SUCCESS:
            return {
                ...state,
                organizationRole: action.data.role,
                classroomOnlyReportingUser: action.data.classroomOnlyReportingUser,
            }
        case userConstants.GETUSERINFO.FAILURE:
            return {
                ...state,
                error: action.error,
            }

        case userConstants.CREATE_USER_MESSAGE.REQUEST:
            return {
                ...state,
            }
        case userConstants.CREATE_USER_MESSAGE.SUCCESS:
            return {
                ...state,
            }
        case userConstants.CREATE_USER_MESSAGE.FAILURE:
            return {
                ...state,
                error: action.error,
            }

        case userConstants.UPDATEUSER.REQUEST:
            return {
                ...state,
                updatingUser: true,
            }
        case userConstants.UPDATEUSER.SUCCESS:
            return {
                ...state,
                info: action.data,
                updatingUser: false,
            }
        case userConstants.UPDATEUSER.FAILURE:
            return {
                ...state,
                updatingUser: false,
                error: action.error,
            }

        case userConstants.GET_USER_STATS.REQUEST:
            return {
                ...state,
                fetchingUserStats: true,
            }
        case userConstants.GET_USER_STATS.SUCCESS:
            return {
                ...state,
                userStats: action.data,
                fetchingUserStats: false,
            }
        case userConstants.GET_USER_STATS.FAILURE:
            return {
                ...state,
                fetchingUserStats: false,
                error: action.error,
            }
        case userConstants.RESEND_INVITE.REQUEST:
            return {
                ...state,
            }
        case userConstants.RESEND_INVITE.SUCCESS:
            return {
                ...state,
            }
        case userConstants.RESEND_INVITE.FAILURE:
            return {
                ...state,
                error: action.error,
            }

        case userConstants.ADMIN_UPDATE_USER_MASTER_ORGANIZATION.REQUEST:
            return {
                ...state,
            }
        case userConstants.ADMIN_UPDATE_USER_MASTER_ORGANIZATION.SUCCESS:
            return {
                ...state,
            }
        case userConstants.ADMIN_UPDATE_USER_MASTER_ORGANIZATION.FAILURE:
            return {
                ...state,
                error: action.error,
            }

        case userConstants.ADMIN_UPDATE_USER.REQUEST:
            return {
                ...state,
                updatingUserAttributes: true,
            }
        case userConstants.ADMIN_UPDATE_USER.SUCCESS:
            return {
                ...state,
                updatingUserAttributes: false,
            }
        case userConstants.ADMIN_UPDATE_USER.FAILURE:
            return {
                ...state,
                error: action.error,
                updatingUserAttributes: false,
            }

        case userConstants.GET_USER_ACTIVITY.REQUEST:
            return {
                ...state,
                fetchingUserActivity: true,
            }
        case userConstants.GET_USER_ACTIVITY.SUCCESS:
            return {
                ...state,
                userActivity: action.data,
                fetchingUserActivity: false,
            }
        case userConstants.UPDATE_USER_ORGANIZATION_ROLE.SUCCESS:
            return {
                ...state,
            }

        case userConstants.UPDATE_USER_ORGANIZATION_ROLE.FAILURE:
            return {
                ...state,
                error: action.error,
            }
        case userConstants.GET_USER_ACTIVITY.FAILURE:
            return {
                ...state,
                fetchingUserActivity: false,
                error: action.error,
            }

        case authConstants.LOGOUT.SUCCESS:
            return initialState

        case userConstants.SET_USER_DASHBOARD_LANGUAGE.REQUEST:
            return {
                ...state,
                userDashboardLanguageId: action.payload.languageId,
            }

        case userConstants.GET_USER_COURSE_GROUPS.REQUEST:
            return {
                ...state,
            }
        case userConstants.GET_USER_COURSE_GROUPS.SUCCESS:
            let userIdToCourseGroupMapper = state.userIdToCourseGroupMapper ? state.userIdToCourseGroupMapper : {}
            userIdToCourseGroupMapper[action.data.userId] = action.data.courseGroups
            return {
                ...state,
                userIdToCourseGroupMapper: userIdToCourseGroupMapper,
            }
        case userConstants.GET_USER_COURSE_GROUPS.FAILURE:
            return {
                ...state,
            }

        case userConstants.GET_USER_COURSE_GROUP_PROGRESSION.REQUEST:
            return {
                ...state,
            }
        case userConstants.GET_USER_COURSE_GROUP_PROGRESSION.SUCCESS:
            let userIdToCourseGroupProgressionMapper = state.userIdToCourseGroupProgressionMapper
                ? state.userIdToCourseGroupProgressionMapper
                : {}
            let courseGroupIdToProgressionsMapper =
                action.data.userId in userIdToCourseGroupProgressionMapper
                    ? userIdToCourseGroupProgressionMapper[action.data.userId]
                    : {}
            courseGroupIdToProgressionsMapper[action.data.courseGroupId] = action.data.courseGroupProgression
            userIdToCourseGroupProgressionMapper[action.data.userId] = courseGroupIdToProgressionsMapper
            return {
                ...state,
                userIdToCourseGroupProgressionMapper: userIdToCourseGroupProgressionMapper,
            }
        case userConstants.GET_USER_COURSE_GROUP_PROGRESSION.FAILURE:
            return {
                ...state,
            }

        case userConstants.GET_USER_LESSON_PROGRESSIONS.REQUEST:
            return {
                ...state,
            }
        case userConstants.GET_USER_LESSON_PROGRESSIONS.SUCCESS:
            let userIdToLessonProgressionsMapper = state.userIdToLessonProgressionsMapper
                ? state.userIdToLessonProgressionsMapper
                : {}
            userIdToLessonProgressionsMapper[action.data.userId] = action.data.userLessonProgressions
            return {
                ...state,
                userIdToLessonProgressionsMapper: userIdToLessonProgressionsMapper,
            }
        case userConstants.GET_USER_LESSON_PROGRESSIONS.FAILURE:
            return {
                ...state,
            }

        default:
            return state
    }
}
