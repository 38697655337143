import React from 'react'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core'
import { Box } from '@mui/material'
import TextField from '@mui/material/TextField'
import { AutoCourseCreateDialogStepType } from '../../../../Logic/AutoCourseCreationConstants'
import { CapeeshColors } from '../../../../assets/ColorPalette'
import { styled } from '@mui/material/styles'
import Switch from '@mui/material/Switch'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        justifyContent: 'center',
    },
    topMainText: {
        width: '100%',
        textAlign: 'center',
        color: 'white',
        fontSize: 32,
        fontFamily: 'Rubik',
        fontWeight: '400',
        wordWrap: 'break-word',
    },
    topSecondaryText: {
        width: '100%',
        textAlign: 'center',
        color: 'rgba(255, 255, 255, 0.80)',
        fontSize: 14,
        fontFamily: 'Rubik',
        fontWeight: '400',
        wordWrap: 'break-word',
        marginBottom: 16,
    },
    prevTextContainer: {
        display: 'flex',
        padding: 16,
        flexDirection: 'column',
        alignItems: 'center',
        gap: 16,
        alignSelf: 'center',
        borderRadius: 16,
        border: '2px solid var(--white--32, rgba(255, 255, 255, 0.32))',
        marginBottom: 16,
    },
    prevTextContainerMainText: {
        color: 'white',
        textAlign: 'center',
        fontFamily: 'Rubik',
        fontWeight: 400,
        fontsize: 20,
        fontStyle: 'normal',
    },
    prevTextContainerSubText: {
        color: CapeeshColors.white80,
        textAlign: 'center',
        fontFamily: 'Rubik',
        fontWeight: 400,
        fontsize: 14,
        fontStyle: 'normal',
    },
}))

const AutoCourseCreateTeachingContent = (props) => {
    // props.handleSetAuthorInputMetaData
    // props.maxLenght
    // props.authorMetadataDto
    // props.handleToggleTranslations
    // props.teachingText

    const classes = useStyles()

    const intl = useIntl()

    const messages = defineMessages({
        copyAndPasteTextHere: {
            id: 'create.knowledge.ai.teaching.content.paste.text.placeholder',
            defaultMessage: 'Copy and paste your text in here',
        },
    })

    const GreenSwitch = styled(Switch)(({ theme }) => ({
        '& .MuiSwitch-switchBase.Mui-checked': {
            color: 'white',
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            backgroundColor: CapeeshColors.BlueBright,
        },
    }))

    return (
        <div className={classes.mainContainer}>
            <Typography className={classes.topMainText}>
                <FormattedMessage
                    id={'create.knowledge.ai.teaching.content.title'}
                    defaultMessage={'Add detailed description'}
                />
            </Typography>
            <Typography className={classes.topSecondaryText}>
                <FormattedMessage
                    id={'create.knowledge.ai.teaching.content.description'}
                    defaultMessage={
                        'Write a more detailed description of the lessons, or add your own teaching material.'
                    }
                />
            </Typography>
            <div className={classes.prevTextContainer}>
                <div className={classes.prevTextContainerMainText}>
                    <FormattedMessage
                        id={'create.knowledge.ai.teaching.content.should.teach.title'}
                        defaultMessage={'The course should teach:'}
                    />
                </div>
                <div className={classes.prevTextContainerSubText}>{props.teachingText}</div>
            </div>
            <Box
                style={{
                    color: 'var(--White, #FFF)',
                    fontFamily: 'Rubik',
                    fontSize: 16,
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '140%',
                    minHeight: '50px',
                }}>
                <TextField
                    placeholder={intl.formatMessage(messages.copyAndPasteTextHere)}
                    multiline
                    style={{
                        borderRadius: 8,
                        border: '1px solid var(--White, #FFF)',
                        background: 'rgba(255, 255, 255, 0.16)',
                        width: 664,
                        height: 186,
                    }}
                    autoFocus
                    margin="dense"
                    id="name"
                    fullWidth
                    variant="standard"
                    sx={{
                        '& .MuiInputBase-input::placeholder': {
                            color: 'white',
                        },
                        '& .MuiInputBase-input': {
                            color: 'white',
                            fontSize: '14px',
                            marginLeft: '8px',
                        },
                    }}
                    onClick={(e) => e.stopPropagation()}
                    onChange={(e) =>
                        props.handleSetAuthorInputMetaData(
                            AutoCourseCreateDialogStepType.SupportingText,
                            e.target.value
                        )
                    }
                    rows={4}
                    InputProps={{
                        disableUnderline: true,
                    }}
                />
            </Box>
            <Typography
                variant="caption"
                component="div"
                color={props.authorMetadataDto?.supportingText.split(' ').length > props.maxLenght ? 'red' : 'white'}
                style={{
                    textAlign: 'right',
                    color: 'white',
                    fontSize: 14,
                    fontFamily: 'Rubik',
                    fontWeight: '400',
                    padding: 8,
                }}>
                {props.authorMetadataDto?.supportingText.trim()
                    ? props.authorMetadataDto?.supportingText.split(' ').length
                    : 0}{' '}
                / {props.maxLenght}
            </Typography>
        </div>
    )
}

AutoCourseCreateTeachingContent.propTypes = {}

const mapStateToProps = (state) => {
    return {
        organization: state.organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(AutoCourseCreateTeachingContent)
