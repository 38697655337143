import { studentAuthConstants } from '../actions'

let authToken = JSON.parse(localStorage.getItem('authToken'))
const initialState = {
    studentLoggedIn: !!authToken,
    sendingPin: false,
    sendingEmail: false,
    resetStep: 0,
    authToken: authToken ? authToken : null,
    checkingShouldRegister: false,
    studentShouldRegister: false,
    message: '',
    error: '',
    errorMessage: '',
    studentRegistering: false,
}

export function studentAuth(state = initialState, action) {
    switch (action.type) {
        case studentAuthConstants.STUDENT_SHOULD_REGISTER.REQUEST:
            return {
                ...state,
                checkingShouldRegister: true,
            }
        case studentAuthConstants.STUDENT_SHOULD_REGISTER.SUCCESS:
            return {
                ...state,
                checkingShouldRegister: false,
                studentShouldRegister: action.data.shouldSignUp,
            }
        case studentAuthConstants.STUDENT_SHOULD_REGISTER.FAILURE:
            return {
                ...state,
                checkingShouldRegister: false,
            }

        case studentAuthConstants.STUDENT_LOGIN.REQUEST:
            return {
                ...state,
                studentLoggingIn: true,
            }
        case studentAuthConstants.STUDENT_LOGIN.SUCCESS:
            return {
                ...state,
                studentLoggedIn: true,
                studentLoggingIn: false,
                authToken: action.data,
            }
        case studentAuthConstants.STUDENT_LOGIN.FAILURE:
            return {
                ...state,
                studentLoggingIn: false,
                message: 'The email or password was incorrect. Please try again.',
            }
        case studentAuthConstants.STUDENT_REGISTER.REQUEST:
            return {
                ...state,
                studentRegistering: true,
            }
        case studentAuthConstants.STUDENT_REGISTER.SUCCESS:
            return {
                ...state,
                studentRegistering: false,
                studentLoggedIn: true,
                authToken: action.data,
            }
        case studentAuthConstants.STUDENT_REGISTER.FAILURE:
            return {
                ...state,
                studentRegistering: false,
                error: action.message,
            }
        case studentAuthConstants.STUDENT_FORGOT_PASSWORD_PIN.REQUEST:
            return {
                ...state,
                sendingEmail: true,
                sendingPin: false,
                resetStep: 0,
                error: '',
                errorMessage: '',
            }
        case studentAuthConstants.STUDENT_FORGOT_PASSWORD_PIN.SUCCESS:
            return {
                ...state,
                sentPin: false,
                sendingEmail: false,
                sendingPin: false,
                resetStep: 1,
                error: '',
            }
        case studentAuthConstants.STUDENT_FORGOT_PASSWORD_PIN.FAILURE:
            return {
                ...state,
                sendingEmail: false,
                notifyError: true,
                resetStep: 0,
                error: action.message,
                errorMessage: 'We could not find that email address. Please check and try again.',
            }
        case studentAuthConstants.STUDENT_UPDATE_FORGOT_PASSWORD.REQUEST:
            return {
                ...state,
                errorMessage: '',
            }
        case studentAuthConstants.STUDENT_UPDATE_FORGOT_PASSWORD.SUCCESS:
            let resetStep = 1
            if (action.data.success) {
                return {
                    ...state,
                    resetStep: 2,
                }
            }
            return {
                ...state,
                errorMessage: 'The PIN code does not seem to be correct. Please check and try again.',
                notifyError: true,
            }
        case studentAuthConstants.STUDENT_UPDATE_FORGOT_PASSWORD.FAILURE:
            return {
                ...state,
                errorMessage: 'We could not update the password. Please ensure the password has at least 8 characters.',
                notifyError: true,
            }
        case studentAuthConstants.STUDENT_START_OVER.REQUEST:
            return {
                ...state,
                resetStep: 0,
            }
        case studentAuthConstants.STUDENT_CLEAR_ERRORS.REQUEST:
            return {
                ...state,
                notifyError: false,
            }
        case studentAuthConstants.STUDENT_LOGOUT.SUCCESS:
            return initialState
        default:
            return state
    }
}
