import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Grid, makeStyles } from '@material-ui/core'
import { TextField } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import { FormattedMessage } from 'react-intl'
import EmailValidator from 'email-validator'
import { CapeeshColors } from '../../assets/ColorPalette'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'

const useStyles = makeStyles((theme) => ({}))

const MultipleStudentAddComponent = ({
    role = 'Student',
    onStudentRowsChanged,
    showTitle,
    title,
    description,
    initialStudentRows = [],
}) => {
    const [rowInFocus, setRowInFocus] = useState(0)

    const tempStudentRowsToAdd =
        initialStudentRows?.length > 0 ? initialStudentRows : [{ index: 0, email: '', name: '', validEmail: false }]
    const [studentRowsToAdd, setStudentRowsToAdd] = React.useState(tempStudentRowsToAdd)

    useEffect(() => {
        if (studentRowsToAdd[studentRowsToAdd.length - 1] && studentRowsToAdd[studentRowsToAdd.length - 1].email) {
            handleAddStudentRow(studentRowsToAdd.length, '', '', false)
        }
        onStudentRowsChanged(studentRowsToAdd)
    }, [studentRowsToAdd])

    const handleAddStudentRow = (index, email, name, emailValid) => {
        let currentIndex = index ? index : studentRowsToAdd.length
        const newStudentRow = { index: currentIndex, email: email, name: name, validEmail: false }
        setStudentRowsToAdd([...studentRowsToAdd, newStudentRow])
    }

    const handleUpdateStudentRowEmail = (index, email) => {
        const updatedStudentRows = studentRowsToAdd.map((user) => {
            if (user.index === index) {
                return {
                    index: index,
                    email: email,
                    name: user.name,
                    validEmail: EmailValidator.validate(email),
                }
            } else {
                return user
            }
        })
        setStudentRowsToAdd(updatedStudentRows)
    }

    useEffect(() => {
        if (studentRowsToAdd[studentRowsToAdd.length - 1] && studentRowsToAdd[studentRowsToAdd.length - 1].email) {
            handleAddStudentRow(studentRowsToAdd.length, '', '', false)
        }
    }, [studentRowsToAdd])

    const handleUpdateStudentRowName = (index, name) => {
        const updatedStudentRows = studentRowsToAdd.map((user) => {
            if (user.index === index) {
                return {
                    index: index,
                    email: user.email,
                    name: name,
                    validEmail: user.validEmail,
                }
            } else {
                return user
            }
        })
        setStudentRowsToAdd(updatedStudentRows)
    }

    const handlePastedMultiple = (index, email, otherEmails) => {
        const updatedStudentRows = studentRowsToAdd.map((user) => {
            if (user.index === index) {
                return {
                    index: index,
                    email: email,
                    name: user.name,
                    validEmail: EmailValidator.validate(email),
                }
            } else {
                return user
            }
        })
        let otherEmailsIndex = updatedStudentRows.length
        for (const otherEmail of otherEmails) {
            updatedStudentRows.push({
                index: otherEmailsIndex,
                email: otherEmail,
                name: '',
                validEmail: EmailValidator.validate(email),
            })
            otherEmailsIndex++
        }
        setStudentRowsToAdd(updatedStudentRows)
    }

    const handleChangeEmailRow = (index, email) => {
        // check if there is more than one pasted
        let possibleEmailString = email.replace(';', ' ')
        possibleEmailString = possibleEmailString.replace(',', ' ')
        const possibleEmails = possibleEmailString.trim().split(/\s+/)
        if (possibleEmails.length > 1) {
            console.log(possibleEmails)
            const okEmails = []
            for (const email of possibleEmails) {
                if (email) {
                    okEmails.push(email)
                }
            }

            if (okEmails.length > 1) {
                // need to add all the emails after this index
                handlePastedMultiple(index, okEmails[0], okEmails.splice(1))
            }
        } else {
            handleUpdateStudentRowEmail(index, email)
        }
    }

    const handleChangeNameRow = (index, name) => {
        handleUpdateStudentRowName(index, name)
    }

    const handleDeleteUserRow = (index) => {
        const updatedStudentRows = studentRowsToAdd.filter((user) => user.index !== index)
        let newIndex = 0
        for (const user of updatedStudentRows) {
            user.index = newIndex
            newIndex++
        }
        setStudentRowsToAdd(updatedStudentRows)
    }

    const addUserBlock = (userDto, index) => {
        return (
            <div style={{ width: '700px', display: 'flex', flexDirection: 'row', gap: '16px' }}>
                <TextField
                    value={userDto.email}
                    onClick={() => setRowInFocus(index)}
                    onChange={(event) => handleChangeEmailRow(index, event.target.value)}
                    label={<FormattedMessage id="globalwords.email" defaultMessage="Email" />}
                    style={{
                        height: '55px',
                        width: '350px',
                        borderRadius: '10px',
                        border: userDto.validEmail ? '2px dashed #80D387' : '',
                        borderColor: userDto.validEmail ? CapeeshColors.GreenBright : CapeeshColors.GreenBright,
                        color: 'white',
                    }}
                    InputProps={{ disableUnderline: true }}
                    variant="filled"
                />

                <TextField
                    value={userDto.name}
                    onClick={(event) => setRowInFocus(index)}
                    onChange={(event) => handleChangeNameRow(index, event.target.value)}
                    label={<FormattedMessage id="globalwords.name.optional" defaultMessage="Name (optional)" />}
                    style={{ height: '40px', width: '300px', borderRadius: '10px' }}
                    InputProps={{ disableUnderline: true }}
                    variant="filled"
                />

                {studentRowsToAdd.length > 1 && (
                    <IconButton aria-label="delete" style={{ marginTop: '10px' }}>
                        <DeleteIcon
                            onClick={(event) => {
                                handleDeleteUserRow(index)
                            }}
                            fontSize="small"
                        />
                    </IconButton>
                )}
            </div>
        )
    }

    return (
        <div
            style={{
                display: 'flex',
                paddingLeft: '16px',
                paddingRight: '16px',
                flexDirection: 'column',
                gap: '16px',
                paddingBottom: '16px',
            }}>
            {showTitle && (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                    }}>
                    <DialogTitle id="form-dialog-title">{title}</DialogTitle>
                    <DialogContentText>{description}</DialogContentText>
                </div>
            )}
            {studentRowsToAdd.map((userDto, index) => addUserBlock(userDto, index))}
        </div>
    )
}

MultipleStudentAddComponent.propTypes = {}

const mapStateToProps = (state) => {
    return {
        organization: state.organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(MultipleStudentAddComponent)
