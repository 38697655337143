import React, { useState, useEffect, useRef, useCallback } from 'react'
import Grid from '@mui/material/Grid'
import { makeStyles, TextField } from '@material-ui/core'
import { styled } from '@mui/material/styles'

import { connect } from 'react-redux'
import 'react-image-crop/dist/ReactCrop.css'
import { useReactFlow, applyNodeChanges } from 'react-flow-renderer'
import Button from '@material-ui/core/Button'
import { lessonActions, topicActions, userActions } from '../../../_state/actions'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContentText from '@material-ui/core/DialogContentText'
import Typography from '@material-ui/core/Typography'
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

import IconButton from '@material-ui/core/IconButton'
import { makeGetRequest } from '../../../_state/helpers/api'
import { configWithAuth, history } from '../../../_state/helpers'
import { v4 as uuidv4 } from 'uuid'
import DeleteIcon from '@material-ui/icons/Delete'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import CreateIcon from '@material-ui/icons/Create'
import MessageRoundedIcon from '@mui/icons-material/MessageRounded'
import { MenuItem, Select } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import Backdrop from '@mui/material/Backdrop'
import Box from '@mui/material/Box'
import { ExtractMimeType } from '../../../Logic/Base64Helpers'

import {
    AdminPreviewScenarioNodeType,
    AdminSketchScenarioNodeType,
    ScenarioDeepLinkType,
    ScenarioInteractiveElementType,
    ScenarioNodeType,
    ScenarioTextType,
    CustomLessonAuthorNoteType,
    UserMessageType,
    ScenarioAudioPlayType,
    ToggleModeViewTypes,
    LessonType,
    AudioFieldType,
    ImageFieldType,
    MentorEnum,
    ScenarioAudioPlayType as AudioPlayType,
} from '../../../Logic/ScenarioConstants'
import ScenarioSupportedLanguagePane from './OptionsPanes/ScenarioSupportedLanguagePane'
import ScenarioEditNode from './ScenarioEditNode'
import ScenarioAddNodeSelector from './ScenarioAddNodeSelector'
import ScenarioAuthorAddComment from './ScenarioAuthorAddComment'
import ScenarioAuthorComment from './ScenarioAuthorComment'
import DataPickerSidebar from './DataPickerSidebar/DataPickerSidebar'
import moment from 'moment'
import Badge from '@mui/material/Badge'
import Zoom from '@mui/material/Zoom'
import RemoveIcon from '@mui/icons-material/Remove'
import Divider from '@mui/material/Divider'
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded'

import { convertDataURIToBinary } from '../../../_state/helpers/base64Logic'
import { nodeTypes } from './Nodes'
import ButtonEdgeSmart from './EdgesSmart'
import { isNodeExitsOccupied } from './ScenarioStages/StageHelperFiles'
import LessonUndoPane from './OptionsPanes/LessonUndoPane'
import CapeeshStyledModal from '../../../Components/CapeeshStyledModal'
import Drawer from '@material-ui/core/Drawer'
import CapeeshReactFlow from './CapeeshReactFlow'

import { PromptTypes, GPTPrompt } from '../../../Logic/ScenarioConstants'
import { CapeeshColors } from '../../../assets/ColorPalette'
import Slider from '@mui/material/Slider'
import OpenAI from 'openai'
import { AdminUserCredentialType } from '../../../Logic/CapeeshConstants'
import { GlobalEvents } from '../../../assets/GlobalEvents'
import LessonBuilderHeaderBar from './Components/LessonBuilderHeaderBar'
import LessonOptionsModal from './LessonOptionsModal'

const fourSpaces = '\u00A0\u00A0\u00A0\u00A0'

const drawerWidth = 1000

const onNodeContextMenu = (event, node) => {
    event.preventDefault()
}
const useStyles = makeStyles((theme) => ({
    container: {
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',
        gridGap: theme.spacing(3),
    },
    divider: {
        margin: theme.spacing(2, 0),
    },
    imagePreview: {
        height: '70vh',
        overflow: 'hidden',
    },

    l1ChooserStyle: {
        marginLeft: '20px',
        paddingLeft: '10px',
        paddingRight: '10px',
        width: '60px',
        height: '60px',
        border: '5px solid #143349',
        boxSizing: 'border-box',
        borderRadius: '10px',
        maxWidth: '7px',
        maxHeight: '40px',
        minWidth: '78px',
        minHeight: '40px',
    },

    largeChooserStyle: {
        marginLeft: '20px',
        paddingLeft: '10px',
        paddingRight: '10px',
        width: '240px',
        height: '60px',
        border: '5px solid #143349',
        boxSizing: 'border-box',
        borderRadius: '10px',
        maxWidth: '300px',
        maxHeight: '40px',
        minWidth: '78px',
        minHeight: '40px',
    },

    optionsTitle: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '30px',
        lineHeight: '22px',
        paddingBottom: '8px',
        color: '#35323C',
    },

    button: {
        backgroundColor: 'primary',
        fontColor: 'white',
        color: 'white',
        borderRadius: 4,
        marginRight: '10px',
        textTransform: 'none',
    },

    topBarbutton: {
        backgroundColor: 'primary',
        fontColor: 'white',
        color: 'white',
        borderRadius: 4,
        marginRight: '10px',
        textTransform: 'none',
        marginLeft: '10px',
        marginTop: '5px',
    },

    saveButton: {
        backgroundColor: '#143349',
        fontColor: 'white',
        color: 'white',
    },

    languageForm: {
        marginBottom: '50px',
    },
    grow: {
        flexGrow: 1,
    },
    leftPanel: {
        width: '100%',
    },
    rightPanel: {
        height: '20px',
        float: 'right',
    },
    draw: {
        width: '3000px',
    },
    drawPhone: {
        width: '500px',
    },
    drawGrid: {
        width: 'auto',
    },
    dialog: {
        minWidth: '1000px',
    },
    dialogTitle: {
        backgroundColor: '#143349',
        fontColor: 'white',
    },
    dialogTitleDiv: {
        display: 'flex',
    },
    actionButtons: {
        marginTop: '10px',
    },
    root: {
        overflow: 'hidden',
    },

    menuItem: {
        height: 35,
    },

    menuItemChild: {
        height: 40,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
}))

const edgeTypes = {
    buttonedge: ButtonEdgeSmart,
}

const LessonBuilderController = (props) => {
    const [currentX, setCurrentX] = useState(1)
    const [currentY, setCurrentY] = useState(200)
    const [nodes, setNodes] = useState([])
    const [edges, setEdges] = useState([])
    const [toggleMultiSelect, setToggleMultiSelect] = useState([])
    const [canPerformCollabCheck, setCanPerformCollabCheck] = useState(false)

    const [currentDraggedNodeId, setCurrentDraggedNodeId] = useState(null)

    const [step, setStep] = useState(280)
    const [showEditNode, setShowEditNode] = useState(false)
    const [currentNode, setCurrentNode] = useState(null)
    const [currentNodeObject, setCurrentNodeObject] = useState(null)
    const [nodeToDrag, setNodeToDrag] = useState(null)
    const nodeToDragRef = useRef()
    nodeToDragRef.current = nodeToDrag

    const [askingForImages, setAskingForImages] = useState(false)
    const [isAudioDownloading, setIsAudioDownloading] = useState(false)
    const [scenarioImages, setScenarioImages] = useState([])

    const [currentNodeUploadImageHolder, setCurrentNodeUploadImageHolder] = useState(null)

    const [displayComments, setDisplayComments] = useState(false)
    const [displayAuthorAddComments, setDisplayAuthorAddComments] = useState(false)

    const [selectedNodeAudioTrackers, setSelectedNodeAudioTrackers] = useState([])

    const [deepLinksOpen, setDeepLinksOpen] = useState(false)

    let nodeAudioTracker = {
        // interface
        trackerId: '',
        audioFieldType: AudioFieldType.NotDefined,
        audioNiceName: '',
        audioBlob: '',
        audioUrl: '',
        audioBlobUrl: '',
        audioFileExtension: '',
        testOptionId: null,
        optionId: null,
        fileReferenceId: null,
        localFile: false,
        recordedInCap: false,
    }

    const [currentPlayAudioBlobUrl, setCurrentPlayAudioBlobUrl] = useState(null)

    const [currentAudioTracker, setCurrentAudioTracker] = useState(null)

    const [openOptions, setOpenOptions] = React.useState(false)
    const [openSupportedLanguages, setOpenSupportedLanguages] = React.useState(false)
    const [openRevertModal, setOpenRevertModal] = React.useState(false)
    const [openDataPickerPanel, setOpenDataPickerPanel] = React.useState(false)
    const openDataPickerPanelStateRef = useRef()
    openDataPickerPanelStateRef.current = openDataPickerPanel
    const [openPlayErrorHelp, setOpenPlayErrorHelp] = React.useState(false)
    const [savingScenarioOptions, setSavingScenarioOptions] = useState(false)
    const [openAddNodes, setOpenAddNodes] = useState(false)
    const [isSketchModeSelected, setIsSketchModeSelected] = useState(false)

    const [topicId, setTopicId] = useState(null)
    const [parentNodeId, setParentNodeId] = React.useState(null)
    const [selectedScenario, setSelectedScenario] = React.useState(null)

    // publishing logic
    const [checkForCanBePublished, setCheckForCanBePublished] = React.useState(false)
    const [canBePublished, setCanBePublished] = React.useState(false)

    const selectedScenarioStateRef = useRef()
    selectedScenarioStateRef.current = selectedScenario

    const currentNodeObjectStateRef = useRef()
    currentNodeObjectStateRef.current = currentNodeObject

    const canPerformCollabCheckStateRef = useRef()
    canPerformCollabCheckStateRef.current = canPerformCollabCheck

    // delete stage confirmation
    const [showStageDeleteConfirmation, setShowStageDeleteConfirmation] = useState(false)
    const [nodeToDeleteAfterConfirmation, setNodeToDeleteAfterConfirmation] = useState(false)

    const [externalServiceIsBusy, setExternalServiceIsBusy] = useState(false)
    const [hasLoadedOnce, setHasLoadedOnce] = useState(false)

    const { computedMatch } = props
    const topicIdFromParams = computedMatch.params.topicId
    const [noiseSuppression, setNoiseSuppression] = React.useState(false)

    const [openai, setOpenai] = useState(null)

    if (topicId === null && topicIdFromParams) {
        setTopicId(Math.floor(topicIdFromParams))
    }
    const subtitleTypes = ['No subtitles', 'L1 subtitles', 'L2 subtitles']

    const deepLinkTypes = ['Brief stage and End stage', 'Brief stage', 'End stage']

    const stateRef = useRef()
    stateRef.current = nodes
    const stateRefCurrentY = useRef()
    stateRefCurrentY.current = currentY
    const stateRefCollab = useRef()
    stateRefCollab.current = canPerformCollabCheck

    // lessonPreview

    const [showLessonPreview, setShowLessonPreview] = useState(false)
    const playFromNodeId = useRef(null)

    // useEffect(() => {
    //   const {computedMatch, organization} = props;
    //
    //   const customLessonId = computedMatch.params.customLessonId;
    //
    //   const interval = setInterval(() => {
    //     if(stateRefCollab.current === true) {
    //       props.dispatch(lessonActions.checkForChanges(customLessonId, organization.selectedOrganization));
    //     }
    //   }, 3000);
    //   return () => clearInterval(interval);
    // }, []);

    useEffect(() => {
        const { computedMatch, organization } = props
        const customLessonId = computedMatch.params.customLessonId
        const topicIdFromParams = computedMatch.params.topicId

        const openAiCert = props.user.info?.credentialsDtos?.find(
            (x) => x.adminUserCredentialType === AdminUserCredentialType.OpenAI
        )

        if (openAiCert && !openai) {
            const tempOpenai = new OpenAI({
                // obviously this should be moved to backend, still in here from initial experiment last spring
                apiKey: openAiCert.apiKey,
                dangerouslyAllowBrowser: true,
            })
            setOpenai(tempOpenai)
        }

        if (customLessonId) {
            props.dispatch(lessonActions.getScenario(customLessonId, organization.selectedOrganization))
            props.dispatch(lessonActions.getCustomLessonSupportedLanguages(customLessonId))
            props.dispatch(lessonActions.getLessonVersions(customLessonId))
        }

        if (topicIdFromParams) {
            setTopicId(topicIdFromParams)
            if (props.user?.organizationRole === 'admin') {
                props.dispatch(topicActions.getTopicDataPickerForLessonData(topicIdFromParams))
                // props.dispatch(topicActions.getTopicDataPickerForNLP(topicIdFromParams));
                // props.dispatch(topicActions.getTopicDataPickerForNLPDistractors(topicIdFromParams));
                // props.dispatch(topicActions.getTopicDataPickerForSentenceSuggestion(topicIdFromParams));
            }
        }
        props.dispatch(lessonActions.getLessonAudioReferences(organization.selectedOrganization, customLessonId))

        // set can edit lesson
        props.dispatch(lessonActions.lessonBuilderCanEditLesson(false))

        if (props.lesson.lessonBuilderViewMode === undefined) {
            props.dispatch(lessonActions.lessonBuilderViewMode(ToggleModeViewTypes.NormalEdit))
        }

        if (props.lesson.lessonBuilderViewMode === ToggleModeViewTypes.SketchMode) {
            setIsSketchModeSelected(true)
        } else {
            setIsSketchModeSelected(false)
        }
        return function cleanup() {
            props.dispatch(lessonActions.generalReset())
        }
    }, [])

    useEffect(() => {
        let tempNodes = nodes //[...nodes])
        if (selectedScenarioStateRef.current && selectedScenarioStateRef.current.scenarioNodes)
            selectedScenarioStateRef.current.scenarioNodes = tempNodes
    }, [nodes])

    const reactFlowWrapper = useRef(null)

    useEffect(() => {
        const { computedMatch, organization } = props
        if (props.lesson.savingNode === false) {
            setShowEditNode(false)
            setCanPerformCollabCheck(true)
            setCurrentNodeObject(null)
            setCurrentNode(null)
            setCurrentAudioTracker(null)
            setSelectedNodeAudioTrackers([])
        }
    }, [props.lesson.savingNode])

    useEffect(() => {
        const { computedMatch } = props
        if (
            !props.lesson.selectedScenario ||
            props.lesson.selectedScenario.id !== computedMatch.params.customLessonId
        ) {
            return
        }

        let toggleViewMode =
            props.lesson.lessonBuilderViewMode > 0 ? props.lesson.lessonBuilderViewMode : ToggleModeViewTypes.NormalEdit

        setSelectedScenario(props.lesson.selectedScenario)
        let tempNodes = JSON.parse(JSON.stringify(props.lesson.selectedScenario.scenarioNodes)) //[...nodes])
        tempNodes.forEach((tempNode) => {
            let newData = {}
            newData.onDuplicate = onDuplicate
            newData.onAddOther = onAddOther
            newData.onAdd = onAdd
            newData.onSuggest = onSuggest
            newData.onUpdateSelection = onUpdateSelection
            newData.onUpdateAnswerText = onUpdateAnswerText
            newData.onUpdateSelectedNode = handleUpdateSelectedNode
            newData.onPlayAppFromNode = handlePlayAppFromNode
            newData.onDeleteNode = handleDeleteFromNode
            newData.onAddAlternative = onAddAlternative
            if (currentNodeObjectStateRef) {
                newData.currentNodeObject = currentNodeObjectStateRef.nodeId
            }
            tempNode.data.onUpdateSelectedNode = handleUpdateSelectedNode
            tempNode.data.onUpdateAnswerText = onUpdateAnswerText
            tempNode.data.onPlayAppFromNode = handlePlayAppFromNode
            tempNode.data.onAddAlternative = onAddAlternative
            tempNode.type = getNodeTypeForToggleViewModeType(tempNode.type, toggleViewMode)
            if (currentNodeObjectStateRef) {
                newData.currentNodeObject = currentNodeObjectStateRef.nodeId
            }
            newData.canEditLesson = props.lesson.canEditLesson
            tempNode.data = {
                ...tempNode.data,
                ...newData,
            }

            if (
                toggleViewMode === ToggleModeViewTypes.DisplayTemplate &&
                tempNode.backgroundImageUrl &&
                !tempNode.data.imageUrl
            ) {
                scenarioImages.forEach((result) => {
                    if (tempNode.backgroundImageUrl === 'image/file/' + result.imageReferenceId) {
                        let fileUrlurlSpeak = URL.createObjectURL(result.image)
                        tempNode.data.imageUrl = fileUrlurlSpeak //result.imageUrl
                    }
                })
            }
        })
        setNodes(tempNodes)

        setEdgesWithCanEditLesson(props.lesson.selectedScenario.scenarioEdges)

        if (
            (props.user?.organizationRole === 'admin' || props.user?.organizationRole === 'teacher') &&
            props.lesson.canEditLesson &&
            props.lesson.validated &&
            !props.lesson.selectedScenario.published
        ) {
            setCanBePublished(false)
            setCheckForCanBePublished(true)
        }
    }, [props.lesson.selectedScenario])

    useEffect(() => {
        if (checkForCanBePublished && !canBePublished) {
            const customLessonId = computedMatch.params.customLessonId

            const interval = setInterval(() => {
                if (stateRefCollab.current === true) {
                    props.dispatch(lessonActions.canPublishScenario(customLessonId))
                }
            }, 3000)
            return () => clearInterval(interval)
        }
    }, [checkForCanBePublished])

    useEffect(() => {
        if (props.lesson.canPublishCurrentScenario) {
            setCheckForCanBePublished(false)
            setCanBePublished(true)
        }
    }, [props.lesson.canPublishCurrentScenario])

    useEffect(() => {
        if (props.lesson.latestVersionIsPublished) {
            setCheckForCanBePublished(false)
            setCanBePublished(false)
        }
    }, [props.lesson.latestVersionIsPublished])

    const setEdgesWithCanEditLesson = (scenarioEdges) => {
        if (!scenarioEdges) {
            return
        }
        var tempEdges = scenarioEdges
        tempEdges.forEach((tempEdge) => {
            tempEdge.data.showLabels = props.lesson.canEditLesson
        })
        setEdges(tempEdges)
    }

    useEffect(() => {
        const { computedMatch, organization } = props
        const customLessonId = computedMatch.params.customLessonId
        if (props.lesson.reloadingNodes) {
        }
    }, [props.lesson.reloadingNodes])

    useEffect(() => {
        if (props.lesson.updatedPosition === true) {
            let tempScenarioNodes = props.lesson.selectedScenario.scenarioNodes
            tempScenarioNodes.forEach((tempNode) => {
                if (tempNode.data) {
                    tempNode.data.onDuplicate = onDuplicate
                    tempNode.data.onAddOther = onAddOther
                    tempNode.data.onAdd = onAdd
                    tempNode.data.onSuggest = onSuggest
                    tempNode.data.onUpdateSelection = onUpdateSelection
                    tempNode.data.onUpdateAnswerText = onUpdateAnswerText
                    tempNode.data.onUpdateSelectedNode = handleUpdateSelectedNode

                    tempNode.data.onPlayAppFromNode = handlePlayAppFromNode
                    tempNode.data.onAddAlternative = onAddAlternative
                    if (currentNodeObjectStateRef) {
                        tempNode.data.currentNodeObject = currentNodeObjectStateRef.nodeId
                    }
                }
            })
            setCanPerformCollabCheck(true)
        }
    }, [props.lesson.updatedPosition])

    useEffect(() => {
        const { organization } = props

        if (props.lesson.selectedScenario === null || props.lesson.completedScenarioDownload === false) {
            return
        } else {
            let toggleViewMode =
                props.lesson.lessonBuilderViewMode > 0
                    ? props.lesson.lessonBuilderViewMode
                    : ToggleModeViewTypes.NormalEdit

            let isPreviouslyEdited = false
            let tempScenarioNodes = props.lesson.selectedScenario.scenarioNodes
            tempScenarioNodes.forEach((tempNode) => {
                let newData = {}
                tempNode.position = tempNode.automaticPosition
                tempNode.type = getNodeTypeForToggleViewModeType(tempNode.type, toggleViewMode)

                newData.onDuplicate = onDuplicate
                newData.onAddOther = onAddOther
                newData.onAdd = onAdd
                newData.onSuggest = onSuggest
                newData.onUpdateSelection = onUpdateSelection
                newData.onUpdateAnswerText = onUpdateAnswerText
                newData.onUpdateSelectedNode = handleUpdateSelectedNode

                newData.onPlayAppFromNode = handlePlayAppFromNode
                newData.onDeleteNode = handleDeleteFromNode

                newData.onAddAlternative = onAddAlternative
                if (currentNodeObjectStateRef) {
                    newData.currentNodeObject = currentNodeObjectStateRef.nodeId
                }

                newData.canEditLesson = props.lesson.canEditLesson

                tempNode.data = {
                    ...tempNode.data,
                    ...newData,
                    //currentNodeObject: currentNodeTemp.nodeId,
                }

                // set all existing images first if not alredy set on the node data
                if (toggleViewMode === ToggleModeViewTypes.DisplayTemplate && !tempNode.data.imageUrl) {
                    scenarioImages.forEach((result) => {
                        if (tempNode.backgroundImageUrl === 'image/file/' + result.imageReferenceId) {
                            let fileUrlurlSpeak = URL.createObjectURL(result.image)
                            tempNode.data.imageUrl = fileUrlurlSpeak
                        }
                    })
                }
            })
            tempScenarioNodes.forEach((n) => {
                // if the user has manually edit the node positions previously...
                if (n.xPos !== 0 && n.yPos !== 0 && n.scenarioNodeType) isPreviouslyEdited = true
                if (n.xPos !== 0 && n.yPos !== 0 && n.scenarioNodeType) isPreviouslyEdited = true
            })

            {
                tempScenarioNodes.forEach((n, index) => {
                    if (n.scenarioNodeType && n.xPos && n.yPos) {
                        if (isPreviouslyEdited) {
                            n.position.x = n.xPos
                            n.position.y = n.yPos
                        } else {
                            n.position = n.automaticPosition
                            n.xPos = n.automaticPosition.x
                            n.yPos = n.automaticPosition.y
                        }
                    }
                })
            }

            setNodes(tempScenarioNodes)
            setEdgesWithCanEditLesson(props.lesson.selectedScenario.scenarioEdges)
            let scenarioImageReferences = getScenarioImageReferences(props.lesson.selectedScenario.scenarioNodes)
            // TODO set a spinner on the images graphic for each node graphic
            const promises = scenarioImageReferences.map(
                (imageReferenceId) =>
                    new Promise((resolve, reject) => {
                        setAskingForImages(true)
                        makeGetRequest(
                            'admin/organization/' +
                                organization.selectedOrganization +
                                '/image/file/reference/' +
                                imageReferenceId +
                                '/base64',
                            configWithAuth()
                        )
                            .then((result) => {
                                let type = null
                                const mimeTypeImage = ExtractMimeType(result.data)
                                switch (mimeTypeImage) {
                                    case 'image/jpeg':
                                        type = 'jpeg'
                                        break
                                    case 'image/png':
                                        type = 'png'
                                        break
                                    default:
                                        type = 'unknown'
                                        break
                                }
                                var binary = convertDataURIToBinary(result.data)
                                var blob = new Blob([binary], { type: type })
                                var blobUrl = URL.createObjectURL(blob)
                                resolve({
                                    type: type,
                                    image: blob,
                                    imageUrl: blobUrl,
                                    imageReferenceId: imageReferenceId,
                                })
                            })
                            .catch((error) => {
                                console.log('error', error)
                                return reject(error)
                            })
                    })
            )
            Promise.all(promises).then((results) => {
                let arrayCombined = scenarioImages.concat(results)
                arrayCombined = [...new Set([...scenarioImages, ...results])]
                setScenarioImages(arrayCombined)
            })
            if (props.lesson.completedScenarioDownload === true) {
                setHasLoadedOnce(true)
            }
        }
    }, [props.lesson.completedScenarioDownload])

    useEffect(() => {
        // updating the data attribute (which is what the Node engine MUST use) with the new image information
        let nodesTemp = [...nodes]
        nodesTemp.forEach((node) => {
            let updatedBackgroundImage = false
            let updatedDefinitionImage = false
            scenarioImages.forEach((result) => {
                if (
                    updatedBackgroundImage === false &&
                    node.backgroundImageUrl === 'image/file/' + result.imageReferenceId
                ) {
                    var fileUrlurlSpeak = URL.createObjectURL(result.image)
                    node.data.imageUrl = fileUrlurlSpeak //result.imageUrl
                    updatedBackgroundImage = true
                }

                if (
                    updatedDefinitionImage === false &&
                    node.testOptions &&
                    node.testOptions.length > 0 &&
                    node.testOptions[0].imageUrl === 'image/file/' + result.imageReferenceId
                ) {
                    var fileUrlurlSpeak = URL.createObjectURL(result.image)
                    node.data.testOptionImageUrl = fileUrlurlSpeak //result.imageUrl
                    updatedDefinitionImage = true
                }
            })
        })
        setNodes(nodesTemp)
        setEdgesWithCanEditLesson(props.lesson.selectedScenario?.scenarioEdges)
    }, [scenarioImages])

    useEffect(() => {
        if (props.lesson.lessonAudioReferences && currentNode) {
            let nodeAudioTrackers = []

            props.lesson.lessonAudioReferences.forEach((audioReference) => {
                if (audioReference.apiUrl === nodes[currentNode].backgroundAudioUrl) {
                    let newAudioObject = {}
                    newAudioObject.trackerId = uuidv4()
                    newAudioObject.audioFileExtension = AudioFieldType.BackgroundAudioUrl
                    newAudioObject.audioUrl = audioReference.apiUrl
                    newAudioObject.audioNiceName = audioReference.fileName
                    nodeAudioTrackers.push(newAudioObject)
                } else if (audioReference.apiUrl === nodes[currentNode].sfxAudioUrl) {
                    let newAudioObject = {}
                    newAudioObject.trackerId = uuidv4()
                    newAudioObject.audioFileExtension = AudioFieldType.SfxAudioUrl
                    newAudioObject.audioUrl = audioReference.apiUrl
                    newAudioObject.audioNiceName = audioReference.fileName
                    nodeAudioTrackers.push(newAudioObject)
                }
            })
            setSelectedNodeAudioTrackers(nodeAudioTrackers)
        }
    }, [currentNode])

    const { getViewport, setViewport } = useReactFlow()
    const handleTransform = useCallback(
        (type, position) => {
            let currentViewport = getViewport()
            if (type === 'in') {
                setViewport(
                    {
                        x: 30,
                        y: currentViewport.zoom < 0.3 ? 80 : 30,
                        zoom: currentViewport.zoom > 0.4 ? 0.4 : currentViewport.zoom,
                    },
                    { duration: 800 }
                )
            } else if (type === 'out') {
                setViewport(
                    {
                        x: currentViewport.x,
                        y: currentViewport.y,
                        zoom: currentViewport.zoom * 1.1,
                    },
                    { duration: 800 }
                )
            } else if (type === 'pin') {
                setViewport(
                    {
                        x: position.x * currentViewport.zoom * -1 + Math.min(60 / currentViewport.zoom, 350),
                        y: currentViewport.y,
                        zoom: currentViewport.zoom,
                    },
                    { duration: 800 }
                )
            }
        },
        [setViewport]
    )

    const onDuplicate = (nodeId, type) => {
        // todo - remove backgroundimage url from testimage stage
        if (!props.lesson.canEditLesson) {
            return
        }
        setOpenAddNodes(false)
        handleDuplicateStage(nodeId, type, false)
    }

    const onAdd = (nodeId, type) => {
        if (!props.lesson.canEditLesson) {
            return
        }
        handleDuplicateStage(nodeId, type, true)
    }

    const handleGoToTopic = () => {
        const courseGroupId = props.computedMatch.params.courseGroupId
        const topicId = props.computedMatch.params.topicId
        history.push('/creator/course/group/' + courseGroupId + '/topic/' + topicId)
    }

    const getNodeTextRecursive = (nodes, depth, nodeId, existingText, initialDepth, subSearchDepth) => {
        let text = existingText
        let found = false
        let localSubSearchDepth = subSearchDepth

        nodes.forEach((n) => {
            if (
                (n.type === 2 || n.type === 3) &&
                n.options &&
                n.options.length > 0 &&
                n.options[0].nextScenarioNodeId === nodeId
            ) {
                let character = n.type === 2 ? 'Man: ' : 'Woman: '
                text.push({
                    text: n.options[0].text,
                    descriptiveType: character,
                    type: n.type,
                })
                found = true
                localSubSearchDepth = localSubSearchDepth - 1
            } else if (
                n.type === ScenarioNodeType.TestDefinition &&
                n.options[0].nextScenarioNodeId === nodeId &&
                initialDepth === localSubSearchDepth
            ) {
                text.push({
                    text: n.testOptions[1].text,
                    descriptiveType: 'Background Information:',
                    type: n.type,
                })
                found = true
            } else if (
                n.type === ScenarioNodeType.Instruction &&
                n.options[0].nextScenarioNodeId === nodeId &&
                initialDepth === localSubSearchDepth
            ) {
                text.push({
                    text: n.testOptions[2].text,
                    descriptiveType: 'Background Information:',
                    type: n.type,
                })
                found = true
            } else if (
                n.type === ScenarioNodeType.TestImage &&
                n.options[0].nextScenarioNodeId === nodeId &&
                initialDepth === localSubSearchDepth
            ) {
                text.push({
                    text: n.testOptions[2].text,
                    descriptiveType: 'Background Information:',
                    type: n.type,
                })
                found = true
            } else if (
                n.type === ScenarioNodeType.TestRule &&
                n.options[0].nextScenarioNodeId === nodeId &&
                initialDepth === localSubSearchDepth
            ) {
                // to do: check the language is the L2?
                n.testOptions.forEach((to) => {
                    text.push({
                        text: to.text,
                        descriptiveType: 'Background Information:',
                        type: n.type,
                    })
                })
                found = true
            } else found = false
            if (depth > 1 && found === true) {
                text = getNodeTextRecursive(nodes, depth - 1, n.nodeId, text, initialDepth, localSubSearchDepth)
            }
        })
        return text
    }

    const onAddAlternative = (nodeId) => {
        if (!props.lesson.canEditLesson) {
            return
        }
        const { computedMatch } = props

        let scenarioL2LanguageId = selectedScenario.languageId
        let nodesTemp = selectedScenarioStateRef.current.scenarioNodes //[...nodes]
        let node = null
        nodesTemp.forEach((tempNode) => {
            if (nodeId === tempNode.nodeId) {
                node = tempNode
                let dataOptions = tempNode.data.options
                dataOptions.push('')
                tempNode.data = {
                    ...tempNode.data,
                    options: dataOptions,
                    newlyAdded: true,
                    //currentNodeObject: currentNodeTemp.nodeId,
                }
                tempNode.options.push(addOption('', 0, null))
            }
            tempNode.data.onDuplicate = onDuplicate
            tempNode.data.onAddOther = onAddOther
            tempNode.data.onAdd = onAdd
            tempNode.data.onSuggest = onSuggest
            tempNode.data.onUpdateSelection = onUpdateSelection
            tempNode.data.onUpdateAnswerText = onUpdateAnswerText
            tempNode.data.onUpdateSelectedNode = handleUpdateSelectedNode

            tempNode.data.onPlayAppFromNode = handlePlayAppFromNode
            tempNode.data.onAddAlternative = onAddAlternative
        })

        setNodes(nodesTemp)
        const customLessonId = computedMatch.params.customLessonId
        let nodesFinal = []
        nodesTemp.forEach((n) => {
            // force ReactNode to realise changes require rerender.
            nodesFinal.push(n)
        })

        setNodes(nodesFinal)
    }

    const handleDataPickerSidebarClose = () => {
        setOpenDataPickerPanel(false)
        setNodes(nodes.filter((x) => x.type !== ScenarioNodeType.Pin))
        handleTransform('out')
        setOpenDataPickerPanel(false)
    }

    const handleGetLessonStages = (id) => {
        //Todo FIXME next release
        //props.dispatch(topicActions.getTopicDataPickerForLessonData(topicIdFromParams));
    }

    const handleUpdateSelectedNode = () => {
        //Todo FIXME needed for support for other node types in sketch mode (future release)
    }

    const handleSetPlayFromNodeIdDefaultValue = () => {
        if (playFromNodeId.current === null) {
            return selectedScenario.nodeId
        }

        return playFromNodeId.current
    }

    const onUpdateAnswerText = (text, index, nodeId, failure, scoreAlteration, scoreReasoning) => {
        if (!props.lesson.canEditLesson) {
            return
        }
        let scenarioL2LanguageId = selectedScenario.languageId
        const { computedMatch } = props
        let nodesTemp = selectedScenarioStateRef.current.scenarioNodes //[...nodes]
        nodesTemp.forEach((tempNode) => {
            if (nodeId === tempNode.nodeId) {
                let tempOptions = tempNode.options
                if (index >= tempNode.options.length) {
                    tempNode.data.options.push(text)
                    tempNode.options.push(addOption(text, 0, null))
                } else {
                    if (text !== null) {
                        //delete tempNode.options[index]
                        let dataOptions = tempNode.data.options
                        //delete dataOptions[index]

                        tempNode.options[index].text = text
                        tempNode.options[index].textLanguageId = scenarioL2LanguageId
                        dataOptions[index] = text
                        tempNode.data = {
                            ...tempNode.data,
                            options: dataOptions,
                            //currentNodeObject: currentNodeTemp.nodeId,
                        }
                        delete tempNode.options[index].textAudioUrl
                    }
                }
            }
            tempNode.data.onDuplicate = onDuplicate
            tempNode.data.onAddOther = onAddOther
            tempNode.data.onAdd = onAdd
            tempNode.data.onSuggest = onSuggest
            tempNode.data.onUpdateSelection = onUpdateSelection
            tempNode.data.onUpdateAnswerText = onUpdateAnswerText
            tempNode.data.onUpdateSelectedNode = handleUpdateSelectedNode
            tempNode.data.onPlayAppFromNode = handlePlayAppFromNode
            tempNode.data.onAddAlternative = onAddAlternative
        })

        let nodesFinal = []
        nodesTemp.forEach((n) => {
            // force ReactNode to realise changes require rerender.
            nodesFinal.push(n)
        })
        setNodes(nodesFinal)
    }

    const onSuggest = (nodeId, optionIndex = 0, shouldSaveToServer = true) => {
        if (!props.lesson.canEditLesson) {
            return
        }

        if (!!!canPerformCollabCheck || props.lesson.isBusy) {
            return
        }
        props.dispatch(lessonActions.lessonBuilderBusy(true))
        setCanPerformCollabCheck(false)

        let gptString = ''
        let lastQuestion = ''
        let containsInformation = false
        let nodeToUpdate = null
        let stop = ''
        let searchTextArray = getNodeTextRecursive(stateRef.current, 5, nodeId, [], 5, 5)
        searchTextArray.forEach((text) => {
            switch (text.type) {
                case ScenarioNodeType.TestDefinition:
                    containsInformation = true
                    break
                case ScenarioNodeType.TestImage:
                    containsInformation = true
                    break
                case ScenarioNodeType.TestRule:
                    containsInformation = true
                    break
            }
        })

        if (containsInformation) {
            gptString +=
                'Continue the conversation between two people. Make reference to subjects related to the Background Information. Continue in the same language.\n'
        } else {
            gptString += 'Continue the conversation between two people.\n'
        }
        searchTextArray.reverse().forEach((text) => {
            switch (text.type) {
                case ScenarioNodeType.TestDefinition:
                    gptString += text.descriptiveType + ' ' + text.text + '.\n'
                    break
                case ScenarioNodeType.TestImage:
                    gptString += text.descriptiveType + ' ' + text.text + '.\n'
                    break
                case ScenarioNodeType.TestRule:
                    gptString += text.descriptiveType + ' ' + text.text + '.\n'
                    break
                case ScenarioNodeType.Listen:
                    gptString += text.descriptiveType + ' ' + text.text + '\n'
                    break
                case ScenarioNodeType.Speak:
                    gptString += text.descriptiveType + ' ' + text.text + '\n'
                    break
                case ScenarioNodeType.BranchingMultiChoice:
                    gptString += text.descriptiveType + ' ' + text.text + '\n'
                    break
            }
        })
        stateRef.current.forEach((node) => {
            if (node.nodeId === nodeId) {
                nodeToUpdate = node
                if (node.type === 2) {
                    lastQuestion = '\nMan: '
                }
                if (node.type === 3) {
                    lastQuestion = '\nWoman: '
                }
            }
        })
        stop = ['Man:', 'Background Information:', 'Male', 'Woman:']
        gptString += lastQuestion
        // openai.createCompletion("text-davinci-001", {
        //   prompt: gptString,
        //   temperature: 0.8,
        //   max_tokens: 35, //60,
        //   top_p: 1,
        //   frequency_penalty: 0,
        //   presence_penalty: 0,
        //   stop: stop,
        // })
        // .then((response) => {
        //   let text = response.data.choices[0].text;
        //     if(shouldSaveToServer === true) {
        //       nodeToUpdate.options[optionIndex].text = text.trim()
        //       if(nodeToUpdate !== null) {
        //         handleUpdateScenarioNode(nodeToUpdate, props.organization.organizationId, null) // setCurrentNodeObject(currentNodeObjectTemp)
        //       }
        //     } else {
        //       let currentNodeObjectTemp = JSON.parse(JSON.stringify(currentNodeObject));
        //
        //       if (optionIndex >= (currentNodeObjectTemp.options.length)) {
        //         currentNodeObjectTemp.data.options.push(text.trim())
        //         currentNodeObjectTemp.options.push(addOption(text.trim(), 0, null))
        //       } else {
        //         if (text !== null) {
        //           currentNodeObjectTemp.options[optionIndex].text = text.trim()
        //           currentNodeObjectTemp.options[optionIndex].textLanguageId = selectedScenario.languageId;;
        //         }
        //     }
        //     setCanPerformCollabCheck(true)
        //     props.dispatch(lessonActions.lessonBuilderBusy(false));
        //     setCurrentNodeObject(currentNodeObjectTemp)
        //   }
        // })
    }

    const onAddOther = (nodeId) => {
        if (!props.lesson.canEditLesson) {
            return
        }
        setParentNodeId(nodeId)
        setOpenAddNodes(true)
    }

    const handleDisableCollabCheck = (canPerform) => {
        setCanPerformCollabCheck(canPerform)
    }

    const getScenarioImageReferences = (nodes) => {
        let scenarioImageReferences = []
        nodes.map((node) => {
            if (
                node.backgroundImageUrl &&
                !scenarioImageReferences.includes(node.backgroundImageUrl.replace('image/file/', ''))
            ) {
                let shouldAdd = true
                scenarioImages.forEach((existingImageDownload) => {
                    if (existingImageDownload.imageReferenceId === node.backgroundImageUrl.replace('image/file/', '')) {
                        shouldAdd = false
                    }
                })
                if (shouldAdd) {
                    scenarioImageReferences.push(node.backgroundImageUrl.replace('image/file/', ''))
                }
            }
            if (
                node.testOptions &&
                node.testOptions.length > 0 &&
                node.testOptions[0].imageUrl &&
                !scenarioImageReferences.includes(node.testOptions[0].imageUrl.replace('image/file/', ''))
            ) {
                let shouldAdd = true
                scenarioImages.forEach((existingImageDownload) => {
                    if (
                        existingImageDownload.imageReferenceId ===
                        node.testOptions[0].imageUrl.replace('image/file/', '')
                    ) {
                        shouldAdd = false
                    }
                })
                if (shouldAdd) {
                    scenarioImageReferences.push(node.testOptions[0].imageUrl.replace('image/file/', ''))
                }
            }
        })
        return scenarioImageReferences
    }

    const onEdgesChange = useCallback((changes) => {
        if (showEditNode === true) return
        setCanPerformCollabCheck(false)
        let elementsToRemove = null
        changes.forEach((newChange) => {
            edges.forEach((edge) => {
                if (newChange.selected === true && newChange.id === edge.id) {
                    elementsToRemove = edge
                }
            })
        })

        if (!props.lesson.canEditLesson) {
            return
        }
        let nodesTemp = JSON.parse(JSON.stringify(nodes)) //[...nodes])
        let newNodes = []
        let nodeTemp = null
        nodesTemp.forEach((node) => {
            if (
                !!!(
                    node.id === elementsToRemove.id ||
                    node.nodeId === 'edge' + elementsToRemove.id ||
                    node.target === elementsToRemove.id ||
                    node.source === elementsToRemove.id
                )
            ) {
                newNodes.push(node)
            }
            if (node.options) {
                node.options.forEach((option, index) => {
                    if ('exit' + index === elementsToRemove.sourceHandle) {
                        if (
                            node.id === elementsToRemove.source &&
                            option.nextScenarioNodeId === elementsToRemove.target
                        ) {
                            option.nextScenarioNodeId = null
                        }
                        if (
                            node.id === elementsToRemove.source &&
                            option.nextScenarioNodeId === elementsToRemove.target
                        ) {
                            nodeTemp = JSON.parse(JSON.stringify(node)) //[...nodes])
                        }
                    }
                    if (option.nextScenarioNodeId === elementsToRemove.id) {
                        option.nextScenarioNodeId = null
                    }
                    if (option.target === elementsToRemove.id) {
                        option.target = null
                    }
                })
            }
        })
        setNodes(newNodes)
        if (nodeTemp) {
            //handleUpdateScenarioNode(nodeTemp, organization.selectedOrganization, null, null, true);
        }
        props.dispatch(
            lessonActions.updateScenario(
                {
                    ...selectedScenarioStateRef.current,
                    scenarioNodes: newNodes,
                },
                selectedScenarioStateRef.current.id
            ),
            true,
            true
        )
    })

    const handleToggleMultiSelect = (elementsToRemove, nodes) => {
        setToggleMultiSelect(!!!toggleMultiSelect)
    }

    const onElementsRemove = (elementsToRemove, nodes) => {
        if (!props.lesson.canEditLesson) {
            return
        }
        let nodesTemp = JSON.parse(JSON.stringify(nodes)) //[...nodes])
        let newNodes = []
        if (elementsToRemove[0].type === 'edge') {
            nodesTemp.forEach((node) => {
                if (node.id !== elementsToRemove[0].id) {
                    //newNodes.push(node)
                }
            })
        }
        let nodeTemp = null
        nodesTemp.forEach((node) => {
            if (
                !!!(
                    node.id === elementsToRemove[0].id ||
                    node.nodeId === 'edge' + elementsToRemove[0].id ||
                    node.target === elementsToRemove[0].id ||
                    node.source === elementsToRemove[0].id
                )
            ) {
                newNodes.push(node)
            }
            if (node.options) {
                node.options.forEach((option, index) => {
                    if ('exit' + index === elementsToRemove[0].sourceHandle) {
                        if (
                            node.id === elementsToRemove[0].source &&
                            option.nextScenarioNodeId === elementsToRemove[0].target
                        ) {
                            option.nextScenarioNodeId = null
                        }
                        if (
                            node.id === elementsToRemove[0].source &&
                            option.nextScenarioNodeId === elementsToRemove[0].target
                        ) {
                            nodeTemp = JSON.parse(JSON.stringify(node)) //[...nodes])
                        }
                    }
                    if (option.nextScenarioNodeId === elementsToRemove[0].id) {
                        option.nextScenarioNodeId = null
                    }
                    if (option.target === elementsToRemove[0].id) {
                        option.target = null
                    }
                })
            }
        })
        setNodes(newNodes)
        if (nodeTemp) {
            //handleUpdateScenarioNode(nodeTemp, organization.selectedOrganization, null, null, true);
        }
        props.dispatch(
            lessonActions.updateScenario(
                {
                    ...selectedScenarioStateRef.current,
                    scenarioNodes: newNodes,
                },
                selectedScenarioStateRef.current.id
            )
        )
        //  setNodes((els) => removeElements(elementsToRemove, els)); - Previous method of deleting nodes. But does not take into account our needs
    }

    const handleOnPlayFromUrl = (audioUrl) => {
        // handle play from anywhere and update the audio trackers automatically
        var audioTracker = selectedNodeAudioTrackers.find((x) => x.audioUrl === audioUrl)
        if (!audioTracker) {
            audioTracker = {
                trackerId: uuidv4(),
                audioUrl: audioUrl,
                audioFieldType: AudioFieldType.NotDefined,
                audioNiceName: '',
                audioBlob: '',
                audioBlobUrl: '',
                audioFileExtension: '',
                optionId: null,
                fileReferenceId: null,
            }
            setSelectedNodeAudioTrackers((prevSelectedNodeAudioTrackers) => [
                ...prevSelectedNodeAudioTrackers,
                audioTracker,
            ])
        }
        playAudioTracker(audioTracker)
    }

    const handleOnPlaySelectedAudio = (audioTracker) => {
        playAudioTracker(audioTracker)
    }
    const playAudioTracker = (audioTracker) => {
        if (!audioTracker) {
            return
        }
        if (audioTracker.audioBlobUrl) {
            setCurrentPlayAudioBlobUrl(audioTracker.audioBlobUrl)
            setCurrentAudioTracker(audioTracker)
        } else if (audioTracker.audioUrl) {
            setIsAudioDownloading(true)
            const { organization } = props
            var SOUND_MARKER = 'sound/file/'
            var soundMarkerIndex = audioTracker.audioUrl.indexOf(SOUND_MARKER) + SOUND_MARKER.length
            var audioFileReferenceId = audioTracker.audioUrl.substring(soundMarkerIndex)
            const promise = new Promise((resolve, reject) => {
                makeGetRequest(
                    'admin/organization/' +
                        organization.selectedOrganization +
                        '/audio/file/reference/' +
                        audioFileReferenceId +
                        '/base64',
                    configWithAuth()
                )
                    .then((result) => {
                        const typeAudio = ExtractMimeType(result.data)
                        const selectedAudio = result.data
                        var binary = convertDataURIToBinary(result.data)
                        var thefileAudio = new File([selectedAudio], 'tempNameAudio', {
                            type: typeAudio,
                            lastModified: new Date(),
                        })
                        var blob = new Blob([binary], { type: typeAudio })
                        var blobUrl = URL.createObjectURL(blob)

                        audioTracker.audioBlobUrl = blobUrl
                        audioTracker.fileReferenceId = audioFileReferenceId
                        audioTracker.localFile = false
                        setCurrentAudioTracker(audioTracker)
                        setCurrentPlayAudioBlobUrl(blobUrl)

                        setIsAudioDownloading(false)
                        resolve({
                            type: typeAudio,
                            image: blob,
                            imageUrl: blobUrl,
                        })
                    })
                    .catch((error) => {
                        console.log('error', error)
                        return reject(error)
                    })
            })
        }
    }

    const onConnect = (params) => {
        setCanPerformCollabCheck(false)
        const { computedMatch, organization } = props
        let nodeTemp = null
        let nodesTemp = JSON.parse(JSON.stringify(nodes)) //[...nodes]
        nodesTemp.forEach((el) => {
            if (el.nodeId === params.source) {
                if (el.options.length === 0) {
                    el.options.push(addOption('', 0, params.target))
                } else {
                    switch (params.sourceHandle) {
                        case 'exit0':
                            el.options[0].nextScenarioNodeId = params.target
                            break
                        case 'exit1':
                            if (el.options.length < 2) {
                                el.options.push(addOption('', 0, params.target))
                            } else {
                                el.options[1].nextScenarioNodeId = params.target
                            }
                            break
                        case 'exit2':
                            if (el.options.length < 3) {
                                el.options.push(addOption('', 0, params.target))
                            } else {
                                el.options[2].nextScenarioNodeId = params.target
                            }
                            break
                        default:
                            console.error('Node did not have a recognised Capeesh exit name.')
                    }
                }
                nodeTemp = JSON.parse(JSON.stringify(el)) //[...nodes]
            }
        })
        // setNodes(newNodes)

        if (nodeTemp) {
            handleUpdateScenarioNode(nodeTemp, organization.selectedOrganization, null)
        }
    }

    const onElementClick = (event, node) => {
        setCurrentNodeUploadImageHolder(null)

        if (openDataPickerPanelStateRef.current === true) {
            return
        }
        let tempEdge = edges
        let edgeNodeArray = []
        edgeNodeArray.push('edge' + node.nodeId)
        node.options.forEach((option) => {
            edgeNodeArray.push('edge' + option.nextScenarioNodeId)
        })
        tempEdge.forEach((edge) => {
            edge.data.isOne = 'true'
        })
        setEdges(tempEdge)

        if (isSketchModeSelected) return
        if (props.lesson.isBusy) return

        if (node.position) {
            setCurrentX(node.position.x + step)
            setCurrentY(node.position.y + step)
        }

        if (!!!node.data.isConnector) {
            // user pressed the node open edit node
            setShowEditNode(true)
            nodes.forEach((n, index) => {
                if (n.id === node.id) {
                    let nodeTemp = JSON.parse(JSON.stringify(n)) //[...nodes]
                    setCurrentNode(index)
                    setCurrentNodeObject(nodeTemp)
                }
            })
        }
    }

    const onNodesChange = useCallback(
        (changes) => {
            if (changes.length > 1 && currentDraggedNodeId != null) {
                setNodes((nds) =>
                    applyNodeChanges(
                        changes.filter((x) => x && x.id === currentDraggedNodeId),
                        nds
                    )
                )
            } else {
                setNodes((nds) => applyNodeChanges(changes, nds))
            }
        },
        [setNodes]
    )

    const handleAutoTranslation = async (text, l2, l1) => {
        setExternalServiceIsBusy(true)
        let translationPrompt = GPTPrompt(PromptTypes.Translation, selectedScenario.l1Id, {
            l1Language: selectedScenario.l1Id,
            textToTranslate: text,
            languages: props.metadata.metadata.languages,
            originLanguage: selectedScenario.languageId,
        })

        const response = await openai.chat.completions.create({
            model: 'gpt-4',
            messages: [
                {
                    role: 'system',
                    content: translationPrompt,
                },
            ],
            temperature: 0,
            max_tokens: 256,
            top_p: 1,
            frequency_penalty: 0,
            presence_penalty: 0,
        })
        let textTranslateResult = response.choices[0].message.content.trim('').replace(/['"]+/g, '')
        let currentNodeObjectTemp = JSON.parse(JSON.stringify(currentNodeObject)) //[...nodes]
        switch (currentNodeObject.type) {
            case ScenarioNodeType.TestDefinition:
                currentNodeObjectTemp.testOptions[1].explanation = textTranslateResult
                setCurrentNodeObject(currentNodeObjectTemp)
                break
            case ScenarioNodeType.Listen:
                handleUpdateL1SubtitleText(textTranslateResult)
                break
            case ScenarioNodeType.TestImage:
                currentNodeObjectTemp.testOptions[1].text = textTranslateResult
                setCurrentNodeObject(currentNodeObjectTemp)
                break
            case ScenarioNodeType.Instruction:
                currentNodeObjectTemp.testOptions[2].explanation = textTranslateResult
                setCurrentNodeObject(currentNodeObjectTemp)
                break
        }
        setExternalServiceIsBusy(false)
    }

    const handleNodeDrag = (event, node) => {
        if (props.lesson.isBusy) return
        if (!props.lesson.canEditLesson) {
            return
        }
        if (node) {
            let s = ''
        }

        setCanPerformCollabCheck(false)
    }

    const handleNodeDragStart = (event, node) => {
        if (!props.lesson.canEditLesson) {
            return
        }
        if (node) {
            setCurrentDraggedNodeId(null)
        }
    }

    const handleNodeDragStop = (event, node) => {
        if (node.type === ScenarioNodeType.Pin) {
            handleTransform('pin', node.position)
            return
        }
        if (!props.lesson.canEditLesson) {
            return
        }
        setCurrentDraggedNodeId(node.id)
        // setCanPerformCollabCheck(true)
        const { computedMatch } = props
        const customLessonId = computedMatch.params.customLessonId
        let nodesTemp = JSON.parse(JSON.stringify(nodes)) //[...nodes]
        let nodeTemp = null
        nodesTemp.forEach((n, index) => {
            if (n.id === node.id) {
                n.position = node.position
                n.xPos = node.position.x
                n.yPos = node.position.y
                nodeTemp = n
            }
        })
        //setNodes(nodesTemp)
        //setEdges(props.lesson.selectedScenario.scenarioEdges)
        if (nodeTemp) {
            props.dispatch(lessonActions.updateScenarioNodePosition(customLessonId, nodeTemp))
        }
    }

    const handleImageDrop = (blobUrl, nodeId, uploadImageHolder) => {
        if (!props.lesson.canEditLesson) {
            return
        }

        if (uploadImageHolder.imageFieldType === ImageFieldType.BackgroundImageUrl) {
            // update node with background image url since that is not handled by setting it directly on the test option
            let nodesTemp = JSON.parse(JSON.stringify(nodes)) //[...nodes]
            let currentNodeObjectTemp = JSON.parse(JSON.stringify(currentNodeObject)) //[...nodes]

            nodesTemp.forEach((node) => {
                if (node.nodeId === nodeId) {
                    node.backgroundImageUrl = blobUrl
                    node.data = null
                    node.data = {
                        ...node.data,
                        imageUrl: uploadImageHolder.imageUrl,
                    }
                }
            })
            currentNodeObjectTemp.backgroundImageUrl = blobUrl
            setCurrentNodeObject(currentNodeObjectTemp)
        }

        setCurrentNodeUploadImageHolder(uploadImageHolder)
    }

    const handleMentorSelect = (mentor) => {
        if (!props.lesson.canEditLesson) {
            return
        }
        let currentNodeObjectTemp = JSON.parse(JSON.stringify(currentNodeObject)) //[...nodes]
        let screenObjectDto = {
            normalizedSize: { x: 0.5, y: 0.5 },
            normalizedPosition: { x: 0.5, y: 0.5 },
        }
        let mentorDto = {}
        if (mentor >= 0) {
            if (currentNodeObjectTemp.scenarioMentors && currentNodeObjectTemp.scenarioMentors[0].screenObjectDto) {
                screenObjectDto = currentNodeObjectTemp.scenarioMentors[0].screenObjectDto
            }
            mentorDto = {
                animationLengthInSeconds: 3,
                direction: { x: 0, y: 0, z: 0 },
                mentor: mentor,
                screenObjectDto: screenObjectDto,
            }
            currentNodeObjectTemp.scenarioMentors = [mentorDto]
        } else {
            delete currentNodeObjectTemp.scenarioMentors
        }
        setCurrentNodeObject(currentNodeObjectTemp)
    }

    const handleMentorMove = (x, y) => {
        if (!props.lesson.canEditLesson) {
            return
        }
        x = x.toFixed(3)
        y = y.toFixed(3)
        let currentNodeObjectTemp = JSON.parse(JSON.stringify(currentNodeObject)) //[...nodes]
        currentNodeObjectTemp.scenarioMentors[0].screenObjectDto.normalizedPosition.x = x
        currentNodeObjectTemp.scenarioMentors[0].screenObjectDto.normalizedPosition.y = y
        setCurrentNodeObject(currentNodeObjectTemp)
    }

    const handleUpdateCommentState = (noteType, createdAt) => {
        let currentNodeObjectTemp = JSON.parse(JSON.stringify(currentNodeObject)) //[...nodes]
        let editedComment = null
        currentNodeObjectTemp.authorNoteDtos.forEach((an) => {
            if (moment(createdAt).isSame(moment(an.createdAt))) {
                an.authorNoteType = noteType
                editedComment = an
            }
        })
        setCurrentNodeObject(currentNodeObjectTemp)
        if (editedComment) {
            handleCreateOrEditComment(1, editedComment)
        }
    }

    const handleDeleteComment = (createdAt) => {
        const { computedMatch } = props
        const customLessonId = computedMatch.params.customLessonId
        let currentNodeObjectTemp = JSON.parse(JSON.stringify(currentNodeObject)) //[...nodes]
        let newAuthorComments = []
        setCurrentNodeObject(currentNodeObjectTemp)
        currentNodeObjectTemp.authorNoteDtos.forEach((an, index) => {
            if (an.createdAt !== createdAt) {
                newAuthorComments.push(an)
            }
        })
        currentNodeObjectTemp.authorNoteDtos = newAuthorComments
        setCurrentNodeObject(currentNodeObjectTemp)
        props.dispatch(lessonActions.updateScenarioNodeComment(customLessonId, currentNodeObjectTemp))
    }

    const handleChangeRuleCardDistractor = (distractor, alternative, testOption) => {
        if (!props.lesson.canEditLesson) {
            return
        }
        let currentNodeObjectTemp = JSON.parse(JSON.stringify(currentNodeObject)) //[...nodes]
        currentNodeObjectTemp.testOptions.forEach((to) => {
            if (to.testOptionId === testOption.testOptionId) {
                to.alternatives.forEach((alt) => {
                    if (alt.textSnippetId === alternative.textSnippetId) {
                        alt.text = distractor
                        if (distractor === '') {
                            //FIXME
                        }
                    }
                })
            }
        })
        setCurrentNodeObject(currentNodeObjectTemp)
    }

    const handleTestOptionLanguageTypeChanged = (testOption, testOptionIsInL1) => {
        if (!props.lesson.canEditLesson) {
            return
        }
        let currentNodeObjectTemp = JSON.parse(JSON.stringify(currentNodeObject)) //[...nodes]
        currentNodeObjectTemp.testOptions.forEach((to) => {
            if (to.testOptionId === testOption.testOptionId) {
                if (!testOptionIsInL1 && to.textLanguageId !== selectedScenario.languageId) {
                    to.textSnippetId = null
                    to.textLanguageId = selectedScenario.languageId
                    to.alternatives.forEach((alt) => {
                        alt.textSnippetId = null
                        alt.textLanguageId = selectedScenario.languageId
                    })
                } else if (testOptionIsInL1 && to.textLanguageId !== selectedScenario.l1Id) {
                    to.audioPlayType = 0
                    to.textSnippetId = null
                    to.textLanguageId = selectedScenario.l1Id
                    to.alternatives.forEach((alt) => {
                        alt.textSnippetId = null
                        alt.textLanguageId = selectedScenario.l1Id
                    })
                }
            }
        })
        setCurrentNodeObject(currentNodeObjectTemp)
    }

    const handleToggleRuleCardDistractorCorrect = (testOptionId, alternative) => {
        if (!props.lesson.canEditLesson) {
            return
        }

        let currentNodeObjectTemp = JSON.parse(JSON.stringify(currentNodeObject)) //[...nodes]
        currentNodeObjectTemp.testOptions.forEach((to) => {
            if (to.testOptionId === testOptionId) {
                to.alternatives.forEach((alt) => {
                    if (alt.textSnippetId === alternative.textSnippetId) {
                        alt.correct = !alternative.correct
                    }
                })
            }
        })
        setCurrentNodeObject(currentNodeObjectTemp)
    }

    const handleDeleteAlternativeDistractor = (testOptionId, alternative) => {
        if (!props.lesson.canEditLesson) {
            return
        }
        let currentNodeObjectTemp = JSON.parse(JSON.stringify(currentNodeObject)) //[...nodes]
        let foundAlternativeDistractorToDelete = null
        currentNodeObjectTemp.testOptions.forEach((to) => {
            if (to.testOptionId === testOptionId) {
                let newAlternativesList = []
                to.alternatives.forEach((alt) => {
                    if (alt.textSnippetId !== alternative.textSnippetId) {
                        newAlternativesList.push(alt)
                    }
                })
                to.alternatives = newAlternativesList
            }
        })
        setCurrentNodeObject(currentNodeObjectTemp)
    }

    const handleChangeTestOptionLanguageId = (testOptionId, languageId) => {
        if (!props.lesson.canEditLesson) {
            return
        }
        if (languageId !== selectedScenario.l1Id && languageId !== selectedScenario.languageId) {
            return
        }
        let currentNodeObjectTemp = JSON.parse(JSON.stringify(currentNodeObject)) //[...nodes]
        currentNodeObjectTemp.testOptions.forEach((to) => {
            if (to.testOptionId === testOptionId) {
                to.textLanguageId = languageId
            }
        })
        setCurrentNodeObject(currentNodeObjectTemp)
    }

    const handleChangeTextImageQuestionL1 = (testOptionId, text) => {
        if (!props.lesson.canEditLesson) {
            return
        }
        let currentNodeObjectTemp = JSON.parse(JSON.stringify(currentNodeObject)) //[...nodes]

        currentNodeObjectTemp.testOptions.forEach((to) => {
            if (to.testOptionId === testOptionId) {
                to.text = text
            }
        })
        setCurrentNodeObject(currentNodeObjectTemp)
    }

    const syllableRegex = /[^aeiouy]*[aeiouy]+(?:[^aeiouy]*$|[^aeiouy](?=[^aeiouy]))?/gi
    const syllabify = (words) => {
        if (words.match(syllableRegex) === null) {
            return [words]
        }
        return words.match(syllableRegex)
    }

    const chunkSentence = (sentence, chunks) => {
        let maxChunks = sentence.split(' ')
        let split = maxChunks.length / chunks
        let alternativesArray = []
        for (let x = 0; x < maxChunks.length; x++) {
            if (alternativesArray[parseInt(x / split, 10)]) {
                alternativesArray[parseInt(x / split, 10)] =
                    alternativesArray[parseInt(x / split, 10)] + ' ' + maxChunks[x]
            } else {
                alternativesArray[parseInt(x / split, 10)] = maxChunks[x]
            }
        }
        return alternativesArray
    }
    const handleUpdateTestOptionOnCurrentNode = (testOption) => {
        let currentNodeObjectTemp = JSON.parse(JSON.stringify(currentNodeObject)) //[...nodes]

        let newTestOptions = []
        let found = false
        currentNodeObjectTemp.testOptions.forEach((to) => {
            if (to.testOptionId === testOption.testOptionId) {
                newTestOptions.push(testOption)
                found = true
            } else {
                newTestOptions.push(to)
            }
        })
        if (found) {
            currentNodeObjectTemp.testOptions = newTestOptions
            setCurrentNodeObject(currentNodeObjectTemp)
        }
    }

    const handleUpdateAllTestOptionOnCurrentNode = (testOptions) => {
        let currentNodeObjectTemp = JSON.parse(JSON.stringify(currentNodeObject)) //[...nodes]

        let newTestOptions = []
        let found = false
        currentNodeObjectTemp.testOptions = testOptions
        setCurrentNodeObject(currentNodeObjectTemp)
    }

    const handleUpdateAllTestOptionsAndType = (scenarioNodeType, testOptions) => {
        let currentNodeObjectTemp = JSON.parse(JSON.stringify(currentNodeObject))
        currentNodeObjectTemp.scenarioNodeType = scenarioNodeType
        currentNodeObjectTemp.testOptions = testOptions
        setCurrentNodeObject(currentNodeObjectTemp)
    }

    const handleChangeTextImageQuestionL2 = (testOptionId, text, newValue = 1, testOptionIndex) => {
        if (!props.lesson.canEditLesson) {
            return
        }
        //props.onEditDistractors(chunkedLarge, "chunks4", props.text)
        let currentNodeObjectTemp = JSON.parse(JSON.stringify(currentNodeObject)) //[...nodes]
        switch (newValue) {
            case 0:
                // every letter
                let tempArraySyllables = text.split('')
                currentNodeObjectTemp = calculateEditDistractors(tempArraySyllables, 'letters', testOptionIndex)
                break
            case 1:
                // every syllable
                let tempArrayLetters = text.split(' ')
                tempArrayLetters = tempArrayLetters.map(syllabify)
                if (tempArrayLetters.length === 1) {
                    tempArrayLetters = text.split('')
                }
                tempArrayLetters.forEach((alternativeSyllable) => {})
                currentNodeObjectTemp = calculateEditDistractors(tempArrayLetters, 'syllables', testOptionIndex)
                break
            case 2:
                // every word
                let tempArrayWords = text.split(' ')
                currentNodeObjectTemp = calculateEditDistractors(tempArrayWords, 'words', testOptionIndex)
                break
            case 3:
                // small chunks
                let chunkedLarge = chunkSentence(text, 4)
                currentNodeObjectTemp = calculateEditDistractors(chunkedLarge, 'chunks4', testOptionIndex)
                break
        }
        let languageId = selectedScenario.languageId

        currentNodeObjectTemp.testOptions.forEach((to) => {
            if (to.testOptionId === testOptionId) {
                to.text = text
                to.textLanguageId = languageId
            }
        })
        setCurrentNodeObject(currentNodeObjectTemp)
    }

    const handleChangeTextImageTitle = (textSnippetId, text) => {
        if (!props.lesson.canEditLesson) {
            return
        }
        let currentNodeObjectTemp = JSON.parse(JSON.stringify(currentNodeObject)) //[...nodes]
        let languageId = selectedScenario.languageId
        currentNodeObjectTemp.texts.forEach((to) => {
            if (to.textSnippetId === textSnippetId) {
                to.text = text
                //to.textLanguageId = languageId;
            }
        })
        setCurrentNodeObject(currentNodeObjectTemp)
    }

    const handleAddDistractor = (type, text, index, testOptionId) => {
        if (!props.lesson.canEditLesson) {
            return
        }
        var scenarioTemp = JSON.parse(JSON.stringify(selectedScenario))
        var currentNodeTemp = JSON.parse(JSON.stringify(currentNodeObject))
        let interactionLanguageId = selectedScenario.languageId // TODO: pass in language

        switch (type) {
            case 'ruleCardMultichoice':
                currentNodeTemp.testOptions.forEach((to) => {
                    if (to.testOptionId === testOptionId) {
                        to.alternatives.push({
                            associatedTextLanguageId: -1,
                            correct: true,
                            startIndex: index,
                            text: text,
                            textSnippetId: null,
                            textLanguageId: interactionLanguageId,
                            textAudioUrl: null,
                        })
                    }
                })

                break
            case 'textImageKeyboard':
                currentNodeTemp.testOptions.forEach((to) => {
                    if (to.testOptionId === testOptionId) {
                        to.alternatives.push({
                            associatedTextLanguageId: -1,
                            correct: true,
                            startIndex: -1,
                            text: text,
                            textSnippetId: null,
                            textLanguageId: interactionLanguageId,
                            textAudioUrl: null,
                        })
                    }
                })

                break
            case 'textImageMultichoice':
                currentNodeTemp.testOptions.forEach((to) => {
                    if (to.testOptionId === testOptionId) {
                        to.alternatives.push({
                            associatedTextLanguageId: -1,
                            correct: to.alternatives.length === 0 ? true : false,
                            startIndex: -1,
                            text: text,
                            textSnippetId: null,
                            textLanguageId: interactionLanguageId,
                            textAudioUrl: null,
                        })
                        if (to.alternatives.length === 0) {
                            currentNodeTemp.testOptions[2].text = text
                        }
                    }
                })

                break
            case 'sentenceBuilder':
                let maxIndex = -1
                let maxWordLength = 0
                currentNodeTemp.testOptions.forEach((to) => {
                    if (to.testOptionId === testOptionId) {
                        to.alternatives.forEach((alternative) => {
                            if (alternative.startIndex > maxIndex) {
                                maxIndex = alternative.startIndex
                                maxWordLength = alternative.text.length
                            }
                        })
                        to.alternatives.push({
                            associatedTextLanguageId: -1,
                            correct: true,
                            startIndex: maxIndex + maxWordLength,
                            text: text,
                            textSnippetId: null,
                            textLanguageId: interactionLanguageId,
                            textAudioUrl: null,
                        })
                    }
                })

                break
            case 'sentenceBuilder2':
                break
        }
        setCurrentNodeObject(currentNodeTemp)
        //setSelectedScenario(scenarioTemp)
    }

    const handleEditText = (text, type) => {
        if (!props.lesson.canEditLesson) {
            return
        }
        var scenarioTemp = JSON.parse(JSON.stringify(selectedScenario))
        var currentNodeTemp = JSON.parse(JSON.stringify(currentNodeObject))

        switch (type) {
            case 'textImageSentenceBuilder':
                currentNodeTemp.testOptions[2].text = text
                break
        }
        setCurrentNodeObject(currentNodeTemp)
        //setSelectedScenario(scenarioTemp)
    }

    const isFirstAtIndex = (text, startIndex, textSnippetId, alternatives) => {
        let isFound = false
        let isFirst = false
        alternatives.forEach((alternative) => {
            if (isFound === false) {
                if (alternative.startIndex === startIndex) {
                    isFound = true
                    if (alternative.textSnippetId === textSnippetId) {
                        isFirst = true
                    }
                }
            }
        })
        return isFirst
    }

    const handleSanitiseStage = (testOptionId) => {
        if (!props.lesson.canEditLesson) {
            return
        }
        var currentNodeTemp = JSON.parse(JSON.stringify(currentNodeObject))
        currentNodeTemp.testOptions.forEach((to) => {
            if (to.testOptionId === testOptionId) {
            }
        })
        setCurrentNodeObject(currentNodeTemp)
    }

    const handleDeletePuzzleBlockPair = (specifiedIndex) => {
        if (!props.lesson.canEditLesson) {
            return
        }
        let currentNodeTemp = JSON.parse(JSON.stringify(currentNodeObject))
        let testOptionAlternatives = JSON.parse(JSON.stringify(currentNodeTemp.testOptions[0].alternatives))
        currentNodeTemp.testOptions[0].alternatives = currentNodeTemp.testOptions[0].alternatives
            .slice(0, specifiedIndex)
            .concat(
                currentNodeTemp.testOptions[0].alternatives.slice(
                    specifiedIndex + 1,
                    currentNodeTemp.testOptions[0].alternatives.length
                )
            )
        setCurrentNodeObject(currentNodeTemp)
    }

    const handleReorderTestOptions = (newOrder) => {
        if (!props.lesson.canEditLesson) {
            return
        }
        var currentNodeTemp = JSON.parse(JSON.stringify(currentNodeObject))
        currentNodeTemp.testOptions = newOrder
        setCurrentNodeObject(currentNodeTemp)
    }

    const handleAddRule = () => {
        if (!props.lesson.canEditLesson) {
            return
        }

        let currentNodeTemp = JSON.parse(JSON.stringify(currentNodeObject))
        let testOptionId = uuidv4()
        let ruleLanguageId = selectedScenario.languageId

        currentNodeTemp.testOptions.push({
            alternatives: [],
            audioPlayType: 0,
            elementAlignmentType: 1,
            explanation: '',
            explanationLanguageId: -1,
            mentor: 1,
            scenarioInteractiveElementType: 4,
            testOptionId: testOptionId,
            text: '',
            textSnippetId: null,
            textLanguageId: ruleLanguageId,
            textAudioUrl: null,
            interactionScoringDynamicConfigurationV1Dto: {
                shouldBeScored: false,
            },
        })
        setCurrentNodeObject(currentNodeTemp)
    }

    const handleAddMultiChoiceQuestion = () => {
        if (!props.lesson.canEditLesson) {
            return
        }
        const currentNodeTemp = JSON.parse(JSON.stringify(currentNodeObject))

        let shouldBeScored = selectedScenario.lessonType === LessonType.NotDefined

        currentNodeTemp.testOptions.push({
            testOptionId: uuidv4(),
            text: '',
            textSnippetId: null,
            textLanguageId: selectedScenario.languageId,
            alternatives: [],
            audioPlayType: 0,
            elementAlignmentType: 1,
            explanation: '',
            explanationLanguageId: -1,
            mentor: 1,
            scenarioInteractiveElementType: 1,
            textAudioUrl: null,
            interactionScoringDynamicConfigurationV1Dto: {
                shouldBeScored: shouldBeScored,
            },
        })

        setCurrentNodeObject(currentNodeTemp)
    }

    const handleDeleteRule = (testOptionId) => {
        if (!props.lesson.canEditLesson) {
            return
        }
        var currentNodeTemp = JSON.parse(JSON.stringify(currentNodeObject))
        let localTestOptionsArray = []
        currentNodeTemp.testOptions.forEach((to) => {
            if (to.testOptionId !== testOptionId) {
                localTestOptionsArray.push(to)
            }
        })
        currentNodeTemp.testOptions = localTestOptionsArray
        setCurrentNodeObject(currentNodeTemp)
    }

    const onRulecardTextChange = (testOptionId, text, selectionStart, selectionEnd) => {
        if (!props.lesson.canEditLesson) {
            return
        }
        var scenarioTemp = JSON.parse(JSON.stringify(selectedScenario))
        var currentNodeTemp = JSON.parse(JSON.stringify(currentNodeObject))
        let i = 0
        let indicesToRemove = []
        currentNodeTemp.testOptions.forEach((to) => {
            if (to.testOptionId === testOptionId) {
                if (text === '') {
                    to.alternatives = []
                }
                indicesToRemove = []
                to.alternatives.forEach(function (alternative, index, object) {
                    if (
                        selectionStart >= alternative.startIndex &&
                        selectionStart <= alternative.startIndex + alternative.text.length
                    ) {
                        // ||
                        // text edited where an existing alternative exists. Therefore, deleted the alternative and its relations
                        if (
                            isFirstAtIndex(
                                alternative.text,
                                alternative.startIndex,
                                alternative.textSnippetId,
                                to.alternatives
                            )
                        ) {
                            let currentAlternative = alternative
                            to.alternatives.forEach(function (altRelation, indexRelation, objectRelation) {
                                if (altRelation.startIndex === currentAlternative.startIndex) {
                                    indicesToRemove.push(to.alternatives[indexRelation].textSnippetId)
                                }
                            })
                            indicesToRemove.push(to.alternatives[index].textSnippetId)
                        }
                    }

                    if (alternative.startIndex > selectionStart) {
                        // something changed before the alternative. Update its start position by length of change
                        alternative.startIndex = alternative.startIndex + (text.length - to.text.length)
                    }
                    let foundAValidAlternative = false
                    let isFound = false
                    let validAlternative = false
                    to.alternatives.forEach((alt) => {
                        if (isFound === false) {
                            if (alt.startIndex === alternative.startIndex) {
                                isFound = true
                                console.log('to.text', to.text)
                                console.log(
                                    'alt.text.length',
                                    alt.text.length,
                                    'found this',
                                    alt,
                                    to.text.substring(alt.startIndex, alt.text.length),
                                    alt.text === to.text.substring(alt.startIndex, alt.text.length + alt.startIndex)
                                )
                                if (alt.text === text.substring(alt.startIndex, alt.text.length + alt.startIndex)) {
                                    validAlternative = true
                                }
                            }
                        }
                    })
                    if (validAlternative === false) {
                        indicesToRemove.push(alternative.textSnippetId)
                    }
                })

                indicesToRemove.forEach((element) => {
                    to.alternatives = to.alternatives.filter((item) => item.textSnippetId !== element)
                })
                to.text = text
            }
            i++
        })
        setCurrentNodeObject(currentNodeTemp)
    }

    const handleToggleCorrect = (testOptionId, textSnippetId) => {
        if (!props.lesson.canEditLesson) {
            return
        }
        var scenarioTemp = JSON.parse(JSON.stringify(selectedScenario))
        var currentNodeTemp = JSON.parse(JSON.stringify(currentNodeObject))

        currentNodeTemp.testOptions.forEach((to) => {
            if (to.testOptionId === testOptionId) {
                to.alternatives.forEach((alternative) => {
                    if (alternative.textSnippetId === textSnippetId) {
                        alternative.correct = !!!alternative.correct
                    }
                })
            }
        })

        setCurrentNodeObject(currentNodeTemp)
        //setSelectedScenario(scenarioTemp)
    }

    const calculateEditDistractors = (alternatives, type, testOptionIndex = 2) => {
        var currentNodeTemp = JSON.parse(JSON.stringify(currentNodeObject))
        let currentIndex = 0
        let newNodeAlternatives = []
        let interactionLanguageId = selectedScenario.languageId // TODO: pass in language

        alternatives.forEach((alternative, index) => {
            if (type !== 'syllables') {
                newNodeAlternatives.push({
                    associatedTextLanguageId: -1,
                    correct: true,
                    startIndex: currentIndex,
                    text: alternative,
                    textSnippetId: null,
                    textLanguageId: interactionLanguageId,
                    textAudioUrl: null,
                })
            }
            switch (type) {
                case 'letters':
                    currentIndex++
                    break
                case 'syllables':
                    if (alternative) {
                        alternative.forEach((syllable) => {
                            newNodeAlternatives.push({
                                associatedTextLanguageId: -1,
                                correct: true,
                                startIndex: currentIndex,
                                text: syllable,
                                textSnippetId: null,
                                textLanguageId: interactionLanguageId,
                                textAudioUrl: null,
                            })
                            currentIndex += syllable.length
                        })
                        currentIndex++
                    }
                    break
                case 'words':
                    currentIndex += alternative.length + 1
                    break
                case 'chunks4':
                    currentIndex += alternative.length + 1
                    break
            }
        })
        currentNodeTemp.testOptions[testOptionIndex].alternatives = newNodeAlternatives
        currentNodeTemp.testOptions[testOptionIndex].scenarioInteractiveElementType = 3
        return currentNodeTemp
    }

    const handleEditDistractors = (alternatives, type, testOptionIndex = 2) => {
        if (!props.lesson.canEditLesson) {
            return
        }
        let currentNodeTemp = calculateEditDistractors(alternatives, type, testOptionIndex)
        setCurrentNodeObject(currentNodeTemp)
    }

    const handleDeleteDistractor = (textSnippetId, type, index, testOptionId) => {
        if (!props.lesson.canEditLesson) {
            return
        }
        //
        var scenarioTemp = JSON.parse(JSON.stringify(selectedScenario))
        var currentNodeTemp = JSON.parse(JSON.stringify(currentNodeObject))

        switch (type) {
            case 'textImageMultichoice':
                let alternativeToDelete = null
                //currentNodeTemp.testOptions[2].alternatives = filteredItems
                currentNodeTemp.testOptions.forEach((to) => {
                    if (to.testOptionId === testOptionId) {
                        const filteredItems = to.alternatives.filter((item) => item.textSnippetId !== textSnippetId)

                        to.alternatives = filteredItems
                    }
                })
                break
            case 'sentenceBuilder':
                let filteredItemsSentenceBuilder = []
                for (let i = 0; i < currentNodeTemp.testOptions[2].alternatives.length; i++) {
                    if (i !== index) {
                        filteredItemsSentenceBuilder.push(currentNodeTemp.testOptions[2].alternatives[i])
                    }
                }
                currentNodeTemp.testOptions.forEach((to) => {
                    if (to.testOptionId === testOptionId) {
                        to.alternatives = filteredItemsSentenceBuilder
                    }
                })
                //currentNodeTemp.testOptions[2].alternatives = filteredItemsSentenceBuilder
                break
        }

        setCurrentNodeObject(currentNodeTemp)
    }

    const handleToggleReasoning = (id, index) => {
        if (!props.lesson.canEditLesson) {
            return
        }
        let currentNodeObjectTemp = JSON.parse(JSON.stringify(currentNodeObject))
        let reasoningLanguagedId = selectedScenario.languageId

        if (
            currentNodeObjectTemp.options[index].scoreReasoning ||
            currentNodeObjectTemp.options[index].scoreReasoning === ''
        ) {
            delete currentNodeObjectTemp.options[index].scoreReasoning
            delete currentNodeObjectTemp.options[index].scoreReasoningSnippetdId
            delete currentNodeObjectTemp.options[index].scoreReasoningLanguageId
        } else {
            currentNodeObjectTemp.options[index].scoreReasoning = ''
            currentNodeObjectTemp.options[index].scoreReasoningSnippetdId = undefined
            currentNodeObjectTemp.options[index].scoreReasoningLanguageId = reasoningLanguagedId
        }
        setCurrentNodeObject(currentNodeObjectTemp)
    }

    const handleChangedNodeType = (scenarioNodeType) => {
        if (!props.lesson.canEditLesson) {
            return
        }
        let currentNodeObjectTemp = JSON.parse(JSON.stringify(currentNodeObject))
        currentNodeObjectTemp.scenarioNodeType = scenarioNodeType
        setCurrentNodeObject(currentNodeObjectTemp)
    }

    const handleChangedNodeScoring = (nodeScoringEnabled) => {
        if (!props.lesson.canEditLesson) {
            return
        }

        let currentNodeObjectTemp = JSON.parse(JSON.stringify(currentNodeObject))

        if (currentNodeObjectTemp.lessonNodeScoringDynamicConfigurationV1Dto == null) {
            currentNodeObjectTemp.lessonNodeScoringDynamicConfigurationV1Dto = {
                shouldBeScored: nodeScoringEnabled,
            }
        } else {
            currentNodeObjectTemp.lessonNodeScoringDynamicConfigurationV1Dto.shouldBeScored = nodeScoringEnabled
        }

        setCurrentNodeObject(currentNodeObjectTemp)
    }

    const handleOnNodeInteractionScoringChanged = (testOptionIndex, shouldBeScored) => {
        if (!props.lesson.canEditLesson) {
            return
        }

        let currentNodeObjectTemp = JSON.parse(JSON.stringify(currentNodeObject))

        currentNodeObjectTemp.testOptions[testOptionIndex].interactionScoringDynamicConfigurationV1Dto = {
            shouldBeScored: shouldBeScored,
        }

        // Forcing backend to regenerate the node scoring.
        currentNodeObjectTemp.lessonNodeScoringDynamicConfigurationV1Dto = null

        setCurrentNodeObject(currentNodeObjectTemp)
    }

    const handleChangeLanguageIdForScoreReasoning = (id, index, scoreReasoningIsInL1) => {
        if (!props.lesson.canEditLesson) {
            return
        }
        let currentNodeObjectTemp = JSON.parse(JSON.stringify(currentNodeObject))
        var reasoningLanguagedId = selectedScenario.languageId
        if (scoreReasoningIsInL1) {
            reasoningLanguagedId = selectedScenario.l1Id
        }

        if (currentNodeObjectTemp.options?.length >= index) {
            currentNodeObjectTemp.options[index].scoreReasoningLanguageId = reasoningLanguagedId
        }

        setCurrentNodeObject(currentNodeObjectTemp)
    }

    const handleChangeLanguageDebrief = (id, index, debriefIsInL1) => {
        if (!props.lesson.canEditLesson) {
            return
        }
        if (selectedScenario == null) return

        let currentNodeObjectTemp = JSON.parse(JSON.stringify(currentNodeObject))
        var reasoningLanguageId = selectedScenario.languageId
        if (debriefIsInL1) {
            reasoningLanguageId = selectedScenario.l1Id
        }

        if (currentNodeObjectTemp.options && index < currentNodeObjectTemp.options.length && index >= 0) {
            currentNodeObjectTemp.options[index].textLanguageId = reasoningLanguageId
        }

        setCurrentNodeObject(currentNodeObjectTemp)
    }

    const handleMentorSizeChange = (normalizedHeight, x, y) => {
        if (!props.lesson.canEditLesson) {
            return
        }
        let currentNodeObjectTemp = JSON.parse(JSON.stringify(currentNodeObject)) //[...nodes]
        currentNodeObjectTemp.scenarioMentors[0].screenObjectDto.normalizedSize.y = normalizedHeight

        x = x.toFixed(3)
        y = y.toFixed(3)
        currentNodeObjectTemp.scenarioMentors[0].screenObjectDto.normalizedPosition.x = x
        currentNodeObjectTemp.scenarioMentors[0].screenObjectDto.normalizedPosition.y = y
        setCurrentNodeObject(currentNodeObjectTemp)
    }

    const handleDeleteVoice = (type = null, testOptionId = null) => {
        if (!props.lesson.canEditLesson) {
            return
        }
        let currentNodeObjectTemp = JSON.parse(JSON.stringify(currentNodeObject)) //[...nodes]

        // remove tracking of for the testOptionId
        let filteredNodeAudioTrackers = selectedNodeAudioTrackers.filter((x) => x.testOptionId !== testOptionId)
        if (selectedNodeAudioTrackers.length !== filteredNodeAudioTrackers) {
            setSelectedNodeAudioTrackers(filteredNodeAudioTrackers)
        }

        switch (type) {
            case ScenarioNodeType.TestRule:
                currentNodeObjectTemp.testOptions.forEach((to) => {
                    if (to.testOptionId === testOptionId) {
                        delete to.textAudioUrl
                    }
                })
                break
            case ScenarioNodeType.TestDefinition:
                delete currentNodeObjectTemp.testOptions[1].textAudioUrl
                if (currentNodeObjectTemp.testOptions[1].alternatives?.length > 0) {
                    delete currentNodeObjectTemp.testOptions[1].alternatives[0].textAudioUrl
                }
                break
            case ScenarioNodeType.TestImage:
                currentNodeObjectTemp.testOptions[2].textAudioUrl = null
                break
            case ScenarioNodeType.MultiChoice:
            case ScenarioNodeType.ListenMultiChoice:
                delete currentNodeObjectTemp.testOptions[0].textAudioUrl
                break
            case ScenarioNodeType.Listen:
                delete currentNodeObjectTemp.options[0].textAudioUrl
                break
            case ScenarioNodeType.TestListenWithSlider:
                currentNodeObjectTemp.testOptions.forEach((to) => {
                    if (to.testOptionId === testOptionId) {
                        delete to.textAudioUrl
                    }
                })
                break
            case ScenarioNodeType.Instruction:
                delete currentNodeObjectTemp.testOptions[2].textAudioUrl
                break
            default:
                break
        }

        if (type === null) {
            console.log('type is null', type === null, type)
            delete currentNodeObjectTemp.options[0].textAudioUrl
        }
        setCurrentNodeObject(currentNodeObjectTemp)
    }

    const handleChangePuzzleBlockDescriptionLanguage = (l1OrL2) => {
        if (!props.lesson.canEditLesson) {
            return
        }
        let currentNodeObjectTemp = JSON.parse(JSON.stringify(currentNodeObject)) //[...nodes]
        let languageSelection = selectedScenario.languageId
        if (l1OrL2 === 0) {
            languageSelection = selectedScenario.l1Id
        }
        currentNodeObjectTemp.texts[0].textLanguageId = languageSelection
        setCurrentNodeObject(currentNodeObjectTemp)
    }

    const handleChangePuzzleBlockColumnLanguage = (leftOrRight, l1OrL2) => {
        if (!props.lesson.canEditLesson) {
            return
        }
        let currentNodeObjectTemp = JSON.parse(JSON.stringify(currentNodeObject)) //[...nodes]
        let languageSelection = selectedScenario.languageId
        if (l1OrL2 === 0) {
            languageSelection = selectedScenario.l1Id
        }
        if (leftOrRight === 'left') {
            currentNodeObjectTemp.testOptions[0].alternatives.forEach((alternative) => {
                alternative.textLanguageId = languageSelection
            })
        } else {
            currentNodeObjectTemp.testOptions[0].alternatives.forEach((alternative) => {
                alternative.associatedTextLanguageId = languageSelection
            })
        }
        setCurrentNodeObject(currentNodeObjectTemp)
    }

    const handleChangePuzzleBlockText = (id, text, leftOrRight) => {
        if (!props.lesson.canEditLesson) {
            return
        }
        let currentNodeObjectTemp = JSON.parse(JSON.stringify(currentNodeObject)) //[...nodes]
        currentNodeObjectTemp.testOptions[0].alternatives.forEach((alternative) => {
            if (leftOrRight === 'left') {
                if (alternative.textSnippetId === id) {
                    alternative.text = text
                }
            } else {
                if (alternative.associatedTextSnippetId === id) {
                    alternative.associatedText = text
                }
            }
        })
        setCurrentNodeObject(currentNodeObjectTemp)
    }

    const handleAddPuzzleBlocks = () => {
        if (!props.lesson.canEditLesson) {
            return
        }
        let textSnippetId = uuidv4()
        let associatedTextSnippetId = uuidv4()
        let leftSideLanguage = selectedScenario.languageId
        let rightSideLanguage = selectedScenario.l1Id

        let currentNodeObjectTemp = JSON.parse(JSON.stringify(currentNodeObject)) //[...nodes]
        if (currentNodeObjectTemp.testOptions[0].alternatives.length > 0) {
            leftSideLanguage = currentNodeObjectTemp.testOptions[0].alternatives[0].textLanguageId
            rightSideLanguage = currentNodeObjectTemp.testOptions[0].alternatives[0].associatedTextLanguageId
        }

        currentNodeObjectTemp.testOptions[0].alternatives.push({
            associatedText: '',
            associatedTextLanguageId: rightSideLanguage,
            associatedTextSnippetId: associatedTextSnippetId,
            correct: false,
            startIndex: -1,
            text: '',
            textLanguageId: leftSideLanguage,
            textSnippetId: textSnippetId,
        })
        setCurrentNodeObject(currentNodeObjectTemp)
    }

    const handleAddPuzzleBlocksFromExisting = (blocksToAdd) => {
        if (!props.lesson.canEditLesson) {
            return
        }
        let leftSideLanguage = selectedScenario.languageId
        let rightSideLanguage = selectedScenario.l1Id
        let currentNodeObjectTemp = JSON.parse(JSON.stringify(currentNodeObject)) //[...nodes]
        blocksToAdd.forEach((block) => {
            if (currentNodeObjectTemp.testOptions[0].alternatives.length > 0) {
                leftSideLanguage = currentNodeObjectTemp.testOptions[0].alternatives[0].textLanguageId
                rightSideLanguage = currentNodeObjectTemp.testOptions[0].alternatives[0].associatedTextLanguageId
            }

            let leftBlock = {}
            let rightBlock = {}

            if (block.l1TextLanguageId === rightSideLanguage) {
                leftBlock.text = block.l1
                leftBlock.textSnippetId = block.l1TextSnippetId
                leftBlock.textLanguageId = block.l1TextLanguageId

                rightBlock.text = block.l2
                rightBlock.textSnippetId = block.l2TextSnippetId
                rightBlock.textLanguageId = block.l2TextLanguageId
                rightBlock.textAudioUrl = block.textAudioUrl
            } else {
                leftBlock.text = block.l2
                leftBlock.textSnippetId = block.l2TextSnippetId
                leftBlock.textLanguageId = block.l2TextLanguageId
                leftBlock.textAudioUrl = block.textAudioUrl

                rightBlock.text = block.l1
                rightBlock.textSnippetId = block.l1TextSnippetId
                rightBlock.textLanguageId = block.l1TextLanguageId
            }
            currentNodeObjectTemp.testOptions[0].alternatives =
                currentNodeObjectTemp.testOptions[0].alternatives.filter(
                    (x) => x.associatedText !== '' || x.text !== ''
                )
            currentNodeObjectTemp.testOptions[0].alternatives.push({
                associatedText: leftBlock.text,
                associatedTextLanguageId: leftBlock.textLanguageId,
                associatedTextSnippetId: leftBlock.textSnippetId,
                correct: false,
                startIndex: -1,
                text: rightBlock.text,
                textLanguageId: rightBlock.textLanguageId,
                textSnippetId: rightBlock.textSnippetId,
                textAudioUrl: block.textAudioUrl,
            })
        })

        setCurrentNodeObject(currentNodeObjectTemp)
    }

    const handleVoiceDrop = (
        blob,
        blobUrl,
        niceName,
        testOptionId = null,
        localFile = false,
        recordedInCap = false
    ) => {
        if (!props.lesson.canEditLesson) {
            return
        }
        let currentNodeObjectTemp = JSON.parse(JSON.stringify(currentNodeObject)) //[...nodes]

        if (testOptionId) {
            let audioTracker = selectedNodeAudioTrackers.find((x) => x.testOptionId === testOptionId)
            if (!audioTracker) {
                audioTracker = {
                    trackerId: uuidv4(),
                    audioFieldType: AudioFieldType.TestOption,
                    audioNiceName: '',
                    audioBlob: '',
                    audioBlobUrl: '',
                    audioFileExtension: '',
                    testOptionId: testOptionId,
                    optionId: null,
                    fileReferenceId: null,
                }
                setSelectedNodeAudioTrackers((prevSelectedNodeAudioTrackers) => [
                    ...prevSelectedNodeAudioTrackers,
                    audioTracker,
                ])
            }
            audioTracker.recordedInCap = recordedInCap
            audioTracker.localFile = localFile
            audioTracker.audioBlob = blob
            audioTracker.audioBlobUrl = blobUrl
            audioTracker.audioNiceName = niceName
        } else {
            let audioTracker = selectedNodeAudioTrackers.find((x) => x.audioBlobUrl === blobUrl)
            if (!audioTracker) {
                audioTracker = {
                    trackerId: uuidv4(),
                    audioFieldType: AudioFieldType.Option,
                    audioNiceName: '',
                    audioUrl: null,
                    audioBlob: '',
                    audioBlobUrl: '',
                    audioFileExtension: '',
                    testOptionId: testOptionId,
                    optionId: null,
                    fileReferenceId: null,
                }
                setSelectedNodeAudioTrackers((prevSelectedNodeAudioTrackers) => [
                    ...prevSelectedNodeAudioTrackers,
                    audioTracker,
                ])
            }
            audioTracker.recordedInCap = recordedInCap
            audioTracker.localFile = localFile
            audioTracker.audioBlob = blob
            audioTracker.audioBlobUrl = blobUrl
            audioTracker.audioNiceName = niceName
            setCurrentAudioTracker(audioTracker)
            setCurrentPlayAudioBlobUrl(blobUrl)
        }

        if (currentNodeObjectTemp.scenarioNodeType === ScenarioNodeType.TestRule) {
            currentNodeObjectTemp.testOptions.forEach((to) => {
                if (to.testOptionId === testOptionId) {
                    to.textAudioUrl = blobUrl
                }
            })
        } else if (currentNodeObjectTemp.scenarioNodeType === ScenarioNodeType.TestImage) {
            currentNodeObjectTemp.testOptions[2].textAudioUrl = blobUrl
        } else if (
            currentNodeObjectTemp.scenarioNodeType === ScenarioNodeType.ListenMultiChoice ||
            currentNodeObjectTemp.scenarioNodeType === ScenarioNodeType.MultiChoice
        ) {
            currentNodeObjectTemp.testOptions[0].textAudioUrl = blobUrl
        } else if (currentNodeObjectTemp.scenarioNodeType === ScenarioNodeType.TestDefinition) {
            currentNodeObjectTemp.testOptions[1].textAudioUrl = blobUrl
        } else if (currentNodeObjectTemp.scenarioNodeType === ScenarioNodeType.TestImage) {
            currentNodeObjectTemp.testOptions[2].textAudioUrl = blobUrl
        } else if (currentNodeObjectTemp.scenarioNodeType === ScenarioNodeType.Listen) {
            currentNodeObjectTemp.options[0].textAudioUrl = blobUrl
        } else if (currentNodeObjectTemp.scenarioNodeType === ScenarioNodeType.TestListenWithSlider) {
            {
                currentNodeObjectTemp.testOptions.forEach((to) => {
                    if (to.testOptionId === testOptionId) {
                        to.textAudioUrl = blobUrl
                    }
                })
            }
        } else if (currentNodeObjectTemp.scenarioNodeType === ScenarioNodeType.Instruction) {
            currentNodeObjectTemp.testOptions[2].textAudioUrl = blobUrl
        } else {
            console.log('adding audio but not actually setting it on a option / test option')
        }
        setCurrentNodeObject(currentNodeObjectTemp)
    }

    const handleAudioDrop = (files, audioFieldType, niceName, nodeId) => {
        if (!props.lesson.canEditLesson) {
            return
        }

        if (audioFieldType !== AudioFieldType.BackgroundAudioUrl && audioFieldType !== AudioFieldType.SfxAudioUrl) {
            console.log('LBC: handleAudioDrop called with wrong audio field type')
        }

        // save selected audio and record in the selectedNodeAudio object
        const blobUrl = URL.createObjectURL(files[0])

        var audioTracker = selectedNodeAudioTrackers.find((x) => x.audioFieldType === audioFieldType)
        if (!audioTracker) {
            audioTracker = {
                trackerId: uuidv4(),
                audioFieldType: audioFieldType,
                audioNiceName: files[0].name,
                audioBlob: files[0], // blob,
                audioUrl: null,
                audioBlobUrl: blobUrl,
                audioFileExtension: '',
                testOptionId: null,
                optionId: null,
                fileReferenceId: null,
                localFile: true,
            }
            setSelectedNodeAudioTrackers((prevSelectedNodeAudioTrackers) => [
                ...prevSelectedNodeAudioTrackers,
                audioTracker,
            ])
        } else {
            audioTracker.audioBlob = files[0]
            audioTracker.audioNiceName = files[0].name
            audioTracker.audioBlobUrl = blobUrl
            audioTracker.localFile = true
        }

        setCurrentPlayAudioBlobUrl(blobUrl)
        setCurrentAudioTracker(audioTracker)

        // Add to nodes data for updating remote data
        let nodesTemp = JSON.parse(JSON.stringify(nodes)) //[...nodes]
        nodesTemp.forEach((node) => {
            if (node.nodeId === nodeId) {
                if (audioFieldType === AudioFieldType.BackgroundAudioUrl) {
                    node.backgroundAudioUrl = blobUrl
                } else if (audioFieldType === AudioFieldType.SfxAudioUrl) {
                    node.backgroundAudioUrl = blobUrl
                }
            }
        })
        setNodes(nodesTemp)
        setEdgesWithCanEditLesson(props.lesson.selectedScenario.scenarioEdges)
        // update our local storage of selected node's audio data:

        let currentNodeObjectTemp = JSON.parse(JSON.stringify(currentNodeObject)) //[...nodes]

        if (audioFieldType === AudioFieldType.BackgroundAudioUrl) {
            currentNodeObjectTemp.backgroundAudioUrl = blobUrl
        } else if (audioFieldType === AudioFieldType.SfxAudioUrl) {
            currentNodeObjectTemp.sfxAudioUrl = blobUrl
        } else {
            //TODO: MORTEN figure out the other types of audio...
        }

        setCurrentNodeObject(currentNodeObjectTemp)
    }

    const collisionDetection = (x, y) => {
        // Some collision detection for spawning duplicate trainers not behind other trainers. Assumes all trainers are the same height.
        let final = y
        let isYFree = true

        stateRef.current.forEach((node) => {
            if (node.position !== null && node.position) {
                if (
                    y > node.position.y - 150 &&
                    y < node.position.y + 150 &&
                    x > node.position.x - 150 &&
                    x < node.position.x + 150
                ) {
                    isYFree = false
                }
            }
        })
        if (!isYFree) {
            final = collisionDetection(x, y + 100)
        }
        return final
    }

    const handleCreateItemsOrNodesFromDatapicker = (itemsToAdd, modeSelect, shouldConnect) => {
        if (!props.lesson.canEditLesson) return
        let nodesTemp = [...stateRef.current]
        setOpenDataPickerPanel(false)
        let startingNode = nodesTemp.filter((x) => x.type === ScenarioNodeType.Pin) // a "pin" node is created when library button is pressed
        let startingX = startingNode[0].position.x
        let startingY = startingNode[0].position.y
        let newNodes = []
        nodesTemp = nodesTemp.filter((x) => x.type !== ScenarioNodeType.Pin)
        if (modeSelect === 1) {
            // create entire nodes
            let xPos = startingX + 300
            itemsToAdd.forEach((item, index) => {
                let nodeGuid = uuidv4()
                let nodeToDuplicate = JSON.parse(JSON.stringify(item)) //[...nodes]
                let newYShifter = collisionDetection(xPos, startingY)
                nodeToDuplicate.position = { x: xPos, y: newYShifter }
                nodeToDuplicate.xPos = xPos
                nodeToDuplicate.yPos = newYShifter
                nodeToDuplicate.nodeId = nodeGuid
                xPos = xPos + 300
                item.nodeId = nodeGuid
                newNodes.push(nodeToDuplicate)
            })
        } else {
            // Create definitions
            let xPos = startingX + 300
            itemsToAdd.forEach((item, index) => {
                let nodeGuid = uuidv4()
                let nodeToDuplicate = JSON.parse(JSON.stringify(item)) //[...nodes]
                let testDefinitionOptionGuid0 = uuidv4()
                let testDefinitionOptionGuid1 = uuidv4()
                let newYShifter = collisionDetection(xPos, startingY)
                nodeToDuplicate.position = { x: xPos, y: newYShifter }
                nodeToDuplicate.xPos = xPos
                nodeToDuplicate.yPos = newYShifter
                nodeToDuplicate.nodeId = nodeGuid
                nodeToDuplicate.id = nodeGuid
                //if(item.imageUrl) {
                //  nodeToDuplicate.imageUrl = item.imageUrl
                // }
                xPos = xPos + 300
                nodeToDuplicate.testOptions = [
                    {
                        testOptionId: testDefinitionOptionGuid0,
                        alternatives: [],
                        scenarioInteractiveElementType: 7,
                        imageUrl: item.imageUrl ? item.imageUrl : null,
                    },
                    {
                        testOptionId: testDefinitionOptionGuid1,
                        alternatives: [
                            {
                                correct: true,
                                text: item.text,
                                textLanguageId: -1,
                                startIndex: -1,
                            },
                        ],
                        explanation: item.l1TranslationText || '',
                        explanationLanguageId: item.l1TranslationTextLanguageId,
                        scenarioInteractiveElementType: 10,
                        text: item.text,
                        textLanguageId: -1,
                        audioPlayType: 1,
                        textAudioUrl: item.textAudioUrl ? item.textAudioUrl : null,
                    },
                ]
                nodeToDuplicate.scenarioNodeType = ScenarioNodeType.TestDefinition
                nodeToDuplicate.type = ScenarioNodeType.TestDefinition
                nodeToDuplicate.isNew = true
                newNodes.push(nodeToDuplicate)
            })
        }

        // Connect nodes if author requested. If speak stage, connect first speak answer.
        newNodes.forEach((item, index) => {
            if (shouldConnect === true && index < newNodes.length - 1) {
                if (item.options && item.options.length > 0) {
                    item.options[0].nextScenarioNodeId = newNodes[index + 1].nodeId
                } else {
                    item.options = [
                        {
                            failure: false,
                            scoreAlteration: 0,
                            scoreReasoningLanguageId: 0,
                            text: '',
                            nextScenarioNodeId: newNodes[index + 1].nodeId,
                        },
                    ]
                }
            }
        })
        nodesTemp = [...nodesTemp, ...newNodes]
        props.dispatch(
            lessonActions.updateScenario(
                {
                    ...selectedScenarioStateRef.current,
                    scenarioNodes: nodesTemp,
                },
                selectedScenarioStateRef.current.id
            )
        )
        return
    }

    const handleDuplicateStage = (nodeId, type, shouldConnectIfFree) => {
        if (openDataPickerPanelStateRef.current === true) {
            return
        }
        if (!props.lesson.canEditLesson) {
            return
        }
        props.dispatch(lessonActions.lessonBuilderBusy(true))
        let availableConnectionIndex = isNodeExitsOccupied(nodeId, stateRef.current)
        let nodeToDuplicate = null
        let newYShifter = 0
        let imageLocation = 'backgroundImageUrl'
        let sourceNode = null
        stateRef.current.forEach((node) => {
            if (node.nodeId === nodeId) {
                nodeToDuplicate = JSON.parse(JSON.stringify(node)) //[...nodes]
                sourceNode = JSON.parse(JSON.stringify(node)) //[...nodes]
                switch (node.scenarioNodeType) {
                    case ScenarioNodeType.TestImage:
                        imageLocation = 'testOptions' // strings for readability
                        break
                    case ScenarioNodeType.TestDefinition:
                        imageLocation = 'testOptions'
                        break
                    case ScenarioNodeType.Instruction:
                        imageLocation = 'testOptions'
                        break
                }
            }
        })

        let nodeVerticalSize = 300
        if (isSketchModeSelected) nodeVerticalSize = 420
        newYShifter = collisionDetection(nodeToDuplicate.position.x + nodeVerticalSize, nodeToDuplicate.position.y)
        if (nodeToDuplicate) {
            delete nodeToDuplicate.authorNoteDtos
            if (nodeToDuplicate.type !== type) {
                if (nodeToDuplicate.type === ScenarioNodeType.Brief) {
                    delete nodeToDuplicate.backgroundImageUrl
                }
                switch (type) {
                    case ScenarioNodeType.TestAssociationPuzzle:
                        delete nodeToDuplicate.scenarioMentors
                        delete nodeToDuplicate.data.scenarioMentors
                        delete nodeToDuplicate.data.testOptionImageUrl
                        delete nodeToDuplicate.texts
                        delete nodeToDuplicate.backgroundAudioUrl
                        let testOptionGuidPuzzle0 = uuidv4()
                        let textsTextSnippetId = uuidv4()

                        nodeToDuplicate.lessonNodeScoringDynamicConfigurationV1Dto = {
                            shouldBeScored: selectedScenario.lessonType === LessonType.NotDefined,
                        }

                        let tempTestOptions = [
                            {
                                audioPlayType: 2,
                                elementAlignmentType: 1,
                                scenarioInteractiveElementType: 11,
                                testOptionId: testOptionGuidPuzzle0,
                                textLanguageId: -1,
                            },
                        ]
                        let tempTexts = [
                            {
                                text: '',
                                textLanguageId: selectedScenario.l1Id,
                                textSnippetId: textsTextSnippetId,
                                textType: 2,
                            },
                        ]
                        nodeToDuplicate.type = 14
                        nodeToDuplicate.scenarioNodeType = 14
                        nodeToDuplicate.testOptions = tempTestOptions
                        nodeToDuplicate.testOptions[0].alternatives = []
                        let newPuzzleAssociatedTextSnippetId = uuidv4()
                        let newPuzzletextSnippetId = uuidv4()
                        nodeToDuplicate.testOptions[0].alternatives.push({
                            associatedText: '',
                            associatedTextLanguageId: selectedScenario.l1Id,
                            associatedTextSnippetId: newPuzzleAssociatedTextSnippetId,
                            correct: false,
                            startIndex: -1,
                            text: '',
                            textLanguageId: selectedScenario.languageId,
                            textSnippetId: newPuzzletextSnippetId,
                        })

                        nodeToDuplicate.data.options = []
                        nodeToDuplicate.data.onDuplicate = onDuplicate
                        nodeToDuplicate.data.onAddOther = onAddOther
                        nodeToDuplicate.data.onAdd = onAdd
                        nodeToDuplicate.data.onSuggest = onSuggest
                        nodeToDuplicate.data.onUpdateSelection = onUpdateSelection
                        nodeToDuplicate.data.onUpdateAnswerText = onUpdateAnswerText
                        nodeToDuplicate.data.onUpdateSelectedNode = handleUpdateSelectedNode

                        nodeToDuplicate.data.onPlayAppFromNode = handlePlayAppFromNode
                        nodeToDuplicate.data.onAddAlternative = onAddAlternative
                        if (currentNodeObjectStateRef) {
                            nodeToDuplicate.data.currentNodeObject = currentNodeObjectStateRef.nodeId
                        }

                        nodeToDuplicate.texts = tempTexts
                        break

                    case ScenarioNodeType.Debrief:
                        nodeToDuplicate.lessonNodeScoringDynamicConfigurationV1Dto = {
                            shouldBeScored: false,
                        }

                        delete nodeToDuplicate.scenarioMentors
                        delete nodeToDuplicate.data.scenarioMentors
                        delete nodeToDuplicate.data.testOptionImageUrl
                        delete nodeToDuplicate.texts
                        delete nodeToDuplicate.backgroundAudioUrl
                        nodeToDuplicate.testOptions = []
                        stateRef.current.forEach((node) => {
                            // look for and replace the Debrief image with the latest brief or debrief image details
                            if (
                                node.scenarioNodeType === ScenarioNodeType.Brief ||
                                node.scenarioNodeType === ScenarioNodeType.Debrief
                            ) {
                                nodeToDuplicate.backgroundImageUrl = node.backgroundImageUrl
                                nodeToDuplicate.data.backgroundImageUrl = node.data.backgroundImageUrl
                                nodeToDuplicate.data.imageUrl = node.data.imageUrl
                            }
                        })
                        break

                    case ScenarioNodeType.Watch:
                        nodeToDuplicate.lessonNodeScoringDynamicConfigurationV1Dto = {
                            shouldBeScored: false,
                        }

                        nodeToDuplicate.nodeLengthSeconds = 3
                        nodeToDuplicate.backgroundImageUrl =
                            imageLocation === 'backgroundImageUrl'
                                ? nodeToDuplicate.backgroundImageUrl
                                : nodeToDuplicate.testOptions[0].imageUrl
                        delete nodeToDuplicate.texts
                        delete nodeToDuplicate.data.testOptionImageUrl
                        nodeToDuplicate.testOptions = []
                        break

                    case ScenarioNodeType.Listen:
                        nodeToDuplicate.lessonNodeScoringDynamicConfigurationV1Dto = {
                            shouldBeScored: false,
                        }

                        nodeToDuplicate.backgroundImageUrl =
                            imageLocation === 'backgroundImageUrl'
                                ? nodeToDuplicate.backgroundImageUrl
                                : nodeToDuplicate.testOptions[0].imageUrl
                        nodeToDuplicate.testOptions = []
                        delete nodeToDuplicate.texts
                        delete nodeToDuplicate.data.testOptionImageUrl
                        break

                    case ScenarioNodeType.Speak:
                        nodeToDuplicate.lessonNodeScoringDynamicConfigurationV1Dto = {
                            shouldBeScored: true,
                        }

                        nodeToDuplicate.backgroundImageUrl =
                            imageLocation === 'backgroundImageUrl'
                                ? nodeToDuplicate.backgroundImageUrl
                                : nodeToDuplicate.testOptions[0].imageUrl
                        nodeToDuplicate.texts = nodeToDuplicate.texts.filter(
                            (x) => x.textType === ScenarioTextType.Question
                        )
                        delete nodeToDuplicate.data.testOptionImageUrl
                        nodeToDuplicate.testOptions = []
                        break

                    case ScenarioNodeType.BranchingMultiChoice:
                        nodeToDuplicate.lessonNodeScoringDynamicConfigurationV1Dto = {
                            shouldBeScored: true,
                        }

                        nodeToDuplicate.backgroundImageUrl =
                            imageLocation === 'backgroundImageUrl'
                                ? nodeToDuplicate.backgroundImageUrl
                                : nodeToDuplicate.testOptions[0].imageUrl
                        nodeToDuplicate.texts = nodeToDuplicate.texts.filter(
                            (x) => x.textType === ScenarioTextType.Question
                        )
                        delete nodeToDuplicate.data.testOptionImageUrl
                        nodeToDuplicate.testOptions = []
                        break

                    case ScenarioNodeType.MultiChoice:
                        nodeToDuplicate.lessonNodeScoringDynamicConfigurationV1Dto = {
                            shouldBeScored: selectedScenario.lessonType === LessonType.NotDefined,
                        }

                        nodeToDuplicate.backgroundImageUrl =
                            imageLocation === 'backgroundImageUrl'
                                ? nodeToDuplicate.backgroundImageUrl
                                : nodeToDuplicate.testOptions[0].imageUrl
                        delete nodeToDuplicate.texts
                        delete nodeToDuplicate.data.testOptionImageUrl
                        nodeToDuplicate.testOptions = [
                            {
                                audioPlayType: ScenarioAudioPlayType.PlayBefore,
                                scenarioInteractiveElementType: ScenarioInteractiveElementType.MultiChoice,
                                testOptionId: uuidv4(),
                                text: '',
                                textSnippetId: null,
                                textLanguageId: selectedScenarioStateRef.current.languageId,
                                textAudioUrl: '',
                            },
                        ]
                        break

                    case ScenarioNodeType.ListenMultiChoice:
                        nodeToDuplicate.lessonNodeScoringDynamicConfigurationV1Dto = {
                            shouldBeScored: selectedScenario.lessonType === LessonType.NotDefined,
                        }

                        nodeToDuplicate.backgroundImageUrl =
                            imageLocation === 'backgroundImageUrl'
                                ? nodeToDuplicate.backgroundImageUrl
                                : nodeToDuplicate.testOptions[0].imageUrl
                        delete nodeToDuplicate.texts
                        delete nodeToDuplicate.data.testOptionImageUrl
                        nodeToDuplicate.testOptions = [
                            {
                                audioPlayType: ScenarioAudioPlayType.PlayBefore,
                                scenarioInteractiveElementType: ScenarioInteractiveElementType.ListenButtonWithSlider,
                                testOptionId: uuidv4(),
                                text: '',
                                textSnippetId: null,
                                textLanguageId: selectedScenarioStateRef.current.languageId,
                                textAudioUrl: '',
                            },
                        ]
                        break

                    case ScenarioNodeType.TestRule:
                        delete nodeToDuplicate.scenarioMentors
                        delete nodeToDuplicate.data.scenarioMentors
                        delete nodeToDuplicate.backgroundAudioUrl
                        nodeToDuplicate.texts = [
                            {
                                text: '',
                                textLanguageId: selectedScenarioStateRef.current.languageId,
                                textSnippetId: null,
                                textType: 2,
                            },
                        ]
                        nodeToDuplicate.testOptions = []
                        delete nodeToDuplicate.backgroundImageUrl
                        break

                    case ScenarioNodeType.TestDialog:
                        delete nodeToDuplicate.scenarioMentors
                        delete nodeToDuplicate.data.scenarioMentors
                        delete nodeToDuplicate.backgroundAudioUrl
                        nodeToDuplicate.texts = [
                            {
                                text: '',
                                textLanguageId: selectedScenarioStateRef.current.languageId,
                                textSnippetId: null,
                                textType: 2,
                            },
                        ]
                        nodeToDuplicate.testOptions = []
                        delete nodeToDuplicate.backgroundImageUrl
                        break

                    case ScenarioNodeType.TestImage:
                        let testOptionGuid0 = uuidv4()
                        let testOptionGuid1 = uuidv4()
                        let testOptionGuid2 = uuidv4()
                        let textSnippetIdTitle = uuidv4()
                        let l1 = ''
                        let l2 = ''
                        let textAudioUrl = null
                        if (nodeToDuplicate.testOptions?.length > 1 && nodeToDuplicate.testOptions[1].textAudioUrl) {
                            textAudioUrl = nodeToDuplicate.testOptions[1].textAudioUrl
                        }

                        nodeToDuplicate.lessonNodeScoringDynamicConfigurationV1Dto = {
                            shouldBeScored: selectedScenario.lessonType === LessonType.NotDefined,
                        }

                        if (nodeToDuplicate.type === ScenarioNodeType.TestDefinition) {
                            if (
                                nodeToDuplicate.testOptions?.length > 1 &&
                                nodeToDuplicate.testOptions[1]?.explanation !== ''
                            ) {
                                l1 = nodeToDuplicate.testOptions[1].explanation
                                l2 = nodeToDuplicate.testOptions[1].text
                            }
                        }

                        delete nodeToDuplicate.backgroundAudioUrl
                        delete nodeToDuplicate.scenarioMentors
                        delete nodeToDuplicate.data.scenarioMentors
                        nodeToDuplicate.testOptions = [
                            {
                                testOptionId: testOptionGuid0,
                                alternatives: [],
                                imageUrl:
                                    imageLocation === 'backgroundImageUrl'
                                        ? nodeToDuplicate.backgroundImageUrl
                                        : nodeToDuplicate.testOptions[0].imageUrl,
                                //imageUrl: null,//"image/file/a99a5f1a-5492-449f-8e19-48ed5ec967f8",
                                scenarioInteractiveElementType: 7,
                            },
                            {
                                testOptionId: testOptionGuid1,
                                alternatives: [],
                                scenarioInteractiveElementType: 4,
                                text: l1,
                                textLanguageId: selectedScenarioStateRef.current.l1Id,
                                elementAlignmentType: 1,
                            },
                            {
                                testOptionId: testOptionGuid2,
                                alternatives: [],
                                scenarioInteractiveElementType: 3,
                                text: l2,
                                textLanguageId: selectedScenarioStateRef.current.languageId,
                                textAudioUrl: null,
                                elementAlignmentType: 1,
                                audioPlayType: 2,
                            },
                        ]
                        if (textAudioUrl) {
                            nodeToDuplicate.testOptions[2].textAudioUrl = textAudioUrl
                        }
                        nodeToDuplicate.texts = [
                            {
                                text: '',
                                textLanguageId: -1,
                                textSnippetId: textSnippetIdTitle,
                                textType: 2,
                            },
                        ]
                        delete nodeToDuplicate.backgroundImageUrl
                        break

                    case ScenarioNodeType.TestListenWithSlider:
                        let testListenWithSliderTestOptionGuid0 = uuidv4()
                        let testListenWithSliderTestOptionGuid1 = uuidv4()
                        let testListenWithSliderTestOptionGuid2 = uuidv4()
                        let testListenWithSliderTextSnippetIdTitle = uuidv4()

                        nodeToDuplicate.lessonNodeScoringDynamicConfigurationV1Dto = {
                            shouldBeScored: selectedScenario.lessonType === LessonType.NotDefined,
                        }

                        delete nodeToDuplicate.backgroundAudioUrl
                        delete nodeToDuplicate.scenarioMentors
                        delete nodeToDuplicate.data.scenarioMentors
                        nodeToDuplicate.testOptions = [
                            {
                                testOptionId: testListenWithSliderTestOptionGuid0,
                                //imageUrl: null,//"image/file/a99a5f1a-5492-449f-8e19-48ed5ec967f8",
                                scenarioInteractiveElementType: ScenarioInteractiveElementType.ListenButtonWithSlider,
                                audioPlayType: ScenarioAudioPlayType.PlayBefore,
                            },
                            {
                                testOptionId: testListenWithSliderTestOptionGuid1,
                                alternatives: [],
                                scenarioInteractiveElementType: ScenarioInteractiveElementType.Text,
                                text: '',
                                textLanguageId: selectedScenario.l1Id,
                                elementAlignmentType: 1,
                            },
                            {
                                testOptionId: testListenWithSliderTestOptionGuid2,
                                alternatives: [],
                                scenarioInteractiveElementType: ScenarioInteractiveElementType.FillInBlankDistractors,
                                text: '',
                                textLanguageId: -1,
                                textAudioUrl: null,
                                elementAlignmentType: ScenarioAudioPlayType.DontPlay,
                                audioPlayType: 0,
                            },
                        ]

                        nodeToDuplicate.texts = [
                            {
                                text: '',
                                textLanguageId: -1,
                                textSnippetId: testListenWithSliderTextSnippetIdTitle,
                                textType: 2,
                            },
                        ]
                        delete nodeToDuplicate.backgroundImageUrl
                        break

                    case ScenarioNodeType.Instruction:
                        let instructionStageImageTestOptionId = uuidv4()
                        let instructionStageMentorAnimationTestOptionId = uuidv4()
                        let instructionStageInstructionTestOptionId = uuidv4()
                        nodeToDuplicate.texts = []
                        delete nodeToDuplicate.scenarioMentors
                        delete nodeToDuplicate.data.scenarioMentors
                        let instructionExplanation = ''
                        let instructionDefinition = ''

                        nodeToDuplicate.lessonNodeScoringDynamicConfigurationV1Dto = {
                            shouldBeScored: false,
                        }

                        if (nodeToDuplicate.type === ScenarioNodeType.TestImage) {
                            if (nodeToDuplicate.testOptions?.length > 2) {
                                instructionExplanation = nodeToDuplicate.testOptions[2].explanation
                                instructionDefinition = nodeToDuplicate.testOptions[2].text
                            }
                        }

                        nodeToDuplicate.testOptions = [
                            {
                                testOptionId: instructionStageImageTestOptionId,
                                alternatives: [],
                                scenarioInteractiveElementType: 7,
                                imageUrl:
                                    imageLocation === 'backgroundImageUrl'
                                        ? nodeToDuplicate.backgroundImageUrl
                                        : nodeToDuplicate.testOptions[0].imageUrl,
                            },
                            {
                                testOptionId: instructionStageMentorAnimationTestOptionId,
                                scenarioInteractiveElementType: ScenarioInteractiveElementType.MentorAnimation,
                                mentor: MentorEnum.NoMentor,
                            },
                            {
                                testOptionId: instructionStageInstructionTestOptionId,
                                alternatives: [
                                    {
                                        correct: true,
                                        text: instructionDefinition,
                                        textLanguageId: -1,
                                        startIndex: -1,
                                    },
                                ],
                                explanation: instructionExplanation,
                                explanationLanguageId: selectedScenario.l1Id,
                                scenarioInteractiveElementType: ScenarioInteractiveElementType.Instruction,
                                text: instructionDefinition,
                                textLanguageId: -1,
                                audioPlayType: 1,
                            },
                        ]

                        delete nodeToDuplicate.backgroundAudioUrl
                        delete nodeToDuplicate.backgroundImageUrl
                        break

                    case ScenarioNodeType.TestDefinition:
                        let testDefinitionOptionGuid0 = uuidv4()
                        let testDefinitionOptionGuid1 = uuidv4()
                        nodeToDuplicate.texts = []
                        delete nodeToDuplicate.scenarioMentors
                        delete nodeToDuplicate.data.scenarioMentors
                        let explanation = ''
                        let definition = ''

                        nodeToDuplicate.lessonNodeScoringDynamicConfigurationV1Dto = {
                            shouldBeScored: selectedScenario.lessonType === LessonType.NotDefined,
                        }

                        let textAudioUrlDefinition = null
                        if (nodeToDuplicate.testOptions?.length > 2 && nodeToDuplicate.testOptions[2].textAudioUrl) {
                            textAudioUrlDefinition = nodeToDuplicate.testOptions[2].textAudioUrl
                        }

                        if (nodeToDuplicate.type === ScenarioNodeType.TestImage) {
                            if (nodeToDuplicate.testOptions?.length > 2) {
                                explanation = nodeToDuplicate.testOptions[1].text
                                definition = nodeToDuplicate.testOptions[2].text
                            }
                        }

                        nodeToDuplicate.testOptions = [
                            {
                                testOptionId: testDefinitionOptionGuid0,
                                alternatives: [],
                                scenarioInteractiveElementType: 7,
                                imageUrl:
                                    imageLocation === 'backgroundImageUrl'
                                        ? nodeToDuplicate.backgroundImageUrl
                                        : nodeToDuplicate.testOptions[0].imageUrl,
                            },
                            {
                                testOptionId: testDefinitionOptionGuid1,
                                alternatives: [
                                    {
                                        correct: true,
                                        text: definition,
                                        textLanguageId: -1,
                                        startIndex: -1,
                                    },
                                ],
                                explanation: explanation,
                                explanationLanguageId: selectedScenario.l1Id,
                                scenarioInteractiveElementType: 10,
                                text: definition,
                                textLanguageId: -1,
                                audioPlayType: 1,
                            },
                        ]
                        if (textAudioUrlDefinition) {
                            nodeToDuplicate.testOptions[1].textAudioUrl = textAudioUrlDefinition
                        }
                        delete nodeToDuplicate.backgroundAudioUrl
                        delete nodeToDuplicate.backgroundImageUrl
                        break

                    default:
                        nodeToDuplicate.backgroundImageUrl =
                            imageLocation === 'backgroundImageUrl'
                                ? nodeToDuplicate.backgroundImageUrl
                                : nodeToDuplicate.testOptions[0].imageUrl
                        nodeToDuplicate.lessonNodeScoringDynamicConfigurationV1Dto = {
                            shouldBeScored: false,
                        }
                }
            } else if (nodeToDuplicate.type === ScenarioNodeType.TestImage) {
                nodeToDuplicate.testOptions[2].alternatives = []
                nodeToDuplicate.testOptions[2].text = ''
                nodeToDuplicate.testOptions[2].audioPlayType = 2
            } else if (nodeToDuplicate.type === ScenarioNodeType.TestDefinition) {
                let testDefinitionOptionGuid0 = uuidv4()
                let testDefinitionOptionGuid1 = uuidv4()

                delete nodeToDuplicate.scenarioMentors
                delete nodeToDuplicate.data.scenarioMentors

                nodeToDuplicate.testOptions = [
                    {
                        testOptionId: testDefinitionOptionGuid0,
                        alternatives: [],
                        scenarioInteractiveElementType: 7,
                        imageUrl: null,
                    },
                    {
                        testOptionId: testDefinitionOptionGuid1,
                        alternatives: [
                            {
                                correct: true,
                                text: '',
                                textLanguageId: -1,
                                startIndex: -1,
                            },
                        ],
                        explanation: '',
                        explanationLanguageId: -1,
                        scenarioInteractiveElementType: 10,
                        text: '',
                        textLanguageId: -1,
                        audioPlayType: 1,
                    },
                ]
                delete nodeToDuplicate.backgroundImageUrl
            } else if (nodeToDuplicate.type === ScenarioNodeType.TestAssociationPuzzle) {
                let duplicatePuzzletextSnippetId = uuidv4()
                let duplicatePuzzleAssociatedTextSnippetId = uuidv4()
                nodeToDuplicate.testOptions[0].alternatives.push({
                    associatedText: '',
                    associatedTextLanguageId: selectedScenario.l1Id,
                    associatedTextSnippetId: duplicatePuzzleAssociatedTextSnippetId,
                    correct: false,
                    startIndex: -1,
                    text: '',
                    textLanguageId: selectedScenario.languageId,
                    textSnippetId: duplicatePuzzletextSnippetId,
                })
            }
            let nodeGuid = uuidv4()
            let newX = nodeToDuplicate.xPos + 300
            let newY = newYShifter
            let nodesTemp = [...stateRef.current]
            delete nodeToDuplicate.sfxAudioUrl
            nodeToDuplicate.nodeId = nodeGuid
            nodeToDuplicate.id = nodeGuid
            nodeToDuplicate.position = { x: newX, y: newY }
            nodeToDuplicate.xPos = newX
            nodeToDuplicate.yPos = newY
            nodeToDuplicate.options = []
            nodeToDuplicate.scenarioNodeType = type
            nodeToDuplicate.type = type
            nodeToDuplicate.isNew = true
            nodeToDuplicate.data.id = nodeGuid
            nodeToDuplicate.data.options = []
            nodeToDuplicate.data.onDuplicate = onDuplicate
            nodeToDuplicate.data.onAddOther = onAddOther
            nodeToDuplicate.data.onAdd = onAdd
            nodeToDuplicate.data.onSuggest = onSuggest
            nodeToDuplicate.data.onUpdateSelection = onUpdateSelection
            nodeToDuplicate.data.onUpdateAnswerText = onUpdateAnswerText
            nodeToDuplicate.data.onUpdateSelectedNode = handleUpdateSelectedNode

            nodeToDuplicate.data.onPlayAppFromNode = handlePlayAppFromNode
            nodeToDuplicate.data.onAddAlternative = onAddAlternative
            if (currentNodeObjectStateRef) {
                nodeToDuplicate.data.currentNodeObject = currentNodeObjectStateRef.nodeId
            }

            nodesTemp.push(nodeToDuplicate)
            nodesTemp.forEach((no) => {
                if (no?.nodeId === sourceNode.nodeId) {
                    if (availableConnectionIndex > -1) {
                        no.options[availableConnectionIndex].nextScenarioNodeId = nodeToDuplicate.nodeId
                    }
                }
            })
            setCurrentY(stateRefCurrentY.current)
            props.dispatch(
                lessonActions.updateScenario(
                    {
                        ...selectedScenarioStateRef.current,
                        scenarioNodes: nodesTemp,
                    },
                    selectedScenarioStateRef.current.id
                )
            )
        }
    }

    const handleDeleteFromNode = (nodeId) => {
        let localSelectedNode = currentNode
        if (nodeId !== null) {
            selectedScenarioStateRef.current.scenarioNodes.forEach((node) => {
                if (node.nodeId === nodeId) {
                    localSelectedNode = node
                }
            })
        }
        if (localSelectedNode) {
            console.log('found node for node id ' + nodeId)
        } else {
            console.log('did not find node for node id ' + nodeId)
        }

        setNodeToDeleteAfterConfirmation(localSelectedNode)
        setShowStageDeleteConfirmation(true)
    }

    const handlePlayAppFromEditNode = (event) => {
        handlePlayAppFromNode(currentNodeObject.nodeId)
    }

    const handlePlayAppFromBrowser = () => {
        handleOpenLessonPreview(currentNodeObject.nodeId)
    }

    const handlePlayAppFromNode = (nodeId) => {
        if (!!!selectedScenarioStateRef.current.validated) {
            setOpenPlayErrorHelp(true)
            return
        }
        let localSelectedNode = currentNodeObject
        if (nodeId !== null && currentNodeObject.nodeId !== nodeId) {
            selectedScenarioStateRef.current.scenarioNodes.forEach((node) => {
                if (node.nodeId === nodeId) {
                    localSelectedNode = node
                }
            })
        }
        const { user } = props

        let startLessonMessage = {
            lessonId: selectedScenarioStateRef.current.id,
            lessonName: selectedScenarioStateRef.current.name,
            lessonL1Id: selectedScenarioStateRef.current.l1Id,
            lessonL2Id: selectedScenarioStateRef.current.languageId,
            lessonImageUrl: '',
            lessonNodeId: localSelectedNode.nodeId,
            lessonNodeType: localSelectedNode.scenarioNodeType,
        }

        props.dispatch(
            userActions.createUserMessage(
                user.info.id,
                JSON.stringify(startLessonMessage),
                UserMessageType.StartScenarioLesson,
                selectedScenarioStateRef.current.organizationId
            )
        )
    }

    const handlePlayAppInBrowserFromNode = (nodeId) => {
        playFromNodeId.current = nodeId
    }

    const handleClose = (event) => {
        //setShowEditNode(false)
        setOpenPlayErrorHelp(false)
        setDisplayComments(false)
        setCurrentPlayAudioBlobUrl(null)
        setCurrentAudioTracker(null)
        setSelectedNodeAudioTrackers([])
        setOpenOptions(false)
        setShowEditNode(false)
        setCanPerformCollabCheck(true)
        setCurrentNodeObject(null)
        setCurrentNode(null)
        setOpenAddNodes(false)
        setCurrentNodeUploadImageHolder(null)
    }

    const publishLesson = (event) => {
        const { organization } = props
        setCheckForCanBePublished(false)
        setCanBePublished(false)
        props.dispatch(lessonActions.publishScenario(selectedScenario.id, organization.selectedOrganization))
    }
    const handleToggleCanEditMode = (event) => {
        if (props.user?.organizationRole === 'admin' || props.user?.organizationRole === 'teacher') {
            let canEditLessonTemp = !!!props.lesson.canEditLesson
            props.dispatch(lessonActions.lessonBuilderCanEditLesson(canEditLessonTemp))

            // resetNodesAfterCanEditLessonChanged(canEditLessonTemp);
        }
    }

    const hangleToggleViewChange = (event) => {
        let tempToggleViewModeType = ToggleModeViewTypes.NormalEdit
        if (!props.lesson.lessonBuilderViewMode) {
            props.dispatch(lessonActions.lessonBuilderViewMode(ToggleModeViewTypes.NormalEdit))
        }

        switch (props.lesson.lessonBuilderViewMode) {
            case ToggleModeViewTypes.NormalEdit:
                setIsSketchModeSelected(true)
                props.dispatch(lessonActions.lessonBuilderViewMode(ToggleModeViewTypes.SketchMode))
                tempToggleViewModeType = ToggleModeViewTypes.SketchMode
                break
            case ToggleModeViewTypes.SketchMode:
                setIsSketchModeSelected(false)
                props.dispatch(lessonActions.lessonBuilderViewMode(ToggleModeViewTypes.DisplayTemplate))
                tempToggleViewModeType = ToggleModeViewTypes.DisplayTemplate
                break
            case ToggleModeViewTypes.DisplayTemplate:
                setIsSketchModeSelected(false)
                props.dispatch(lessonActions.lessonBuilderViewMode(ToggleModeViewTypes.NormalEdit))
                tempToggleViewModeType = ToggleModeViewTypes.NormalEdit
                break
        }
        changeNodesForViewMode(tempToggleViewModeType)
    }

    const getNodeTypeForToggleViewModeType = (nodeType, toggleViewModeType) => {
        if (toggleViewModeType === ToggleModeViewTypes.NormalEdit) {
            switch (nodeType) {
                case AdminSketchScenarioNodeType.Watch:
                    return ScenarioNodeType.Watch
                case AdminSketchScenarioNodeType.Listen:
                    return ScenarioNodeType.Listen
                case AdminSketchScenarioNodeType.Speak:
                    return ScenarioNodeType.Speak
                case AdminSketchScenarioNodeType.Debrief:
                    return ScenarioNodeType.Debrief
                case AdminSketchScenarioNodeType.Brief:
                    return ScenarioNodeType.Brief
                case AdminPreviewScenarioNodeType.Watch:
                    return ScenarioNodeType.Watch
                case AdminPreviewScenarioNodeType.Listen:
                    return ScenarioNodeType.Listen
                case AdminPreviewScenarioNodeType.Speak:
                    return ScenarioNodeType.Speak
                case AdminPreviewScenarioNodeType.Debrief:
                    return ScenarioNodeType.Debrief
                case AdminPreviewScenarioNodeType.BranchingMultiChoice:
                    return ScenarioNodeType.BranchingMultiChoice
                case AdminPreviewScenarioNodeType.Brief:
                    return ScenarioNodeType.Brief
                case AdminPreviewScenarioNodeType.TestComprehension:
                    return ScenarioNodeType.TestComprehension
                case AdminPreviewScenarioNodeType.TestRule:
                    return ScenarioNodeType.TestRule
                case AdminPreviewScenarioNodeType.TestListenWithSlider:
                    return ScenarioNodeType.TestListenWithSlider
                case AdminPreviewScenarioNodeType.TestDefinition:
                    return ScenarioNodeType.TestDefinition
                case AdminPreviewScenarioNodeType.TestListenFillInBlank:
                    return ScenarioNodeType.TestListenFillInBlank
                case AdminPreviewScenarioNodeType.TestImage:
                    return ScenarioNodeType.TestImage
                case AdminPreviewScenarioNodeType.TestDialog:
                    return ScenarioNodeType.TestDialog
                case AdminPreviewScenarioNodeType.TestAssociationPuzzle:
                    return ScenarioNodeType.TestAssociationPuzzle
                case AdminSketchScenarioNodeType.Multichoice:
                    return ScenarioNodeType.MultiChoice
                case AdminSketchScenarioNodeType.ListenMultichoice:
                    return ScenarioNodeType.MultiChoice
            }
        } else if (toggleViewModeType === ToggleModeViewTypes.SketchMode) {
            switch (nodeType) {
                case ScenarioNodeType.Watch:
                    return AdminSketchScenarioNodeType.Watch
                case ScenarioNodeType.Listen:
                    return AdminSketchScenarioNodeType.Listen
                case ScenarioNodeType.Speak:
                    return AdminSketchScenarioNodeType.Speak
                case ScenarioNodeType.MultiChoice:
                    return AdminSketchScenarioNodeType.Multichoice
                case ScenarioNodeType.ListenMultiChoice:
                    return AdminSketchScenarioNodeType.ListenMultichoice
                case ScenarioNodeType.Debrief:
                    return AdminSketchScenarioNodeType.Debrief
                case ScenarioNodeType.Brief:
                    return AdminSketchScenarioNodeType.Brief
                case AdminPreviewScenarioNodeType.Watch:
                    return ScenarioNodeType.Watch
                case AdminPreviewScenarioNodeType.Listen:
                    return ScenarioNodeType.Listen
                case AdminPreviewScenarioNodeType.Speak:
                    return ScenarioNodeType.Speak
                case AdminPreviewScenarioNodeType.Debrief:
                    return ScenarioNodeType.Debrief
                case AdminPreviewScenarioNodeType.BranchingMultiChoice:
                    return AdminSketchScenarioNodeType.Multichoice
                case AdminPreviewScenarioNodeType.Brief:
                    return ScenarioNodeType.Brief
                case AdminPreviewScenarioNodeType.TestComprehension:
                    return ScenarioNodeType.TestComprehension
                case AdminPreviewScenarioNodeType.TestRule:
                    return ScenarioNodeType.TestRule
                case AdminPreviewScenarioNodeType.TestListenWithSlider:
                    return ScenarioNodeType.TestListenWithSlider
                case AdminPreviewScenarioNodeType.TestDefinition:
                    return ScenarioNodeType.TestDefinition
                case AdminPreviewScenarioNodeType.TestListenFillInBlank:
                    return ScenarioNodeType.TestListenFillInBlank
                case AdminPreviewScenarioNodeType.TestImage:
                    return ScenarioNodeType.TestImage
                case AdminPreviewScenarioNodeType.TestDialog:
                    return ScenarioNodeType.TestDialog
                case AdminPreviewScenarioNodeType.TestAssociationPuzzle:
                    return ScenarioNodeType.TestAssociationPuzzle
            }
        } else if (toggleViewModeType === ToggleModeViewTypes.DisplayTemplate) {
            switch (nodeType) {
                case AdminSketchScenarioNodeType.Watch:
                    return AdminPreviewScenarioNodeType.Watch
                case AdminSketchScenarioNodeType.Listen:
                    return AdminPreviewScenarioNodeType.Listen
                case AdminSketchScenarioNodeType.Speak:
                    return AdminPreviewScenarioNodeType.Speak
                case AdminSketchScenarioNodeType.Debrief:
                    return AdminPreviewScenarioNodeType.Debrief
                case AdminSketchScenarioNodeType.Brief:
                    return AdminPreviewScenarioNodeType.Brief
                case ScenarioNodeType.Watch:
                    return AdminPreviewScenarioNodeType.Watch
                case ScenarioNodeType.Listen:
                    return AdminPreviewScenarioNodeType.Listen
                case ScenarioNodeType.Speak:
                    return AdminPreviewScenarioNodeType.Speak
                case ScenarioNodeType.Debrief:
                    return AdminPreviewScenarioNodeType.Debrief
                case ScenarioNodeType.BranchingMultiChoice:
                    return AdminPreviewScenarioNodeType.BranchingMultiChoice
                case ScenarioNodeType.Brief:
                    return AdminPreviewScenarioNodeType.Brief
                case ScenarioNodeType.TestComprehension:
                    return AdminPreviewScenarioNodeType.TestComprehension
                case ScenarioNodeType.TestRule:
                    return AdminPreviewScenarioNodeType.TestRule
                case ScenarioNodeType.TestListenWithSlider:
                    return AdminPreviewScenarioNodeType.TestListenWithSlider
                case ScenarioNodeType.TestDefinition:
                    return AdminPreviewScenarioNodeType.TestDefinition
                case ScenarioNodeType.TestListenFillInBlank:
                    return AdminPreviewScenarioNodeType.TestListenFillInBlank
                case ScenarioNodeType.TestImage:
                    return AdminPreviewScenarioNodeType.TestImage
                case ScenarioNodeType.TestDialog:
                    return AdminPreviewScenarioNodeType.TestDialog
                case ScenarioNodeType.TestAssociationPuzzle:
                    return AdminPreviewScenarioNodeType.TestAssociationPuzzle
            }
        }
        return nodeType
    }

    const changeNodesForViewMode = (toggleViewModeType) => {
        let nodesTemp = JSON.parse(JSON.stringify(selectedScenarioStateRef.current.scenarioNodes)) //[...nodes]
        let newData = {}
        nodesTemp.forEach((node) => {
            newData.onDuplicate = onDuplicate
            newData.onAddOther = onAddOther
            newData.onAdd = onAdd
            newData.onSuggest = onSuggest
            newData.onUpdateSelection = onUpdateSelection
            newData.onUpdateAnswerText = onUpdateAnswerText
            newData.onUpdateSelectedNode = handleUpdateSelectedNode
            newData.onPlayAppFromNode = handlePlayAppFromNode
            newData.onDeleteNode = handleDeleteFromNode
            newData.onAddAlternative = onAddAlternative
            newData.canEditLesson = props.lesson.canEditLesson
            node.data = {
                ...node.data,
                ...newData,
            }
            node.type = getNodeTypeForToggleViewModeType(node.type, toggleViewModeType)
            scenarioImages.forEach((result) => {
                if (node.backgroundImageUrl === 'image/file/' + result.imageReferenceId) {
                    let backgroupFileUrl = URL.createObjectURL(result.image)
                    node.data.imageUrl = backgroupFileUrl
                }
            })
        })
        setNodes(nodesTemp)
        setEdgesWithCanEditLesson(props.lesson.selectedScenario.scenarioEdges)
    }

    const addOption = (text, score, nextScenarioNodeId) => {
        return {
            failure: false,
            scoreAlteration: score,
            text: text,
            textLanguageId: selectedScenario.languageId,
            nextScenarioNodeId,
            textAudioUrl: null,
            textSnippetId: null,
        }
    }

    const handleChangeStageLength = (value) => {
        if (!props.lesson.canEditLesson) {
            return
        }
        //var scenarioTemp = JSON.parse(JSON.stringify(selectedScenario));
        //scenarioTemp.shortDescription = text
        var currentNodeTemp = JSON.parse(JSON.stringify(currentNodeObject))
        currentNodeTemp.nodeLengthSeconds = value
        setCurrentNodeObject(currentNodeTemp)
    }

    const handleUpdateNodeAudio = (
        audioFieldType,
        inputUrl,
        nodeId,
        niceName,
        audioTrackerId,
        testOptionId,
        remove = false
    ) => {
        if (!props.lesson.canEditLesson) {
            return
        }
        // user has typed in text field for changing an existing answer's text. We should update the model
        let nodesTemp = JSON.parse(JSON.stringify(nodes)) //[...nodes]
        let currentNodeObjectTemp = JSON.parse(JSON.stringify(currentNodeObject)) //[...nodes]

        if (remove) {
            let newSelectedNodeAudioTrackers = selectedNodeAudioTrackers.filter((x) => x.trackerId !== audioTrackerId)
            setSelectedNodeAudioTrackers(newSelectedNodeAudioTrackers)
            setCurrentAudioTracker(null)
            setCurrentPlayAudioBlobUrl(null)
        } else {
            let apiUrl = null
            let audioBlobUrl = null
            let audioTracker = null
            if (inputUrl.startsWith('sound/file/')) {
                apiUrl = inputUrl
                audioTracker = selectedNodeAudioTrackers.find((x) => x.apiUrl === apiUrl)
            } else if (inputUrl.startWith('blob')) {
                audioBlobUrl = inputUrl
                audioTracker = selectedNodeAudioTrackers.find((x) => x.audioBlobUrl === inputUrl)
            }

            if (!apiUrl && !audioBlobUrl) {
                console.log('ERROR: updated ' + audioFieldType + ' with bad url')
                return
            }

            if (!audioTracker) {
                audioTracker = {
                    trackerId: uuidv4(),
                    audioFieldType: audioFieldType,
                    audioNiceName: '',
                    audioBlob: '',
                    audioUrl: apiUrl,
                    audioBlobUrl: audioBlobUrl,
                    audioFileExtension: '',
                    testOptionId: null,
                    optionId: null,
                    fileReferenceId: null,
                }
                setSelectedNodeAudioTrackers([...selectedNodeAudioTrackers, audioTracker])
            }

            audioTracker.audioNiceName = niceName

            setCurrentAudioTracker(audioTracker)
            setCurrentPlayAudioBlobUrl(inputUrl)
        }

        nodesTemp.forEach((node) => {
            if (node.nodeId === nodeId) {
                node[audioFieldType] = inputUrl

                if (audioFieldType === AudioFieldType.BackgroundAudioUrl) {
                    node.backgroundAudioUrl = inputUrl
                } else if (audioFieldType === AudioFieldType.SfxAudioUrl) {
                    node.sfxAudioUrl = inputUrl
                } else {
                    switch (node.scenarioNodeType) {
                        case ScenarioNodeType.Listen:
                            if (node.options.length >= 1) {
                                node.options[0].textAudioUrl = inputUrl
                            }
                            break
                        case ScenarioNodeType.TestImage:
                            if (node.testOptions.length >= 3) {
                                node.testOptions[2].textAudioUrl = inputUrl
                            }
                            break
                        case ScenarioNodeType.TestDefinition:
                            if (node.testOptions.length >= 2) {
                                node.testOptions[1].textAudioUrl = inputUrl
                            }
                            break
                        case ScenarioNodeType.TestRule:
                            if (testOptionId) {
                                node.testOptions.map((testOption) => {
                                    if (testOption.testOptionId === testOptionId) {
                                        testOption.textAudioUrl = inputUrl
                                    }
                                })
                            }
                            break
                        case ScenarioNodeType.ListenMultiChoice:
                            if (node.testOptions.length >= 1) {
                                node.testOptions[0].textAudioUrl = inputUrl
                            }
                    }
                }
            }
        })

        currentNodeObjectTemp[audioFieldType] = inputUrl

        if (audioFieldType === AudioFieldType.BackgroundAudioUrl) {
            currentNodeObjectTemp.backgroundAudioUrl = inputUrl
        } else if (audioFieldType === AudioFieldType.SfxAudioUrl) {
            currentNodeObjectTemp.sfxAudioUrl = inputUrl
        } else {
            switch (currentNodeObjectTemp.scenarioNodeType) {
                case ScenarioNodeType.Listen:
                    currentNodeObjectTemp.options[0].textAudioUrl = inputUrl
                    break
                case ScenarioNodeType.TestImage:
                    currentNodeObjectTemp.testOptions[2].textAudioUrl = inputUrl
                    break
                case ScenarioNodeType.TestDefinition:
                    currentNodeObjectTemp.testOptions[1].textAudioUrl = inputUrl
                    break
                case ScenarioNodeType.TestRule:
                    if (testOptionId) {
                        currentNodeObjectTemp.testOptions.map((testOption) => {
                            if (testOption.testOptionId === testOptionId) {
                                testOption.textAudioUrl = inputUrl
                            }
                        })
                    }
                    break
                case ScenarioNodeType.ListenMultiChoice:
                    currentNodeObjectTemp.testOptions[0].textAudioUrl = inputUrl
                    break
            }
        }

        setCurrentNodeObject(currentNodeObjectTemp)
        setNodes(nodesTemp)
        setEdgesWithCanEditLesson(props.lesson.selectedScenario.scenarioEdges)
    }

    const handleUpdateScenarioShortDescription = (text, isInL1) => {
        if (!props.lesson.canEditLesson) {
            return
        }
        var scenarioTemp = JSON.parse(JSON.stringify(selectedScenario))
        var shortDescriptionLanguageId = selectedScenario.languageId
        if (isInL1) {
            shortDescriptionLanguageId = selectedScenario.l1Id
        }
        scenarioTemp.shortDescription = text
        scenarioTemp.shortDescriptionLanguageId = shortDescriptionLanguageId
        scenarioTemp.shortDescriptionSnippetId = null
        setSelectedScenario(scenarioTemp)
    }

    const handleUpdateScenarioLessonType = (lessonType) => {
        if (!props.lesson.canEditLesson) {
            return
        }

        if (selectedScenario.lessonType !== lessonType) {
            var scenarioTemp = JSON.parse(JSON.stringify(selectedScenario))
            scenarioTemp.lessonType = lessonType
            setSelectedScenario(scenarioTemp)
        }
    }

    const handleUpdateScenarioDefinition = (text, explanation, nodeId, explanationIsInL1) => {
        if (!props.lesson.canEditLesson) {
            return
        }
        var scenarioTemp = JSON.parse(JSON.stringify(selectedScenario))
        let languageId = selectedScenario.languageId
        var explanationLanguageId = selectedScenario.languageId
        if (explanationIsInL1) {
            explanationLanguageId = selectedScenario.l1Id
        }

        var currentNodeTemp = JSON.parse(JSON.stringify(currentNodeObject))
        currentNodeTemp.testOptions[1].text = text
        currentNodeTemp.testOptions[1].textSnippetId = null
        currentNodeTemp.testOptions[1].textLanguageId = languageId

        currentNodeTemp.testOptions[1].explanation = explanation
        currentNodeTemp.testOptions[1].explanationSnippetId = null
        currentNodeTemp.testOptions[1].explanationLanguageId = explanationLanguageId
        currentNodeTemp.testOptions[1].alternatives[0].text = text
        setCurrentNodeObject(currentNodeTemp)
    }

    const handleUpdateRuleCardTitle = (text, nodeId) => {
        if (!props.lesson.canEditLesson) {
            return
        }
        var scenarioTemp = JSON.parse(JSON.stringify(selectedScenario))
        scenarioTemp.shortDescription = text
        var currentNodeTemp = JSON.parse(JSON.stringify(currentNodeObject))
        currentNodeTemp.texts[0].text = text
        setCurrentNodeObject(currentNodeTemp)
    }

    const handleBranchingMultiChoiceQuestionChanged = (text) => {
        if (!props.lesson.canEditLesson) {
            return
        }
        const currentNodeTemp = JSON.parse(JSON.stringify(currentNodeObject))

        let questionIndex = currentNodeTemp.texts.findIndex((x) => x.textType === ScenarioTextType.Question)
        if (questionIndex >= 0) {
            currentNodeTemp.texts[questionIndex].text = text
        } else {
            currentNodeTemp.texts.push({
                textType: ScenarioTextType.Question,
                text: text,
                textLanguageId: selectedScenario.l1Id,
            })
        }

        setCurrentNodeObject(currentNodeTemp)
    }

    const handleBranchingMultiChoiceQuestionLanguageIdChanged = (isInL1) => {
        if (!props.lesson.canEditLesson) {
            return
        }
        const currentNodeTemp = JSON.parse(JSON.stringify(currentNodeObject))

        let textLanguageId = selectedScenario.languageId
        if (isInL1) {
            textLanguageId = selectedScenario.l1Id
        }

        let questionIndex = currentNodeTemp.texts.findIndex((x) => x.textType === ScenarioTextType.Question)
        if (questionIndex >= 0) {
            currentNodeTemp.texts[questionIndex].textLanguageId = textLanguageId
        } else {
            currentNodeTemp.texts.push({
                textType: ScenarioTextType.Question,
                text: '',
                textLanguageId: textLanguageId,
            })
        }

        setCurrentNodeObject(currentNodeTemp)
    }

    const handleMultiChoiceQuestionDeleted = (testOptionIndex) => {
        if (!props.lesson.canEditLesson) {
            return
        }
        const currentNodeTemp = JSON.parse(JSON.stringify(currentNodeObject))

        if (testOptionIndex < 0 || testOptionIndex >= currentNodeTemp.testOptions.length) {
            console.log('handleUpdateMultiChoiceQuestion - TestOption index is out of bounds')
            return
        }

        currentNodeTemp.testOptions.splice(testOptionIndex, 1)
        setCurrentNodeObject(currentNodeTemp)
    }

    const handleUpdateMultiChoiceQuestionChanged = (text, testOptionIndex) => {
        if (!props.lesson.canEditLesson) {
            return
        }
        const currentNodeTemp = JSON.parse(JSON.stringify(currentNodeObject))

        if (testOptionIndex < 0 || testOptionIndex >= currentNodeTemp.testOptions.length) {
            console.log('handleUpdateMultiChoiceQuestion - TestOption index is out of bounds')
            return
        }

        currentNodeTemp.testOptions[testOptionIndex].text = text
        setCurrentNodeObject(currentNodeTemp)

        if (currentNodeTemp.testOptions[testOptionIndex].alternatives.length === 0) {
            const newAlternative = {
                text: '',
                correct: true,
            }
            currentNodeTemp.testOptions[testOptionIndex].alternatives.push(newAlternative)

            const newDistractor = {
                text: '',
                correct: false,
            }
            for (let i = 0; i < 3; i++) {
                currentNodeTemp.testOptions[testOptionIndex].alternatives.push(newDistractor)
            }
        }
    }

    const handleUpdateMultiChoiceAlternativeChanged = (text, testOptionIndex, alternativeIndex) => {
        if (!props.lesson.canEditLesson) {
            return
        }
        const currentNodeTemp = JSON.parse(JSON.stringify(currentNodeObject))

        if (testOptionIndex < 0 || testOptionIndex >= currentNodeTemp.testOptions.length) {
            console.log('handleUpdateMultiChoiceAlternative - TestOption index is out of bounds')
            return
        }

        if (alternativeIndex < 0) {
            console.log('handleUpdateMultiChoiceAlternative - Alternative index is out of bounds')
            return
        }

        if (alternativeIndex >= currentNodeTemp.testOptions[testOptionIndex].alternatives.length) {
            if (alternativeIndex > 3 || currentNodeTemp.testOptions[testOptionIndex].alternatives.length >= 4) {
                console.log('handleUpdateMultiChoiceAlternative - Alternative index is out of bounds')
                return
            }

            const newAlternative = {
                text: '',
                correct: false,
            }
            currentNodeTemp.testOptions[testOptionIndex].alternatives.push(newAlternative)
        }

        currentNodeTemp.testOptions[testOptionIndex].alternatives[alternativeIndex].text = text
        setCurrentNodeObject(currentNodeTemp)
    }

    const handleDistractorTypeChange = (type, testOptionId) => {
        if (!props.lesson.canEditLesson) {
            return
        }
        let currentNodeObjectTemp = JSON.parse(JSON.stringify(currentNodeObject)) //[...nodes]
        currentNodeObjectTemp.testOptions.forEach((to) => {
            if (to.testOptionId === testOptionId) {
                switch (type) {
                    case '4':
                        to.alternatives = []
                        to.scenarioInteractiveElementType = 4
                        break
                    case '2':
                        to.scenarioInteractiveElementType = 2
                        break
                    case '3':
                        to.scenarioInteractiveElementType = 3
                        break
                }
            }
        })
        setCurrentNodeObject(currentNodeObjectTemp)
    }

    const handleUpdateL1SubtitleText = (text) => {
        if (!props.lesson.canEditLesson) {
            return
        }
        var previousText = {}
        var currentNodeTemp = JSON.parse(JSON.stringify(currentNodeObjectStateRef.current))
        var previousSubtitleTexts = currentNodeTemp.texts.filter(
            (x) => x.textType === ScenarioTextType.Subtitle && x.textLanguageId === selectedScenario.l1Id
        )
        if (previousSubtitleTexts.length > 0) {
            var previousL1Subtitle = previousSubtitleTexts[0]
            previousL1Subtitle.text = text
        } else {
            var newL1Subtitle = {
                text: text,
                textLanguageId: selectedScenario.l1Id,
                textType: ScenarioTextType.Subtitle,
            }
            currentNodeTemp.texts.push(newL1Subtitle)
        }
        setCurrentNodeObject(currentNodeTemp)
    }

    const handleCreateOrEditComment = (createOrEdit, newAuthorComment) => {
        if (!props.lesson.canEditLesson) {
            return
        }
        const { computedMatch } = props
        const customLessonId = computedMatch.params.customLessonId
        let nodesTemp = JSON.parse(JSON.stringify(nodes)) //[...nodes]
        let nodeTemp = null
        let currentNodeTemp = JSON.parse(JSON.stringify(currentNodeObject))
        let authorNotes = []
        if (currentNodeTemp.authorNoteDtos) {
            authorNotes = currentNodeTemp.authorNoteDtos
        }
        if (createOrEdit === 0 && newAuthorComment !== null) {
            authorNotes.push(newAuthorComment)
        }
        if (createOrEdit === 1) {
            currentNodeTemp.authorNoteDtos.forEach((an) => {
                if (moment(newAuthorComment.createdAt).isSame(moment(an.createdAt))) {
                    an.authorNoteType = newAuthorComment.authorNoteType
                }
            })
        }
        nodesTemp.forEach((n, index) => {
            if (n.id === currentNodeTemp.id) {
                nodeTemp = n
            }
        })
        if (nodeTemp) {
            nodeTemp.authorNoteDtos = authorNotes
            props.dispatch(lessonActions.updateScenarioNodeComment(customLessonId, nodeTemp))
        }
    }

    const handleChangeInteraction = (interactionType, stageType, testOptionIndex) => {
        if (!props.lesson.canEditLesson) {
            return
        }
        var scenarioTemp = JSON.parse(JSON.stringify(selectedScenario))
        var currentNodeTemp = JSON.parse(JSON.stringify(currentNodeObject))

        let interactionLanguageId = selectedScenario.languageId // TODO: pass in language

        var audioUrl = null
        if (
            currentNodeTemp?.testOptions?.length >= testOptionIndex &&
            !currentNodeTemp.testOptions[testOptionIndex].textAudioUrl?.startsWith('sound/snippet/')
        ) {
            audioUrl = currentNodeTemp.testOptions[testOptionIndex].textAudioUrl
        }

        switch (interactionType) {
            case 0:
                currentNodeTemp.testOptions[testOptionIndex].alternatives = []
                currentNodeTemp.testOptions[testOptionIndex].scenarioInteractiveElementType = 2
                setCurrentNodeObject(currentNodeTemp)
                break
            case 1:
                //distractors
                let exsitingText = null
                currentNodeTemp.testOptions[testOptionIndex].alternatives = []
                currentNodeTemp.testOptions[testOptionIndex].scenarioInteractiveElementType = 3
                setCurrentNodeObject(currentNodeTemp)
                break
            case 2:
                //sentence builder
                let tempArrayWords = currentNodeTemp.testOptions[testOptionIndex].text.split(' ')
                //currentNodeTemp.testOptions[2].alternatives = tempArrayWords
                currentNodeTemp.testOptions[testOptionIndex].scenarioInteractiveElementType = 3
                handleEditDistractors(tempArrayWords, 'words', testOptionIndex)
                break
            case 3:
                //Reading (no distractors)
                currentNodeTemp.testOptions[testOptionIndex].alternatives = []
                currentNodeTemp.testOptions[testOptionIndex].scenarioInteractiveElementType = 4
                setCurrentNodeObject(currentNodeTemp)
                break
        }

        if (currentNodeTemp.testOptions[testOptionIndex].interactionScoringDynamicConfigurationV1Dto == null) {
            let shouldBeScored =
                selectedScenario.lessonType === LessonType.NotDefined &&
                currentNodeTemp.testOptions[testOptionIndex].scenarioInteractiveElementType !==
                    ScenarioInteractiveElementType.Text

            currentNodeTemp.testOptions[testOptionIndex].interactionScoringDynamicConfigurationV1Dto = {
                shouldBeScored: shouldBeScored,
            }
        }

        // Forcing backend to regenerate the node scoring.
        currentNodeTemp.lessonNodeScoringDynamicConfigurationV1Dto = null
    }

    const handleUpdateScenarioBrief = (text, isInL1) => {
        if (!props.lesson.canEditLesson) {
            return
        }
        var briefLanguageId = selectedScenario.languageId
        if (isInL1) {
            briefLanguageId = selectedScenario.l1Id
        }
        let index = 0
        var currentNodeTemp = JSON.parse(JSON.stringify(currentNodeObject))
        if (index >= currentNodeTemp.options.length) {
            currentNodeTemp.data.options.push(text)
            currentNodeTemp.options.push(addOption(text, 0, null))
        } else {
            if (text !== null) {
                currentNodeTemp.options[index].text = text
                currentNodeTemp.options[index].textLanguageId = briefLanguageId
            }

            if (text !== null) {
                currentNodeTemp.data.options[index] = text
            }
        }
        setCurrentNodeObject(currentNodeTemp)
    }

    const handleUpdateScenarioName = (text, isInL1) => {
        if (!props.lesson.canEditLesson) {
            return
        }
        var scenarioTemp = JSON.parse(JSON.stringify(selectedScenario))
        var scenarioNameLanguageId = selectedScenario.languageId
        console.log('isInL1', isInL1)
        if (isInL1) {
            console.log('Setting to l1id')
            scenarioNameLanguageId = selectedScenario.l1Id
        }
        scenarioTemp.name = text
        scenarioTemp.nameSnippetId = null
        scenarioTemp.nameLanguageId = scenarioNameLanguageId
        setSelectedScenario(scenarioTemp)
    }

    const onUpdateSelection = (updateableNodeId, hasRequestedAdd) => {
        props.dispatch(lessonActions.lessonBuilderBusy(true))
        const { computedMatch } = props
        let nodesTemp = JSON.parse(JSON.stringify(selectedScenarioStateRef.current.scenarioNodes)) //[...nodes]
        let node = null
        nodesTemp.forEach((n) => {
            if (n.nodeId === updateableNodeId) {
                node = n
                if (n.options) {
                    let tempOptions = []
                    n.options.forEach((option, index) => {
                        if (option.text === '' && (n.data.newlyAdded === undefined || n.data?.newlyAdded === false)) {
                        } else {
                            tempOptions.push(option)
                        }
                    })
                    n.options = tempOptions
                }
            }
        })

        const customLessonId = computedMatch.params.customLessonId
        props.dispatch(
            lessonActions.updateScenarioNode(
                node,
                selectedScenario.id,
                selectedScenarioStateRef.current.organizationId,
                null,
                null,
                true,
                ImageFieldType.NotDefined,
                customLessonId,
                props.lesson.imageReferences,
                selectedNodeAudioTrackers,
                props.lesson.lessonAudioReferences,
                {
                    ...selectedScenarioStateRef.current.scenarioNodes,
                    scenarioNodes: nodesTemp,
                }
            )
        )
    }

    const handleChangeWhenSpoken = (value, testOptionId) => {
        var currentNodeTemp = JSON.parse(JSON.stringify(currentNodeObject))
        if (currentNodeTemp.testOptions) {
            currentNodeTemp.testOptions.forEach((testOption) => {
                if (testOption.testOptionId === testOptionId) {
                    testOption.audioPlayType = value
                }
                setCurrentNodeObject(currentNodeTemp)
            })
        }
    }

    const handleSetPuzzleInteractionType = (interactionType) => {
        if (
            interactionType !== ScenarioInteractiveElementType.AssociationPuzzleSequential &&
            interactionType !== ScenarioInteractiveElementType.AssociationPuzzle &&
            interactionType !== ScenarioInteractiveElementType.AssociationPuzzleSequentialKeepRight
        ) {
            return
        }
        var currentNodeTemp = JSON.parse(JSON.stringify(currentNodeObject))
        if (currentNodeTemp.testOptions) {
            currentNodeTemp.testOptions[0].scenarioInteractiveElementType = interactionType
            setCurrentNodeObject(currentNodeTemp)
        }
    }

    const handleUpdateAnswerText = (text, index, nodeId, failure, scoreAlteration, scoreReasoning) => {
        // user has typed in text field for changing an existing answer's text or given score alteration. We should update the model
        let scenarioL2LanguageId = selectedScenario.languageId

        var currentNodeTemp = JSON.parse(JSON.stringify(currentNodeObject))
        if (index >= currentNodeTemp.options.length) {
            currentNodeTemp.data.options.push(text)
            currentNodeTemp.options.push(addOption(text, 0, null))
        } else {
            if (text !== null) {
                currentNodeTemp.options[index].text = text
                if (currentNodeTemp.type !== ScenarioNodeType.Debrief) {
                    currentNodeTemp.options[index].textLanguageId = scenarioL2LanguageId
                }
            }

            delete currentNodeTemp.options[index].textAudioUrl
            currentNodeTemp.options[index].failure = failure
            currentNodeTemp.options[index].scoreAlteration = scoreAlteration
            currentNodeTemp.options[index].scoreReasoning = scoreReasoning
            if (!currentNodeTemp.options[index].scoreReasoningLanguageId) {
                currentNodeTemp.options[index].scoreReasoningLanguageId = scenarioL2LanguageId
            }
            currentNodeTemp.options[index].scoreReasoningSnippetId = null
            if (text !== null) {
                currentNodeTemp.data.options[index] = text
            }
        }
        setCurrentNodeObject(currentNodeTemp)
    }

    const handleAddKeyboardTestDistractorLetters = (startIndex, text, testOption) => {
        let currentNodeObjectTemp = JSON.parse(JSON.stringify(currentNodeObject)) //[...nodes]
        let tempSnippetId = uuidv4() // this will be deleted by the backend and changed to the actual snippet id
        let correctDefault = text !== ''

        currentNodeObjectTemp.testOptions.forEach((to) => {
            if (to.testOptionId === testOption.testOptionId) {
                if (to.scenarioInteractiveElementType === ScenarioInteractiveElementType.Text) {
                    to.scenarioInteractiveElementType = ScenarioInteractiveElementType.FillInBlankDistractors
                }
                let alternativeAtCorrectStartIndexCounter = 0
                let hasUpdatedKeyboardDistractor = false

                to.alternatives.forEach((alt) => {
                    if (alt.startIndex === startIndex) {
                        alternativeAtCorrectStartIndexCounter++
                    }
                    if (alternativeAtCorrectStartIndexCounter > 1) {
                        alt.text = text
                        hasUpdatedKeyboardDistractor = true
                    }
                })

                if (hasUpdatedKeyboardDistractor === false) {
                    to.alternatives.push({
                        associatedTextLanguageId: -1,
                        correct: correctDefault,
                        startIndex: startIndex,
                        text: text,
                        textLanguageId: to.textLanguageId,
                        textSnippetId: tempSnippetId,
                        textAudioUrl: null,
                    })
                }
            }
        })
        setCurrentNodeObject(currentNodeObjectTemp)
    }

    const handleAddRuleCardAlternative = (startIndex, text, testOption) => {
        let currentNodeObjectTemp = JSON.parse(JSON.stringify(currentNodeObject)) //[...nodes]

        let tempSnippetId = uuidv4() // this will be deleted by the backend and changed to the actual snippet id

        let correctDefault = text !== ''

        currentNodeObjectTemp.testOptions.forEach((to) => {
            if (to.testOptionId === testOption.testOptionId) {
                if (to.scenarioInteractiveElementType === ScenarioInteractiveElementType.Text) {
                    to.scenarioInteractiveElementType = ScenarioInteractiveElementType.FillInBlankDistractors
                }
                to.alternatives.push({
                    associatedTextLanguageId: -1,
                    correct: correctDefault,
                    startIndex: startIndex,
                    text: text,
                    textLanguageId: to.textLanguageId,
                    textSnippetId: tempSnippetId,
                    textAudioUrl: null,
                })
            }
        })

        setCurrentNodeObject(currentNodeObjectTemp)
    }
    const deleteStageConfirmed = () => {
        setShowStageDeleteConfirmation(false)
        props.dispatch(lessonActions.lessonBuilderBusy(true))
        onElementsRemove(
            [
                {
                    id: nodeToDeleteAfterConfirmation.nodeId,
                    type: nodeToDeleteAfterConfirmation.type,
                    isConnector: false,
                },
            ],
            nodes
        )
        setShowEditNode(false)
        setCanPerformCollabCheck(true)

        setNodeToDeleteAfterConfirmation(null)
    }
    const deleteStage = (nodeToDelete) => {
        setNodeToDeleteAfterConfirmation(nodeToDelete)
        setShowStageDeleteConfirmation(true)
    }
    const deleteStageConfirmationCanceled = () => {
        setShowStageDeleteConfirmation(false)
        setNodeToDeleteAfterConfirmation(null)
    }

    const handleLanguageDefaultL1Changed = (defaultL1Id) => {
        if (defaultL1Id != null) {
            var scenarioTemp = JSON.parse(JSON.stringify(selectedScenario))
            scenarioTemp.l1Id = defaultL1Id

            handleUpdateScenarioLanguages(scenarioTemp)
        }
    }

    const handleLanguageChanged = (languageId) => {
        if (languageId != null) {
            var scenarioTemp = JSON.parse(JSON.stringify(selectedScenario))
            scenarioTemp.languageId = languageId

            handleUpdateScenarioLanguages(scenarioTemp)
        }
    }

    const handleSaveScenarioOptions = () => {
        setOpenOptions(false)
        handleUpdateScenario()
    }

    const handleChangeLessonType = (lessonType) => {
        if (
            lessonType === LessonType.NotDefined ||
            lessonType === LessonType.Roleplay ||
            lessonType === LessonType.Exam
        ) {
            handleUpdateScenarioLessonType(lessonType)
        }
    }

    const handleLanguageForShortDescription = (languageId) => {
        if (languageId !== undefined) {
            console.log('languageId', languageId)
            var shortDescriptionIsInL1 = languageId === selectedScenario.l1Id
            handleUpdateScenarioShortDescription(selectedScenario.shortDescription, shortDescriptionIsInL1)
        }
    }

    const handleUpdateScenarioShortDescriptionChange = (newDescription) => {
        var shortDescriptionIsInL1 = selectedScenario.shortDescriptionLanguageId !== selectedScenario.languageId
        handleUpdateScenarioShortDescription(newDescription, shortDescriptionIsInL1)
    }

    const handleAddScenarioDeepLink = (event) => {
        var scenarioTemp = JSON.parse(JSON.stringify(selectedScenario))
        var newDeepLink = {
            deepLinkText: '',
            deepLinkUrl: '',
            scenarioDeepLinkType: ScenarioDeepLinkType.BriefAndDebrief,
        }
        if (!scenarioTemp.deepLinks) {
            scenarioTemp.deepLinks = []
        }
        scenarioTemp.deepLinks.push(newDeepLink)
        setSelectedScenario(scenarioTemp)
    }

    const handleUpdateScenarioRemoveDeepLink = (index) => {
        var scenarioTemp = JSON.parse(JSON.stringify(selectedScenario))
        if (scenarioTemp.deepLinks && index >= 0 && index < scenarioTemp.deepLinks.length) {
            scenarioTemp.deepLinks.splice(index, 1)
            setSelectedScenario(scenarioTemp)
        }
    }

    const handleUpdateScenarioDeepLinkType = (e, index) => {
        var scenarioTemp = JSON.parse(JSON.stringify(selectedScenario))
        if (scenarioTemp.deepLinks && index >= 0 && index < scenarioTemp.deepLinks.length) {
            scenarioTemp.deepLinks[index].scenarioDeepLinkType = e.target.value
            setSelectedScenario(scenarioTemp)
        }
    }

    const handleUpdateScenarioDeepLinkText = (event, index) => {
        var scenarioTemp = JSON.parse(JSON.stringify(selectedScenario))
        if (scenarioTemp.deepLinks?.length - 1 >= index) {
            scenarioTemp.deepLinks[index].deepLinkText = event.target.value
        } else {
            var newDeepLink = {
                deepLinkText: event.target.value,
                deepLinkUrl: '',
                scenarioDeepLinkType: ScenarioDeepLinkType.BriefAndDebrief,
            }
            if (!scenarioTemp.deepLinks) {
                scenarioTemp.deepLinks = []
            }
            scenarioTemp.deepLinks.push(newDeepLink)
        }
        setSelectedScenario(scenarioTemp)
    }

    const handleUpdateScenarioDeepLinkUrl = (event, index) => {
        var scenarioTemp = JSON.parse(JSON.stringify(selectedScenario))
        if (scenarioTemp.deepLinks?.length - 1 >= index) {
            scenarioTemp.deepLinks[index].deepLinkUrl = event.target.value
        } else {
            var newDeepLink = {
                deepLinkText: '',
                deepLinkUrl: event.target.value,
                scenarioDeepLinkType: ScenarioDeepLinkType.BriefAndDebrief,
            }
            if (!scenarioTemp.deepLinks) {
                scenarioTemp.deepLinks = []
            }
            scenarioTemp.deepLinks.push(newDeepLink)
        }
        setSelectedScenario(scenarioTemp)
    }

    const handleSubtitleType = (subtitleType) => {
        if (subtitleType >= 0 && subtitleType < subtitleTypes.length) {
            var scenarioTemp = JSON.parse(JSON.stringify(selectedScenario))
            scenarioTemp.subtitleType = subtitleType
            setSelectedScenario(scenarioTemp)
        }
    }

    const handleLanguageForName = (languageId) => {
        if (languageId !== undefined) {
            console.log('setting name!!')
            var nameIsInL1 = languageId === selectedScenario.l1Id
            handleUpdateScenarioName(selectedScenario.name, nameIsInL1)
        }
    }

    const handleScenarioNameChange = (newName) => {
        var scenarioNameIsInL1 = selectedScenario.nameLanguageId === selectedScenario.l1Id
        handleUpdateScenarioName(newName, scenarioNameIsInL1)
    }

    const handleCancelScenarioOptions = () => {
        setOpenOptions(false)
    }

    const handleCustomLessonTranslationImport = (l1Id) => {
        if (!props.lesson.canEditLesson) {
            return
        }
        const { computedMatch } = props
        const customLessonId = computedMatch.params.customLessonId
        var input = document.createElement('input')
        input.type = 'file'
        input.onchange = (event) => {
            // getting a hold of the file reference
            var file = event.target.files[0]
            props.dispatch(lessonActions.importCustomLessonTranslation(customLessonId, l1Id, file))
        }
        input.click()
    }

    const handleRevertToPreviousVersion = (customLessonVersionId) => {
        if (!props.lesson.canEditLesson) {
            return
        }

        const { computedMatch, organization } = props
        const customLessonId = computedMatch.params.customLessonId

        props.dispatch(
            lessonActions.revertToLessonVersion(
                organization.selectedOrganization,
                customLessonId,
                customLessonVersionId
            )
        )
        setOpenRevertModal(false)
    }

    const handleCustomLessonTranslationExport = (l1Id) => {
        const customLessonId = selectedScenario.id
        const { metadata } = props
        var now = new Date()
        var dateString = now.toISOString().substring(0, 10)
        var l1Language = metadata.metadata.languages.find((x) => x.id === l1Id)
        let filename = selectedScenario.name + '_translation_' + l1Id + '_' + dateString + '.xlsx'
        if (l1Language) {
            filename =
                selectedScenario.name +
                '_translation_' +
                l1Language.languageTag +
                '_' +
                l1Language.friendlyName +
                '_' +
                dateString +
                '.xlsx'
        }
        props.dispatch(lessonActions.exportCustomLessonTranslation(customLessonId, l1Id, filename))
    }

    const handleDeleteSupportedL1Id = (l1Id) => {
        if (!props.lesson.canEditLesson) {
            return
        }
        const { computedMatch } = props
        const customLessonId = computedMatch.params.customLessonId
        props.dispatch(lessonActions.deleteCustomLessonSupportedLanguage(customLessonId, l1Id))
    }

    const handleAddSupportedL1Id = (l1Id) => {
        if (!props.lesson.canEditLesson) {
            return
        }
        const { computedMatch } = props
        const customLessonId = computedMatch.params.customLessonId
        props.dispatch(lessonActions.addCustomLessonSupportedLanguage(customLessonId, l1Id))
    }

    const handleDeleteAnswer = (index, nodeId) => {
        if (!props.lesson.canEditLesson) {
            return
        }
        // user has typed in text field for changing an existing answer's text. We should update the model
        var nodesTemp = JSON.parse(JSON.stringify(nodes))
        var currentNodeTemp = JSON.parse(JSON.stringify(currentNodeObject))
        currentNodeTemp.options = currentNodeTemp.options
            .slice(0, index)
            .concat(currentNodeTemp.options.slice(index + 1, currentNodeTemp.options.length))
        currentNodeTemp.data.options = currentNodeTemp.data.options
            .slice(0, index)
            .concat(currentNodeTemp.data.options.slice(index + 1, currentNodeTemp.data.options.length))
        setCurrentNodeObject(currentNodeTemp)
    }

    const handleUpdateScenario = () => {
        if (!props.lesson.canEditLesson) {
            return
        }
        props.dispatch(
            lessonActions.updateScenario(
                {
                    ...selectedScenarioStateRef.current,
                    scenarioNodes: stateRef.current,
                },
                selectedScenarioStateRef.current.id
            )
        )
    }

    const handleUpdateScenarioLanguages = (scenario) => {
        if (!props.lesson.canEditLesson) {
            return
        }
        props.dispatch(
            lessonActions.updateScenario(
                {
                    ...scenario,
                    scenarioNodes: stateRef.current,
                },
                selectedScenarioStateRef.current.id
            )
        )
    }

    const handleUpdateScenarioNode = (node, organizationId, referencedCurrentNodeObject = null) => {
        if (!props.lesson.canEditLesson) {
            return
        }
        props.dispatch(lessonActions.lessonBuilderBusy(true))
        let updateableNode = null
        if (currentNodeObject === null) {
            nodes.forEach((n) => {
                if (node.nodeId === n.nodeId) {
                    updateableNode = node
                }
            })
        } else {
            updateableNode = currentNodeObject
        }
        if (referencedCurrentNodeObject !== null) {
            updateableNode = referencedCurrentNodeObject
        }

        const { computedMatch } = props
        const customLessonId = computedMatch.params.customLessonId
        let nodesTemp = JSON.parse(JSON.stringify(nodes)) //[...nodes]
        let newNodesTemp = []
        nodesTemp.forEach((node) => {
            if (node?.testOptions) {
                node.testOptions.forEach((testOption) => {
                    if (
                        testOption.scenarioInteractiveElementType ===
                            ScenarioInteractiveElementType.FillInBlankWriting ||
                        testOption.scenarioInteractiveElementType ===
                            ScenarioInteractiveElementType.FillInBlankDistractors
                    ) {
                        if (testOption.alternatives.length === 0) {
                            testOption.scenarioInteractiveElementType = ScenarioInteractiveElementType.Text
                        }
                    }
                })
            }
            if (node.id === updateableNode.nodeId) {
                newNodesTemp.push(updateableNode)
            } else {
                newNodesTemp.push(node)
            }
        })

        setNodes(newNodesTemp)
        setEdgesWithCanEditLesson(props.lesson.selectedScenario.scenarioEdges)

        let imageFile = null
        let imageFileName = null
        let isSharable = true
        let imageFieldType = ImageFieldType.NotDefined
        if (currentNodeUploadImageHolder !== null) {
            imageFile = currentNodeUploadImageHolder.image
            imageFileName = currentNodeUploadImageHolder.imageFileName
            imageFieldType = currentNodeUploadImageHolder.imageFieldType
        }

        // need to also add images in the image object and see if their references exist on the server
        props.dispatch(
            lessonActions.updateScenarioNode(
                updateableNode,
                selectedScenario.id,
                organizationId,
                imageFileName,
                imageFile,
                isSharable,
                imageFieldType,
                customLessonId,
                props.lesson.imageReferences,
                selectedNodeAudioTrackers,
                props.lesson.lessonAudioReferences,
                {
                    ...selectedScenario,
                    scenarioNodes: nodesTemp,
                }
            )
        )
        setCurrentNodeUploadImageHolder(null)
    }

    const handleUpdateImage = (organizationId, name, file, isSharable) => {
        props.dispatch(lessonActions.addImage(organizationId, name, file, isSharable))
    }

    const handleCreateAuthorNote = (authorNote) => {
        let currentNodeObjectTemp = JSON.parse(JSON.stringify(currentNodeObject)) //[...nodes]
        let existingAuthorNotes = []
        let newAuthorComment = {
            authorName: props.user.info.name,
            authorUserId: props.user.info.id,
            note: authorNote,
            xPos: 0,
            yPos: 0,
            authorNoteType: CustomLessonAuthorNoteType.Created,
            createdAt: new Date(),
        }

        if (currentNodeObjectTemp.authorNoteDtos) {
            existingAuthorNotes = currentNodeObjectTemp.authorNoteDtos
        }
        currentNodeObjectTemp.authorNoteDtos = [...existingAuthorNotes, newAuthorComment]
        setCurrentNodeObject(currentNodeObjectTemp)
        setDisplayAuthorAddComments(false)
        handleCreateOrEditComment(0, newAuthorComment)
    }

    const selectedNodeTitle = () => {
        if (nodes[currentNode]) {
            switch (nodes[currentNode].scenarioNodeType) {
                case ScenarioNodeType.Watch:
                    return 'Cutscene options'
                case ScenarioNodeType.Listen:
                    return 'Listen stage options'
                case ScenarioNodeType.Speak:
                    return 'Branching stage options'
                case ScenarioNodeType.Debrief:
                    return 'End stage options'
                case ScenarioNodeType.BranchingMultiChoice:
                    return 'Branching stage options'
                case ScenarioNodeType.Brief:
                    return 'Brief stage options'
                case ScenarioNodeType.TestRule:
                    return 'Rule card stage options'
                case ScenarioNodeType.TestDefinition:
                    return 'Definition stage options'
                case ScenarioNodeType.TestImage:
                    return 'Test stage options'
                case ScenarioNodeType.TestAssociationPuzzle:
                    return 'Puzzle stage options'
                case ScenarioNodeType.MultiChoice:
                    return 'Quiz stage options'
                case ScenarioNodeType.ListenMultiChoice:
                    return 'Quiz stage options'
                case ScenarioNodeType.Instruction:
                    return 'Instruction stage options'
                case ScenarioNodeType.TestDialog:
                    return 'Dialog stage options'
                case ScenarioNodeType.TestListenWithSlider:
                    return 'Audio story options'
                default:
                    return 'Unknown stage type'
            }
        } else {
            return ''
        }
    }

    const isViewableNodeType = () => {
        // for instance, is not a connector node
        if (nodes[currentNode] != null && nodes[currentNode].scenarioNodeType) return true
        return false
    }

    const handleChangeExamPassRate = (value) => {
        let scenarioTemp = JSON.parse(JSON.stringify(selectedScenario))
        scenarioTemp.lessonScoringDynamicConfigurationV1Dto.lessonStarThresholds = [
            value / 100,
            value / 100,
            value / 100,
        ]
        setSelectedScenario(scenarioTemp)
    }

    const handleChangeLessonPassRate = (values) => {
        let scenarioTemp = JSON.parse(JSON.stringify(selectedScenario))

        const star1 = values[0] / 100
        const star2 = values[1] / 100
        const star3 = values[2] / 100

        scenarioTemp.lessonScoringDynamicConfigurationV1Dto.lessonStarThresholds = [star1, star2, star3]
        setSelectedScenario(scenarioTemp)
    }

    const DrawerHeader = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    }))

    const handleOpenLessonPreview = (nodeId) => {
        document.dispatchEvent(GlobalEvents.UnityLessonPreviewHideShowToggle)
        document.dispatchEvent(
            new CustomEvent('initLessonPreviewWithInfo', {
                detail: {
                    customLessonId: selectedScenario.id,
                    l1Id: selectedScenario.l1Id,
                    nodeId: nodeId,
                    clickableSkipButton: true,
                    l2Id: selectedScenario.languageId,
                },
            })
        )
    }
    const handleOpenLibrary = (event, value) => {
        if (openDataPickerPanel === true) {
            setOpenDataPickerPanel(false)
            setNodes(nodes.filter((x) => x.type !== ScenarioNodeType.Pin))
            handleTransform('out')
        } else {
            handleTransform('in')
            let briefNode = nodes.filter((x) => x.type === ScenarioNodeType.Brief)
            let nodePin = {
                type: ScenarioNodeType.Pin,
                nodeType: ScenarioNodeType.Pin,
                position: {
                    x: briefNode[0].position.x,
                    y: briefNode[0].position.y + 300,
                },
                data: {},
            }
            nodePin.onDuplicate = onDuplicate
            nodePin.onAddOther = onAddOther
            nodePin.onAdd = onAdd
            nodePin.onSuggest = onSuggest
            nodePin.onUpdateSelection = onUpdateSelection
            nodePin.onUpdateAnswerText = onUpdateAnswerText
            nodePin.onUpdateSelectedNode = handleUpdateSelectedNode
            nodePin.onPlayAppFromNode = handlePlayAppFromNode
            nodePin.onDeleteNode = handleDeleteFromNode
            nodePin.onAddAlternative = onAddAlternative
            nodePin.data.onUpdateSelectedNode = handleUpdateSelectedNode
            nodePin.data.onUpdateAnswerText = onUpdateAnswerText
            nodePin.data.onPlayAppFromNode = handlePlayAppFromNode
            nodePin.data.onAddAlternative = onAddAlternative
            setNodes([...nodes, nodePin])
            setOpenDataPickerPanel(true)
        }
    }

    const classes = useStyles()
    return (
        <Grid container className={classes.root}>
            <Grid className={classes.leftPanel} item xs={12} sm={12} md={12}>
                <React.Fragment>
                    {openAddNodes && (
                        <ScenarioAddNodeSelector
                            onHandleClose={handleClose}
                            onCreateNode={onDuplicate}
                            parentNodeId={parentNodeId}
                            isSketchModeSelected={props.lesson.lessonBuilderViewMode === ToggleModeViewTypes.SketchMode}
                        />
                    )}

                    {openOptions && (
                        <LessonOptionsModal
                            selectedScenario={selectedScenario}
                            isOpen={openOptions}
                            onLanguageForName={handleLanguageForName}
                            onScenarioNameChange={handleScenarioNameChange}
                            onLanguageForShortDescription={handleLanguageForShortDescription}
                            onUpdateScenarioShortDescriptionChange={handleUpdateScenarioShortDescriptionChange}
                            onClose={() => setOpenOptions(false)}
                            onChangeLessonType={handleChangeLessonType}
                            subtitleTypes={subtitleTypes}
                            onChangeExamPassRate={handleChangeExamPassRate}
                            onSaveScenarioOptions={handleSaveScenarioOptions}
                            savingScenarioOptions={savingScenarioOptions}
                            lesson={props.lesson}
                            onChangeLessonPassRate={handleChangeLessonPassRate}
                            onSubtitleType={handleSubtitleType}
                        />
                    )}

                    {deepLinksOpen && (
                        <Dialog
                            fullWidth={true}
                            maxWidth={'md'}
                            open={deepLinksOpen}
                            onClose={() => setDeepLinksOpen(false)}>
                            <DialogTitle className={classes.dialogTitle}>
                                <div className={classes.dialogTitleDiv}>
                                    <Typography
                                        variant="body2"
                                        style={{
                                            marginTop: '3px',
                                            boxShadow: 'none',
                                            flex: 1,
                                            fontSize: 36,
                                            color: 'white',
                                            justifyContent: 'center',
                                            paddingLeft: '15px',
                                            textAlign: 'left',
                                            alignItems: 'center',
                                        }}>
                                        Capeesh admins:
                                    </Typography>
                                    <IconButton onClick={() => setDeepLinksOpen(false)} size="medium">
                                        <CancelOutlinedIcon
                                            style={{
                                                fill: 'white',
                                                width: 38,
                                                height: 38,
                                            }}
                                            size="medium"
                                        />
                                    </IconButton>
                                </div>
                            </DialogTitle>
                            <DialogContent>
                                <Grid container>
                                    <Grid item xs={8} sm={8} md={8} className={classes.scenario}>
                                        <Typography
                                            variant="body1"
                                            className={classes.optionsTitle}
                                            style={{
                                                width: '300px',
                                            }}>
                                            Deep links
                                        </Typography>
                                    </Grid>

                                    {selectedScenario.deepLinks?.map((deepLinkDto, index) => (
                                        <Grid item xs={12} sm={12} md={12} className={classes.scenarioTextField}>
                                            <Typography variant="body1" className={classes.scenarioDescriptionText}>
                                                Deep link {index + 1} for brief and debrief
                                            </Typography>

                                            <TextField
                                                value={deepLinkDto?.deepLinkUrl ? deepLinkDto.deepLinkUrl : ''}
                                                onChange={(event) => handleUpdateScenarioDeepLinkUrl(event, index)}
                                                label={'Type an deep link'}
                                                style={{
                                                    borderRadius: '10px',
                                                    marginBottom: '25px',
                                                    width: '30%',
                                                }}
                                                InputProps={{
                                                    disableUnderline: true,
                                                    shrink: 'false',
                                                }}
                                                disabled={!props.lesson.canEditLesson}
                                                className={classes.textFields}
                                                variant="filled"
                                            />

                                            <TextField
                                                value={deepLinkDto?.deepLinkText ? deepLinkDto.deepLinkText : ''}
                                                onChange={(event) => handleUpdateScenarioDeepLinkText(event, index)}
                                                label={'Type an deep text'}
                                                style={{
                                                    borderRadius: '10px',
                                                    marginBottom: '25px',
                                                    marginLeft: '10px',
                                                    width: '30%',
                                                }}
                                                InputProps={{
                                                    disableUnderline: true,
                                                    shrink: 'false',
                                                }}
                                                className={classes.textFields}
                                                disabled={!props.lesson.canEditLesson}
                                                variant="filled"
                                            />

                                            <Select
                                                style={{
                                                    borderRadius: '10px',
                                                    marginTop: '10px',
                                                    marginLeft: '10px',
                                                    width: '15%',
                                                    paddingLeft: '10px',
                                                    paddingRight: '10px',
                                                    border: '5px solid #143349',
                                                    boxSizing: 'border-box',
                                                }}
                                                disableUnderline
                                                value={deepLinkDto.scenarioDeepLinkType}
                                                disabled={!props.lesson.canEditLesson}
                                                onChange={(e) => {
                                                    handleUpdateScenarioDeepLinkType(e, index)
                                                }}>
                                                {deepLinkTypes?.map((deepLinkDesc, deepLinkDescIndex) => (
                                                    <MenuItem key={deepLinkDescIndex} value={deepLinkDescIndex}>
                                                        {deepLinkDesc}
                                                    </MenuItem>
                                                ))}
                                            </Select>

                                            <Button
                                                className={classes.saveButton}
                                                onClick={() => {
                                                    handleUpdateScenarioRemoveDeepLink(index)
                                                }}
                                                disabled={!props.lesson.canEditLesson}
                                                style={{
                                                    borderRadius: '10px',
                                                    marginLeft: '10px',
                                                }}
                                                audoWidth>
                                                Remove
                                            </Button>
                                        </Grid>
                                    ))}

                                    <Button
                                        onClick={() => handleAddScenarioDeepLink()}
                                        className={classes.saveButton}
                                        style={{
                                            marginRight: '5px',
                                            borderRadius: '10px',
                                            marginBottom: '25px',
                                        }}
                                        disabled={!props.lesson.canEditLesson}
                                        variant="outlined">
                                        Add deep link
                                    </Button>
                                </Grid>
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        setDeepLinksOpen(false)
                                        handleUpdateScenario()
                                    }}>
                                    Save
                                </Button>
                            </DialogContent>
                        </Dialog>
                    )}

                    {openSupportedLanguages && (
                        <Dialog fullWidth={true} maxWidth={'md'} open={openSupportedLanguages} onClose={handleClose}>
                            <DialogTitle className={classes.dialogTitle}>
                                <div className={classes.dialogTitleDiv}>
                                    <Typography
                                        variant="body2"
                                        style={{
                                            marginTop: '3px',
                                            boxShadow: 'none',
                                            flex: 1,
                                            fontSize: 36,
                                            color: 'white',
                                            justifyContent: 'center',
                                            paddingLeft: '15px',
                                            textAlign: 'left',
                                            alignItems: 'center',
                                        }}>
                                        Scenario Supported Languages
                                    </Typography>
                                    <IconButton
                                        onClick={() => setOpenSupportedLanguages(false)}
                                        size="medium"
                                        color={'white'}>
                                        <CancelOutlinedIcon
                                            className={classes.closeBustton}
                                            style={{
                                                fill: 'white',
                                                width: 38,
                                                height: 38,
                                            }}
                                            size="medium"
                                        />
                                    </IconButton>
                                </div>
                            </DialogTitle>
                            <DialogContent>
                                <ScenarioSupportedLanguagePane
                                    supportedLanguages={props.lesson.selectedLessonSupportedLanguages}
                                    customLessonId={props.customLessonId}
                                    onDeleteL1Id={handleDeleteSupportedL1Id}
                                    onAddL1Id={handleAddSupportedL1Id}
                                    onExportTranslationForL1Id={handleCustomLessonTranslationExport}
                                    onImportTranslationForL1Id={handleCustomLessonTranslationImport}
                                />
                            </DialogContent>
                        </Dialog>
                    )}

                    {showStageDeleteConfirmation && (
                        <CapeeshStyledModal
                            open={showStageDeleteConfirmation}
                            textTitle={'Delete confirmation'}
                            textLine1={'Do you really want delete the stage?'}
                            onButton2={() => deleteStageConfirmed()}
                            onButton1={() => deleteStageConfirmationCanceled()}
                            button1Text={'Cancel'}
                            button1Variant={'outlined'}
                            button2Text={'Yes'}
                            onClose={() => deleteStageConfirmationCanceled()}
                        />
                    )}

                    {openRevertModal && (
                        <Dialog fullWidth={true} maxWidth={'md'} open={openRevertModal} onClose={handleClose}>
                            <DialogTitle className={classes.dialogTitle}>
                                <div className={classes.dialogTitleDiv}>
                                    <Typography
                                        variant="body2"
                                        style={{
                                            marginTop: '3px',
                                            boxShadow: 'none',
                                            flex: 1,
                                            fontSize: 36,
                                            color: 'white',
                                            justifyContent: 'center',
                                            paddingLeft: '15px',
                                            textAlign: 'left',
                                            alignItems: 'center',
                                        }}>
                                        Revert to versions (undo)
                                    </Typography>
                                    <IconButton onClick={() => setOpenRevertModal(false)} size="medium" color={'white'}>
                                        <CancelOutlinedIcon
                                            className={classes.closeBustton}
                                            style={{
                                                fill: 'white',
                                                width: 38,
                                                height: 38,
                                            }}
                                            size="medium"
                                        />
                                    </IconButton>
                                </div>
                            </DialogTitle>
                            <DialogContent>
                                <LessonUndoPane
                                    onRevertToPreviousVersion={handleRevertToPreviousVersion}
                                    currentVersionIndex={props.lesson.selectedScenario?.generationIndex}
                                />
                            </DialogContent>
                        </Dialog>
                    )}

                    {openPlayErrorHelp && (
                        <Dialog
                            open={openPlayErrorHelp}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description">
                            <DialogTitle id="alert-dialog-title">{'Cannot preview your lesson'}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Your lesson has validation errors so we cannot preview it. Check that each stage is
                                    connected to another stage.
                                </DialogContentText>
                                <DialogContentText id="alert-dialog-description">
                                    A stage with validation errors has a red border.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} autoFocus>
                                    OK
                                </Button>
                            </DialogActions>
                        </Dialog>
                    )}

                    {showEditNode && isViewableNodeType() && currentNodeObject && (
                        <Dialog
                            fullWidth={true}
                            maxWidth={displayComments ? 'lg' : 'md'}
                            open={showEditNode}
                            onClose={handleClose}
                            sx={{
                                width: displayComments ? '1300px' : '1000px',
                            }}
                            style={{
                                minWidth: '1000px',
                                overflow: 'hidden',
                            }}>
                            <Grid container>
                                <Grid
                                    item
                                    xs={displayComments === true ? 9 : 12}
                                    sm={displayComments === true ? 9 : 12}
                                    md={displayComments === true ? 9 : 12}
                                    className={classes.drawPhone}>
                                    <DialogTitle className={classes.dialogTitle}>
                                        <div className={classes.dialogTitleDiv}>
                                            <Typography
                                                variant="body2"
                                                style={{
                                                    marginTop: '3px',
                                                    //width: '71px',
                                                    boxShadow: 'none',
                                                    flex: 1,
                                                    //letterSpacing: 0.1,
                                                    fontSize: 36,
                                                    color: 'white',
                                                    justifyContent: 'center',
                                                    paddingLeft: '15px',
                                                    textAlign: 'left',
                                                    alignItems: 'center',
                                                }}>
                                                {selectedNodeTitle()}
                                            </Typography>

                                            {props.lesson.selectedScenario != null && props.lesson.validated && (
                                                <Button
                                                    onClick={() => handlePlayAppFromBrowser()}
                                                    style={{
                                                        color: 'white',
                                                        boxShadow: 'none',
                                                        borderRadius: '0',
                                                        borderLeft: '5px',
                                                        borderRight: '0px',
                                                        borderTop: '0px',
                                                        borderBottom: '0px',
                                                        textTransform: 'none',
                                                        fontWeight: 'bold',
                                                        justifyContent: 'center',
                                                        borderColor: 'white',
                                                        textAlign: 'right',
                                                        alignItems: 'right',
                                                    }}
                                                    color="primary">
                                                    Play in browser
                                                </Button>
                                            )}

                                            {props.lesson.selectedScenario != null && !props.lesson.validated && (
                                                <Typography
                                                    style={{
                                                        color: 'white',
                                                        boxShadow: 'none',
                                                        borderRadius: '0',
                                                        borderLeft: '5px',
                                                        borderRight: '0px',
                                                        borderTop: '0px',
                                                        borderBottom: '0px',
                                                        textTransform: 'none',
                                                        fontWeight: 'bold',
                                                        justifyContent: 'right',
                                                        borderColor: 'white',
                                                        textAlign: 'right',
                                                        alignItems: 'right',
                                                        marginTop: '15px',
                                                    }}>
                                                    Fix validation errors to play
                                                </Typography>
                                            )}

                                            {currentNodeObject.scenarioNodeType !== ScenarioNodeType.Brief &&
                                                props.lesson.canEditLesson && (
                                                    <IconButton
                                                        onClick={() => deleteStage(currentNodeObject)}
                                                        size="medium">
                                                        <DeleteIcon
                                                            style={{
                                                                fill: 'white',
                                                                width: 38,
                                                                height: 38,
                                                            }}
                                                            size="medium"
                                                        />
                                                    </IconButton>
                                                )}
                                            <IconButton
                                                onClick={() => {
                                                    setShowEditNode(false)
                                                    setCanPerformCollabCheck(true)
                                                }}
                                                size="medium">
                                                <CancelOutlinedIcon
                                                    style={{
                                                        fill: 'white',
                                                        width: 38,
                                                        height: 38,
                                                    }}
                                                    size="medium"
                                                />
                                            </IconButton>
                                        </div>
                                    </DialogTitle>
                                </Grid>
                                {displayComments && (
                                    <Grid item xs={3} sm={3} md={3}>
                                        <DialogTitle
                                            style={{
                                                backgroundColor: '#EFEFF4',
                                                fontColor: '#143349',
                                            }}>
                                            <div className={classes.dialogTitleDiv}>
                                                <Typography
                                                    variant="body2"
                                                    style={{
                                                        marginTop: '10px',
                                                        //width: '71px',
                                                        boxShadow: 'none',
                                                        flex: 1,
                                                        //letterSpacing: 0.1,
                                                        fontSize: 28,
                                                        color: '#143349',
                                                        justifyContent: 'center',
                                                        paddingLeft: '15px',
                                                        textAlign: 'left',

                                                        alignItems: 'center',
                                                    }}>
                                                    Comments
                                                </Typography>
                                                <IconButton
                                                    onClick={() => setDisplayComments(!!!displayComments)}
                                                    size="medium"
                                                    color={'#143349'}>
                                                    <RemoveIcon
                                                        style={{
                                                            fill: '#143349',
                                                            width: 38,
                                                            height: 38,
                                                        }}
                                                        size="medium"
                                                    />
                                                </IconButton>
                                            </div>
                                        </DialogTitle>
                                    </Grid>
                                )}
                            </Grid>
                            <Grid container>
                                <Grid
                                    item
                                    xs={displayComments === true ? 9 : 12}
                                    sm={displayComments === true ? 9 : 12}
                                    md={displayComments === true ? 9 : 12}
                                    className={classes.drawPhone}>
                                    <DialogContent
                                        style={{
                                            padding: '0px',
                                            overflow: 'hidden',
                                        }}>
                                        <Grid
                                            container
                                            className={classes.drawGsrid}
                                            style={{
                                                maxHeight: '80vh',
                                                overflow: 'auto',
                                                // paddingLeft: '10px',
                                                // paddingTop: '10px',
                                            }}
                                            spacing={0}>
                                            <Grid item xs={12} sm={12} md={12} className={classes.drawPhsone}>
                                                <ScenarioEditNode
                                                    handleBranchingMultiChoiceQuestionChanged={
                                                        handleBranchingMultiChoiceQuestionChanged
                                                    }
                                                    handleBranchingMultiChoiceQuestionLanguageIdChanged={
                                                        handleBranchingMultiChoiceQuestionLanguageIdChanged
                                                    }
                                                    handleMultiChoiceQuestionDeleted={handleMultiChoiceQuestionDeleted}
                                                    handleUpdateMultiChoiceQuestionChanged={
                                                        handleUpdateMultiChoiceQuestionChanged
                                                    }
                                                    handleUpdateMultiChoiceAlternativeChanged={
                                                        handleUpdateMultiChoiceAlternativeChanged
                                                    }
                                                    onSuggestalternative={onSuggest}
                                                    onDisableCollabCheck={handleDisableCollabCheck}
                                                    onClose={handleClose}
                                                    isAudioDownloading={isAudioDownloading}
                                                    scenarioName={selectedScenario.name}
                                                    scenarioNameLanguageId={selectedScenario.nameLanguageId}
                                                    currentAudioTracker={currentAudioTracker}
                                                    currentAudioBlobUrl={currentPlayAudioBlobUrl}
                                                    selectedNodeAudioTrackers={selectedNodeAudioTrackers}
                                                    languageId={selectedScenario.languageId}
                                                    l1Id={selectedScenario.l1Id}
                                                    nodes={nodes}
                                                    node={currentNodeObject}
                                                    nodeIndex={currentNode}
                                                    onAutoTranslation={handleAutoTranslation}
                                                    onChangePuzzleBlockColumnLanguage={
                                                        handleChangePuzzleBlockColumnLanguage
                                                    }
                                                    onChangePuzzleBlockDescriptionLanguage={
                                                        handleChangePuzzleBlockDescriptionLanguage
                                                    }
                                                    onChangePuzzleBlockText={handleChangePuzzleBlockText}
                                                    deleteAnswer={handleDeleteAnswer}
                                                    onAudioDrop={handleAudioDrop}
                                                    onChangeStageLength={handleChangeStageLength}
                                                    onChangeRuleCardDistractor={handleChangeRuleCardDistractor}
                                                    onToggleRuleCardDistractorCorrect={
                                                        handleToggleRuleCardDistractorCorrect
                                                    }
                                                    onDeleteAlternativeDistractor={handleDeleteAlternativeDistractor}
                                                    onTestOptionTextLanguageTypeChange={
                                                        handleTestOptionLanguageTypeChanged
                                                    }
                                                    onChangeTextImageQuestion={handleChangeTextImageQuestionL1}
                                                    onChangeTestOptionLanguageId={handleChangeTestOptionLanguageId}
                                                    onChangeTextImageQuestionL2={handleChangeTextImageQuestionL2}
                                                    onChangeTextImageTitle={handleChangeTextImageTitle}
                                                    onVoiceDrop={handleVoiceDrop}
                                                    onVoiceDelete={handleDeleteVoice}
                                                    onAddPuzzleBlocks={handleAddPuzzleBlocks}
                                                    onAddPuzzleBlocksFromExisting={handleAddPuzzleBlocksFromExisting}
                                                    onDistractorTypeChange={handleDistractorTypeChange}
                                                    onMentorSelected={handleMentorSelect}
                                                    onMentorSizeChange={handleMentorSizeChange}
                                                    onMentorMove={handleMentorMove}
                                                    onToggleCorrect={handleToggleCorrect}
                                                    onRulecardTextChange={onRulecardTextChange}
                                                    onReorderTestOptions={handleReorderTestOptions}
                                                    onAddMultiChoiceQuestion={handleAddMultiChoiceQuestion}
                                                    onAddRule={handleAddRule}
                                                    onDeleteRule={handleDeleteRule}
                                                    onEditText={handleEditText}
                                                    onAddDistractor={handleAddDistractor}
                                                    onEditDistractors={handleEditDistractors}
                                                    onDeleteDistractor={handleDeleteDistractor}
                                                    onSetPuzzleInteractionType={handleSetPuzzleInteractionType}
                                                    onChangeInteraction={handleChangeInteraction}
                                                    onCreateOrEditComment={handleCreateOrEditComment}
                                                    scenarioImages={scenarioImages}
                                                    toggleReasoning={handleToggleReasoning}
                                                    onChangeScoreReasoningL1Id={handleChangeLanguageIdForScoreReasoning}
                                                    onChangeDebriefL1Id={handleChangeLanguageDebrief}
                                                    updateImageDrop={handleImageDrop}
                                                    updateAnswerText={handleUpdateAnswerText}
                                                    updateL1SubtitleText={handleUpdateL1SubtitleText}
                                                    updateScenarioNameText={handleUpdateScenarioName}
                                                    onChangeWhenSpoken={handleChangeWhenSpoken}
                                                    updateRuleCardTitle={handleUpdateRuleCardTitle}
                                                    updateScenarioBriefText={handleUpdateScenarioBrief}
                                                    updateScenarioDefinitionText={handleUpdateScenarioDefinition}
                                                    updateImage={handleUpdateImage}
                                                    updateScenario={handleUpdateScenario}
                                                    updateScenarioNode={handleUpdateScenarioNode}
                                                    updateRuleCardAlternative={handleAddRuleCardAlternative}
                                                    updateAddKeyboardTestDistractorLetters={
                                                        handleAddKeyboardTestDistractorLetters
                                                    }
                                                    updateNodeAudio={handleUpdateNodeAudio}
                                                    onPlaySelectedAudio={handleOnPlaySelectedAudio}
                                                    onPlaySelectedAudioUrl={handleOnPlayFromUrl}
                                                    onSanitiseStage={handleSanitiseStage}
                                                    onDeletePuzzleBlockPair={handleDeletePuzzleBlockPair}
                                                    displayComments={displayComments}
                                                    toggleDisplayComments={() => setDisplayComments(!!!displayComments)}
                                                    className={classes.drawPhone}
                                                    subtitleType={selectedScenario.subtitleType}
                                                    canEditLesson={props.lesson.canEditLesson}
                                                    externalServiceIsBusy={externalServiceIsBusy}
                                                    onChangedNodeType={handleChangedNodeType}
                                                    onChangedNodeScoring={handleChangedNodeScoring}
                                                    handleOnNodeInteractionScoringChanged={
                                                        handleOnNodeInteractionScoringChanged
                                                    }
                                                    onUpdateTestOptionOnCurrentNode={
                                                        handleUpdateTestOptionOnCurrentNode
                                                    }
                                                    onUpdateAllTestOptionOnCurrentNode={
                                                        handleUpdateAllTestOptionOnCurrentNode
                                                    }
                                                    onUpdateAllTestOptionsOnCurrentNodeAndNodeType={
                                                        handleUpdateAllTestOptionsAndType
                                                    }
                                                    noiseSuppression={noiseSuppression}
                                                    onToggleNoiseSuppression={() =>
                                                        setNoiseSuppression(!!!noiseSuppression)
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                    </DialogContent>
                                </Grid>
                                {displayComments && (
                                    <React.Fragment>
                                        <Grid item xs={3} sm={3} md={3}>
                                            <Grid
                                                container
                                                direction="column"
                                                justifyContent="space-between"
                                                alignItems="stretch"
                                                spacing={0}>
                                                <React.Fragment>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        md={12}
                                                        style={{
                                                            minHeight: displayAuthorAddComments ? '390px' : '490px',
                                                            maxHeight: '55vh',
                                                            overflow: 'auto',
                                                        }}>
                                                        <Grid container>
                                                            <div
                                                                style={{
                                                                    flex: 1,
                                                                    justifyContent: 'center',
                                                                    textAlign: 'left',
                                                                    alignItems: 'center',
                                                                }}>
                                                                <Divider
                                                                    flexItem
                                                                    style={{
                                                                        background: 'white',
                                                                        marginLeft: '22px',
                                                                        marginRight: '22px',
                                                                        marginTop: '9px',
                                                                        textColor: '#494949',
                                                                        fontWeight: 300,
                                                                    }}>
                                                                    Unresolved
                                                                </Divider>
                                                                {(!!!currentNodeObject.authorNoteDtos ||
                                                                    currentNodeObject?.authorNoteDtos?.filter(
                                                                        (x) =>
                                                                            x.authorNoteType ===
                                                                            CustomLessonAuthorNoteType.Created
                                                                    ).length === 0) && (
                                                                    <Typography
                                                                        variant="body1"
                                                                        style={{
                                                                            fontSize: 16,
                                                                            fontFamily: 'Lato',
                                                                            marginRight: '12px',
                                                                            marginTop: '10px',
                                                                            marginBottom: '2px',
                                                                            textAlign: 'center',
                                                                        }}>
                                                                        No unresolved comments
                                                                    </Typography>
                                                                )}
                                                                {currentNodeObject?.authorNoteDtos
                                                                    ?.filter(
                                                                        (x) =>
                                                                            x.authorNoteType ===
                                                                            CustomLessonAuthorNoteType.Created
                                                                    )
                                                                    .map((comment, index) => (
                                                                        <Grid item xs={12} sm={12} md={12}>
                                                                            <ScenarioAuthorComment
                                                                                comment={comment}
                                                                                onUpdateCommentState={
                                                                                    handleUpdateCommentState
                                                                                }
                                                                                onDeleteComment={handleDeleteComment}
                                                                                userId={props.user.info.id}
                                                                            />
                                                                        </Grid>
                                                                    ))}
                                                                <Divider
                                                                    flexItem
                                                                    style={{
                                                                        background: 'white',
                                                                        marginLeft: '22px',
                                                                        marginRight: '22px',
                                                                        marginTop: '9px',
                                                                        textColor: '#494949',
                                                                        fontWeight: 300,
                                                                    }}>
                                                                    Resolved
                                                                </Divider>
                                                                {(!!!currentNodeObject.authorNoteDtos ||
                                                                    currentNodeObject?.authorNoteDtos?.filter(
                                                                        (x) =>
                                                                            x.authorNoteType ===
                                                                            CustomLessonAuthorNoteType.Accepted
                                                                    ).length === 0) && (
                                                                    <Typography
                                                                        variant="body1"
                                                                        style={{
                                                                            fontSize: 16,
                                                                            fontFamily: 'Lato',
                                                                            marginLeft: '12px',
                                                                            marginTop: '10px',
                                                                            marginBottom: '2px',
                                                                            textAlign: 'center',
                                                                        }}>
                                                                        No resolved comments
                                                                    </Typography>
                                                                )}
                                                                {currentNodeObject?.authorNoteDtos
                                                                    ?.filter(
                                                                        (x) =>
                                                                            x.authorNoteType ===
                                                                            CustomLessonAuthorNoteType.Accepted
                                                                    )
                                                                    .map((comment, index) => (
                                                                        <Grid item xs={12} sm={12} md={12}>
                                                                            <ScenarioAuthorComment
                                                                                comment={comment}
                                                                                onUpdateCommentState={
                                                                                    handleUpdateCommentState
                                                                                }
                                                                                index={index}
                                                                                onDeleteComment={handleDeleteComment}
                                                                                userId={props.user.info.id}
                                                                            />
                                                                        </Grid>
                                                                    ))}
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </React.Fragment>
                                                {displayAuthorAddComments ? (
                                                    <Grid item xs={12} sm={12} md={12}>
                                                        <ScenarioAuthorAddComment
                                                            onCreateAuthorNote={handleCreateAuthorNote}
                                                            onCloseAuthorNote={() => setDisplayAuthorAddComments(false)}
                                                        />
                                                    </Grid>
                                                ) : (
                                                    <Grid container justifyContent="flex-end">
                                                        <Grid item xs={3} sm={3} md={3}>
                                                            <Zoom
                                                                in={true}
                                                                style={{
                                                                    transitionDelay:
                                                                        currentNodeObject?.authorNoteDtos?.length *
                                                                            100 || 0,
                                                                }}>
                                                                <IconButton
                                                                    onClick={() => setDisplayAuthorAddComments(true)}
                                                                    size="medium"
                                                                    color={'white'}>
                                                                    <AddCircleRoundedIcon
                                                                        style={{
                                                                            fill: '#143349',
                                                                            width: 58,
                                                                            height: 58,
                                                                        }}
                                                                    />
                                                                </IconButton>
                                                            </Zoom>
                                                        </Grid>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </React.Fragment>
                                )}
                            </Grid>
                        </Dialog>
                    )}
                </React.Fragment>
            </Grid>
            <React.Fragment>
                <Drawer
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                        },
                    }}
                    variant="persistent"
                    anchor="right"
                    open={openDataPickerPanel === true}>
                    <DrawerHeader style={{ backgroundColor: '#FF5159' }}>
                        <IconButton onClick={handleDataPickerSidebarClose}>
                            <ChevronRightIcon />
                        </IconButton>
                    </DrawerHeader>
                    <Divider />
                    <Grid container className={classes.root}>
                        <Grid item xs={12} sm={12} md={12}>
                            <DataPickerSidebar
                                onGetLessonStages={handleGetLessonStages}
                                onCreateItemsOrNodesFromDatapicker={handleCreateItemsOrNodesFromDatapicker}
                                topicId={topicId}
                            />
                        </Grid>
                    </Grid>

                    <Divider />
                </Drawer>

                <Backdrop
                    sx={{
                        color: 'grey',
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={props.lesson.completedScenarioDownload === false && hasLoadedOnce === true}
                    invisible={true}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </React.Fragment>
            {
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        minHeight: 'calc(100vh - 170px)',
                    }}>
                    <div>
                        <LessonBuilderHeaderBar
                            canEditLesson={props.lesson.canEditLesson}
                            showAdminButtons={props.user?.organizationRole === 'admin' && props.lesson.canEditLesson}
                            showPreview={!!!props.lesson.validated}
                            onOpenOptions={() => setOpenOptions(true)}
                            onOpenSupportedLanugages={() => setOpenSupportedLanguages(true)}
                            onRevertModal={() => setOpenRevertModal(true)}
                            onOpenLibrary={handleOpenLibrary}
                            validated={props.lesson.validated}
                            isBusy={props.lesson.isBusy}
                            canBePublished={canBePublished}
                            onPublishLesson={() => publishLesson()}
                            onGoToTopic={handleGoToTopic}
                            checkForCanBePublished={checkForCanBePublished}
                            onToggleCanEditMode={() => handleToggleCanEditMode()}
                            isCreatorUser={
                                props.user?.organizationRole === 'admin' || props.user?.organizationRole === 'teacher'
                            }
                            lessonLoaded={props.lesson.selectedScenario != null}
                            onClickDeepLinks={() => setDeepLinksOpen(true)}
                        />
                    </div>
                    <Box
                        sx={{
                            flexGrow: 1,
                            p: 2,
                            height: 'inherit',
                            color: 'secondary.contrastText',
                        }}>
                        <CapeeshReactFlow
                            elements={nodes}
                            nodes={nodes}
                            edges={edges}
                            onConnect={onConnect}
                            onNodeContextMenu={onNodeContextMenu}
                            edgeTypes={edgeTypes}
                            nodeTypes={nodeTypes}
                            nodesConnectable={props.lesson.canEditLesson && !openDataPickerPanel}
                            nodesDraggable={props.lesson.canEditLesson && !!!props.lesson.isBusy}
                            onElementClick={onElementClick}
                            onNodeDragStart={handleNodeDragStart}
                            handleNodeDragStop={handleNodeDragStop}
                            handleNodeDrag={handleNodeDrag}
                            onNodesChange={onNodesChange}
                            onEdgesChange={onEdgesChange}
                            onToggleMultiSelect={handleToggleMultiSelect}></CapeeshReactFlow>
                    </Box>
                </Box>
            }
        </Grid>
    )
}

function mapStateToProps(state) {
    const { organization, usergroup, topic, lesson, metadata, user } = state
    return {
        organization,
        topic,
        usergroup,
        lesson,
        metadata,
        user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(LessonBuilderController)
