import { asyncRequest } from './asyncRequest'

export const studentAuthConstants = {
    STUDENT_REGISTER: new asyncRequest('STUDENT_REGISTER'),
    STUDENT_LOGIN: new asyncRequest('STUDENT_LOGIN'),
    STUDENT_LOGOUT: new asyncRequest('STUDENT_LOGOUT'),
    STUDENT_FORGOT_PASSWORD_PIN: new asyncRequest('STUDENT_FORGOT_PASSWORD_PIN'),
    STUDENT_UPDATE_FORGOT_PASSWORD: new asyncRequest('STUDENT_UPDATE_FORGOT_PASSWORD'),
    STUDENT_SHOULD_REGISTER: new asyncRequest('STUDENT_SHOULD_REGISTER'),
    STUDENT_START_OVER: new asyncRequest('START_OVER'),
    STUDENT_CLEAR_ERRORS: new asyncRequest('CLEAR_ERRORS'),
}

export const studentAuthActions = {
    studentLogin(email, password) {
        return {
            type: studentAuthConstants.STUDENT_LOGIN.REQUEST,
            payload: {
                email: email,
                password: password,
                userDevice: {
                    DeviceGuid: JSON.parse(localStorage.getItem('deviceGuid'))?.deviceGuid,
                    AppVersion: 'CapeeshWeb:3.1.1',
                },
            },
        }
    },

    studentRegister(email, password, allowEmailNotifications) {
        return {
            type: studentAuthConstants.STUDENT_REGISTER.REQUEST,
            payload: {
                email: email,
                newPassword: password,
                allowedToSendEmailNotifications: allowEmailNotifications,
                userDevice: {
                    DeviceGuid: JSON.parse(localStorage.getItem('deviceGuid'))?.deviceGuid,
                    AppVersion: 'CapeeshWeb:3.1.1',
                },
            },
        }
    },

    studentShouldRegister(email) {
        return {
            type: studentAuthConstants.STUDENT_SHOULD_REGISTER.REQUEST,
            payload: {
                email: email,
            },
        }
    },

    studentForgotPasswordPin(email) {
        return {
            type: studentAuthConstants.STUDENT_FORGOT_PASSWORD_PIN.REQUEST,
            payload: {
                email,
            },
        }
    },
    studentUpdateForgotPassword(email, pin, newPassword) {
        return {
            type: studentAuthConstants.STUDENT_UPDATE_FORGOT_PASSWORD.REQUEST,
            payload: {
                email,
                pin,
                newPassword,
            },
        }
    },
    studentStartOver() {
        return {
            type: studentAuthConstants.STUDENT_START_OVER.REQUEST,
            payload: {},
        }
    },
    studentClearErrors() {
        return {
            type: studentAuthConstants.STUDENT_CLEAR_ERRORS.REQUEST,
            payload: {},
        }
    },
    studentLogout() {
        return {
            type: studentAuthConstants.STUDENT_LOGOUT.REQUEST,
        }
    },
}
