import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import { Button, makeStyles } from '@material-ui/core'
import CapeeshCreateFromPage from '../Assets/creator_front_page.png'
import { ReactComponent as CapeeshCreateLogo } from '../Assets/capeesh_create_logo.svg'
import { FormattedMessage } from 'react-intl'
import { CapeeshColors } from '../../../../assets/ColorPalette'

const useStyles = makeStyles((theme) => ({
    main: {
        display: 'flex',
        flexDirection: 'row',
        gap: '64px',
        paddingLeft: '15%',
        paddingRight: '15%',
        [theme.breakpoints.down('md')]: {
            paddingLeft: '10%',
            paddingRight: '10%',
        },
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '4%',
            paddingRight: '4%',
        },
    },
    imageContainer: { display: 'flex', justifyContent: 'center', justifyItems: 'center', width: '400px' },
    rightSideContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'left',
        gap: '16px',
        color: CapeeshColors.DarkText,
    },
    welcomeTitle: {
        color: CapeeshColors.DarkText,
        fontFamily: 'Rubik',
        fontsize: '32px',
        fontStyle: 'normal',
        fontWeight: 500,
    },
    welcomeDescription: {
        color: CapeeshColors.DarkText,
        fontFamily: 'Rubik',
        fontsize: '20px',
        fontStyle: 'normal',
        fontWeight: 500,
    },
    topicListButton: {
        color: 'white',
        textTransform: 'none',
        borderRadius: '12px',
        width: '300px',
    },
}))

const CreatorHeader = ({ isAdminUser, handleGoToCourseListClicked }) => {
    const classes = useStyles()

    return (
        <div className={classes.main}>
            <div className={classes.imageContainer}>
                <img width={'400px'} src={CapeeshCreateFromPage} alt="Capeesh create welcome image" />
            </div>
            <div className={classes.rightSideContainer}>
                <CapeeshCreateLogo />
                <Typography variant="h4" className={classes.welcomeTitle}>
                    <FormattedMessage
                        id={'create.course.welcome.message'}
                        defaultMessage={'Welcome to the Capeesh Authoring Platform!'}
                    />
                </Typography>
                <Typography variant="body2" className={classes.welcomeDescription}>
                    <FormattedMessage
                        id={'create.course.welcome.sub.message'}
                        defaultMessage={
                            'From here you can create fantastic learning content. Your list of courses is shown below. Each\n' +
                            '                    course contains topics, which themselves contain lessons.'
                        }
                    />
                </Typography>

                <div style={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
                    {isAdminUser && (
                        <Button
                            className={classes.topicListButton}
                            variant={'contained'}
                            color={'primary'}
                            onClick={() => handleGoToCourseListClicked()}>
                            <FormattedMessage
                                id="create.button.gototopiclist"
                                defaultMessage="Capeesh Admin: Go to topic list"
                            />
                        </Button>
                    )}
                </div>
            </div>
        </div>
    )
}

CreatorHeader.propTypes = {}

const mapStateToProps = (state) => {
    return {
        organization: state.organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(CreatorHeader)
