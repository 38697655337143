import React, { useEffect, useState } from 'react'
import {
    Grid,
    Card,
    CardHeader,
    CardContent,
    Stepper,
    Step,
    StepLabel,
    Button,
    TextField,
    CardActions,
    CircularProgress,
    FormControl,
    FormHelperText,
    Typography,
} from '@material-ui/core'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { organizationActions, usergroupActions } from '../../_state/actions'
import CourseGroupCard from '../../Components/CourseGroupCard/CourseGroupCard'
import Dialog from '@mui/material/Dialog'

const styles = (theme) => ({})

const steps = [
    <FormattedMessage id="dashboard.newclassroom.info" defaultMessage="Classroom info" />,
    <FormattedMessage id="dashboard.newclassroom.choosecontent" defaultMessage="Choose content" />,
]

const CreateNewClassroomDialog = (props) => {
    const { usergroup, user, organization } = props

    const [currentStep, setCurrentStep] = useState(0)
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [creatingUserGroup, setCreatingUserGroup] = useState(false)
    const [existingUserGroup, setExistingUserGroup] = useState(false)

    const [organizationCourseGroups, setOrganizationCourseGroups] = useState([])
    const [organizationCourseGroupsAvailable, setOrganizationCourseGroupsAvailable] = useState(false)

    const [availableCourseGroups, setAvailableCourseGroups] = useState([])

    const [blockClassroomCreation, setBlockClassroomCreation] = useState(true)
    const [classroomCreationErrorMessage, setClassroomCreationErrorMessage] = useState('')

    const [checkedCourseGroupIds, setCheckedCourseGroupIds] = useState([])

    useEffect(() => {
        props.dispatch(usergroupActions.resetCreateUserGroup())
        recalculateAvailableCourseGroupData()
        props.dispatch(organizationActions.getOrganizationCourseGroups(organization.selectedOrganization))
        props.dispatch(organizationActions.getAvailableCourseGroups(organization.selectedOrganization))
    }, [])

    useEffect(() => {
        recalculateAvailableCourseGroupData()
    }, [organization.organizationCourseGroups])

    useEffect(() => {
        recalculateAvailableCourseGroupData()
    }, [organization.availableCourseGroups])

    const recalculateAvailableCourseGroupData = () => {
        let tempAvailableCourseGroups =
            organization.organizationCourseGroups?.length > 0 ? organization.organizationCourseGroups : []
        let tempCourseGroupIds = tempAvailableCourseGroups.map((x) => x.id)
        if (organization.availableCourseGroups?.length > 0) {
            organization.availableCourseGroups.forEach((x) => {
                if (x.organizationId === organization.selectedOrganization && !tempCourseGroupIds.includes(x.id)) {
                    tempAvailableCourseGroups.push(x)
                    tempCourseGroupIds.push(x.id)
                }
            })
        }

        tempAvailableCourseGroups.sort((a, b) => a.name.localeCompare(b.name))

        setAvailableCourseGroups(tempAvailableCourseGroups)
        if (tempAvailableCourseGroups.length > 0) {
            setOrganizationCourseGroupsAvailable(true)
        }
    }

    const handleNameChanged = (e) => {
        setName(e.target.value)
        handleCheckForCreationErrors(e.target.value, checkedCourseGroupIds)
    }

    const handleCheckForCreationErrors = (checkName, checkCourseGroupIds) => {
        if (usergroup.usergroups.some((x) => x.name === checkName)) {
            setBlockClassroomCreation(true)
            setClassroomCreationErrorMessage('Name already in use')
            return
        }

        if (checkName === '') {
            setBlockClassroomCreation(true)
            setClassroomCreationErrorMessage('Cannot have empty name')
            return
        }

        if (checkCourseGroupIds.length === 0) {
            setBlockClassroomCreation(true)
            setClassroomCreationErrorMessage('Need to add at least one Course when creating the classroom')
            return
        }

        setBlockClassroomCreation(false)
    }

    const handleDescriptionChanged = (e) => {
        setDescription(e.target.value)
    }

    const handleBackClicked = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1)
        }
    }

    const handleCreateClassroomClicked = () => {
        if (usergroup.usergroups.some((x) => x.name === name)) {
            return
        }

        const { organization } = props
        setCreatingUserGroup(true)

        props.dispatch(
            usergroupActions.createUserGroup(
                organization.selectedOrganization,
                name,
                description,
                checkedCourseGroupIds
            )
        )
    }

    useEffect(() => {
        if (creatingUserGroup && !props.usergroup.creatingCourseGroup) {
            setCreatingUserGroup(false)
            props.onCloseDialog()
        }
    }, [props.usergroup.creatingUserGroup])

    const handleFinishedSettingClassroomInfo = () => {
        setCurrentStep(1)
    }

    const handleToggleCheckForCourseGroupId = (value) => {
        const currentIndex = checkedCourseGroupIds.indexOf(value)
        const newChecked = [...checkedCourseGroupIds]
        if (currentIndex === -1) {
            newChecked.push(value)
        } else {
            newChecked.splice(currentIndex, 1)
        }
        setCheckedCourseGroupIds(newChecked)

        handleCheckForCreationErrors(name, newChecked)
    }

    return (
        <Dialog open={props.open} maxWidth={'lg'} onClose={props.onCloseDialog}>
            <div style={{ width: '1000px', padding: '25px' }}>
                <Grid container spacing={10}>
                    <Grid item xs={12}>
                        <Stepper activeStep={currentStep} alternativeLabel>
                            {steps.map((label, index) => {
                                return (
                                    <Step key={index}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                )
                            })}
                        </Stepper>
                        {currentStep === 0 && (
                            <div>
                                <Card>
                                    <CardHeader title={steps[currentStep]} />
                                    <CardContent>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <FormControl fullWidth error={existingUserGroup} margin="normal">
                                                    <TextField
                                                        id="name"
                                                        disabled={usergroup.creatingUserGroup || creatingUserGroup}
                                                        label={
                                                            <FormattedMessage
                                                                id="globalwords.name5"
                                                                defaultMessage="Name"
                                                            />
                                                        }
                                                        value={name}
                                                        onChange={handleNameChanged}
                                                    />
                                                    {existingUserGroup && (
                                                        <FormHelperText id="component-error-text">
                                                            <FormattedMessage
                                                                id="dashboard.newclassroom.error.classroomexists"
                                                                defaultMessage="Classroom already exists"
                                                            />
                                                        </FormHelperText>
                                                    )}
                                                </FormControl>
                                            </Grid>
                                            {user.organizationRole === 'admin' && (
                                                <Grid item xs={12}>
                                                    <TextField
                                                        disabled={usergroup.creatingUserGroup || creatingUserGroup}
                                                        id="description"
                                                        label={
                                                            <FormattedMessage
                                                                id="globalwords.description"
                                                                defaultMessage="Description"
                                                            />
                                                        }
                                                        value={description}
                                                        onChange={handleDescriptionChanged}
                                                        margin="normal"
                                                        fullWidth
                                                        multiline
                                                        rows="4"
                                                    />
                                                </Grid>
                                            )}
                                        </Grid>
                                    </CardContent>
                                    <CardActions>
                                        <Button disabled size="large" onClick={handleBackClicked}>
                                            <FormattedMessage id="globalwords.back" defaultMessage="Back" />
                                        </Button>
                                        <Button
                                            disabled={
                                                name.length === 0 ||
                                                existingUserGroup ||
                                                usergroup.creatingUserGroup ||
                                                creatingUserGroup
                                            }
                                            size="large"
                                            color={'primary'}
                                            variant={'contained'}
                                            onClick={handleFinishedSettingClassroomInfo}>
                                            {usergroup.creatingUserGroup || creatingUserGroup ? (
                                                <CircularProgress size={24} />
                                            ) : (
                                                <FormattedMessage id="globalwords.next" defaultMessage="Next" />
                                            )}
                                        </Button>
                                    </CardActions>
                                </Card>
                            </div>
                        )}

                        {currentStep === 1 && (
                            <div>
                                <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                                    <CardHeader title={steps[currentStep]} />
                                    <CardContent>
                                        <Grid item xs={12}>
                                            <Typography style={{ marginTop: '30xp' }} color="primary" variant="body1">
                                                <FormattedMessage
                                                    id="dashboard.newclassroom.choosecontentdescription"
                                                    defaultMessage="Choose which content the classroom should get access to."
                                                />
                                            </Typography>
                                            <Grid container spacing={1} style={{ marginTop: '10px' }}>
                                                {availableCourseGroups.map((courseGroup, index) => (
                                                    <Grid item xs={12} key={courseGroup.id}>
                                                        <CourseGroupCard
                                                            courseGroup={courseGroup}
                                                            index={index}
                                                            slideDirection={'right'}
                                                            checked={
                                                                checkedCourseGroupIds.indexOf(courseGroup.id) !== -1
                                                            }
                                                            handleCheckMarkCourseGroup={
                                                                handleToggleCheckForCourseGroupId
                                                            }
                                                        />
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                    <CardActions disableSpacing sx={{ mt: 'auto' }}>
                                        <Button disabled={currentStep === 0} size="large" onClick={handleBackClicked}>
                                            <FormattedMessage id="globalwords.back" defaultMessage="Back" />
                                        </Button>
                                        <Button
                                            disabled={
                                                blockClassroomCreation ||
                                                usergroup.creatingUserGroup ||
                                                creatingUserGroup
                                            }
                                            size="large"
                                            color={'primary'}
                                            variant={'contained'}
                                            onClick={handleCreateClassroomClicked}>
                                            {usergroup.creatingUserGroup || creatingUserGroup ? (
                                                <CircularProgress size={24} />
                                            ) : (
                                                <FormattedMessage id="globalwords.create" defaultMessage="Create" />
                                            )}
                                        </Button>
                                    </CardActions>
                                </Card>
                            </div>
                        )}
                    </Grid>
                </Grid>
            </div>
        </Dialog>
    )
}

function mapStateToProps(state) {
    const { usergroup, user, organization } = state
    return {
        usergroup,
        user,
        organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewClassroomDialog)
