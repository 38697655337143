import React, { Fragment, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import {
    AutoCourseCreateDialogStepType,
    AutoCourseCreateType,
    AutoCourseCreatorLessonType,
    AutoCourseCreatorLevelType,
    AutoCourseCreatorProcessStages,
    AutoCourseCreatorToneOfVoice,
} from '../../../../Logic/AutoCourseCreationConstants'
import AutoCourseCreateIntroScreen from './AutoCourseCreateIntroScreen'
import { autoCourseCreationActions, topicActions } from '../../../../_state/actions'
import { motion } from 'framer-motion'
import AutoCourseCreateTeachingContent from './AutoCourseCreateTeachingContent'
import ACCLanguageSidebarCompliance from './ACCLanguageSidebarCompliance'
import ACCContinueButton from './ACCContinueButton'
import ACCLanguageLoadingSpinner from '../AutoCourseCreateLanguage/Components/ACCLanguageLoadingSpinner'
import ACCAnalysisCompletedPage from './ACCAnalysisCompletedPage'
import ReviewTeachingMaterialACC from './ReviewTeachingMaterialACC'
import AutoCourseCreateControllerEndStep from './AutoCourseCreateControllerEndStep'
import TeachingMaterialEditModal from './TeachingMaterialEditModal'
import { useLocation } from 'react-router-dom'
import BasicLessonPreviewDialog from '../../../../Components/BasicLessonPreviewDialog'
import CapeeshStyledModal from '../../../../Components/CapeeshStyledModal'
import { useIntl, defineMessages, FormattedMessage } from 'react-intl'
import { GlobalEvents } from '../../../../assets/GlobalEvents'
import Button from '@mui/material/Button'
import { CapeeshColors } from '../../../../assets/ColorPalette'
import { history } from '../../../../_state/helpers'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles((theme) => ({
    mainPageContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        display: 'flex',
        flexDirection: 'column',
        minWidth: '87vv',
        minHeight: '87vh',
        overflow: 'visible',
    },
    mainBackgroundContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        display: 'flex',
        position: 'fixed',
        zIndex: -1,
        left: '0%',
        top: '0%',
        width: '100%',
        height: '100vh',
        background:
            'linear-gradient(0deg, rgba(9, 40, 79, 0.24) 0%, rgba(9, 40, 79, 0.24) 100%), linear-gradient(72deg, rgba(162, 68, 160, 0.00) 47.32%, #1971DD 98.15%), linear-gradient(284deg, #A0439F 13.94%, #5B1E80 55.99%, #09284F 98.04%)',
        backgroundSize: 'cover',
    },

    lessonPlanButtonContainer: {
        display: 'flex',
        padding: '8px',
        justifyContent: 'space-between',
        borderRadius: '16px',
        width: '700px',
        // minWidth: '460px',
        // height: '60px',
        background: CapeeshColors.Primary900,
    },
    lessonPlanBottomFooter: {
        position: 'fixed',
        display: 'flex',
        justifyContent: 'center',
        top: 'calc(100% - 65px)',
        left: 'calc(100vh - 350px)',
        zIndex: 88,
        alignItems: 'center',
        alignSelf: 'center',
    },
}))

const AutoCourseCreateControllerImproved = (props) => {
    const classes = useStyles()

    const { computedMatch } = props

    const intl = useIntl()

    const messages = defineMessages({
        editTeachingMaterialTitle: {
            id: 'create.knowledge.ai.edit.teach.material.title',
            defaultMessage: 'Edit teaching material',
        },
        editTeachingMaterialSecondaryText: {
            id: 'create.knowledge.ai.edit.teach.material.secondaryText',
            defaultMessage:
                'Delve into the depths of user desires by conducting thorough user research and crafting vibrant personas, illuminating the paths that lead to user satisfaction.',
        },
        editTeachingMaterialFirstInputTitle: {
            id: 'create.knowledge.ai.edit.teach.material.first.input.title',
            defaultMessage: 'Title',
        },
        editTeachingMaterialDescriptionAndRules: {
            id: 'create.knowledge.ai.edit.teach.material.second.input.title',
            defaultMessage: 'Description and Rules',
        },
        saveChanges: {
            id: 'globalwords.save.changes',
            defaultMessage: 'Save changes',
        },
        discardChanges: {
            id: 'globalwords.discard.changes',
            defaultMessage: 'Discard changes',
        },
        addNewTeachingMaterialTitle: {
            id: 'create.knowledge.ai.add.new.teach.material.title',
            defaultMessage: 'Add new teaching material',
        },
        addNewTeachingMaterialSecondaryText: {
            id: 'create.knowledge.ai.add.new.teach.material.secondary.text',
            defaultMessage:
                'This material will be taught in your lessons. Edit it as you wish, or delete it if not relevant',
        },
        describleTeachMaterial: {
            id: 'create.knowledge.ai.add.new.teach.material.describe',
            defaultMessage: 'Describe the teaching material in a few words',
        },
        writeOutOrPasteTeachingMaterial: {
            id: 'create.knowledge.ai.add.new.teach.material.write.out.or.paste',
            defaultMessage: 'Write out of paste the teaching material that you wish to teach.',
        },
        addContent: { id: 'create.knowledge.ai.add.new.teach.material.add.content', defaultMessage: 'Add content' },
        cancel: { id: 'globalwords.cancel', defaultMessage: 'Cancel' },
    })

    const statusTexts = [
        <FormattedMessage
            id={'create.knowledge.ai.spinner.review.teaching.msg1'}
            defaultMessage={'Thanks! We are now analysing your content.'}
        />,
        <FormattedMessage
            id={'create.knowledge.ai.spinner.review.teaching.msg2'}
            defaultMessage={'This will take one to two more minutes - please don’t close the page.'}
        />,
        <FormattedMessage
            id={'create.knowledge.ai.spinner.review.teaching.msg3'}
            defaultMessage={'The text you added is being transformed into core teaching material.'}
        />,
        <FormattedMessage
            id={'create.knowledge.ai.spinner.review.teaching.msg4'}
            defaultMessage={'This core teaching material will be used to make your interactive lessons.'}
        />,
        <FormattedMessage
            id={'create.knowledge.ai.spinner.review.teaching.msg5'}
            defaultMessage={"You'll be able to make as many lessons as you wish."}
        />,
        <FormattedMessage
            id={'create.knowledge.ai.spinner.review.teaching.msg6'}
            defaultMessage={
                'OK, around one minute left! The next step will be to review the suggested teaching material.'
            }
        />,
    ]

    const courseGroupId = props.computedMatch.params.courseGroupId

    let topicIdAsInt = parseInt(props.computedMatch.params.topicId)
    if (topicIdAsInt <= 0) {
        topicIdAsInt = null
    }
    const topicId = topicIdAsInt

    const maxWordsInTeachingMaterial = 400

    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    const [openEditModal, setOpenEditModal] = useState(false)
    const [dialogTitle, setDialogTitle] = useState('')
    const [dialogSecondaryText, setDialogSecondaryText] = useState('')
    const [firstInputFieldTitle, setFirstInputFieldTitle] = useState('')
    const [firstTextBoxValue, setFirstTextBoxValue] = useState('')
    const [secondInputFieldTitle, setSecondInputFieldTitle] = useState('')
    const [secondInputFieldText, setSecondInputFieldText] = useState('')
    const [mainButtonText, setMainButtonText] = useState('')
    const [secondaryButtonText, setSecondaryButtonText] = useState('')
    const [selectedSection, setSelectedSection] = useState(null)
    const [selectedConcept, setSelectedConcept] = useState(null)
    const [attributeText, setAttributeText] = useState('')
    const [showLessonPlanDeleteConfirmation, setShowLessonPlanDeleteConfirmation] = useState(false)
    const [lessonPlanToDeleteId, setLessonPlanToDeleteId] = useState(null)
    const [lessonPlanToDeleteName, setLessonPlanToDeleteName] = useState('')
    const [selectedLesson, setSelectedLesson] = useState(null)

    const [attachingManyCustomLessonsToTopic, setAttachingManyCustomLessonsToTopic] = useState(false)

    const queryParamsSearch = useLocation().search

    const handleOpenEditModal = (isEdit, concept, section) => {
        if (isEdit) {
            setDialogTitle(intl.formatMessage(messages.editTeachingMaterialTitle))
            setDialogSecondaryText(intl.formatMessage(messages.editTeachingMaterialSecondaryText))
            setFirstInputFieldTitle(intl.formatMessage(messages.editTeachingMaterialFirstInputTitle))
            setFirstTextBoxValue(concept.text)
            setSecondInputFieldTitle(intl.formatMessage(messages.editTeachingMaterialDescriptionAndRules))
            setSecondInputFieldText(concept.explanation)
            setMainButtonText(intl.formatMessage(messages.saveChanges))
            setSecondaryButtonText(intl.formatMessage(messages.discardChanges))
            setSelectedSection(section.sectionId)
            setSelectedConcept(concept.conceptId)
            setAttributeText('conceptExplanation')
        } else {
            setDialogTitle(intl.formatMessage(messages.addNewTeachingMaterialTitle))
            setDialogSecondaryText(intl.formatMessage(messages.addNewTeachingMaterialSecondaryText))
            setFirstInputFieldTitle(intl.formatMessage(messages.describleTeachMaterial))
            setFirstTextBoxValue('')
            setSecondInputFieldTitle(intl.formatMessage(messages.writeOutOrPasteTeachingMaterial))
            setSecondInputFieldText('')
            setMainButtonText(intl.formatMessage(messages.addContent))
            setSecondaryButtonText(intl.formatMessage(messages.cancel))
            setSelectedSection(null)
            setSelectedConcept(null)
            setAttributeText('conceptNew')
        }

        setOpenEditModal(true)
    }

    const handleCloseEditModal = () => {
        setOpenEditModal(false)
    }

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth)
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    const handleToggleTranslations = () => {
        let localMetaData = { ...authorMetadataDto }
        localMetaData.includeTranslation = !!!localMetaData.includeTranslation

        props.dispatch(autoCourseCreationActions.accStoreAuthorInputMetadata(localMetaData))
        setAuthorMetadataDto(localMetaData)
    }

    const tempAuthorMetadataDto = {
        autoCourseCreateType: AutoCourseCreateType.Complience,
        authorCourseName: '',
        authorWhatText: '',
        authorWhyText: '',
        authorTargetAudienceText: 'Young adults',
        authorToneOfVoice: AutoCourseCreatorToneOfVoice.AbePersonality,
        authorLessonType: AutoCourseCreatorLessonType.Rules,
        organizationId: props.organization.selectedOrganization,
        l2Id: 1, // Gets swapped out in the initial useEffect
        defaultL1Id: 2, // Gets swapped out in the initial useEffect
        monolingual: false,
        authorTextLanguageId: 2,
        includeTranslation: true,
        includeExplanation: true,
        includeRuleTranslations: true,
        includeExam: false,
        teachAllText: false,
        level: AutoCourseCreatorLevelType.Beginner,
        numberOfLessons: 4,
        wordsPerLesson: 7,
        supportingText: '',
        topicId: topicId,
        courseGroupId: courseGroupId,
    }

    const [authorMetadataDto, setAuthorMetadataDto] = useState(tempAuthorMetadataDto)
    const [isTransitioning, setIsTransitioning] = useState(false)
    const [isWorking, setIsWorking] = useState(false)
    const [showLessonPreview, setShowLessonPreview] = useState(false)
    const [isTransitioningFromLoading, setIsTransitioningFromLoading] = useState(false)
    const [currentLessonId, setCurrentLessonId] = useState('')
    const slideAnimationDuration = 1

    const handleDoStageTransition = () => {
        setIsTransitioning(true)
        setTimeout(() => {
            proceedToNextStage()
            setIsTransitioning(false)
        }, slideAnimationDuration * 1000)
    }

    const handleCloseLessonPreview = () => {
        setShowLessonPreview(false)
    }

    const handleSetLessonId = (lessonId) => {
        document.dispatchEvent(GlobalEvents.UnityLessonPreviewHideShowToggle)
        document.dispatchEvent(
            new CustomEvent('initLessonPreviewWithInfo', {
                detail: {
                    customLessonId: lessonId,
                    l1Id: authorMetadataDto.defaultL1Id,
                    nodeId: null,
                    clickableSkipButton: true,
                    l2Id: authorMetadataDto.l2Id,
                },
            })
        )
    }

    const proceedToNextStage = () => {
        let next = props.autoCourseCreation.processingState + 1
        props.dispatch(autoCourseCreationActions.transitionStage({ stage: next }))
    }

    const handleResetData = () => {
        props.dispatch(autoCourseCreationActions.accResetAll())
        props.dispatch(autoCourseCreationActions.accResetAllLessonPlans())
        props.dispatch(autoCourseCreationActions.accResetAllSections())
        props.dispatch(autoCourseCreationActions.transitionStage({ stage: 1 }))
        let localMetaData = { ...authorMetadataDto }
        localMetaData.authorWhatText = ''
        localMetaData.authorCourseName = ''
        localMetaData.supportingText = ''
        props.dispatch(autoCourseCreationActions.accStoreAuthorInputMetadata(localMetaData))
        setAuthorMetadataDto(localMetaData)
    }

    const handleGoToTopic = () => {
        history.push('/creator/course/group/' + courseGroupId + '/topic/' + topicIdAsInt)
    }

    const numberOfLessonDrafts = () => {
        let numberOfLessonDrafts = 0
        props.autoCourseCreation?.lessonPlansDtos?.map((lessonPlanDto, index) =>
            lessonPlanDto.lessons?.map((lessonSuggestionDto, index2) => {
                if (lessonSuggestionDto.createdLessonId?.length > 0) {
                    numberOfLessonDrafts++
                }
            })
        )
        return numberOfLessonDrafts
    }
    const handleAddLessonsAsDrafts = () => {
        const latestCustomLessonIds = []

        props.autoCourseCreation?.lessonPlansDtos?.map((lessonPlanDto, index) =>
            lessonPlanDto.lessons?.map((lessonSuggestionDto, index2) => {
                if (lessonSuggestionDto.createdLessonId?.length > 0) {
                    let numberOfLessons = lessonSuggestionDto.createdLessonId?.length
                    let lastCustomLessonId = lessonSuggestionDto.createdLessonId[numberOfLessons - 1]
                    latestCustomLessonIds.push(lastCustomLessonId)
                }
            })
        )

        if (latestCustomLessonIds.length > 0) {
            setAttachingManyCustomLessonsToTopic(true)
            props.dispatch(
                topicActions.attachManyCustomLessonsToTopic(
                    courseGroupId,
                    topicIdAsInt,
                    0,
                    latestCustomLessonIds,
                    false
                )
            )
        }
    }

    useEffect(() => {
        if (attachingManyCustomLessonsToTopic && !props.topic.attachingManyCustomLessonsToTopic) {
            setAttachingManyCustomLessonsToTopic(false)

            // cleanup
            props.dispatch(autoCourseCreationActions.accResetAll())
            props.dispatch(autoCourseCreationActions.accResetAllLessonPlans())
            props.dispatch(autoCourseCreationActions.accResetAllSections())
            props.dispatch(autoCourseCreationActions.transitionStage({ stage: 1 }))

            history.push('/creator/course/group/' + courseGroupId + '/topic/' + topicId)
        }
    }, [props.topic])

    const handleSetAuthorInputMetaData = (attribute, text) => {
        let localMetaData = { ...authorMetadataDto }
        switch (attribute) {
            case AutoCourseCreateDialogStepType.CourseName:
                localMetaData.authorCourseName = text
                break
            case AutoCourseCreateDialogStepType.What:
                localMetaData.authorWhatText = text
                localMetaData.authorCourseName = text
                break
            case AutoCourseCreateDialogStepType.Why:
                localMetaData.authorWhyText = text
                break
            case AutoCourseCreateDialogStepType.SupportingText:
                localMetaData.supportingText = text
                break
            case AutoCourseCreateDialogStepType.ToneOfVoice:
                localMetaData.authorToneOfVoice = text
                break
        }
        props.dispatch(autoCourseCreationActions.accStoreAuthorInputMetadata(localMetaData))
        setAuthorMetadataDto(localMetaData)
    }

    const suggestSections = (localMetaData) => {
        let parseTextRequestDto = {
            authorInputMetadata: localMetaData,
            text: localMetaData.supportingText,
        }
        props.dispatch(autoCourseCreationActions.suggestTextSections(parseTextRequestDto))
    }

    const handleRemoveLessonConfirmed = () => {
        handleUpdateLessonSuggestionDto(lessonPlanToDeleteId, 'removeLesson', null)
        setLessonPlanToDeleteId(null)
        setLessonPlanToDeleteName('')
        setShowLessonPlanDeleteConfirmation(false)
    }
    const handleRemoveLessonNotConfirmed = () => {
        setLessonPlanToDeleteId(null)
        setLessonPlanToDeleteName('')
        setShowLessonPlanDeleteConfirmation(false)
    }

    const handleRemoveLesson = (lessonPlanId, lessonName, selectedLesson) => {
        setLessonPlanToDeleteId(lessonPlanId)
        setLessonPlanToDeleteName(lessonName)
        setShowLessonPlanDeleteConfirmation(true)
        setSelectedLesson(selectedLesson)
    }

    const handleUpdateLessonSuggestionDto = (lessonPlanId, attribute, text, shouldRefreshLessonDetails) => {
        let objectToSend = {}
        let authorInputMetadata = props.autoCourseCreation.authorInputMetadata
        let lessonPlanDtoV2 = selectedLesson

        if (shouldRefreshLessonDetails)
            // the option to reffesh the title and description if user made a fundamental cange to the lesson
            objectToSend = {
                authorInputMetadata,
                text: text,
                lesson: lessonPlanDtoV2,
            }

        props.dispatch(
            autoCourseCreationActions.accUpdateLessonSuggestionDto({
                l2Id: authorInputMetadata.l2Id,
                l1Id: authorInputMetadata.defaultL1Id,
                lessonPlanId: lessonPlanId,
                attribute: attribute,
                text: text,
                shouldRefreshLessonDetails: shouldRefreshLessonDetails,
                additionals: objectToSend,
            })
        )

        setSelectedLesson(null)
    }

    const handleLocalUpdateSuggestSectionsReturnDto = (
        sectionId,
        attribute,
        text,
        conceptId = null,
        otherInformation = null
    ) => {
        props.dispatch(
            autoCourseCreationActions.accLocalUpdateSuggestSectionsReturnDto({
                sectionId: sectionId,
                attribute: attribute,
                text: text,
                conceptId: conceptId,
                otherInformation: otherInformation,
            })
        )
    }

    const handleGenerateLessons = () => {
        if (props.autoCourseCreation.suggestSectionsReturnDto?.sections?.length > 0) {
            props.autoCourseCreation.suggestSectionsReturnDto.sections.forEach((section) => {
                props.dispatch(
                    autoCourseCreationActions.accSuggestLessonPlanForSection({
                        authorInputMetadata: props.autoCourseCreation.authorInputMetadata,
                        text: props.autoCourseCreation.authorInputMetadata.supportingText,
                        section: section,
                    })
                )
            })
        }

        proceedToNextStage()
    }

    useEffect(() => {
        const createFromTopic = new URLSearchParams(queryParamsSearch).get('createFromTopic')
        if (createFromTopic === 'true') {
            const l2Id = new URLSearchParams(queryParamsSearch).get('l2Id')
            const defaultL1Id = new URLSearchParams(queryParamsSearch).get('defaultL1Id')
            if (l2Id && defaultL1Id) {
                authorMetadataDto.l2Id = parseInt(l2Id)
                authorMetadataDto.defaultL1Id = parseInt(defaultL1Id)
                authorMetadataDto.authorTextLanguageId = parseInt(defaultL1Id)
                authorMetadataDto.topicId = topicId
            }
        }
    }, [])

    useEffect(() => {
        if (props.autoCourseCreation.suggestSectionsReturnDto !== null) {
            setIsTransitioningFromLoading(true)
            setTimeout(() => {
                setIsTransitioningFromLoading(false)
                setIsWorking(false)
            }, 2000)
        }
    }, [props.autoCourseCreation.suggestSectionsReturnDto])

    document.addEventListener('unityLessonPreviewDialogClose', () => handleCloseLessonPreview())

    return (
        <div style={{ position: 'relative', zIndex: 1 }}>
            <div
                style={{
                    position: 'fixed',
                    top: 'calc(50% - 120px)',
                    height: '300px',
                    width: '230px',
                    zIndex: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    alignSelf: 'center',
                }}>
                <ACCLanguageSidebarCompliance
                    onStartAgain={handleResetData}
                    currentStage={props.autoCourseCreation.processingState}
                />
            </div>
            <div className={classes.mainPageContainer}>
                <div className={classes.mainBackgroundContainer} />
                {showLessonPlanDeleteConfirmation && (
                    <CapeeshStyledModal
                        open={showLessonPlanDeleteConfirmation}
                        textTitle={'Delete confirmation'}
                        textLine1={
                            'Do you really want delete the lesson plan: "' +
                            lessonPlanToDeleteName +
                            '"? This will remove it from the list and will need to be regenerated.'
                        }
                        onButton2={() => handleRemoveLessonConfirmed()}
                        onButton1={() => handleRemoveLessonNotConfirmed()}
                        button1Text={<FormattedMessage id="globalwords.cancel" defaultMessage="Cancel" />}
                        button1Variant={'outlined'}
                        button2Text={<FormattedMessage id="globalwords.yes" defaultMessage="Yes" />}
                        onClose={() => handleRemoveLessonNotConfirmed()}
                    />
                )}
                {openEditModal && (
                    <TeachingMaterialEditModal
                        openDialog={openEditModal}
                        closeDialog={handleCloseEditModal}
                        dialogTitle={dialogTitle}
                        dialogSecondaryText={dialogSecondaryText}
                        firstInputFieldTitle={firstInputFieldTitle}
                        firstTextBoxValue={firstTextBoxValue}
                        secondInputFieldTitle={secondInputFieldTitle}
                        secondInputFieldText={secondInputFieldText}
                        mainButtonText={mainButtonText}
                        secondaryButtonText={secondaryButtonText}
                        selectedSection={selectedSection}
                        selectedConcept={selectedConcept}
                        attributeText={attributeText}
                        save={handleLocalUpdateSuggestSectionsReturnDto}
                    />
                )}
                <div
                    style={{
                        marginLeft: 28 + 4 * (100 - windowWidth / 28),
                        width: 'auto',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        alignSelf: 'center',
                        height: '100%',
                        marginRight: 16,
                    }}>
                    <motion.div
                        animate={
                            isTransitioning ? { y: [0, -1000], opacity: [1, 0] } : { y: [1000, 0], opacity: [0, 1] }
                        }
                        style={{
                            marginTop: 64,
                            marginBottom: 64,
                            maxWidth: 1440,
                            justifyContent: 'center',
                            alignItems: 'center',
                            alignSelf: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                        }}>
                        {props.autoCourseCreation.processingState === AutoCourseCreatorProcessStages.StartSequence && (
                            <Fragment>
                                <AutoCourseCreateIntroScreen
                                    handleSetAuthorInputMetaData={handleSetAuthorInputMetaData}
                                    authorMetadataDto={authorMetadataDto}
                                />
                                <ACCContinueButton
                                    disabledCondition={
                                        props?.autoCourseCreation?.authorInputMetadata?.authorWhatText.length === 0
                                    }
                                    text={<FormattedMessage id={'globalwords.continue'} defaultMessage={'Continue'} />}
                                    click={handleDoStageTransition}
                                />
                            </Fragment>
                        )}
                        {props.autoCourseCreation.processingState ===
                            AutoCourseCreatorProcessStages.ConfirmTeachingMaterial && (
                            <Fragment>
                                <AutoCourseCreateTeachingContent
                                    handleSetAuthorInputMetaData={handleSetAuthorInputMetaData}
                                    maxLenght={maxWordsInTeachingMaterial}
                                    authorMetadataDto={authorMetadataDto}
                                    handleToggleTranslations={handleToggleTranslations}
                                    teachingText={authorMetadataDto.authorWhatText}
                                />
                                <ACCContinueButton
                                    disabledCondition={
                                        props?.autoCourseCreation?.authorInputMetadata?.supportingText.length === 0
                                    }
                                    text={
                                        <FormattedMessage
                                            id={'create.knowledge.ai.teaching.content.continue'}
                                            defaultMessage={'Create teaching material'}
                                        />
                                    }
                                    click={() => {
                                        suggestSections(authorMetadataDto)
                                        handleDoStageTransition()
                                        setIsWorking(true)
                                    }}
                                />
                            </Fragment>
                        )}
                        {props.autoCourseCreation.processingState ===
                            AutoCourseCreatorProcessStages.TransitioningToReviewLessonPlans && (
                            <Fragment>
                                <motion.div animate={{ opacity: [0, 1] }} transition={{ delay: 1, duration: 1 }}>
                                    {isWorking && (
                                        <ACCLanguageLoadingSpinner
                                            mainText={
                                                <FormattedMessage
                                                    id={'create.knowledge.ai.spinner.thank.you.title'}
                                                    defaultMessage={'Thanks! We are now analysing you content.'}
                                                />
                                            }
                                            infoTexts={statusTexts}
                                            estimatedDuration={120}
                                        />
                                    )}
                                </motion.div>
                                {!isWorking &&
                                    props.autoCourseCreation.processingState ===
                                        AutoCourseCreatorProcessStages.TransitioningToReviewLessonPlans && (
                                        <motion.div animate={{ scale: [0, 1], y: [-100, 100, 0] }}>
                                            <ACCAnalysisCompletedPage />
                                            <ACCContinueButton
                                                disabledCondition={false}
                                                text={
                                                    <FormattedMessage
                                                        id={'create.knowledge.ai.spinner.review.teaching.material'}
                                                        defaultMessage={'Review teaching material'}
                                                    />
                                                }
                                                click={() => {
                                                    handleDoStageTransition()
                                                }}
                                            />
                                        </motion.div>
                                    )}
                            </Fragment>
                        )}
                        {props.autoCourseCreation.processingState ===
                            AutoCourseCreatorProcessStages.ReviewLessonPlans && (
                            <ReviewTeachingMaterialACC
                                suggestSectionsReturnDto={props.autoCourseCreation.suggestSectionsReturnDto}
                                generate={handleGenerateLessons}
                                handleOpen={handleOpenEditModal}
                            />
                        )}
                        {props.autoCourseCreation.processingState === AutoCourseCreatorProcessStages.Finished && (
                            <AutoCourseCreateControllerEndStep
                                computedMatch={computedMatch}
                                dispatch={props.dispatch}
                                handleOpenEditModal={handleOpenEditModal}
                                handleSetLessonId={handleSetLessonId}
                                removeLesson={handleRemoveLesson}
                            />
                        )}
                    </motion.div>
                </div>
            </div>

            {props.autoCourseCreation.processingState === AutoCourseCreatorProcessStages.Finished && (
                <div className={classes.lessonPlanBottomFooter}>
                    <div className={classes.lessonPlanButtonContainer}>
                        <div>
                            <Button
                                style={{
                                    textTransform: 'none',
                                    float: 'left',
                                    background: CapeeshColors.DarkButtonBackground,

                                    color: 'white',
                                }}
                                variant={'outlined'}
                                onClick={handleResetData}>
                                Start again
                            </Button>
                            <Button
                                style={{
                                    textTransform: 'none',
                                    float: 'left',
                                    background: CapeeshColors.DarkButtonBackground,
                                    color: 'white',
                                    marginLeft: '16px',
                                }}
                                variant={'outlined'}
                                onClick={handleGoToTopic}>
                                <FormattedMessage
                                    id={'create.language.bottom.bar.back.to.lesson.list'}
                                    defaultMessage={'Back to lesson list'}
                                />
                            </Button>
                        </div>
                        <div>
                            <Button
                                style={{
                                    textTransform: 'none',
                                    background: CapeeshColors.BlueBright,
                                    borderColor: 'white',
                                    color: 'white',
                                }}
                                variant={'contained'}
                                disabled={numberOfLessonDrafts() === 0 || props.topic.attachingManyCustomLessonsToTopic}
                                onClick={handleAddLessonsAsDrafts}>
                                {props.topic.attachingManyCustomLessonsToTopic && (
                                    <CircularProgress
                                        style={{
                                            marginLeft: '10px',
                                            marginTop: '5px',
                                            height: '20px',
                                            width: '20px',
                                            pointerEvents: 'none',
                                            color: 'white',
                                        }}></CircularProgress>
                                )}

                                {!props.topic.attachingManyCustomLessonsToTopic && (
                                    <FormattedMessage
                                        id={'create.knowledge.ai.generate.save.lesson.drafts'}
                                        defaultMessage={'Save {numberOfLessonDrafts} lessons drafts'}
                                        values={{ numberOfLessonDrafts: numberOfLessonDrafts() }}
                                    />
                                )}
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

AutoCourseCreateControllerImproved.propTypes = {}

const mapStateToProps = (state) => {
    return {
        organization: state.organization,
        autoCourseCreation: state.autoCourseCreation,
        topic: state.topic,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(AutoCourseCreateControllerImproved)
