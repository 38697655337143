import React, { useEffect, useState } from 'react'
import { Typography, makeStyles, Grid } from '@material-ui/core'
import { connect } from 'react-redux'
import { CapeeshColors } from '../../assets/ColorPalette'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'

const useStyles = makeStyles((theme) => ({
    container: {
        borderRadius: '16px',
        backgroundColor: CapeeshColors.Gray5,
        color: 'black',
        height: 'auto',
        minWidth: 250,
        maxWidth: 400,
        padding: '24px',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        width: 'auto',
    },

    title: {
        marginTop: '5px',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        fontFamily: 'Rubik',
        color: CapeeshColors.DarkText,
    },

    bottomPart: {
        // marginLeft: '10px',
        width: '100%',
        marginTop: '16px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },

    stats: {
        // marginLeft: '10px',
        fontSize: '40px',
        fontFamily: 'Rubik',
        fontWeight: 500,
        minWidth: '60px',
        justifyContent: 'flex-start',
    },

    changePart: {
        marginTop: '10px',
        width: '100px',
        justifyContent: 'flex-end',
    },
    changeText: {
        // width: '100px'
        color: CapeeshColors.Green,
        fontSize: '14px',
        fontFamily: 'Rubik',
        fontWeight: '400',
        textAlign: 'center',
        borderRadius: '16px',
        width: 75,
    },
    changeDescription: {
        fontFamily: 'Rubik',
        fontSize: '14px',
        fontWeight: '400',
        textAlign: 'left',
    },
}))

const SimpleFactBox = (props) => {
    // expected props
    // props.title
    // props.statsText
    // props.changeText
    // props.changeDescription
    // props.textColor
    // props.trendColor
    // props.trendColorBackground

    const classes = useStyles()

    return (
        <div
            className={classes.container}
            style={{ backgroundColor: props.backgroundColor ? props.backgroundColor : CapeeshColors.Gray5 }}>
            <Typography className={classes.title} style={{ color: props.textColor ? props.textColor : '' }}>
                {props.title}
            </Typography>

            <div className={classes.bottomPart}>
                <Typography className={classes.stats} style={{ color: props.textColor ? props.textColor : '' }}>
                    {props.statsText}
                </Typography>

                <div className={classes.changePart}>
                    <Typography
                        className={classes.changeText}
                        style={{
                            color: props.trendColor ? props.trendColor : '',
                            backgroundColor: props.trendColorBackground ? props.trendColorBackground : '',
                        }}>
                        {props.changeText}
                        {props.changeText !== '0' && props.changeText !== 0 && (
                            <TrendingUpIcon
                                sx={{
                                    marginLeft: '3px',
                                    fontSize: '14px',
                                    color: props.trendColor ? props.trendColor : CapeeshColors.Green,
                                }}
                            />
                        )}
                    </Typography>

                    <Typography
                        className={classes.changeDescription}
                        style={{ color: props.textColor ? props.textColor : '' }}>
                        {props.changeDescription}
                    </Typography>
                </div>
            </div>
        </div>
    )
}

SimpleFactBox.propTypes = {}

const mapStateToProps = (state) => {
    const { user, organization, metadata } = state
    return {
        metadata,
        user,
        organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(SimpleFactBox)
