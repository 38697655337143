import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { styled } from '@mui/material/styles'
import { connect } from 'react-redux'
import 'react-image-crop/dist/ReactCrop.css'
import '@fontsource/nunito'
import { autoCourseCreationActions, snackbarActions } from '../../../../_state/actions'
import {
    AutoCourseCreateDialogStepType,
    AutoCourseCreatorLessonType,
    AutoCourseCreatorLevelType,
    AutoCourseCreatorToneOfVoice,
    AutoCourseCreteEditModeType,
    AutoCourseCreatorLessonTeachingPlotTypes,
    AutoCourseCreatorLessonTeachingInteractionTypes,
    AutoCourseCreatorStageDefaultInteractions,
    AutoCourseCreatorProcessStages,
    AutoCourseCreateType,
} from '../../../../Logic/AutoCourseCreationConstants'
import { v4 as uuidv4 } from 'uuid'
import IconButton from '@mui/material/IconButton'
import { useLocation } from 'react-router-dom'
import { DragDropContext } from 'react-beautiful-dnd'
import { useSpring, animated } from '@react-spring/web'
import Slide from '@mui/material/Slide'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import AutoCourseSuggestTextSectionsImproved from './AutoCourseSuggestTextSectionsImproved'
import TeachingMaterialEditModal from './TeachingMaterialEditModal'
import Typography from '@material-ui/core/Typography'
import AutoCourseSuggestLessonPlanImproved from './AutoCourseSuggestLessonPlanImproved'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'

const useStyles = makeStyles((theme) => ({
    sideStripCollapse: {
        position: 'absolute',
        top: 0,
        left: 352,
        bottom: 0,
        width: '20px',
        display: 'flex',
        alignItems: 'center',
    },
    sideStripExpand: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        width: '20px',
        display: 'flex',
        alignItems: 'center',
    },
    sideContent: {
        height: '100vh - 106px',
    },
    grid: {
        transition: 'all 1s',
    },
    button: {
        borderRadius: 5,
        background: 'var(--Blue, #44A6EF)',
        textTransform: 'none',
        color: 'white',
        marginLeft: 260,
        marginTop: 90,
        paddingTop: 16,
        paddingBottom: 16,
        paddingLeft: 25,
        paddingRight: 25,
    },
    buttonclicked: {
        transition: 'background 1s',
        background: 'red',
    },

    dialogTitle: {
        backgroundColor: '#143349',
        fontColor: 'white',
    },
    dialogTitleDiv: {
        display: 'flex',
    },
    topText: {
        width: '100%',
        textAlign: 'center',
        color: 'white',
        fontSize: 32,
        fontFamily: 'Rubik',
        fontWeight: '400',
        wordWrap: 'break-word',
    },
    secondaryTopText: {
        width: '100%',
        textAlign: 'center',
        color: 'rgba(255, 255, 255, 0.80)',
        fontSize: 20,
        fontFamily: 'Rubik',
        fontWeight: '400',
        wordWrap: 'break-word',
    },
}))

const MyGrid = styled(Grid, {
    shouldForwardProp: (prop) => prop !== 'isVisible',
})(({ theme, isVisible }) => ({
    transition: theme.transitions.create('width'),
    width: isVisible ? 'auto' : 0,
    overflow: 'hidden',
}))

const AutoCourseCreateControllerEndStep = (props) => {
    const classes = useStyles()
    const { computedMatch } = props
    const [screenState, setScreenState] = useState(0)
    const scollToRef = useRef()
    const [supportingText, setSupportingText] = useState('')
    const [showTopics, setShowTopics] = useState(true)
    const [showPageHeader, setShowPageHeader] = useState(false)
    const [selectedStage, setSelectedStage] = useState(null)
    const [viewSource, setViewSource] = useState(false)
    const [isDragging, setIsDragging] = useState(false)
    const [draggedWidth, setDraggedWidth] = useState(null)
    const [autoCourseCreateEditModeType, setAutoCourseCreateEditModeType] = useState(
        AutoCourseCreteEditModeType.TopicSummary
    )
    const [selectedLesson, setSelectedLesson] = useState(null)
    const [selectedConcept, setSelectedConcept] = useState(null)
    const [selectedSection, setSelectedSection] = useState(null)
    const [conceptExplanation, setConceptExplanation] = useState(null)
    const [conceptTitle, setConceptTitle] = useState(null)
    const [buttonClicked, setButtonClicked] = useState(false)
    const [buttonOpacity, setButtonOpacity] = useState(1)
    const [finishedCreateSections, setFinishedCreateSections] = useState(false)
    const [showStartScreen, setShowStartScreen] = useState(false)
    const [shouldShowStartScreen, setShouldShowStartScreen] = useState(false)
    const [isCreatingNewConcept, setIsCreatingNewConcept] = useState(false)
    const [isDialogueOpen, setIsDialogueOpen] = useState(false)
    const [isNewTeachingMaterialDialogueOpen, setIsNewTeachingMaterialDialogueOpen] = useState(false)
    const statusTexts = [
        'Thanks! We are now analysing your content.',
        'This will take one to two more minutes - please don’t close the page.',
        'The text you added is being transformed into core teaching material.',
        'This core teaching material will be used to make your interactive lessons.',
        "You'll be able to make as many lessons as you wish.",
        'OK, around one minute left! The next step will be to review the suggested teaching material.',
    ]
    const [isResetConfirmationDialogueOpen, setIsResetConfirmationDialogueOpen] = useState(false)
    const [processingStatusText, setProcessingStatusText] = useState(0)
    const [isVisible, setIsVisible] = useState(true)
    const [isStarted, setIsStarted] = useState(false)
    // All done! I've turned your text into a syllabus of things to teach.
    const [estimatedProcessingTime, setEstimatedProcessingTime] = useState('1 minute')
    const [shouldShowProcessingFeedback, setShouldShowProcessingFeedback] = useState(true)

    const [processingState, setProcessingState] = useState(1)
    const [isVisible1, setIsVisible1] = React.useState(true)
    const [entryPoint, setEntryPoint] = React.useState(1)

    const intl = useIntl()

    const messages = defineMessages({
        allImagesFail: {
            id: 'create.knowledge.ai.image.generation.fail.message',
            defaultMessage: 'Failed to generate all images.',
        },
        someImagesFail: {
            id: 'create.knowledge.ai.some.image.generation.fail.message',
            defaultMessage: 'Failed to generate some images.',
        },
    })

    const courseGroupId = props.computedMatch.params.courseGroupId

    let topicIdAsInt = parseInt(props.computedMatch.params.topicId)
    if (topicIdAsInt <= 0) {
        topicIdAsInt = null
    }
    const topicId = topicIdAsInt
    const queryParamsSearch = useLocation().search

    // lesson preview
    const [showLessonPreview, setShowLessonPreview] = useState(false)
    const unityStagePreviewControllerRef = useRef(null)
    const [customLessonIdToPreview, setCustomLessonIdToPreview] = useState(null)

    // lesson plan removal
    const [showLessonPlanDeleteConfirmation, setShowLessonPlanDeleteConfirmation] = useState(false)
    const [lessonPlanToDeleteId, setLessonPlanToDeleteId] = useState(null)
    const [lessonPlanToDeleteName, setLessonPlanToDeleteName] = useState('')
    const maximumTextLength = 400

    const [springsLetsgo, apispringsLetsgo] = useSpring(() => ({
        from: { opacity: 1 },
    }))
    //let x  = '25%'
    const [springsSections, apiSpringsSections] = useSpring(() => ({
        from: { x: 0, width: '66%' },
    }))

    const [springsReviewEntryPoint, apiSpringsReviewEntryPoint] = useSpring(() => ({
        from: { x: 0, width: '25%' },
    }))

    const [springsStartForm, apiSpringsStartForm] = useSpring(() => ({
        from: { x: 0, width: '25%', padding: '55px' },
    }))

    const [springsTextfields, apiSpringsTextfields] = useSpring(() => ({
        from: { opacity: 1 },
    }))

    const [springsLessonPlanExplanation, apiSpringsLessonPlanExplanation] = useSpring(() => ({
        from: { marginBottom: '10px', paddingRight: '40px', height: '40px', width: '100%', opacity: 1 },
    }))

    const tempAuthorMetadataDto = {
        autoCourseCreateType: AutoCourseCreateType.Complience,
        authorCourseName: '',
        authorWhatText: '',
        authorWhyText: '',
        authorTargetAudienceText: 'Young adults',
        authorToneOfVoice: AutoCourseCreatorToneOfVoice.AbePersonality,
        authorLessonType: AutoCourseCreatorLessonType.Rules,
        organizationId: props.organization.selectedOrganization,
        l2Id: 1, // Gets swapped out in the initial useEffect
        defaultL1Id: 2, // Gets swapped out in the initial useEffect
        monolingual: false,
        authorTextLanguageId: 2,
        includeTranslation: true,
        includeExplanation: true,
        includeRuleTranslations: true,
        includeExam: false,
        teachAllText: false,
        level: AutoCourseCreatorLevelType.Beginner,
        numberOfLessons: 4,
        wordsPerLesson: 7,
        supportingText: '',
        topicId: topicId,
        courseGroupId: courseGroupId,
    }

    const [authorMetadataDto, setAuthorMetadataDto] = useState(tempAuthorMetadataDto)
    useEffect(() => {
        if (!isStarted) {
            return
        }

        setIsVisible(true)

        const timer = setInterval(() => {
            setIsVisible(false)

            setTimeout(() => {
                setProcessingStatusText((prev) => {
                    if (prev + 1 >= statusTexts.length) {
                        // We reached the last status. Clear the interval!
                        clearInterval(timer)
                        return prev
                    } else {
                        setIsVisible(true)
                        return prev + 1
                    }
                })
            }, 1000)
        }, 12000)

        return () => {
            clearInterval(timer)
        }
    }, [isStarted, statusTexts.length])

    useEffect(() => {
        let dialogStep1Id = uuidv4()
        let dialogAiStep1 = {
            dialogStepId: dialogStep1Id,
            text: 'What do you want the content to be about?',
            autoCourseCreateDialogStepType: AutoCourseCreateDialogStepType.AIText,
        }

        let dialogStep2Id = uuidv4()
        let dialogStep2 = {
            dialogStepId: dialogStep2Id,
            text: '',
            autoCourseCreateDialogStepType: AutoCourseCreateDialogStepType.What,
            placeholderText: 'Write your answer here',
            inputRows: 2,
            doneButtonText: 'Ok',
        }
        const dialogStepsTemp = [dialogAiStep1, dialogStep2]
        props.dispatch(autoCourseCreationActions.accStoreAuthorDialog(dialogStepsTemp))

        if (screenState === 1 || screenState === 2) {
            scollToRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
    }, [screenState])
    const containerRef = useRef(null)

    const addDialogStep = (dialogStepType, text, placeholderText = '', inputRows = 1, canSkip = false) => {
        let newDialogStepId = uuidv4()

        let newDialogStep = {
            dialogStepId: newDialogStepId,
            text: text,
            autoCourseCreateDialogStepType: dialogStepType,
            placeholderText: placeholderText,
            inputRows: inputRows,
            canSkip: canSkip,
            answer: '',
            answered: false,
        }

        var dialogStepsTemp =
            props.autoCourseCreation?.dialogSteps?.length > 0 ? props.autoCourseCreation?.dialogSteps : []
        dialogStepsTemp.push(newDialogStep)
        props.dispatch(autoCourseCreationActions.accStoreAuthorDialog(dialogStepsTemp))
    }

    const alertUser = (e) => {
        e.preventDefault()
        e.returnValue = ''
    }

    useEffect(() => {
        //props.dispatch(autoCourseCreationActions.accResetAll())
        //props.dispatch(autoCourseCreationActions.accResetAllLessonPlans())
        if (props.autoCourseCreation.processingState === AutoCourseCreatorProcessStages.ReviewLessonPlans) {
            setEntryPoint(AutoCourseCreatorProcessStages.ReviewLessonPlans)
        } else {
            setEntryPoint(1)
        }

        const createFromTopic = new URLSearchParams(queryParamsSearch).get('createFromTopic')
        if (createFromTopic === 'true') {
            const l2Id = new URLSearchParams(queryParamsSearch).get('l2Id')
            const defaultL1Id = new URLSearchParams(queryParamsSearch).get('defaultL1Id')
            if (l2Id && defaultL1Id) {
                authorMetadataDto.l2Id = parseInt(l2Id)
                authorMetadataDto.defaultL1Id = parseInt(defaultL1Id)
                authorMetadataDto.authorTextLanguageId = parseInt(defaultL1Id)
                authorMetadataDto.topicId = topicId
            }
        }
        const selectedLessonPlanId = new URLSearchParams(queryParamsSearch).get('selectedLessonPlanId')
        if (selectedLessonPlanId) {
            handleUpdateSelectedLesson(selectedLessonPlanId)
        }

        window.addEventListener('beforeunload', alertUser)

        //props.dispatch(autoCourseCreationActions.accOnRefreshResetSome());
        return () => {
            window.removeEventListener('beforeunload', alertUser)
        }
    }, [])

    useEffect(() => {
        if (screenState === 3) {
            setScreenState(4)
        }
    }, [props.autoCourseCreation.topicPlanDto])

    useEffect(() => {
        if (props.autoCourseCreation.suggestSectionsReturnDto !== null) {
            setFinishedCreateSections(true)
        }
    }, [props.autoCourseCreation.suggestSectionsReturnDto])

    useEffect(() => {
        if (
            isStarted &&
            buttonClicked &&
            !finishedCreateSections &&
            props.autoCourseCreation.suggestingTextSectionsFailed
        ) {
            // failed to create the text suggestions, resetting to original state
            setFinishedCreateSections(true)
            setIsStarted(false)
            setButtonClicked(false)
            setButtonOpacity(1)
        }
    }, [props.autoCourseCreation.suggestingTextSectionsFailed])

    useEffect(() => {
        if (containerRef !== null && containerRef !== undefined) {
        }
    }, [props.autoCourseCreation])

    useEffect(() => {
        if (screenState === 4) {
            setScreenState(5)
        }
    }, [props.autoCourseCreation.lessonPlanSectionDtos])

    useEffect(() => {
        if (
            props.autoCourseCreation?.lessonPlansDtos?.length ===
            props.autoCourseCreation?.suggestSectionsReturnDto?.sections?.length
        ) {
            const totalAmountOfImages = []
            const actualAmountOfImages = []

            props.autoCourseCreation?.suggestSectionsReturnDto?.sections?.forEach((section) => {
                section?.concepts?.forEach((concept) => {
                    if (
                        concept?.imageUrl !== '' &&
                        concept?.imageUrl &&
                        totalAmountOfImages.indexOf(concept?.conceptId) === -1
                    ) {
                        totalAmountOfImages.push(concept?.conceptId)
                    }
                })
            })

            props.autoCourseCreation?.lessonPlansDtos?.forEach((lessonPlanDto) => {
                lessonPlanDto?.lessons?.forEach((lesson) => {
                    lesson?.concepts?.forEach((concept) => {
                        if (
                            concept?.imageUrl !== '' &&
                            concept?.imageUrl &&
                            actualAmountOfImages.indexOf(concept?.conceptId) === -1
                        ) {
                            actualAmountOfImages.push(concept?.conceptId)
                        }
                    })
                })
            })

            if (actualAmountOfImages.length <= 0) {
                props.dispatch(
                    snackbarActions.enqueueSnackbar({
                        message: intl.formatMessage(messages.allImagesFail),
                        options: {
                            variant: 'warning',
                        },
                    })
                )
            } else if (totalAmountOfImages.length !== actualAmountOfImages.length) {
                props.dispatch(
                    snackbarActions.enqueueSnackbar({
                        message: intl.formatMessage(messages.someImagesFail),
                        options: {
                            variant: 'warning',
                        },
                    })
                )
            }
        }
    }, [props.autoCourseCreation.lessonPlansDtos])

    const handleResetConfirmation = () => {
        setIsResetConfirmationDialogueOpen(true)
    }
    const handleUpdateStagePlotSelection = (
        stageType,
        plotType,
        lessonPlanId,
        shouldRemove,
        coversMultpleConcepts,
        interactionType = 'Reading'
    ) => {
        props.dispatch(
            autoCourseCreationActions.accUpdateLessonSuggestionDto({
                lessonPlanId: lessonPlanId,
                attribute: 'updateExercises',
                text: {
                    stageType: stageType,
                    plotType: plotType,
                    shouldRemove: shouldRemove,
                    coversMultpleConcepts: coversMultpleConcepts,
                    interactionType: interactionType,
                },
            })
        )
    }

    const handleToggleSourceConcepts = () => {
        setViewSource(!!!viewSource)
    }

    const handleToggleVisibility = () => {
        setShowTopics(!showTopics)
    }

    const suggestLessonPlanForSingleSection = (sectionDto) => {
        props.dispatch(
            autoCourseCreationActions.accSuggestLessonPlanForSection({
                authorInputMetadata: props.autoCourseCreation.authorInputMetadata,
                text: props.autoCourseCreation.authorInputMetadata.supportingText,
                section: sectionDto,
            })
        )
    }
    const pause = () => {
        return new Promise((resolve) => setTimeout(resolve, 1000))
    }

    const suggestLessonPlanForAllSections = async () => {
        apiSpringsLessonPlanExplanation.start({
            from: { opacity: 1, marginBottom: '10px', paddingRight: '40px', height: '10px', width: '10px' },
            to: { opacity: 0, width: '0px', height: '0px', marginBottom: '0px', paddingRight: '0px' },
        })
        apiSpringsSections.start({
            from: { x: 0, width: '66%' },
            to: { x: 0, width: '25%' },
        })
        apiSpringsStartForm.start({
            from: { width: '24%' },
            to: { width: '0px', padding: '0px' },
        })
        apiSpringsTextfields.start({
            from: { opacity: 1 },
            to: { opacity: 0 },
        })

        setProcessingState(3)
        props.dispatch(
            autoCourseCreationActions.transitionStage({
                stage: AutoCourseCreatorProcessStages.TransitioningToReviewLessonPlans,
            })
        )

        await pause()

        props.dispatch(
            autoCourseCreationActions.transitionStage({ stage: AutoCourseCreatorProcessStages.ReviewLessonPlans })
        )
        setProcessingState(4)

        if (props.autoCourseCreation.suggestSectionsReturnDto?.sections?.length > 0) {
            props.autoCourseCreation.suggestSectionsReturnDto.sections.forEach((section) => {
                props.dispatch(
                    autoCourseCreationActions.accSuggestLessonPlanForSection({
                        authorInputMetadata: props.autoCourseCreation.authorInputMetadata,
                        text: props.autoCourseCreation.authorInputMetadata.supportingText,
                        section: section,
                    })
                )
            })
        }
    }

    const handleUpdateSelectedLesson = (lessonPlanId) => {
        if (selectedLesson !== null && lessonPlanId === selectedLesson.lessonPlanId) {
            setSelectedLesson(null)
            return
        }
        const foundObject = findObjectWithId(props.autoCourseCreation.lessonPlansDtos, lessonPlanId)
        if (foundObject !== null) {
            setSelectedLesson(foundObject)
            setSelectedStage(null)
        }
        // setSelectedLesson(lessonPlanId)
    }

    const handleUpdateLessonSuggestionDto = (lessonPlanId, attribute, text, shouldRefreshLessonDetails) => {
        let objectToSend = {}
        let authorInputMetadata = props.autoCourseCreation.authorInputMetadata
        let lessonPlanDtoV2 = selectedLesson

        if (shouldRefreshLessonDetails)
            // the option to reffesh the title and description if user made a fundamental cange to the lesson
            objectToSend = {
                authorInputMetadata,
                text: text,
                lesson: lessonPlanDtoV2,
            }

        props.dispatch(
            autoCourseCreationActions.accUpdateLessonSuggestionDto({
                l2Id: authorInputMetadata.l2Id,
                l1Id: authorInputMetadata.defaultL1Id,
                lessonPlanId: lessonPlanId,
                attribute: attribute,
                text: text,
                shouldRefreshLessonDetails: shouldRefreshLessonDetails,
                additionals: objectToSend,
            })
        )
    }

    const handleAddNewStages = (newLessonPlans, lessonPlanId) => {
        props.dispatch(
            autoCourseCreationActions.accUpdateLessonSuggestionDto({
                lessonPlanId: lessonPlanId,
                attribute: 'addNewStagesToComplianceLesson',
                text: {
                    newLessonPlans: newLessonPlans,
                    defaultInteractions: AutoCourseCreatorStageDefaultInteractions,
                },
            })
        )
    }

    const handleDeleteQuizQuestion = (lessonPlanId, questionId) => {
        props.dispatch(
            autoCourseCreationActions.accUpdateLessonSuggestionDto({
                lessonPlanId: lessonPlanId,
                attribute: 'removeQuizQuestion',
                text: { questionId: questionId },
            })
        )
    }

    const handleSuggestQuiz = (event = null) => {
        if (event !== null) {
            event.stopPropagation()
            event.preventDefault()
        }
        let authorInputMetadata = props.autoCourseCreation.authorInputMetadata
        let lessonPlanDtoV2 = selectedLesson
        let selectedSection = null
        props.autoCourseCreation.suggestSectionsReturnDto.sections.forEach((section) => {
            if (section.sectionId === selectedLesson.sectionId) {
                selectedSection = section
            }
        })

        let objectToSend = {
            authorInputMetadata,
            section: selectedSection,
            text: authorInputMetadata.supportingText,
            lesson: lessonPlanDtoV2,
            concepts: lessonPlanDtoV2.concepts,
        }
        props.dispatch(autoCourseCreationActions.accCreateQuiz(objectToSend))
    }

    const handleSuggesScenario = (event) => {
        event.stopPropagation()
        event.preventDefault()
        let authorMetaData = props.autoCourseCreation.authorInputMetadata

        let lessonPlanDtoV2 = selectedLesson

        let sectionsArray = null
        props.autoCourseCreation.suggestSectionsReturnDto.sections.forEach((section) => {
            if (section.sectionId === selectedLesson.sectionId) {
                sectionsArray = [section]
            }
        })

        let objectToSend = {
            ...authorMetaData,
            topicId: topicId,
            lesson: lessonPlanDtoV2,
            sections: sectionsArray,
            organizationId: props.organization.selectedOrganization,
            l2Id: authorMetaData.l2Id,
            defaultL1Id: authorMetaData.defaultL1Id,
        }
        props.dispatch(autoCourseCreationActions.accSuggestScenario(objectToSend))
    }

    const handleLocalUpdateSuggestSectionsReturnDto = (
        sectionId,
        attribute,
        text,
        conceptId = null,
        otherInformation = null
    ) => {
        props.dispatch(
            autoCourseCreationActions.accLocalUpdateSuggestSectionsReturnDto({
                sectionId: sectionId,
                attribute: attribute,
                text: text,
                conceptId: conceptId,
                otherInformation: otherInformation,
            })
        )
    }

    const handleCreateLesson = (lessonId, sectionId, type) => {
        handleUpdateLessonSuggestionDto(lessonId, 'newLesson', { type: type, sectionId: sectionId })
    }

    const handleUpdateConceptsInLesson = (lessonId, conceptId, newLessonPlan) => {
        let authorInputMetadata = props.autoCourseCreation.authorInputMetadata
        let selectedSection = null

        props.autoCourseCreation.suggestSectionsReturnDto.sections.forEach((section) => {
            if (section.sectionId === newLessonPlan.sectionId) {
                selectedSection = section
            }
        })

        let objectToSend = {
            authorInputMetadata,
            section: selectedSection,
            text: authorInputMetadata.supportingText,
            lesson: newLessonPlan,
            topicId: topicId,
            concepts: newLessonPlan.concepts,
            lessonPlanId: newLessonPlan.lessonPlanId,
        }

        props.dispatch(
            autoCourseCreationActions.accUpdateTitleAndDescription({
                lessonPlanId: newLessonPlan.lessonPlanId,
                attribute: 'concepts',
                objectToSend: objectToSend,
            })
        )
    }

    const handleRemoveConcept = (lessonId, conceptId) => {
        let newLessonPlan = {}
        props.autoCourseCreation.lessonPlansDtos.forEach((lessonPlan) => {
            lessonPlan.lessons.forEach((lesson) => {
                if (lesson.lessonPlanId === lessonId) {
                    newLessonPlan = lesson
                    // TODO UPDATE the lesson dto here with the concept removed
                    newLessonPlan.concepts = lesson.concepts.filter((x) => x.conceptId !== conceptId)
                }
            })
        })

        handleUpdateConceptsInLesson(lessonId, conceptId, newLessonPlan)
        //handleUpdateLessonSuggestionDto(newLessonPlan.lessonPlanId, "concepts", newLessonPlan)
    }

    const handleRemoveLessonConfirmed = () => {
        handleUpdateLessonSuggestionDto(lessonPlanToDeleteId, 'removeLesson', null)
        setLessonPlanToDeleteId(null)
        setLessonPlanToDeleteName('')
        setShowLessonPlanDeleteConfirmation(false)
    }
    const handleRemoveLessonNotConfirmed = () => {
        setLessonPlanToDeleteId(null)
        setLessonPlanToDeleteName('')
        setShowLessonPlanDeleteConfirmation(false)
    }

    const handleRemoveLesson = (lessonPlanId, lessonName) => {
        setLessonPlanToDeleteId(lessonPlanId)
        setLessonPlanToDeleteName(lessonName)
        setShowLessonPlanDeleteConfirmation(true)
    }

    const handleGetStageDetails = (event) => {
        event.stopPropagation()
        event.preventDefault()

        let authorInputMetadata = props.autoCourseCreation.authorInputMetadata

        let lessonPlanDtoV2 = selectedLesson
        let selectedSection = null
        props.autoCourseCreation.suggestSectionsReturnDto.sections.forEach((section) => {
            if (section.sectionId === selectedLesson.sectionId) {
                selectedSection = section
            }
        })

        let objectToSend = {
            authorInputMetadata,
            section: selectedSection,
            text: authorInputMetadata.supportingText,
            lesson: lessonPlanDtoV2,
            concepts: lessonPlanDtoV2.concepts,
        }

        props.dispatch(autoCourseCreationActions.accCreateDialogue(objectToSend))
        //props.dispatch(autoCourseCreationActions.accCreateFillInBlanks(objectToSend))
        //handleSuggestQuiz()
    }

    const handleToggleSelectedStages = (stageType) => {
        setSelectedStage(stageType)
        // TODO need to rewire up the adding and removing of stages
    }

    const handlePreviewLesson = (customLessonId) => {
        props.handleSetLessonId(customLessonId)
    }

    const handleMakeLessonFromLessonPlan = (lessonPlansId, lessonPlanId) => {
        // MORTEN main one in use..
        let authorMetaData = props.autoCourseCreation.authorInputMetadata

        let lessonPlansDto = props.autoCourseCreation.lessonPlansDtos.find((x) => x.lessonPlanId === lessonPlansId)
        if (!lessonPlansDto) {
            console.log('Cannot make lesson plans for unknown lessonPlansId ' + lessonPlansId)
            return
        }

        let lessonPlanDtoV2 = lessonPlansDto.lessons.find((x) => x.lessonPlanId === lessonPlanId)
        if (!lessonPlansDto) {
            console.log('Cannot make lesson for unknown lessonPlanId ' + lessonPlanId)
            return
        }

        var lessonType = lessonPlanDtoV2.lessonPlanConfigurationDto.configurationType
        let lessonSubType = lessonPlanDtoV2.lessonSubType

        let sectionsArray = null
        props.autoCourseCreation.suggestSectionsReturnDto.sections.forEach((section) => {
            if (section.sectionId === lessonPlanDtoV2.sectionId) {
                sectionsArray = [section]
            }
        })

        let objectToSend = {
            ...authorMetaData,
            attachToTopicId: null,
            lesson: lessonPlanDtoV2,
            sections: sectionsArray,
            organizationId: props.organization.selectedOrganization,
            l2Id: authorMetadataDto.l2Id,
            defaultL1Id: authorMetaData.defaultL1Id,
        }

        // if (topicId) { // auto attach if created inside of a topic
        //   objectToSend.attachToTopicId = topicId;
        // }

        switch (lessonSubType) {
            case 'Normal':
                if (lessonPlanDtoV2.lessonPlanConfigurationDto.configurationType === 'Traditional') {
                    objectToSend.exercises = lessonPlanDtoV2.lessonPlanConfigurationDto.exercises
                    props.dispatch(autoCourseCreationActions.accCreateLessonWithSelectedStages(objectToSend))
                } else {
                    props.dispatch(autoCourseCreationActions.accCreateLesson(objectToSend))
                }
                break
            default:
                props.dispatch(autoCourseCreationActions.accCreateLesson(objectToSend))
                break
        }
    }

    const handleMakeLesson = (lessonType) => {
        // TODO: remove (probably dependent on lesson being selected eg. opened)
        let authorMetaData = props.autoCourseCreation.authorInputMetadata

        let lessonPlanDtoV2 = selectedLesson

        let sectionsArray = null
        props.autoCourseCreation.suggestSectionsReturnDto.sections.forEach((section) => {
            if (section.sectionId === selectedLesson.sectionId) {
                sectionsArray = [section]
            }
        })

        let objectToSend = {
            ...authorMetaData,
            attachToTopicId: null,
            lesson: lessonPlanDtoV2,
            sections: sectionsArray,
            organizationId: props.organization.selectedOrganization,
            l2Id: authorMetadataDto.l2Id,
            defaultL1Id: authorMetaData.defaultL1Id,
        }

        // if (topicId) { // auto attach if created inside of a topic
        //   objectToSend.attachToTopicId = topicId;
        // }

        switch (lessonType) {
            case 'Traditional':
                if (selectedLesson.lessonPlanConfigurationDto.configurationType === 'Traditional') {
                    objectToSend.exercises = selectedLesson.lessonPlanConfigurationDto.exercises

                    props.dispatch(autoCourseCreationActions.accCreateLessonWithSelectedStages(objectToSend))
                } else {
                    props.dispatch(autoCourseCreationActions.accCreateLesson(objectToSend))
                }
                break
            default:
                props.dispatch(autoCourseCreationActions.accCreateLesson(objectToSend))
                break
        }
    }

    const onDragStart = (result, id) => {
        setIsDragging(true)
    }

    const onDragEnd = (result, id) => {
        setIsDragging(false)
        let lessonPlayToUpdate = null
        let conceptToAdd = null
        let conceptId = result.draggableId
        if (result.destination === null) return
        let lessonId = result.destination.droppableId.replace('listLesson', '')
        props.autoCourseCreation.suggestSectionsReturnDto.sections.forEach((section) => {
            section.concepts.forEach((concept) => {
                if (concept.conceptId === conceptId) {
                    conceptToAdd = concept
                }
            })
        })
        let found = false
        props.autoCourseCreation.lessonPlansDtos.forEach((lessonPlan) => {
            lessonPlan.lessons.forEach((lesson) => {
                if (lesson.lessonPlanId === lessonId) {
                    lesson.concepts.forEach((concept) => {
                        if (concept.conceptId === conceptId) {
                            found = true
                        }
                    })
                    if (found === false) {
                        lesson.concepts.unshift(conceptToAdd)
                        lessonPlayToUpdate = lesson
                    }
                }
            })
        })
        if (lessonPlayToUpdate !== null) {
            //handleUpdateLessonSuggestionDto(lessonId, "concepts", lessonPlayToUpdate)
            handleUpdateConceptsInLesson(lessonId, conceptId, lessonPlayToUpdate)
        }
        return
    }

    const findObjectWithId = (array, id) => {
        // Loop through each inner array
        for (let i = 0; i < array.length; i++) {
            // Find the first object that matches the given id
            const foundObject = array[i].lessons.find((obj) => obj.lessonPlanId === id)

            if (foundObject) {
                return foundObject
            }
        }

        return null // If no matching object is found
    }

    const fadeInStyle = {
        transition: 'opacity 1.5s ease-in',
        opacity: buttonClicked ? 1 : 0,
    }

    const handleAddConcept = () => {
        setIsNewTeachingMaterialDialogueOpen(true)

        props.handleOpenEditModal(false)
    }

    const handleEditConcept = (conceptId) => {
        setIsDialogueOpen(true)
        setIsCreatingNewConcept(false)
        let selectedConcept = null
        props.autoCourseCreation.suggestSectionsReturnDto.sections.forEach((section) => {
            section.concepts.forEach((concept) => {
                if (concept.conceptId === conceptId) {
                    selectedConcept = concept
                    setSelectedConcept(selectedConcept)
                    setSelectedSection(section)
                    setConceptExplanation(concept.explanation)
                    setConceptTitle(concept.text)

                    props.handleOpenEditModal(true, concept, section)
                }
            })
        })
    }
    return (
        <Fragment>
            <Grid item xs={12}>
                <Grid
                    container
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginBottom: '10px',
                    }}>
                    <Typography className={classes.topText}>
                        <FormattedMessage id={'create.knowledge.ai.lesson.plan'} defaultMessage={'Lesson Plan'} />
                    </Typography>
                    <Grid item xs={12}>
                        {props.autoCourseCreation.isSuggestingLessonPlans?.length > 0 ? (
                            <Typography className={classes.secondaryTopText}>
                                <FormattedMessage
                                    id={'create.knowledge.ai.lesson.plan.gen.desc'}
                                    defaultMessage={
                                        'We are currently generating lesson suggestions. This will take a minute or two.'
                                    }
                                />
                            </Typography>
                        ) : (
                            <Typography className={classes.secondaryTopText}>
                                {props.autoCourseCreation?.lessonPlansDtos?.length === 0 ? (
                                    <FormattedMessage
                                        id={'create.knowledge.ai.lesson.plan.no.lesson.plans'}
                                        defaultMessage={'There are no lessons to show.'}
                                    />
                                ) : (
                                    <FormattedMessage
                                        id={'create.knowledge.ai.lesson.plan.lesson.plans.desc'}
                                        defaultMessage={
                                            "Here's a list of lesson suggestions. Click to edit each lesson. When happy, click create lesson and try it out!"
                                        }
                                    />
                                )}
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            </Grid>

            <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        alignSelf: 'center',
                        width: '100%',
                        maxWidth: 1440,
                    }}>
                    <AutoCourseSuggestTextSectionsImproved
                        onEditConcept={handleEditConcept}
                        onAddConcept={handleAddConcept}
                        authorWhatText={props.autoCourseCreation?.authorInputMetadata?.authorWhatText}
                        supportingText={props.autoCourseCreation?.authorInputMetadata?.supportingText}
                        viewSource={viewSource}
                        currentEditModeStep={autoCourseCreateEditModeType}
                        onToggleSourceConcepts={handleToggleSourceConcepts}
                        onLocalUpdateSuggestSectionsReturnDto={handleLocalUpdateSuggestSectionsReturnDto}
                        onToggleVisibility={handleToggleVisibility}
                        accSuggestLessonPlanForSection={suggestLessonPlanForSingleSection}
                        onGenerateAllLessons={suggestLessonPlanForAllSections}
                        compactView={
                            props.autoCourseCreation.processingState !==
                            AutoCourseCreatorProcessStages.ConfirmTeachingMaterial
                        }
                    />
                    <Grid item xs={showTopics ? 9 : 12} style={{ width: '100%', height: '100%' }}>
                        <div
                            style={{
                                background: 'white',
                                borderRadius: 5,
                                overflow: 'auto',
                                minWidth: 745,
                                height: 820,
                            }}>
                            {showTopics === false && (
                                <div style={{ position: 'absolute', left: '0px', top: '50%' }}>
                                    <IconButton onClick={handleToggleVisibility}>
                                        <KeyboardArrowRightIcon style={{ color: 'white' }} />
                                    </IconButton>
                                </div>
                            )}
                            <AutoCourseSuggestLessonPlanImproved
                                onUpdateStagePlotSelection={handleUpdateStagePlotSelection}
                                onResetData={handleResetConfirmation}
                                onGenerateAllLessons={suggestLessonPlanForAllSections}
                                onCreateLesson={handleCreateLesson}
                                onRemoveConcept={handleRemoveConcept}
                                onRemoveLesson={props.removeLesson}
                                onGetStageDetails={handleGetStageDetails}
                                draggedWidth={draggedWidth}
                                isDragging={isDragging}
                                onMakeLesson={handleMakeLesson}
                                onLessonFromLessonPlan={handleMakeLessonFromLessonPlan}
                                onPreviewLesson={handlePreviewLesson}
                                selectedStage={selectedStage}
                                onChangeSelectedStage={handleToggleSelectedStages}
                                onUpdateLessonSuggestionDto={handleUpdateLessonSuggestionDto}
                                selectedLesson={selectedLesson}
                                onUpdateSelectedLesson={handleUpdateSelectedLesson}
                                currentEditModeStep={autoCourseCreateEditModeType}
                                onSuggestScenario={handleSuggesScenario}
                                onSuggestQuiz={handleSuggestQuiz}
                                onAddNewStages={handleAddNewStages}
                                onDeleteQuizQuestion={handleDeleteQuizQuestion}
                            />
                        </div>
                    </Grid>
                </div>
            </DragDropContext>
        </Fragment>
    )
} //            Fine tune your lessons in Lesson Builder

function mapStateToProps(state) {
    const { organization, topic, lesson, metadata, autoCourseCreation, snackbarActions } = state
    return {
        organization,
        topic,
        lesson,
        metadata,
        autoCourseCreation,
        snackbarActions,
    }
}

AutoCourseCreateControllerEndStep.propTypes = {}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(AutoCourseCreateControllerEndStep)
