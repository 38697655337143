import React, { useState, useRef } from 'react'
import { connect } from 'react-redux'
import { CircularProgress, Grid, makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import {
    AutoCourseCreatorLessonSubType,
    AutoCourseCreatorLessonToneOfVoiceType,
    AutoCourseCreatorSimulationType,
    lengthInMinutes,
} from '../../../../Logic/AutoCourseCreationConstants'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import { FormattedMessage, useIntl } from 'react-intl'
import { i18nMessages } from 'Logic/CodeAccessible.i18n.Messages'

const useStyles = makeStyles((theme) => ({}))

const AutoCourseSuggestLessonDetailsSimulation = (props) => {
    const [isFocus, setIsFocus] = useState(false)
    const [focusedText, setFocusedText] = useState('')
    const focusedTextRef = useRef()
    focusedTextRef.current = focusedText
    const [focusedAttribute, setFocusedAttribute] = useState(null)
    const focusedAttributeRef = useRef()
    focusedAttributeRef.current = focusedAttribute
    const [openRoleDialog, setOpenRoleDialog] = useState(false)
    const [dialogTitle, setDialogTitle] = useState('')
    const [dialogMessage, setDialogMessage] = useState('')
    const [dialogExistingText, setDialogExistingText] = useState('')
    const intl = useIntl()
    const handleClickOpen = (roleType, title, message, existingText = '') => {
        setFocusedAttribute(roleType)
        setOpenRoleDialog(true)
        setDialogTitle(title)
        setDialogMessage(message)
        setDialogExistingText(existingText)
        handleTextFocus(roleType, props.selectedLesson.lessonPlanSimulationConfigurationDto.roleAi, roleType)
    }

    const handleCloseModal = () => {
        setOpenRoleDialog(false)
        setDialogExistingText('')
    }

    const handleSaveModal = () => {
        setOpenRoleDialog(false)
        props.onUpdateLessonSuggestionDto(props.selectedLesson.lessonPlanId, focusedAttributeRef.current, focusedText)
    }

    const handleTextFocus = (focusedId, text, attribute) => {
        setIsFocus(focusedId)
        setFocusedAttribute(attribute)
        setFocusedText(text)
    }

    const handleTextChange = (event) => {
        setFocusedText(event.target.value)
    }

    const handleChangeSelect = (element) => {
        props.onUpdateLessonSuggestionDto(props.selectedLesson.lessonPlanId, 'lessonSubType', element.target.value)
    }

    const handleTextBlur = (event) => {
        setIsFocus(false)
        props.onUpdateLessonSuggestionDto(
            props.selectedLesson.lessonPlanId,
            focusedAttributeRef.current,
            focusedTextRef.current
        )
    }

    const handleSelectChangePlot = (element) => {
        props.onUpdateLessonSuggestionDto(props.selectedLesson.lessonPlanId, 'plotText', element.target.value)
    }

    const handleChangeFruitMachineSelection = (fruitMachineType, selection) => {
        props.onUpdateLessonSuggestionDto(props.selectedLesson.lessonPlanId, fruitMachineType, selection)
    }

    const handleChangeRole = (roleType, newRole) => {
        props.onUpdateLessonSuggestionDto(props.selectedLesson.lessonPlanId, roleType, newRole)
    }

    const classes = useStyles()
    return (
        <React.Fragment>
            <Dialog open={openRoleDialog} onClose={handleCloseModal}>
                <DialogTitle>{dialogTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{dialogMessage}</DialogContentText>
                    <TextField
                        multiline
                        defaultValue={dialogExistingText}
                        autoFocus
                        margin="dense"
                        id="name"
                        fullWidth
                        variant="standard"
                        onChange={handleTextChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} style={{ textTransform: 'none' }}>
                        <FormattedMessage id={'globalwords.cancel'} defaultMessage={'Cancel'} />
                    </Button>
                    <Button onClick={handleSaveModal} style={{ textTransform: 'none' }}>
                        <FormattedMessage id={'globalwords.ok'} defaultMessage={'Ok'} />
                    </Button>
                </DialogActions>
            </Dialog>
            <Grid
                container
                alignItems="top"
                style={{ maxHeight: '406px', overflowY: 'auto', alignContent: 'flex-start' }}>
                <Grid item xs={7} style={{ paddingRight: '20px', marginTop: '10px' }}>
                    <Grid container>
                        <Typography
                            style={{
                                marginTop: '10px',
                                fontFamily: 'Rubik',
                                fontWeight: 500,
                                fontSize: '16px',
                                lineHeight: '19px',
                                color: 'black',
                                letterSpacing: '0.41px',
                                textAlign: 'left',
                                marginBottom: '10px',
                            }}>
                            <Button onClick={handleSaveModal} style={{ textTransform: 'none' }}>
                                <FormattedMessage
                                    id={'create.knowledge.ai.lesson.plan.lesson.what.type.of.lesson'}
                                    defaultMessage={'What type of lesson would you like to create?'}
                                />
                            </Button>
                        </Typography>
                        <Grid item xs={12}>
                            <Button
                                onClick={() =>
                                    props.onUpdateLessonSuggestionDto(
                                        props.selectedLesson.lessonPlanId,
                                        'simulationType',
                                        'ConversationWithSomeone',
                                        false
                                    )
                                }
                                style={{
                                    color: props.selectedLesson.lessonSubType === 'Simulation' ? 'white' : '#35323C',
                                    borderRadius: '5px',
                                    background:
                                        props.selectedLesson.lessonSubType === 'Simulation' ? '#44A6EF' : 'white',
                                    border:
                                        props.selectedLesson?.lessonPlanConfigurationDto?.configurationType ===
                                        'Simulation'
                                            ? 0
                                            : '1px solid var(--gray-1, #8E8E93)',
                                    display: 'inline',
                                    height: '31px',
                                    padding: '0 15px',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    gap: '20px',
                                    textTransform: 'none',
                                    marginRight: '10px',
                                }}>
                                <FormattedMessage id={'globalwords.simulation'} defaultMessage={'Simulation'} />
                            </Button>
                            <Button
                                onClick={() =>
                                    props.onUpdateLessonSuggestionDto(
                                        props.selectedLesson.lessonPlanId,
                                        'normalType',
                                        'Traditional',
                                        false
                                    )
                                }
                                style={{
                                    color:
                                        props.selectedLesson.lessonSubType === 'Normal' &&
                                        props.selectedLesson?.lessonPlanConfigurationDto?.configurationType ===
                                            'Traditional'
                                            ? 'white'
                                            : '#35323C',
                                    borderRadius: '5px',
                                    background:
                                        props.selectedLesson.lessonSubType === 'Normal' &&
                                        props.selectedLesson?.lessonPlanConfigurationDto?.configurationType ===
                                            'Traditional'
                                            ? '#44A6EF'
                                            : 'white',
                                    border:
                                        props.selectedLesson?.lessonPlanConfigurationDto?.configurationType ===
                                        'Simulation'
                                            ? 0
                                            : '1px solid var(--gray-1, #8E8E93)',
                                    display: 'inline',
                                    height: '31px',
                                    padding: '0 15px',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    gap: '20px',
                                    textTransform: 'none',
                                    marginRight: '10px',
                                }}>
                                <FormattedMessage id={'globalwords.complience'} defaultMessage={'Compliance'} />
                            </Button>
                            <Button
                                onClick={() =>
                                    props.onUpdateLessonSuggestionDto(
                                        props.selectedLesson.lessonPlanId,
                                        'normalType',
                                        'Quiz',
                                        false
                                    )
                                }
                                style={{
                                    color:
                                        props.selectedLesson.lessonSubType === 'Normal' &&
                                        props.selectedLesson?.lessonPlanConfigurationDto?.configurationType === 'Quiz'
                                            ? 'white'
                                            : '#35323C',
                                    borderRadius: '5px',
                                    background:
                                        props.selectedLesson.lessonSubType === 'Normal' &&
                                        props.selectedLesson?.lessonPlanConfigurationDto?.configurationType === 'Quiz'
                                            ? '#44A6EF'
                                            : 'white',
                                    border:
                                        props.selectedLesson?.lessonPlanConfigurationDto?.configurationType ===
                                        'Simulation'
                                            ? 0
                                            : '1px solid var(--gray-1, #8E8E93)',
                                    display: 'inline',
                                    height: '31px',
                                    padding: '0 15px',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    gap: '20px',
                                    textTransform: 'none',
                                    marginRight: '10px',
                                }}>
                                <FormattedMessage id={'globalwords.quiz'} defaultMessage={'Quiz'} />
                            </Button>
                        </Grid>
                        <Grid item style={{ marginTop: '10px' }}>
                            <Typography
                                style={{
                                    fontFamily: 'Rubik',
                                    fontWeight: 500,
                                    fontSize: '16px',
                                    lineHeight: '19px',
                                    color: 'black',
                                    letterSpacing: '0.41px',
                                    textAlign: 'left',
                                    marginTop: '20px',
                                }}>
                                <FormattedMessage
                                    id={'create.knowledge.ai.lesson.plan.lesson.suggest.lesson.plots'}
                                    defaultMessage={'Suggested lesson plots'}
                                />
                            </Typography>
                        </Grid>
                        <Grid item style={{ marginTop: '15px', marginLeft: '10px', paddingTop: '12px' }}>
                            <Button
                                onClick={(e) => props.onSuggestScenario(e)}
                                style={{
                                    textTransform: 'none',
                                    fontSize: '11px',
                                    background: '#A0439F',
                                    color: 'white',
                                    fontFamily: 'Rubik',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    padding: '10px',
                                    lineHeight: '12px',
                                }}>
                                {props.isScenarioGenerating === true ? (
                                    <CircularProgress style={{ color: 'white', height: '20px', width: '20px' }} />
                                ) : props?.selectedLesson?.lessonPlanConfigurationDto?.quizQuestions?.length > 0 ? (
                                    <FormattedMessage
                                        id={'globalwords.generate.more.suggestions'}
                                        defaultMessage={'Generate more suggestions'}
                                    />
                                ) : (
                                    <FormattedMessage
                                        id={'globalwords.generate.suggestions'}
                                        defaultMessage={'Generate suggestions'}
                                    />
                                )}
                            </Button>
                        </Grid>

                        <Grid item xs={12} style={{ marginTop: '5px' }}>
                            <FormControl fullWidth variant="outlined" style={{ marginTop: '10px' }}>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        },
                                        transformOrigin: {
                                            vertical: 'top',
                                            horizontal: 'left',
                                        },
                                        getContentAnchorEl: null,
                                    }}
                                    style={{
                                        height: '30px',
                                        color: '#35323C',
                                        fontFamily: 'Nunito',
                                        fontSize: '16px',
                                        fontStyle: 'normal',
                                        fontWeight: 400,
                                        lineHeight: '19px',
                                        letterSpacing: '0.41px',
                                        justifyContent: 'center',
                                        paddingTop: '5px',
                                        maxWidth: '100%',
                                    }}
                                    value={props.selectedLesson.lessonPlanSimulationConfigurationDto.plot}
                                    onChange={handleSelectChangePlot}
                                    renderValue={(selected) => {
                                        return (
                                            <Box
                                                component="span"
                                                sx={{
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    display: 'block',
                                                    maxWidth: 'calc(100% - 24px)', // 24px is icon's width
                                                }}>
                                                {selected}
                                            </Box>
                                        )
                                    }}>
                                    <div>
                                        <Button
                                            onClick={() =>
                                                handleClickOpen(
                                                    'plotText',
                                                    'Write the lesson plot',
                                                    'Write the plot that the lesson will be based on. Give as much detail as you wish on what should happen in the story.'
                                                )
                                            }
                                            style={{
                                                textTransform: 'none',
                                                width: '100%',
                                                textAlign: 'left',
                                                textDecoration: 'underline',
                                            }}>
                                            <FormattedMessage
                                                id={'create.knowledge.ai.lesson.plan.lesson.write.new.plot'}
                                                defaultMessage={'Write a new plot'}
                                            />
                                        </Button>
                                    </div>
                                    {props.selectedLesson.lessonPlanSimulationConfigurationDto.possiblePlots.map(
                                        (plot) => (
                                            <MenuItem
                                                key={plot}
                                                value={plot}
                                                style={{
                                                    color: '#35323C',
                                                    fontFamily: 'Rubik',
                                                    fontSize: '16px',
                                                    fontStyle: 'normal',
                                                    fontWeight: 400,
                                                    lineHeight: '19px',
                                                    letterSpacing: '0.41px',
                                                }}>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        width: '100%',
                                                    }}>
                                                    {plot}
                                                    <IconButton
                                                        size="small"
                                                        onClick={() =>
                                                            handleClickOpen(
                                                                'plotText',
                                                                intl.formatMessage(i18nMessages.writeLessonPlot),
                                                                intl.formatMessage(i18nMessages.writeLessonPlotDesc),
                                                                plot
                                                            )
                                                        }>
                                                        <EditIcon fontSize="inherit" />
                                                    </IconButton>
                                                </div>{' '}
                                            </MenuItem>
                                        )
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={5} style={{ paddingLeft: '20px', marginTop: '10px' }}>
                    <Grid container>
                        <Typography
                            style={{
                                fontFamily: 'Rubik',
                                fontWeight: 500,
                                fontSize: '16px',
                                lineHeight: '19px',
                                color: 'black',
                                letterSpacing: '0.41px',
                                textAlign: 'left',
                                marginTop: '10px',
                                marginBottom: '10px',
                            }}>
                            <FormattedMessage
                                id={'create.knowledge.ai.lesson.plan.tone.of.lesson'}
                                defaultMessage={'Tone of lesson'}
                            />
                        </Typography>
                        <Grid item xs={12}>
                            <Button
                                onClick={() => handleChangeFruitMachineSelection('lessonToneOfVoiceType', 'Serious')}
                                style={{
                                    color:
                                        props.selectedLesson.lessonToneOfVoiceType === 'Serious' ? 'white' : '#35323C',
                                    borderRadius: '5px',
                                    background:
                                        props.selectedLesson.lessonToneOfVoiceType === 'Serious' ? '#44A6EF' : 'white',
                                    border:
                                        props.selectedLesson.lessonToneOfVoiceType === 'Serious'
                                            ? 0
                                            : '1px solid var(--gray-1, #8E8E93)',
                                    display: 'inline',
                                    height: '31px',
                                    padding: '0 15px',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    gap: '20px',
                                    textTransform: 'none',
                                    marginRight: '10px',
                                }}>
                                <FormattedMessage
                                    id={'create.knowledge.ai.lesson.plan.serious'}
                                    defaultMessage={'Serious'}
                                />
                            </Button>
                            <Button
                                onClick={() => handleChangeFruitMachineSelection('lessonToneOfVoiceType', 'Playful')}
                                style={{
                                    color:
                                        props.selectedLesson.lessonToneOfVoiceType === 'Playful' ? 'white' : '#35323C',
                                    borderRadius: '5px',
                                    background:
                                        props.selectedLesson.lessonToneOfVoiceType === 'Playful' ? '#44A6EF' : 'white',
                                    border:
                                        props.selectedLesson.lessonToneOfVoiceType === 'Playful'
                                            ? 0
                                            : '1px solid var(--gray-1, #8E8E93)',
                                    display: 'inline',
                                    height: '31px',
                                    padding: '0 15px',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    gap: '20px',
                                    textTransform: 'none',
                                    marginRight: '10px',
                                }}>
                                <FormattedMessage
                                    id={'create.knowledge.ai.lesson.plan.playful'}
                                    defaultMessage={'Playful'}
                                />
                            </Button>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: '30px' }}>
                            <Typography
                                style={{
                                    fontFamily: 'Rubik',
                                    fontWeight: 500,
                                    fontSize: '16px',
                                    lineHeight: '19px',
                                    color: 'black',
                                    letterSpacing: '0.41px',
                                    textAlign: 'left',
                                    marginBottom: '10px',
                                }}>
                                <FormattedMessage id={'globalwords.roles'} defaultMessage={'Roles'} />
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{}}>
                            <Typography
                                style={{
                                    color: '#35323C',
                                    fontFamily: 'Nunito',
                                    fontSize: '16px',
                                    fontStyle: 'normal',
                                    fontWeight: 700,
                                    lineHeight: '19px',
                                    letterSpacing: '0.41px',
                                }}>
                                <FormattedMessage
                                    id={'create.knowledge.ai.lesson.plan.learners.role'}
                                    defaultMessage={"Learner's Role"}
                                />
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl style={{ minWidth: '100px' }}>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={props.selectedLesson.lessonPlanSimulationConfigurationDto.roleLearner}
                                    disableUnderline
                                    style={{
                                        height: '30px',
                                        color: '#35323C',
                                        fontFamily: 'Nunito',
                                        fontSize: '16px',
                                        fontStyle: 'normal',
                                        fontWeight: 400,
                                        lineHeight: '19px',
                                        letterSpacing: '0.41px',
                                        justifyContent: 'center',
                                        paddingTop: '5px',
                                        maxWidth: '100%',
                                    }}
                                    onChange={(e) => handleChangeRole('learnerRole', e.target.value)}>
                                    <div>
                                        <Button
                                            onClick={() =>
                                                handleClickOpen(
                                                    'learnerRole',
                                                    'Add a new role',
                                                    'Enter the name of the new role'
                                                )
                                            }
                                            style={{
                                                textTransform: 'none',
                                                width: '100%',
                                                textAlign: 'left',
                                                textDecoration: 'underline',
                                            }}>
                                            <FormattedMessage
                                                id={'create.knowledge.ai.lesson.plan.add.different.role'}
                                                defaultMessage={'Add a different role'}
                                            />
                                        </Button>
                                    </div>
                                    {props.selectedLesson.lessonPlanSimulationConfigurationDto.possibleRoles.map(
                                        (role, index) => (
                                            <MenuItem
                                                key={index}
                                                value={role}
                                                style={{
                                                    color: '#35323C',
                                                    fontFamily: 'Nunito',
                                                    fontSize: '16px',
                                                    fontStyle: 'normal',
                                                    fontWeight: 400,
                                                    lineHeight: '19px',
                                                    letterSpacing: '0.41px',
                                                }}>
                                                {role}
                                            </MenuItem>
                                        )
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography
                                style={{
                                    marginTop: '20px',
                                    color: '#35323C',
                                    fontFamily: 'Nunito',
                                    fontSize: '16px',
                                    fontStyle: 'normal',
                                    fontWeight: 700,
                                    lineHeight: '19px',
                                    letterSpacing: '0.41px',
                                }}>
                                <FormattedMessage
                                    id={'create.knowledge.ai.lesson.plan.ais.role'}
                                    defaultMessage={"AI's Role"}
                                />
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl style={{}}>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={props.selectedLesson.lessonPlanSimulationConfigurationDto.roleAi}
                                    disableUnderline
                                    style={{
                                        height: '30px',
                                        color: '#35323C',
                                        fontFamily: 'Nunito',
                                        fontSize: '16px',
                                        fontStyle: 'normal',
                                        fontWeight: 400,
                                        lineHeight: '19px',
                                        letterSpacing: '0.41px',
                                        justifyContent: 'center',
                                        paddingTop: '5px',
                                    }}
                                    onChange={(e) => handleChangeRole('aiRole', e.target.value)}>
                                    <div>
                                        <Button
                                            onClick={() =>
                                                handleClickOpen(
                                                    'aiRole',
                                                    'Add a new role',
                                                    'Enter the name of the new role'
                                                )
                                            }
                                            style={{
                                                textTransform: 'none',
                                                width: '100%',
                                                textAlign: 'left',
                                                textDecoration: 'underline',
                                            }}>
                                            <FormattedMessage
                                                id={'create.knowledge.ai.lesson.plan.add.different.role'}
                                                defaultMessage={'Add a different role'}
                                            />
                                        </Button>
                                    </div>
                                    {props.selectedLesson.lessonPlanSimulationConfigurationDto.possibleRoles.map(
                                        (role, index) => (
                                            <MenuItem
                                                key={index}
                                                value={role}
                                                style={{
                                                    color: '#35323C',
                                                    fontFamily: 'Nunito',
                                                    fontSize: '16px',
                                                    fontStyle: 'normal',
                                                    fontWeight: 400,
                                                    lineHeight: '19px',
                                                    letterSpacing: '0.41px',
                                                }}>
                                                {role}
                                            </MenuItem>
                                        )
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

AutoCourseSuggestLessonDetailsSimulation.propTypes = {}

function mapStateToProps(state) {
    const { autoCourseCreation } = state
    return {
        autoCourseCreation,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(AutoCourseSuggestLessonDetailsSimulation)
