import React, { useEffect, useState } from 'react'
import { Typography, makeStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import { makeGetRequest } from '../../../../_state/helpers/api'
import { configWithAuth } from '../../../../_state/helpers'
import { convertDataURIToBinary } from '../../../../_state/helpers/base64Logic'
import { CapeeshColors } from '../../../../assets/ColorPalette'
import { AddAlarmOutlined } from '@material-ui/icons'
import Zoom from '@mui/material/Zoom'

import FallbackCourseImage from '../../../../assets/images/courseGroup/FallbackCourseImage.jpeg'
import IconButton from '@material-ui/core/IconButton'
import Cancel from '@material-ui/icons/Cancel'
import CardContent from '@material-ui/core/CardContent'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles((theme) => ({
    cardContent: {
        backgroundColor: '#F6F7F9 !important',
        display: 'flex',
        width: '100%',
    },
    topicCard: {
        display: 'flex',
        transition: 'all .25s ease',
        cursor: 'pointer',
        '&:hover': {
            boxShadow: '0 5px 15px rgba(0,0,0,0.25)',
        },
        height: '130px',
    },
    courseGroupCardAction: {
        right: '15px',
        height: 'fit-content',
        margin: 'auto',
    },
    button: {
        backgroundColor: 'primary',
        fontColor: 'white',
        color: 'white',
        borderRadius: 4,
        marginRight: '10px',
        textTransform: 'none',
    },

    imageContainer: {
        marginLeft: '10px',
        marginBottom: '10px',
        position: 'relative',
        overflow: 'hidden',
        height: '100px',
        width: '110px',
        borderRadius: '10px',
    },
}))

const TopicPreviewCard = (props) => {
    // expected props
    // props.courseGroupSubPartToTopicMapperDto
    // props.openTopic function

    const classes = useStyles()

    const [description, setDescription] = useState('')
    const [topicNameAsString, setTopicNameAsString] = useState('')

    const [imageUrl, setImageUrl] = useState('')
    const [imageBlobUrl, setImageBlobUrl] = useState('')

    const [supportedLanguagesString, setSupportedLanguagesString] = useState('')

    useEffect(() => {
        if (imageUrl && !imageBlobUrl) {
            let imageReferenceId = imageUrl.replace('image/file/', '')
            makeGetRequest(
                'admin/organization/' +
                    props.organization.selectedOrganization +
                    '/image/file/reference/' +
                    imageReferenceId +
                    '/base64',
                configWithAuth()
            )
                .then((result) => {
                    let type = null
                    const typeImage = result.data.split(';')[0].split(':')[1]
                    switch (typeImage) {
                        case 'image/jpeg':
                            type = 'jpeg'
                            break
                        case 'image/png':
                            type = 'png'
                            break
                        default:
                            type = 'unknown'
                            break
                    }
                    var binary = convertDataURIToBinary(result.data)
                    var blob = new Blob([binary], { type: type })
                    var blobUrl = URL.createObjectURL(blob)
                    setImageBlobUrl(blobUrl)
                })
                .catch((error) => {
                    console.log('error', error)
                })
        }
    }, [imageUrl])

    useEffect(() => {
        if (props.courseGroupSubPartToTopicMapperDto.imageUrl) {
            setImageUrl(props.courseGroupSubPartToTopicMapperDto.imageUrl)
        }
    }, [])

    const handleCardClick = () => {
        if (props.openTopic && props.courseGroupSubPartToTopicMapperDto?.topicId) {
            props.openTopic(props.courseGroupSubPartToTopicMapperDto.topicId)
        }
    }

    const handleDeleteClick = (event) => {
        props.onDeleteTopicFromCourseGroup(props.courseGroupSubPartToTopicMapperDto)
        event.stopPropagation()
        event.preventDefault()
    }

    return (
        <Card
            key={props.courseGroupSubPartToTopicMapperDto?.topicId}
            className={classes.topicCard}
            onClick={() => handleCardClick()}>
            <CardContent
                className={classes.cardContent}
                style={{
                    borderRadius: '10px',
                }}>
                <Grid
                    container
                    style={{
                        flex: 1,
                        flexDirection: 'row',
                    }}>
                    <Grid item lg={2} md={2} sm={2} xs={2} className={classes.imageContainer}>
                        {imageUrl && imageBlobUrl && (
                            <img
                                src={imageBlobUrl}
                                style={{
                                    marginTop: '-20px',
                                    borderRadius: '8px',
                                    padding: '1px',
                                    maxWidth: '150%',
                                    maxHeight: '150%',
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translateY(-50%) translateX(-50%)',
                                }}
                            />
                        )}
                        {imageUrl && !imageBlobUrl && <Typography style={{ marginTop: '70px' }}></Typography>}
                        {!imageUrl && (
                            <img
                                src={FallbackCourseImage}
                                style={{
                                    marginTop: '-20px',
                                    borderRadius: '8px',
                                    padding: '1px',
                                    maxWidth: '150%',
                                    maxHeight: '150%',
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translateY(-50%) translateX(-50%)',
                                }}
                            />
                        )}
                    </Grid>
                    <Grid
                        item
                        lg={9}
                        md={9}
                        sm={9}
                        xs={9}
                        style={
                            {
                                //display: 'flex',
                                //alignItems: 'center',
                                //justifyContent: 'center',
                            }
                        }>
                        <Grid container style={{ display: 'flex', flexDirection: 'row', marginLeft: '10px' }}>
                            <Grid item xs={12}>
                                <Typography
                                    style={{
                                        float: 'left',
                                        fontSize: '24px',
                                        fontFamily: 'Lato',
                                        fontWeight: 'bold',
                                        marginBottom: '5px',
                                        color: CapeeshColors.DeepBlue,
                                    }}>
                                    {props.courseGroupSubPartToTopicMapperDto?.topicName}
                                </Typography>

                                {(props.user.organizationRole === 'admin' ||
                                    props.user.organizationRole === 'teacher') && (
                                    <div style={{ float: 'right' }}>
                                        <IconButton
                                            size="medium"
                                            style={{
                                                color: 'red',
                                            }}
                                            aria-label="delete"
                                            onClick={(event) => {
                                                handleDeleteClick(event)
                                            }}>
                                            <Cancel size={48} />
                                        </IconButton>
                                    </div>
                                )}
                            </Grid>

                            {props.courseGroupSubPartToTopicMapperDto?.topicDescription && (
                                <Grid item xs={12}>
                                    <Typography style={{ fontSize: '14px', color: 'black', marginBottom: '5px' }}>
                                        <b>
                                            <FormattedMessage
                                                id={'globalwords.description'}
                                                defaultMessage={'Description'}
                                            />
                                            :
                                        </b>{' '}
                                        {props.courseGroupSubPartToTopicMapperDto?.topicDescription}
                                    </Typography>
                                </Grid>
                            )}
                            {!props.courseGroupSubPartToTopicMapperDto?.topicDescription && (
                                <Grid item xs={12}>
                                    <Typography style={{ fontSize: '14px', color: 'black', marginBottom: '5px' }}>
                                        <b>
                                            <FormattedMessage
                                                id={'globalwords.description'}
                                                defaultMessage={'Description'}
                                            />
                                            :
                                        </b>{' '}
                                        <FormattedMessage id={'globalwords.notset'} defaultMessage={'not set'} />
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

TopicPreviewCard.propTypes = {}

const mapStateToProps = (state) => {
    const { user, organization, metadata } = state
    return {
        metadata,
        user,
        organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(TopicPreviewCard)
