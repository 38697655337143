import { configWithAuth, configWithoutAuth } from '../helpers'
import { makeGetRequest, makePostRequest } from '../helpers/api'

export const studentAuthService = {
    studentLogin: (payload) => makePostRequest('auth/sign-in', JSON.stringify(payload), configWithoutAuth()),
    studentShouldRegister: (payload) =>
        makePostRequest('auth/should-sign-up', JSON.stringify(payload), configWithoutAuth()),
    studentLogout: () => makePostRequest('auth/sign-out', configWithAuth()),
    studentRegister: (payload) => makePostRequest('auth/sign/up/v2', JSON.stringify(payload), configWithoutAuth()),
    studentForgotPasswordPin: (payload) =>
        makePostRequest('auth/forgot-password-pin', JSON.stringify(payload), configWithoutAuth()),
    studentUpdateForgotPassword: (payload) =>
        makePostRequest('auth/update-forgot-password', JSON.stringify(payload), configWithoutAuth()),
}
