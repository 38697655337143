import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import PrimarySearchAppBar from 'Components/PrimarySearchAppBar'

import { history } from '../_state/helpers'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { organizationActions, userActions, metadataActions } from '../_state/actions'
import privateRoutes from 'routes/privateRoutes'

const styles = (theme) => ({
    main: {
        //padding: '20px',
        margin: '105px 0px 0px 0px',
        [theme.breakpoints.down('sm')]: {
            margin: '138px 0px 0px 0px',
        },
    },
    mainGryffindor: {
        //padding: '20px',
        margin: '64px 0px 0px 0px',
        [theme.breakpoints.down('sm')]: {
            margin: '138px 0px 0px 0px',
        },
    },
})

class PrivateLayout extends React.Component {
    constructor(props) {
        super(props)
        const organizationRoles = props.user?.info?.roles
            ? props.user.info.roles
                  .filter((x) => x.organizationId === props.organization.selectedOrganization)
                  .map((x) => x.role)
            : null
        if (!localStorage.getItem('authToken') || !organizationRoles) {
            history.push('/login')
            return
        }

        if (
            !privateRoutes
                .find((x) => x.path === props.path)
                .acceptRoles.some((role) => organizationRoles.includes(role))
        ) {
            history.push('/dashboard/')
        }
        history.listen((location, action) => {
            // clear alert on location change
            // dispatch(alertActions.clear());
        })
    }

    componentDidMount() {
        const { user, organization } = this.props
        this.props.dispatch(userActions.getUserInfo(organization.selectedOrganization))
        this.props.dispatch(organizationActions.getMyOrganizations())
        this.props.dispatch(metadataActions.getMetadata())

        organization.selectedOrganization &&
            user.info.roles.forEach((element) => {
                if (element.organizationId == organization.selectedOrganization) {
                    switch (element.role) {
                        case 'Admin':
                            this.setState({ hasAdminRights: true, hasTeacherRights: false })
                            break
                        case 'Teacher':
                            this.setState({ hasAdminRights: false, hasTeacherRights: true })
                            break
                        default:
                            this.setState({ hasAdminRights: false, hasTeacherRights: false })
                    }
                }
            })
    }

    render() {
        const {
            component: Component,
            classes,
            location,
            history,
            dispatch,
            user,
            organization,
            computedMatch,
        } = this.props

        const organizationRoles = user?.info?.roles
            ? user.info.roles.filter((x) => x.organizationId === organization.selectedOrganization).map((x) => x.role)
            : null
        if (!organizationRoles) {
            return null
        }
        return (
            <div>
                {!window.location.pathname.includes('rnd/webgl') && (
                    <PrimarySearchAppBar showBreadcrumb={window.location.pathname !== '/'} dispatch={dispatch} />
                )}
                <div className={classes.main}>
                    <Component
                        location={location}
                        computedMatch={computedMatch}
                        history={history}
                        dispatch={dispatch}
                    />
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { organization, user } = state
    return {
        user,
        organization,
    }
}

PrivateLayout.propTypes = {
    classes: PropTypes.object.isRequired,
}

const enhance = compose(connect(mapStateToProps), withStyles(styles))

export default enhance(PrivateLayout)
