import { takeLatest, put } from 'redux-saga/effects'
import { snackbarActions, studentConstants } from '../actions'
import { studentService } from '../services'

export function* getStudentInfo(action) {
    try {
        const getUserInfoResponse = yield call(studentService.getUserInfo)
        yield put({ type: studentConstants.STUDENT_GET_USER_INFO.SUCCESS, data: getUserInfoResponse })
    } catch (e) {
        yield put({ type: studentConstants.STUDENT_GET_USER_INFO.FAILURE })
        yield put(
            snackbarActions.enqueueSnackbar({
                message: 'Could not get user information.',
                options: {
                    variant: 'warning',
                },
            })
        )
    }
}

export function* studentSagas() {
    yield takeLatest(studentConstants.STUDENT_GET_USER_INFO.REQUEST, getStudentInfo)
}
