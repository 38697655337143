import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Typography } from '@material-ui/core'
import { courseGroupActions, organizationActions } from '../../../_state/actions'
import { FormattedMessage } from 'react-intl'
import CreatorCourseGroupComponent from './CreatorCourseGroupComponent'
import { history } from '../../../_state/helpers'
import { CourseGroupType } from '../../../Logic/CourseGroupConstants'
import { CapeeshColors } from '../../../assets/ColorPalette'
import AdjustableModal from './AdjustableModal'
import CourseCreationPreviewMockup from './CourseCreationPreviewMockup'
import ComponentStepper from './ComponentStepper'
import CreateCourseGroupLanguageSelection from './CreateCourseGroupLanguageSelection'
import CreateCourseGroupNameAndImageSelection from './CreateCourseGroupNameAndImageSelection'
import CreatorHeader from './Components/CreatorHeader'
import CreatorNoCourse from './Components/CreatorNoCourses'
import { AddOutlined } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: CapeeshColors.EggShell2,
        backgroundImage: 'radial-gradient(circle at 1px 1px, rgb(225, 225, 221) 1px, transparent 0)',
        backgroundSize: '12px 12px',
        minHeight: '100vh',
    },
    leftSideButtons: {
        color: 'white',
        textTransform: 'none',
        paddingTop: '10px',
        paddingBottom: '10px',
        paddingLeft: '20px',
        paddingRight: '20px',
        borderRadius: '9.56863px',
    },
    capHeader: {
        margiTop: '20px',
        display: 'flex',
        flexWrap: 'nowrap',
        flexGrow: 1,
        width: '100%',
        borderTop: '4px solid lightgray',
    },
    heading: {
        alignSelf: 'stretch',
        color: '#232B35',
        textAlign: 'center',
        fontFamily: 'Rubik',
        fontSize: 16,
        fontStyle: 'normal',
        fontWeight: 400,
        letterSpacing: 0.64,
        textTransform: 'uppercase',
    },
    base: {
        paddingTop: 32,
    },
    createNewCourseButton: {
        background: CapeeshColors.Primary600,
        color: 'white',
        textTransform: 'none',
        borderRadius: '12px',
        width: '200px',
    },
}))

const CreatorDashboard = (props) => {
    const classes = useStyles()

    const [openCreateCourseGroup, setOpenCreateCourseGroup] = useState(false)
    const [organizationCourseGroups, setOrganizationCourseGroups] = useState([])
    const [organizationTopics, setOrganizationTopics] = useState([])
    const [newlyCreatedCourseGroupId, setNewlyCreatedCourseGroupId] = useState(false)
    const [selectedImage, setSelectedImage] = useState(null)
    const [mainText, setMainText] = useState('')
    const [cardDescription, setCardDescription] = useState('')
    const [l1Id, setL1Id] = useState(-1)
    const [l2Id, setL2Id] = useState(-1)
    const [imageForCourse, setImageForCourse] = useState('')
    const [hasFetchedCourseGroups, setHasFetchedCourseGroups] = useState(false)

    useEffect(() => {
        props.dispatch(courseGroupActions.createCourseGroupReset())
        props.dispatch(organizationActions.getAvailableCourseGroups(props.organization.selectedOrganization))
    }, [])

    useEffect(() => {
        if (!props.organization.fetchingCourseGroups) {
            setHasFetchedCourseGroups(true)
        }
    }, [props.organization.fetchingCourseGroups])
    useEffect(() => {
        if (props.organization.availableCourseGroups) {
            setOrganizationCourseGroups(
                props.organization.availableCourseGroups.filter(
                    (x) => x.organizationId === props.organization.selectedOrganization
                )
            )
        }
        setHasFetchedCourseGroups(true)
    }, [props.organization.availableCourseGroups])

    useEffect(() => {
        // listen for new course group successfully created and reset redux layer
        if (props.courseGroup.newlyCreatedCourseGroupId && props.courseGroup.creatingCourseGroup) {
            let courseGroupId = props.courseGroup.newlyCreatedCourseGroupId
            setNewlyCreatedCourseGroupId(props.courseGroup.newlyCreatedCourseGroupId)
            props.dispatch(courseGroupActions.createCourseGroupReset())
            props.dispatch(organizationActions.getAvailableCourseGroups(props.organization.selectedOrganization))
            //history.push("/creator/course/group/" + courseGroupId)
            setHasFetchedCourseGroups(true)
        }
    }, [props.courseGroup.newlyCreatedCourseGroupId])

    const handleGoToCourseListClicked = () => {
        history.push('/creator/topic/list')
    }

    const handleCreateCourseGroup = () => {
        let courseGroupDto = {
            name: mainText,
            nameLanguageId: l2Id,
            description: cardDescription,
            descriptionLanguageId: l2Id,
            l2Id: l2Id,
            l1Id: l1Id,
            organizationId: props.organization.selectedOrganization,
            shared: false,
            courseGroupType: CourseGroupType.NotDefined,
            imageFileReferenceId: imageForCourse,
        }

        setOpenCreateCourseGroup(false)

        props.dispatch(courseGroupActions.createCourseGroup(courseGroupDto, true))
    }

    const handleCloseCreateCourseGroupDialog = () => {
        setOpenCreateCourseGroup(false)

        setMainText('')
        setCardDescription('')
        setL1Id(-1)
        setL2Id(-1)
        setSelectedImage(null)
        setImageForCourse('')
    }

    const stepperComponents = [
        {
            component: (
                <CreateCourseGroupLanguageSelection setL1Id={setL1Id} setL2Id={setL2Id} l1Id={l1Id} l2Id={l2Id} />
            ),
            stepCondition: l1Id !== -1 && l2Id !== -1,
        },
        {
            component: (
                <CreateCourseGroupNameAndImageSelection
                    setMainText={setMainText}
                    setCardDescription={setCardDescription}
                    l2Id={l2Id}
                    selectedImageForCourseGroup={selectedImage}
                    setSelectedImageForCourseGroup={setSelectedImage}
                    mainText={mainText}
                    cardDescription={cardDescription}
                    selectedCourseGroupImageId={imageForCourse}
                    setSeletedGroupGroupImageId={setImageForCourse}
                />
            ),
            stepCondition: mainText.length > 0 && cardDescription.length > 0 && selectedImage,
        },
    ]

    return (
        <div className={classes.root}>
            {openCreateCourseGroup && (
                <AdjustableModal
                    leftContent={
                        <div className={classes.base}>
                            <div className={classes.heading}>
                                <FormattedMessage
                                    id="create.course.dashboard.create.new.modal.title"
                                    defaultMessage="CREATE A NEW COURSE"
                                />
                            </div>
                            <ComponentStepper
                                stepperComponents={stepperComponents}
                                onCancel={handleCloseCreateCourseGroupDialog}
                                onFinishStepper={handleCreateCourseGroup}
                            />
                        </div>
                    }
                    centerContent={
                        <CourseCreationPreviewMockup
                            imageSrc={selectedImage}
                            mainText={mainText}
                            description={cardDescription}
                        />
                    }
                    isOpen={openCreateCourseGroup}
                    closeDialog={handleCloseCreateCourseGroupDialog}
                />
            )}

            {hasFetchedCourseGroups && organizationCourseGroups.length === 0 && (
                <CreatorNoCourse onCreateNewCourseGroup={() => setOpenCreateCourseGroup(!openCreateCourseGroup)} />
            )}
            {hasFetchedCourseGroups && organizationCourseGroups.length > 0 && (
                <React.Fragment>
                    <CreatorHeader
                        isAdminUser={props.user.organizationRole === 'admin'}
                        handleGoToCourseListClicked={handleGoToCourseListClicked}
                    />

                    <div
                        style={{
                            paddingTop: '20px',
                            paddingBottom: '20px',
                            display: 'flex',
                            flexDirection: 'column',

                            alignItems: 'stretch',
                            backgroundColor: 'white',
                            marginLeft: '40px',
                            marginRight: '40px',
                            borderRadius: '16px',
                        }}>
                        <div style={{ padding: '32px' }}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    width: '100%',
                                    position: 'relative',
                                }}>
                                <div>
                                    <Typography
                                        variant="h4"
                                        style={{
                                            fontFamily: 'Lato',
                                            fontStyle: 'normal',
                                            fontWeight: 400,
                                            lineHeight: '23px',
                                            paddingBottom: '16px',
                                            textAlign: 'center',
                                        }}>
                                        <FormattedMessage id="create.header.title" defaultMessage="Your courses" />
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        style={{
                                            fontFamily: 'Lato',
                                            fontStyle: 'normal',
                                            fontWeight: 400,
                                            lineHeight: '23px',
                                            textAlign: 'center',
                                        }}>
                                        <FormattedMessage
                                            id="create.header.description"
                                            defaultMessage="Click on a course to create and edit topics and publish lessons."
                                        />
                                    </Typography>
                                </div>
                                <div style={{ position: 'absolute', display: 'flex', right: 0 }}>
                                    <Button
                                        className={classes.createNewCourseButton}
                                        variant={'contained'}
                                        color={'primary'}
                                        onClick={() => setOpenCreateCourseGroup(true)}>
                                        <AddOutlined
                                            sx={{
                                                marginRight: '15px',
                                                fontSize: '15px',
                                                color: 'white',
                                            }}
                                        />
                                        <FormattedMessage
                                            id="create.button.create.new.courses"
                                            defaultMessage="Create new course"
                                        />
                                    </Button>
                                </div>
                            </div>

                            <div
                                style={{
                                    marginTop: '20px',
                                    width: '100%',
                                }}>
                                <CreatorCourseGroupComponent
                                    onCreateNewCourseGroup={() => setOpenCreateCourseGroup(!openCreateCourseGroup)}
                                    courseGroups={organizationCourseGroups}
                                    newlyCreatedCourseGroupId={newlyCreatedCourseGroupId}
                                />
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )}
        </div>
    )
}

function mapStateToProps(state) {
    const { organization, user, courseGroup, topic } = state
    return {
        organization,
        user,
        courseGroup,
        topic,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(CreatorDashboard)
