import React, { useState, useRef } from 'react'
import { connect } from 'react-redux'
import { ButtonBase, CircularProgress, Grid, makeStyles, Typography } from '@material-ui/core'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import AutoCourseSuggestLessonDetailsQuizItem from './AutoCourseSuggestLessonDetailsQuizItem'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles((theme) => ({}))

const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    background: isDragging && 'grey',
    ...draggableStyle,
})

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
}

const AutoCourseSuggestLessonDetailsQuizView = (props) => {
    const [openDialog, setOpenDialog] = useState(false)

    const onDragEnd = (result) => {
        if (!result.destination) {
            return
        }

        const itemsNew = reorder(
            props.selectedLesson.lessonPlanConfigurationDto.quizQuestions,
            result.source.index,
            result.destination.index
        )
        props.onReorderQuiz(itemsNew)
        //tempQuizItems = itemsNew
    }

    const stopEvent = (event) => {
        event.stopPropagation()
        event.preventDefault()
    }

    const handleChangeFruitMachineSelection = (fruitMachineType, selection) => {
        props.onUpdateLessonSuggestionDto(props.selectedLesson.lessonPlanId, fruitMachineType, selection)
    }

    const handleCloseModal = () => {
        setOpenDialog(false)
    }

    const classes = useStyles()
    return (
        <Grid container alignItems="top" onClick={stopEvent}>
            <Dialog open={openDialog} onClose={handleCloseModal}>
                <DialogTitle>
                    {' '}
                    <FormattedMessage
                        id={'create.knowledge.ai.lesson.plan.lesson.quiz.questions.title'}
                        defaultMessage={'Quiz Questions'}
                    />
                </DialogTitle>
                <DialogContent>
                    {props?.selectedLesson?.lessonPlanConfigurationDto?.quizQuestions?.length > 0 ? (
                        <div
                            style={{
                                padding: '10px',
                                borderRadius: '5px',
                                background: '#EFEFF4',
                                marginTop: '3px',
                                width: '100%',
                            }}>
                            <TableContainer component={Paper}>
                                <Table sx={{}} aria-label="custom pagination table">
                                    <TableBody>
                                        <DragDropContext onDragEnd={onDragEnd}>
                                            <Droppable droppableId="droppable">
                                                {(provided, snapshot) => (
                                                    <div {...provided.droppableProps} ref={provided.innerRef}>
                                                        {props.selectedLesson?.lessonPlanConfigurationDto?.quizQuestions?.map(
                                                            (question, index) => (
                                                                <Draggable
                                                                    key={question.questionId}
                                                                    draggableId={question.questionId}
                                                                    index={index}>
                                                                    {(provided, snapshot) => (
                                                                        <div
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            style={getItemStyle(
                                                                                snapshot.isDragging,
                                                                                provided.draggableProps.style
                                                                            )}>
                                                                            <Grid container alignItems="center">
                                                                                <Grid item xs={11}>
                                                                                    <AutoCourseSuggestLessonDetailsQuizItem
                                                                                        key={question.questionId}
                                                                                        questionNumber={index + 1}
                                                                                        question={question.question}
                                                                                        incorrectAnswer1={
                                                                                            question.incorrectAnswer1
                                                                                        }
                                                                                        incorrectAnswer2={
                                                                                            question.incorrectAnswer2
                                                                                        }
                                                                                        incorrectAnswer3={
                                                                                            question.incorrectAnswer3
                                                                                        }
                                                                                        correctAnswer={
                                                                                            question.correctAnswer
                                                                                        }
                                                                                    />
                                                                                </Grid>

                                                                                <Grid item xs={1}>
                                                                                    <ButtonBase>
                                                                                        <DeleteOutlineIcon
                                                                                            onClick={() =>
                                                                                                props.onDeleteQuizQuestion(
                                                                                                    props.selectedLesson
                                                                                                        .lessonPlanId,
                                                                                                    question.questionId
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    </ButtonBase>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            )
                                                        )}
                                                    </div>
                                                )}
                                            </Droppable>
                                        </DragDropContext>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    ) : (
                        <Grid item xs={12}>
                            <Typography
                                style={{
                                    color: '#8E8E93',
                                    fontFamily: 'Rubik',
                                    fontSize: '12px',
                                    fontStyle: 'normal',
                                    fontWeight: 400,
                                    lineHeight: '23.789px',
                                }}>
                                <FormattedMessage
                                    id={'create.knowledge.ai.lesson.plan.lesson.quiz.no.questions.added'}
                                    defaultMessage={' No questions added to your quiz.'}
                                />
                            </Typography>
                        </Grid>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} style={{ textTransform: 'none' }}>
                        <FormattedMessage id={'globalwords.close'} defaultMessage={'Close'} />
                    </Button>
                </DialogActions>
            </Dialog>
            <Grid item xs={7} style={{ marginTop: '10px' }}>
                <Grid container>
                    <Typography
                        style={{
                            fontFamily: 'Rubik',
                            fontWeight: 500,
                            fontSize: '16px',
                            lineHeight: '19px',
                            color: 'black',
                            letterSpacing: '0.41px',
                            textAlign: 'left',
                            marginTop: '10px',
                        }}>
                        <FormattedMessage
                            id={'create.knowledge.ai.lesson.plan.lesson.what.type.of.lesson'}
                            defaultMessage={'What type of lesson would you like to create?'}
                        />
                    </Typography>
                    <Grid item xs={12} style={{ marginTop: '10px' }}>
                        <Button
                            onClick={() =>
                                props.onUpdateLessonSuggestionDto(
                                    props.selectedLesson.lessonPlanId,
                                    'simulationType',
                                    'ConversationWithSomeone',
                                    false
                                )
                            }
                            style={{
                                color: props.selectedLesson.lessonSubType === 'Simulation' ? 'white' : '#35323C',
                                borderRadius: '5px',
                                background: props.selectedLesson.lessonSubType === 'Simulation' ? '#44A6EF' : 'white',
                                border:
                                    props.selectedLesson?.lessonPlanConfigurationDto?.configurationType === 'Simulation'
                                        ? 0
                                        : '1px solid var(--gray-1, #8E8E93)',
                                display: 'inline',
                                height: '31px',
                                padding: '0 15px',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: '20px',
                                textTransform: 'none',
                                marginRight: '10px',
                            }}>
                            <FormattedMessage id={'globalwords.simulation'} defaultMessage={'Simulation'} />
                        </Button>
                        <Button
                            onClick={() =>
                                props.onUpdateLessonSuggestionDto(
                                    props.selectedLesson.lessonPlanId,
                                    'normalType',
                                    'Traditional',
                                    false
                                )
                            }
                            style={{
                                color:
                                    props.selectedLesson.lessonSubType === 'Normal' &&
                                    props.selectedLesson?.lessonPlanConfigurationDto?.configurationType ===
                                        'Traditional'
                                        ? 'white'
                                        : '#35323C',
                                borderRadius: '5px',
                                background:
                                    props.selectedLesson.lessonSubType === 'Normal' &&
                                    props.selectedLesson?.lessonPlanConfigurationDto?.configurationType ===
                                        'Traditional'
                                        ? '#44A6EF'
                                        : 'white',
                                border:
                                    props.selectedLesson?.lessonPlanConfigurationDto?.configurationType === 'Simulation'
                                        ? 0
                                        : '1px solid var(--gray-1, #8E8E93)',
                                display: 'inline',
                                height: '31px',
                                padding: '0 15px',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: '20px',
                                textTransform: 'none',
                                marginRight: '10px',
                            }}>
                            <FormattedMessage id={'globalwords.complience'} defaultMessage={'Compliance'} />
                        </Button>
                        <Button
                            onClick={() =>
                                props.onUpdateLessonSuggestionDto(
                                    props.selectedLesson.lessonPlanId,
                                    'normalType',
                                    'Quiz',
                                    false
                                )
                            }
                            style={{
                                color:
                                    props.selectedLesson.lessonSubType === 'Normal' &&
                                    props.selectedLesson?.lessonPlanConfigurationDto?.configurationType === 'Quiz'
                                        ? 'white'
                                        : '#35323C',
                                borderRadius: '5px',
                                background:
                                    props.selectedLesson.lessonSubType === 'Normal' &&
                                    props.selectedLesson?.lessonPlanConfigurationDto?.configurationType === 'Quiz'
                                        ? '#44A6EF'
                                        : 'white',
                                border:
                                    props.selectedLesson?.lessonPlanConfigurationDto?.configurationType === 'Simulation'
                                        ? 0
                                        : '1px solid var(--gray-1, #8E8E93)',
                                display: 'inline',
                                height: '31px',
                                padding: '0 15px',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: '20px',
                                textTransform: 'none',
                                marginRight: '10px',
                            }}>
                            <FormattedMessage id={'globalwords.quiz'} defaultMessage={'Quiz'} />
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={5} style={{ paddingLeft: '20px', marginTop: '10px' }}>
                <Grid container>
                    <Typography
                        style={{
                            fontFamily: 'Rubik',
                            fontWeight: 500,
                            fontSize: '16px',
                            lineHeight: '19px',
                            color: 'black',
                            letterSpacing: '0.41px',
                            textAlign: 'left',
                            marginTop: '10px',
                            marginBottom: '10px',
                        }}>
                        <FormattedMessage
                            id={'create.knowledge.ai.lesson.plan.tone.of.lesson'}
                            defaultMessage={'Tone of lesson'}
                        />
                    </Typography>
                    <Grid item xs={12}>
                        <Button
                            onClick={() => handleChangeFruitMachineSelection('lessonToneOfVoiceType', 'Serious')}
                            style={{
                                color: props.selectedLesson.lessonToneOfVoiceType === 'Serious' ? 'white' : '#35323C',
                                borderRadius: '5px',
                                background:
                                    props.selectedLesson.lessonToneOfVoiceType === 'Serious' ? '#44A6EF' : 'white',
                                border:
                                    props.selectedLesson.lessonToneOfVoiceType === 'Serious'
                                        ? 0
                                        : '1px solid var(--gray-1, #8E8E93)',
                                display: 'inline',
                                height: '31px',
                                padding: '0 15px',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: '20px',
                                textTransform: 'none',
                                marginRight: '10px',
                            }}>
                            <FormattedMessage
                                id={'create.knowledge.ai.lesson.plan.serious'}
                                defaultMessage={'Serious'}
                            />
                        </Button>
                        <Button
                            onClick={() => handleChangeFruitMachineSelection('lessonToneOfVoiceType', 'Playful')}
                            style={{
                                color: props.selectedLesson.lessonToneOfVoiceType === 'Playful' ? 'white' : '#35323C',
                                borderRadius: '5px',
                                background:
                                    props.selectedLesson.lessonToneOfVoiceType === 'Playful' ? '#44A6EF' : 'white',
                                border:
                                    props.selectedLesson.lessonToneOfVoiceType === 'Playful'
                                        ? 0
                                        : '1px solid var(--gray-1, #8E8E93)',
                                display: 'inline',
                                height: '31px',
                                padding: '0 15px',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: '20px',
                                textTransform: 'none',
                                marginRight: '10px',
                            }}>
                            <FormattedMessage
                                id={'create.knowledge.ai.lesson.plan.playful'}
                                defaultMessage={'Playful'}
                            />
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Typography
                style={{
                    color: '#35323C',
                    fontFamily: 'Rubik',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '19px',
                    letterSpacing: '0.41px',
                    marginTop: '20px',
                    marginBottom: '10px',
                }}>
                <FormattedMessage
                    id={'create.knowledge.ai.lesson.plan.lesson.quiz.desc'}
                    defaultMessage={'A multiple choice quiz. You can edit, add or delete the questions below.'}
                />
            </Typography>
            <Grid item xs={8} style={{ marginTop: '5px', marginBottom: '5px', paddingTop: '12px' }}>
                <Button
                    onClick={(e) => props.onSuggestQuiz(e)}
                    style={{
                        textTransform: 'none',
                        fontSize: '11px',
                        background: '#A0439F',
                        color: 'white',
                        fontFamily: 'Rubik',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        padding: '10px',
                        lineHeight: '12px',
                    }}>
                    {props.isQuizGenerating === true ? (
                        <CircularProgress style={{ color: 'white', height: '20px', width: '20px' }} />
                    ) : props?.selectedLesson?.lessonPlanConfigurationDto?.quizQuestions?.length > 0 ? (
                        <FormattedMessage
                            id={'create.knowledge.ai.lesson.plan.lesson.quiz.generate.more.questions'}
                            defaultMessage={'Generate more questions'}
                        />
                    ) : (
                        <FormattedMessage
                            id={'create.knowledge.ai.lesson.plan.lesson.quiz.generate.questions'}
                            defaultMessage={'Generate questions'}
                        />
                    )}
                </Button>
                <Button
                    onClick={() => props.onWriteQuizQuestion()}
                    style={{
                        marginLeft: '20px',
                        textTransform: 'none',
                        fontSize: '11px',
                        background: '#A0439F',
                        color: 'white',
                        fontFamily: 'Rubik',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        padding: '10px',
                        lineHeight: '12px',
                    }}>
                    <FormattedMessage
                        id={'create.knowledge.ai.lesson.plan.lesson.quiz.write.questions'}
                        defaultMessage={'Write my own question'}
                    />
                </Button>
            </Grid>
            {props?.selectedLesson?.lessonPlanConfigurationDto?.quizQuestions?.length > 0 ? (
                <div
                    style={{
                        padding: '10px',
                        borderRadius: '5px',
                        background: '#EFEFF4',
                        marginTop: '3px',
                        width: '100%',
                    }}>
                    <TableContainer component={Paper}>
                        <Table sx={{}} aria-label="custom pagination table">
                            <TableBody>
                                <Grid container alignItems="center">
                                    <Grid item xs={11}>
                                        <AutoCourseSuggestLessonDetailsQuizItem
                                            key={
                                                props.selectedLesson.lessonPlanConfigurationDto.quizQuestions[0]
                                                    .questionId
                                            }
                                            questionNumber={1}
                                            question={
                                                props.selectedLesson.lessonPlanConfigurationDto.quizQuestions[0]
                                                    .question
                                            }
                                            incorrectAnswer1={
                                                props.selectedLesson.lessonPlanConfigurationDto.quizQuestions[0]
                                                    .incorrectAnswer1
                                            }
                                            incorrectAnswer2={
                                                props.selectedLesson.lessonPlanConfigurationDto.quizQuestions[0]
                                                    .incorrectAnswer2
                                            }
                                            incorrectAnswer3={
                                                props.selectedLesson.lessonPlanConfigurationDto.quizQuestions[0]
                                                    .incorrectAnswer3
                                            }
                                            correctAnswer={
                                                props.selectedLesson.lessonPlanConfigurationDto.quizQuestions[0]
                                                    .correctAnswer
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <ButtonBase>
                                            <DeleteOutlineIcon
                                                onClick={() =>
                                                    props.onDeleteQuizQuestion(
                                                        props.selectedLesson.lessonPlanId,
                                                        props.selectedLesson.lessonPlanConfigurationDto.quizQuestions[0]
                                                            .questionId
                                                    )
                                                }
                                            />
                                        </ButtonBase>
                                    </Grid>
                                    {props?.selectedLesson?.lessonPlanConfigurationDto?.quizQuestions?.length > 1 && (
                                        <React.Fragment>
                                            <Grid item xs={11}>
                                                <AutoCourseSuggestLessonDetailsQuizItem
                                                    key={
                                                        props.selectedLesson.lessonPlanConfigurationDto.quizQuestions[1]
                                                            .questionId
                                                    }
                                                    questionNumber={2}
                                                    question={
                                                        props.selectedLesson.lessonPlanConfigurationDto.quizQuestions[1]
                                                            .question
                                                    }
                                                    incorrectAnswer1={
                                                        props.selectedLesson.lessonPlanConfigurationDto.quizQuestions[1]
                                                            .incorrectAnswer1
                                                    }
                                                    incorrectAnswer2={
                                                        props.selectedLesson.lessonPlanConfigurationDto.quizQuestions[1]
                                                            .incorrectAnswer2
                                                    }
                                                    incorrectAnswer3={
                                                        props.selectedLesson.lessonPlanConfigurationDto.quizQuestions[1]
                                                            .incorrectAnswer3
                                                    }
                                                    correctAnswer={
                                                        props.selectedLesson.lessonPlanConfigurationDto.quizQuestions[1]
                                                            .correctAnswer
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={1}>
                                                <ButtonBase>
                                                    <DeleteOutlineIcon
                                                        onClick={() =>
                                                            props.onDeleteQuizQuestion(
                                                                props.selectedLesson.lessonPlanId,
                                                                props.selectedLesson.lessonPlanConfigurationDto
                                                                    .quizQuestions[1].questionId
                                                            )
                                                        }
                                                    />
                                                </ButtonBase>
                                            </Grid>
                                        </React.Fragment>
                                    )}
                                    {props?.selectedLesson?.lessonPlanConfigurationDto?.quizQuestions?.length > 2 && (
                                        <React.Fragment>
                                            <Grid item xs={12}>
                                                <ButtonBase onClick={() => setOpenDialog(true)}>
                                                    <Typography
                                                        style={{
                                                            color: '#35323C',
                                                            fontFamily: 'Rubik',
                                                            fontSize: '16px',
                                                            fontStyle: 'normal',
                                                            fontWeight: 400,
                                                            lineHeight: '19px',
                                                            letterSpacing: '0.41px',
                                                            marginLeft: '25px',
                                                            marginBottom: '20px',
                                                        }}>
                                                        <FormattedMessage
                                                            id={
                                                                'create.knowledge.ai.lesson.plan.lesson.quiz.view.or.edit.with.number'
                                                            }
                                                            defaultMessage={'View or edit all {number} questions'}
                                                            values={{
                                                                number: props?.selectedLesson
                                                                    ?.lessonPlanConfigurationDto?.quizQuestions?.length,
                                                            }}
                                                        />
                                                    </Typography>
                                                </ButtonBase>
                                            </Grid>
                                        </React.Fragment>
                                    )}
                                </Grid>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            ) : (
                <Grid item xs={12}>
                    <Typography
                        style={{
                            color: '#8E8E93',
                            fontFamily: 'Rubik',
                            fontSize: '12px',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: '23.789px',
                        }}>
                        No questions added to your quiz.
                    </Typography>
                </Grid>
            )}
        </Grid>
    )
}

AutoCourseSuggestLessonDetailsQuizView.propTypes = {}

function mapStateToProps(state) {
    const { autoCourseCreation } = state
    return {
        autoCourseCreation,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(AutoCourseSuggestLessonDetailsQuizView)
