import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import { Checkbox, makeStyles } from '@material-ui/core'
import Table from '@mui/material/Table'
import { CapeeshColors } from '../../../../assets/ColorPalette'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { FormattedMessage } from 'react-intl'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import xImage from '../../../../assets/images/Navigation.png'
import Dialog from '@mui/material/Dialog'
import { AddOutlined } from '@material-ui/icons'
import { Button } from '@mui/material'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withStyles } from '@material-ui/core/styles'

const checkBoxStyles = (theme) => ({
    root: {
        color: '#C7C7CC !important',
        '&$checked': {
            color: '#C7C7CC',
        },
    },
    checked: {},
})

const DisabledCheckbox = withStyles(checkBoxStyles)(Checkbox)

const useStyles = makeStyles((theme) => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        padding: 32,
    },
    topContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginBottom: 16,
        gap: '16px',
    },
    tableContainer: { display: 'flex', flexDirection: 'row' },
    footerContainer: {
        marginTop: '16px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        gap: '16px',
    },

    titleText: {
        alignSelf: 'stretch',
        color: CapeeshColors.Gray900,
        textAlign: 'center',
        fontFamily: 'Rubik',
        fontSize: 16,
        fontStyle: 'normal',
        fontWeight: 400,
        letterSpacing: 0.64,
        textTransform: 'uppercase',
    },
    subTitleText: {
        color: CapeeshColors.Gray900,
        textAlign: 'center',
        fontFamily: 'Rubik',
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: 400,
    },

    tableHeaderCell: {
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        fontFamily: 'Rubik',
    },

    tableRow: {
        display: 'flex',
        paddingBottom: '8px',
        alignItems: 'flex-start',
        gap: '8px',
        alignSelf: 'stretch',
    },

    tableRowCellName: {
        color: CapeeshColors.Primary600,
        fontSize: '14px',
        fontStyle: 'normal',
        fontFamily: 'Rubik',
        fontWeight: 500,
        textTransform: 'none',
    },
    closeIcon: {
        width: 24,
        height: 24,
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        alignSelf: 'flex-end',
        marginRight: 16,
        marginTop: 16,
        display: 'flex',
        cursor: 'pointer',
    },
    shareButton: {
        color: 'white',
        background: CapeeshColors.Primary600,
        height: '40px',
        marginTop: '16px',
    },
    cancelButton: {
        color: 'black',
        height: '40px',
        marginTop: '16px',
    },
}))

const ShareCourseGroupsToClassroomsModal = ({
    courseGroupId,
    userGroups,
    isOpen,
    onClose,
    onSharedCoursesChanged,
    savingSharedCourses,
}) => {
    const classes = useStyles()

    const [allChecked, setAllChecked] = useState(false)

    // keeep all the state in a mapper
    const [renderUserGroupMapper, setRenderUserGroupMapper] = useState({})

    // flatten out the mapper to render
    const [userGroupList, setUserGroupList] = useState([])

    useEffect(() => {
        if (!userGroups) {
            return
        }

        const tempRenderUserGroupMapper = {}

        for (const userGroup of userGroups) {
            let checked = false
            let cannotDelete = false
            if (userGroup.courseGroupIds?.find((x) => x === courseGroupId)) {
                checked = true
                if (userGroup.courseGroupIds?.length === 1) {
                    cannotDelete = true
                }
            }

            const renderUserGroup = {
                id: userGroup.id,
                name: userGroup.name,
                checked: checked,
                cannotDelete: cannotDelete,
                numberOfUsers: userGroup.numberOfUsers,
            }
            tempRenderUserGroupMapper[userGroup.id] = renderUserGroup
        }
        setRenderUserGroupMapper(tempRenderUserGroupMapper)
        updateRenderList(tempRenderUserGroupMapper)
    }, [])

    const updateRenderList = (newRenderUserGroupMapper) => {
        const newList = []
        let numberOfChecked = 0
        for (const userGroupId in newRenderUserGroupMapper) {
            if (newRenderUserGroupMapper[userGroupId].checked) {
                numberOfChecked++
            }
            newList.push(newRenderUserGroupMapper[userGroupId])
        }
        if (numberOfChecked === newList.length) {
            setAllChecked(true)
        }
        setUserGroupList(newList)
    }

    const handleCheckboxClick = (event, userGroup) => {
        const userGroupId = userGroup?.id

        if (renderUserGroupMapper[userGroup.id]) {
            if (renderUserGroupMapper[userGroup.id].checked && renderUserGroupMapper[userGroup.id].cannotDelete) {
                return
            }
            renderUserGroupMapper[userGroup.id].checked = !renderUserGroupMapper[userGroup.id].checked
        }
        updateRenderList(renderUserGroupMapper)
    }
    const handleToggleAllCheckBox = () => {
        for (const userGroupId in renderUserGroupMapper) {
            renderUserGroupMapper[userGroupId].checked = true
        }

        if (allChecked) {
            // remove all
            for (const userGroupId in renderUserGroupMapper) {
                if (renderUserGroupMapper[userGroupId].cannotDelete) {
                    continue
                }
                renderUserGroupMapper[userGroupId].checked = false
            }
        } else {
            for (const userGroupId in renderUserGroupMapper) {
                renderUserGroupMapper[userGroupId].checked = true
            }
        }
        setAllChecked(!allChecked)
        updateRenderList(renderUserGroupMapper)
    }

    const handleShareCourseGroup = () => {
        const tickedUserGroupIds = []
        for (const userGroupId in renderUserGroupMapper) {
            const userGroup = renderUserGroupMapper[userGroupId]
            if (userGroup.checked) {
                tickedUserGroupIds.push(userGroup.id)
            }
        }
        onSharedCoursesChanged(tickedUserGroupIds)
    }

    return (
        <Dialog open={isOpen} onClose={onClose} maxWidth="lg">
            <img onClick={() => onClose()} src={xImage} className={classes.closeIcon} />
            <div className={classes.main}>
                <div className={classes.topContainer}>
                    <div className={classes.titleText}>SHARE COURSE WITH CLASSROOMS</div>
                    <div className={classes.subTitleText}>
                        Select which classrooms you wish to share the course with from the list below. Lessons in this
                        course will be available for the selected classrooms as soon as they are published.
                    </div>
                </div>
                <div className={classes.tableContainer}>
                    <TableContainer>
                        <Table
                            sx={{
                                minWidth: 400,
                                backgroundColor: CapeeshColors.OffWhite,
                            }}
                            size="small"
                            aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell width="40%">
                                        <Typography className={classes.tableHeaderCell}>
                                            <FormattedMessage
                                                id="dashboard.classroom.table.classroom"
                                                defaultMessage="Classroom"
                                            />
                                        </Typography>
                                    </TableCell>
                                    <TableCell width="20%">
                                        <Typography className={classes.tableHeaderCell}>
                                            <FormattedMessage
                                                id="dashboard.classroom.table.students"
                                                defaultMessage="Students"
                                            />
                                        </Typography>
                                    </TableCell>
                                    <TableCell width="40%" padding="checkbox" align="right">
                                        <div>
                                            <Checkbox
                                                color="primary"
                                                checked={allChecked}
                                                onClick={(event) => handleToggleAllCheckBox()}
                                            />
                                        </div>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {userGroupList
                                    .sort((a, b) => a.name.localeCompare(b.name))
                                    .map((userGroup, index) => (
                                        <TableRow
                                            key={userGroup.id}
                                            sx={{
                                                '&:last-child td, &:last-child th': {
                                                    border: 0,
                                                },
                                                backgroundColor:
                                                    index % 2 === 0 ? CapeeshColors.Gray5 : CapeeshColors.OffWhite,
                                                height: '50px',
                                            }}>
                                            <TableCell width="40%" component="th" scope="row">
                                                <Typography className={classes.tableRowCellName}>
                                                    {userGroup.name}
                                                </Typography>
                                            </TableCell>
                                            <TableCell width="20%" align="right">
                                                {userGroup.numberOfUsers}
                                            </TableCell>
                                            <TableCell width="40%" padding="checkbox" align="right">
                                                {!userGroup.cannotDelete ? (
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            justifyContent: 'flex-end',
                                                            alignItems: 'center',
                                                            gap: '16px',
                                                        }}>
                                                        <Checkbox
                                                            color="primary"
                                                            checked={userGroup.checked}
                                                            onClick={(event) => handleCheckboxClick(event, userGroup)}
                                                        />
                                                    </div>
                                                ) : (
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            justifyContent: 'flex-end',
                                                            alignItems: 'center',
                                                            gap: '16px',
                                                        }}>
                                                        <div> Last course in classroom</div>
                                                        <DisabledCheckbox disabled checked />
                                                    </div>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div className={classes.footerContainer}>
                    <Button
                        className={classes.cancelButton}
                        style={{
                            textTransform: 'none',
                            color: CapeeshColors.DarkText,
                        }}
                        variant={'text'}
                        onClick={() => onClose()}>
                        <FormattedMessage id="globalwords.cancel" defaultMessage="Cancel" />
                    </Button>

                    <Button
                        className={classes.shareButton}
                        style={{
                            textTransform: 'none',
                        }}
                        variant={'contained'}
                        onClick={() => handleShareCourseGroup()}>
                        {savingSharedCourses && (
                            <CircularProgress
                                style={{
                                    height: '20px',
                                    width: '20px',
                                    pointerEvents: 'none',
                                    color: 'white',
                                }}></CircularProgress>
                        )}
                        {!savingSharedCourses && (
                            <FormattedMessage
                                id="create.course.group.share.with.classroom.button.title"
                                defaultMessage={'Update course sharing'}
                            />
                        )}
                    </Button>
                </div>
            </div>
        </Dialog>
    )
}

ShareCourseGroupsToClassroomsModal.propTypes = {}

const mapStateToProps = (state) => {
    return {
        organization: state.organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ShareCourseGroupsToClassroomsModal)
