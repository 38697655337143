import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import { CapeeshColors } from '../../../../assets/ColorPalette'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContentText from '@material-ui/core/DialogContentText'

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
        padding: 16,
        maxHeight: 400,
        overflowY: 'auto',
        overflowX: 'hidden',
    },
    chooserContainer: {
        display: 'flex',
        width: '100%',
        height: 32,
        border: `2px solid ${CapeeshColors.Primary600}`,
        borderRadius: 8,
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        '&:hover': {
            border: `2px solid ${CapeeshColors.BlueBright}`,
        },
    },
    chooserContainerChosen: {
        display: 'flex',
        width: '100%',
        height: 32,
        border: `2px solid ${CapeeshColors.BlueBright}`,
        borderRadius: 8,
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
    },
}))

const ChooserComponent = ({
    listToChooseFrom,
    onSetChosenOption,
    defaultOption = {},
    width = '700px',
    showTitle,
    title,
    description,
}) => {
    const classes = useStyles()

    const [chosenOption, setChosenOption] = useState(defaultOption)

    useEffect(() => {
        setChosenOption(defaultOption)
    }, [])

    const handleClickOption = (item) => {
        setChosenOption(item.item)
        onSetChosenOption(item.item)
    }

    const ChooserItem = ({ item, isChosen }) => {
        return (
            <div
                className={`${isChosen ? classes.chooserContainerChosen : classes.chooserContainer}`}
                onClick={() => handleClickOption(item)}>
                {item.text}
            </div>
        )
    }

    return (
        <div className={classes.mainContainer} style={{ width: width }}>
            {showTitle && (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                    }}>
                    <DialogTitle id="form-dialog-title">{title}</DialogTitle>
                    <DialogContentText>{description}</DialogContentText>
                </div>
            )}
            {listToChooseFrom.map((item, index) => (
                <ChooserItem key={index} item={item} isChosen={chosenOption === item.item} />
            ))}
        </div>
    )
}

ChooserComponent.propTypes = {}

const mapStateToProps = (state) => {
    return {
        organization: state.organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ChooserComponent)
