import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core'

import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContentText from '@material-ui/core/DialogContentText'
import Button from '@material-ui/core/Button'
import { FormattedMessage } from 'react-intl'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import MultipleStudentAddComponent from './MultipleStudentAddComponent'

const useStyles = makeStyles((theme) => ({}))

const MultipleStudentAddModal = ({
    inviteEmailsOpen,
    onInviteEmailsClose,
    title,
    description,
    role = 'Student',
    onInviteStudentsToSchool,
}) => {
    const classes = useStyles()

    const [addStudentRows, setAddStudentRows] = React.useState([])
    const [hasAnyValidEmails, setHasAnyValidEmails] = React.useState(false)

    const handleSendInviteStudentClick = () => {
        onInviteStudentsToSchool(addStudentRows, role)
    }

    const handleStudentRowsAreUpdated = (studentRows) => {
        setAddStudentRows(studentRows)
        setHasAnyValidEmails(studentRows.filter((x) => x.validEmail).length > 0)
    }

    return (
        <Dialog
            open={inviteEmailsOpen}
            onClose={onInviteEmailsClose}
            maxWidth={'md'}
            aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{description}</DialogContentText>
            </DialogContent>

            <MultipleStudentAddComponent
                onStudentRowsChanged={handleStudentRowsAreUpdated}
                onInviteStudentsToSchool={onInviteStudentsToSchool}
                onHasAnyValidEmails={(value) => setHasAnyValidEmails(value)}
            />

            <DialogActions>
                <Button onClick={onInviteEmailsClose} color="primary">
                    <FormattedMessage id="globalwords.cancel" defaultMessage="Cancel" />
                </Button>
                <Button
                    onClick={handleSendInviteStudentClick}
                    variant={'contained'}
                    disabled={!hasAnyValidEmails}
                    color="primary">
                    <FormattedMessage id="globalwords.invite" defaultMessage="Invite" />
                </Button>
            </DialogActions>
        </Dialog>
    )
}

MultipleStudentAddModal.propTypes = {}

const mapStateToProps = (state) => {
    return {
        organization: state.organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(MultipleStudentAddModal)
