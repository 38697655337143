import { asyncRequest } from './asyncRequest'

export const studentConstants = {
    STUDENT_GET_USER_INFO: new asyncRequest('STUDENT_GET_USER_INFO'),
    STUDENT_SET_USER_DASHBOARD_LANGUAGE: new asyncRequest('STUDENT_SET_USER_DASHBOARD_LANGUAGE'),
}

export const studentActions = {
    getStudentInfo(email, password) {
        return {
            type: studentConstants.STUDENT_GET_USER_INFO.REQUEST,
            payload: {},
        }
    },
    setStudentDashboardLanguage(languageId) {
        return {
            type: studentConstants.STUDENT_SET_USER_DASHBOARD_LANGUAGE.REQUEST,
            payload: {
                languageId,
            },
        }
    },
}
