import React from 'react'
import { connect } from 'react-redux'
import { Redirect, useLocation } from 'react-router-dom'
import StudentPrivateLayout from '../Layouts/StudentPrivateLayout'

const StudentPrivateRoute = ({ studentAuth, user, component: Component, ...rest }) => {
    return studentAuth.studentLoggedIn === true ? (
        <StudentPrivateLayout {...rest} component={Component} />
    ) : (
        <Redirect to="/student/login" />
    )
}

function mapStateToProps(state) {
    return {
        studentAuth: state.studentAuth,
    }
}

export default connect(mapStateToProps)(StudentPrivateRoute)
