import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { makeStyles, Typography } from '@material-ui/core'
import { motion } from 'framer-motion'
import ripImageTop from 'assets/images/option-header-tear.svg'
import ripImageBottom from 'assets/images/option-footer-tear.svg'
import ripImageTopYellow from 'assets/images/option-header-tear-yellow.svg'
import ripImageBottomYellow from 'assets/images/option-footer-tear-yellow.svg'
import { ReactComponent as PlotTwistTag } from 'assets/images/plot-twist-tag.svg'
import { CapeeshColors } from '../../../../../assets/ColorPalette'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles((theme) => ({
    mainCardContainer: {
        width: 293,
        borderRadius: 8,
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        display: 'flex',
        marginRight: 8,
        cursor: 'pointer',
    },
    mainHeadingText: {
        color: CapeeshColors.DarkText,
        fontSize: 16,
        fontFamily: 'Rubik',
        fontWeight: '500',
        wordWrap: 'break-word',
    },
    centerOfCardContainer: {
        width: '100%',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: 8,
        display: 'inline-flex',
    },
    centerOfCardText: {
        width: '100%',
        color: CapeeshColors.DarkText,
        fontSize: 16,
        fontFamily: 'Rubik',
        fontWeight: '400',
        wordWrap: 'break-word',
    },
    vocabNumberText: {
        width: '100%',
        color: CapeeshColors.DarkText,
        fontSize: 14,
        fontFamily: 'Rubik',
        fontWeight: '500',
        wordWrap: 'break-word',
        marginTop: 16,
    },
    vocabsText: {
        width: '100%',
        color: 'rgba(53, 50, 60, 0.80)',
        fontSize: 14,
        fontFamily: 'Rubik',
        fontWeight: '400',
        wordWrap: 'break-word',
    },
    topTextContainer: {
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row',
        gap: 8,
        display: 'flex',
    },
}))

const ChapterOptionCard = (props) => {
    const {} = props
    const classes = useStyles()

    const [shouldPulsate, setShouldPulsate] = useState(true)

    const animationFadeInDelay = 0.75

    useEffect(() => {
        setTimeout(() => setShouldPulsate(false), props.animationDelay * 1000)
    }, [])

    return (
        <div className={classes.mainCardContainer} onClick={() => props.optionClicked(props.optionElement)}>
            <img src={props.isTwist ? ripImageTopYellow : ripImageTop} />
            <div
                style={
                    props.shouldShowBottomRip
                        ? {
                              height: '100%',
                              background: props.isTwist ? '#FFF7E1' : 'white',
                              paddingLeft: 16,
                              paddingRight: 16,
                              paddingTop: 8,
                              paddingBottom: 8,
                          }
                        : {
                              height: '100%',
                              background: props.isTwist ? '#FFF7E1' : 'white',
                              paddingLeft: 16,
                              paddingRight: 16,
                              paddingTop: 8,
                              paddingBottom: 16,
                              borderBottomLeftRadius: 8,
                              borderBottomRightRadius: 8,
                          }
                }>
                {shouldPulsate && (
                    <motion.div
                        initial={{ opacity: 1 }}
                        animate={{ opacity: 0 }}
                        transition={{ delay: props.animationDelay, duration: animationFadeInDelay }}
                        style={{ height: '100%', paddingTop: 8, paddingBottom: 8, width: '100%' }}>
                        <div
                            style={{
                                width: 100,
                                height: 20,
                                background: '#EFEFF4',
                                borderRadius: 8,
                                marginBottom: 16,
                            }}></div>
                        <div
                            style={{
                                width: 261,
                                height: 20,
                                background: '#EFEFF4',
                                borderRadius: 8,
                                marginBottom: 4,
                            }}></div>
                        <div
                            style={{
                                width: 229,
                                height: 20,
                                background: '#EFEFF4',
                                borderRadius: 8,
                                marginBottom: 4,
                            }}></div>
                        <div
                            style={{
                                width: 197,
                                height: 20,
                                background: '#EFEFF4',
                                borderRadius: 8,
                                marginBottom: 16,
                            }}></div>
                        <div
                            style={{
                                width: 197,
                                height: 20,
                                background: '#EFEFF4',
                                borderRadius: 8,
                                marginBottom: 4,
                            }}></div>
                        <div
                            style={{
                                width: 261,
                                height: 20,
                                background: '#EFEFF4',
                                borderRadius: 8,
                                marginBottom: 4,
                            }}></div>
                        <div
                            style={{
                                width: 261,
                                height: 20,
                                background: '#EFEFF4',
                                borderRadius: 8,
                                marginBottom: 4,
                            }}></div>
                    </motion.div>
                )}
                {!shouldPulsate && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: animationFadeInDelay * 2 }}>
                        <div className={classes.topTextContainer}>
                            <Typography className={classes.mainHeadingText}>
                                <FormattedMessage
                                    id={'globalwords.option.with.number'}
                                    defaultMessage={'Option {number}'}
                                    values={{ number: props.optionNumber + 1 }}
                                />
                            </Typography>

                            {props.isTwist && <PlotTwistTag />}
                        </div>
                        <div className={classes.centerOfCardContainer}>
                            <Typography className={classes.centerOfCardText}>{props.optionText}</Typography>
                        </div>
                        <div>
                            <Typography className={classes.vocabNumberText}>
                                <FormattedMessage
                                    id={'create.language.ai.language.step5.chapter.option.card.teaching.vocab'}
                                    defaultMessage={'Teaching {number} vocab items:'}
                                    values={{ number: props.amountOfVocabs }}
                                />
                            </Typography>
                            <Typography className={classes.vocabsText}>
                                {props.vocabTexts.map((item, index) => (
                                    <span key={item}>
                                        {item.text + (index + 1 >= props.amountOfVocabs ? '' : ', ')}
                                    </span>
                                ))}
                            </Typography>
                        </div>
                    </motion.div>
                )}
            </div>
            {props.shouldShowBottomRip && <img src={props.isTwist ? ripImageBottomYellow : ripImageBottom} />}
        </div>
    )
}

ChapterOptionCard.propTypes = {}

const mapStateToProps = (state) => {
    return {
        organization: state.organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ChapterOptionCard)
