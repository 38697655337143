import React from 'react'
import { connect } from 'react-redux'
import { Grid, makeStyles } from '@material-ui/core'
import { ReactComponent as CapeeshLogo } from 'assets/images/capeesh_logo.svg'
import PasswordStepper from 'Components/PasswordStepper/PasswordStepper'
import { authActions } from '../../_state/actions'
import { CapeeshColors } from '../../assets/ColorPalette'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Button } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    gridContainer: {
        padding: 30,
    },
    margin: {
        margin: theme.spacing(1),
    },
    forgotpassword: {
        padding: '10px 0px 10px 0px',
        cursor: 'pointer',
    },
    capeeshLogo: {
        fill: CapeeshColors.Primary900,
        display: 'inherit',
        margin: '20px auto',
    },
    centerMidDiv: {
        display: 'flex',
        justifyContent: 'center',
    },
    mainFlex: {
        display: 'flex',
        flexDirection: 'row',
        gap: '32px',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
}))

const ForgotPassword = (props) => {
    const classes = useStyles()

    const forgotPasswordPin = (email) => {
        props.dispatch(authActions.forgotPasswordPin(email))
    }

    const updateForgotPassword = (email, pin, newPassword) => {
        props.dispatch(authActions.updateForgotPassword(email, pin, newPassword))
    }

    const startOver = () => {
        props.dispatch(authActions.startOver())
    }

    const clearErrors = () => {
        props.dispatch(authActions.clearErrors())
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <div style={{ width: '80%', justifyContent: 'center' }}>
                <div className={classes.centerMidDiv}>
                    <CapeeshLogo width={300} height={100} className={classes.capeeshLogo} />
                </div>
                <div className={classes.mainFlex}>
                    <Button
                        style={{ height: '40px', width: '250px' }}
                        variant={'contained'}
                        color={'primary'}
                        component={Link}
                        to={'/login'}>
                        <ArrowBackIcon />
                        <FormattedMessage
                            id={'dashboard.forgotpassword.back.to.login'}
                            defaultMessage={'Back to login'}
                        />
                    </Button>
                    <PasswordStepper
                        forgotPasswordPin={forgotPasswordPin}
                        updateForgotPassword={updateForgotPassword}
                        startOver={startOver}
                        clearErrors={clearErrors}
                        notifyError={props.auth.notifyError}
                        errorMessage={props.auth.errorMessage}
                        resetStep={props.auth.resetStep ? props.auth.resetStep : 0}
                        loginLink={'/login'}
                    />
                </div>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return { auth: state.auth }
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: dispatch,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
