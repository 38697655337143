import React, { useState } from 'react'
import { connect } from 'react-redux'
import {
    Button,
    Grid,
    makeStyles,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip,
} from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import CapeeshStyledModal from '../../../../Components/CapeeshStyledModal'
import { CheckCircleOutline, PanoramaFishEye } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({}))

const LessonUndoPane = (props) => {
    const [order, setOrder] = useState('desc')
    const [orderBy, setOrderBy] = useState('index')
    const [thenOrderBy, setThenOrderBy] = useState('index')

    const [customLessonVersionIdToRevertTo, setCustomLessonVersionIdToRevertTo] = useState(null)
    const [openLessonRevertModal, setOpenLessonRevertModal] = useState(null)

    const lessonVersionColumns = [
        {
            id: 'index',
            numeric: true,
            isBadge: false,
            disablePadding: true,
            label: <FormattedMessage id="create.lesson.undo.index" defaultMessage="Index" />,
        },
        {
            id: 'createdAt',
            disablePadding: true,
            label: <FormattedMessage id="create.lesson.undo.createdat" defaultMessage="Created At" />,
        },
        {
            id: 'createdByUserEmail',
            numeric: false,
            disablePadding: true,
            label: <FormattedMessage id="create.lesson.undo.createdby" defaultMessage="Created by" />,
        },
        {
            id: 'validated',
            numeric: false,
            isBadge: true,
            disablePadding: true,
            label: <FormattedMessage id="create.lesson.validated" defaultMessage="Validated" />,
        },
        {
            id: 'published',
            numeric: false,
            isBadge: true,
            disablePadding: true,
            label: <FormattedMessage id="globalwords.published" defaultMessage="Published" />,
        },
        {
            id: 'revertToButton',
            numeric: false,
            isRevertToButton: true,
            disablePadding: true,
            isButton: true,
            label: <FormattedMessage id="create.lesson.undo.reverttoversion" defaultMessage="Revert to version." />,
        },
    ]

    const handleRevertToVersion = (row, column) => {
        setOpenLessonRevertModal(true)
        setCustomLessonVersionIdToRevertTo(row.id)
    }

    const handleRevertToVersionConfirmed = () => {
        setOpenLessonRevertModal(false)
        props.onRevertToPreviousVersion(customLessonVersionIdToRevertTo)
    }

    const renderCellValue = (row, column) => {
        if (column.isRevertToButton) {
            return (
                <Button variant="outlined" onClick={(event) => handleRevertToVersion(row, column)} color="primary">
                    {'Revert to'}
                </Button>
            )
        }
        if (column.isBadge) {
            return row[column.id] === true ? (
                <CheckCircleOutline style={{ color: 'green' }} />
            ) : (
                <PanoramaFishEye style={{ color: 'red' }} />
            )
        }

        return '' + row[column.id]
    }
    const classes = useStyles()

    function handleCancel() {
        setOpenLessonRevertModal(false)
        setCustomLessonVersionIdToRevertTo(null)
    }

    return (
        <Grid container spacing={3}>
            {openLessonRevertModal && (
                <CapeeshStyledModal
                    open={openLessonRevertModal}
                    textTitle={'Do you really want to revert to a older version of the lesson?'}
                    textLine1={
                        'No changes will be deleted. It is possible to revert to the current version later by going back to index ' +
                        props.currentVersionIndex +
                        '.'
                    }
                    onButton2={() => handleRevertToVersionConfirmed()}
                    onButton1={() => handleCancel()}
                    button1Text={'Cancel'}
                    button1Variant={'outlined'}
                    button2Text={'Yes'}
                    onClose={() => handleCancel()}
                />
            )}
            <Grid item xs={12} sm={12} className={classes.tablescroller}>
                <TableHead>
                    <TableRow>
                        {lessonVersionColumns?.map((column) => {
                            return (
                                <TableCell
                                    key={column.id}
                                    align={column.numeric ? 'right' : 'left'}
                                    padding={column.disablePadding ? 'none' : 'default'}
                                    sortDirection={'desc'}>
                                    <Tooltip
                                        title="Sort"
                                        placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                                        enterDelay={300}>
                                        <TableSortLabel active={orderBy === column.id} direction={order}>
                                            {column.label}
                                        </TableSortLabel>
                                    </Tooltip>
                                </TableCell>
                            )
                        })}
                    </TableRow>
                </TableHead>

                <TableBody>
                    {props.lesson.lessonVersions
                        ?.filter((x) => x.index !== props.currentVersionIndex)
                        .sort((a, b) => b.index - a.index)
                        .map((row, rowIndex) => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={rowIndex}>
                                    {lessonVersionColumns?.map((column, index) => (
                                        <TableCell
                                            key={index}
                                            component="th"
                                            scope="row"
                                            padding="none"
                                            style={{ width: '100px' }}>
                                            {renderCellValue(row, column)}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            )
                        })}
                </TableBody>
            </Grid>
        </Grid>
    )
}

function mapStateToProps(state) {
    const { lesson } = state
    return {
        lesson,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(LessonUndoPane)
