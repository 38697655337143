import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import { CapeeshColors } from '../../../assets/ColorPalette'
import { ReactComponent as Manual } from '../../../assets/images/CreateManualImage.svg'
import { ReactComponent as Language } from '../../../assets/images/CreateLanguageLessonsImage.svg'
import { ReactComponent as Knowledge } from '../../../assets/images/CreateKnowledgeLessonsImage.svg'
import Dialog from '@mui/material/Dialog'
import LessonCreationTypeCard from './LessonCreationTypeCard'
import xImage from '../../../assets/images/Navigation.png'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles((theme) => ({
    modalContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: 32,
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginBottom: 16,
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    titleText: {
        alignSelf: 'stretch',
        color: CapeeshColors.Gray900,
        textAlign: 'center',
        fontFamily: 'Rubik',
        fontSize: 16,
        fontStyle: 'normal',
        fontWeight: 400,
        letterSpacing: 0.64,
        textTransform: 'uppercase',
    },
    subTitleText: {
        color: CapeeshColors.Gray900,
        textAlign: 'center',
        fontFamily: 'Rubik',
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: 400,
    },
    closeIcon: {
        width: 24,
        height: 24,
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        alignSelf: 'flex-end',
        marginRight: 16,
        marginTop: 16,
        display: 'flex',
        cursor: 'pointer',
    },
}))

const LessonCreationTypeModal = ({ isOpen, onClose, onCreateManual, onCreateKnowledge, onCreateLanguage }) => {
    const classes = useStyles()

    return (
        <Dialog open={isOpen} onClose={onClose} maxWidth="lg">
            <img onClick={() => onClose()} src={xImage} className={classes.closeIcon} />
            <div className={classes.modalContainer}>
                <div className={classes.titleContainer}>
                    <div className={classes.titleText}>
                        <FormattedMessage
                            id={'create.course.topic.new.lesson.title'}
                            defaultMessage={'Create a new lesson'}
                        />
                    </div>
                    <div className={classes.subTitleText}>
                        <FormattedMessage
                            id={'create.course.topic.new.lesson.subtitle'}
                            defaultMessage={'Choose what type of lessons you wish to create.'}
                        />
                    </div>
                </div>
                <div className={classes.contentContainer}>
                    <LessonCreationTypeCard
                        image={<Manual />}
                        title={
                            <FormattedMessage
                                id={'create.course.topic.new.lesson.manually.title'}
                                defaultMessage={'Create Manually'}
                            />
                        }
                        subTitle={''}
                        descriptions={[
                            <FormattedMessage
                                id={'create.course.topic.new.lesson.manually.desc1'}
                                defaultMessage={
                                    'Start with a blank canvas and bring your ideas to life, craft unique lessons from scratch. Perfect for those who want complete creative freedom, allowing you to build fully customized experiences tailored to your specific needs.'
                                }
                            />,
                            <FormattedMessage
                                id={'create.course.topic.new.lesson.manually.desc2'}
                                defaultMessage={
                                    "Ideal for experienced users looking to explore every aspect of Capeesh Create's capabilities."
                                }
                            />,
                        ]}
                        buttonText={
                            <FormattedMessage
                                id={'create.course.topic.new.lesson.manually.title'}
                                defaultMessage={'Create Manually'}
                            />
                        }
                        onClickButton={onCreateManual}
                        topColor={'rgba(248, 239, 226, 1)'}
                    />
                    <LessonCreationTypeCard
                        image={<Language />}
                        title={
                            <FormattedMessage
                                id={'create.course.topic.new.lesson.language.ai.title'}
                                defaultMessage={'Create Language Lessons'}
                            />
                        }
                        subTitle={
                            <FormattedMessage
                                id={'create.course.topic.new.lesson.language.ai.subtitle'}
                                defaultMessage={'with the help of AI'}
                            />
                        }
                        descriptions={[
                            <FormattedMessage
                                id={'create.course.topic.new.lesson.language.ai.desc1'}
                                defaultMessage={
                                    'Perfect for teaching highly domain related words, phrases, and sentences,  directly targeting industry specific language learning.'
                                }
                            />,
                            <FormattedMessage
                                id={'create.course.topic.new.lesson.language.ai.desc2'}
                                defaultMessage={
                                    'Helps you create your own immersive stories to reinforce vocabulary learning!'
                                }
                            />,
                        ]}
                        buttonText={
                            <FormattedMessage
                                id={'create.course.topic.new.lesson.language.ai.title'}
                                defaultMessage={'Create Language Lessons'}
                            />
                        }
                        onClickButton={onCreateLanguage}
                        topColor={'rgba(238, 243, 247, 1)'}
                    />
                    <LessonCreationTypeCard
                        image={<Knowledge />}
                        title={
                            <FormattedMessage
                                id={'create.course.topic.new.lesson.knowledge.ai.title'}
                                defaultMessage={'Create Knowledge Lessons'}
                            />
                        }
                        subTitle={
                            <FormattedMessage
                                id={'create.course.topic.new.lesson.language.ai.subtitle'}
                                defaultMessage={'with the help of AI'}
                            />
                        }
                        descriptions={[
                            <FormattedMessage
                                id={'create.course.topic.new.lesson.knowledge.ai.desc1'}
                                defaultMessage={
                                    'Ideal for teaching extended content like rules, instructions, or explanations — perfect for job training! Language support ensures everyone understands and learns the language indirectly.'
                                }
                            />,
                            <FormattedMessage
                                id={'create.course.topic.new.lesson.knowledge.ai.desc2'}
                                defaultMessage={
                                    'Helps you create voice-drive interactive role plays to practice workplace language and scenarios.'
                                }
                            />,
                        ]}
                        buttonText={
                            <FormattedMessage
                                id={'create.course.topic.new.lesson.knowledge.ai.title'}
                                defaultMessage={'Create Knowledge Lessons'}
                            />
                        }
                        onClickButton={onCreateKnowledge}
                        topColor={'rgba(245, 233, 250, 1)'}
                        shouldShowBetaTag={true}
                    />
                </div>
            </div>
        </Dialog>
    )
}

LessonCreationTypeModal.propTypes = {}

const mapStateToProps = (state) => {
    return {
        organization: state.organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(LessonCreationTypeModal)
