import React, { useEffect, useState } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { connect } from 'react-redux'

import { Grid, Typography, Button, makeStyles } from '@material-ui/core'

import { history } from '_state/helpers'
import { TopicLessonType } from '../TopicConstants'
import { CapeeshColors } from '../../../assets/ColorPalette'
import { FormattedMessage } from 'react-intl'
import CustomLessonOrderCard from './Cards/CustomLessonOrderCard'
import { topicActions } from '../../../_state/actions'
import { TopicLessonOrderDisplayType } from '../../../Logic/TopicConstants'
import TopicLessonOrderDraftsEmpty from './Components/TopicLessonOrderEmptyStates/TopicLessonOrderDraftsEmpty'
import TopicLessonOrderPublishedEmpty from './Components/TopicLessonOrderEmptyStates/TopicLessonOrderPublishedEmpty'
import { AddOutlined } from '@material-ui/icons'
import Switch from '@material-ui/core/Switch'

const useStyles = makeStyles((theme) => ({
    languageDropdown: {
        width: '100%',
    },
    scenariosHeader: {},
    button: {
        marginRight: '10px',
        textTransform: 'none',
    },
    cancel: {
        alignItems: 'right',
    },
    media: {
        height: '100px',
    },
    draggable: {
        background: '#f8f8f8',
    },
    divider: {
        borderBottom: '1px solid lightgray',
        width: '100%',
    },

    publishAllButton: {
        height: '40px',
        marginTop: '16px',
    },
}))

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
}

const grid = 8

const getListStyle = (isDraggingOver) => ({
    padding: grid,
})

const TopicLessonOrder = ({
    topicId,
    courseGroupId,
    dispatch,
    topic,
    onDragEnd,
    onToggleGateable,
    onDetachScenario,
    onPreviewLesson,
    showAdminCard = false,
    displayType = TopicLessonOrderDisplayType.Published,
    onPublishLesson,
    onPublishAllLessons,
    showAdminToggle,
    onToggleAdminSection,
    showingAdminSection,
}) => {
    const classes = useStyles()

    const [currentLessonOrder, setCurrentLessonOrder] = useState([])

    const [publishAllEnabled, setPublishAllEnabled] = useState(false)

    useEffect(() => {
        dispatch(topicActions.getLessonOrderForDifficulty(topicId, 0))
    }, [])

    const handleDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return
        }
        const items = reorder(currentLessonOrder, result.source.index, result.destination.index)
        onDragEnd(items, displayType)
    }

    useEffect(() => {
        console.log('publish all useeffect called')
        if (topic.lessonOrder?.length > 0) {
            if (displayType === TopicLessonOrderDisplayType.Published) {
                setCurrentLessonOrder(topic.lessonOrder.filter((x) => x.published))
            } else if (displayType === TopicLessonOrderDisplayType.Unpublished) {
                setCurrentLessonOrder(topic.lessonOrder.filter((x) => !x.published))
                if (topic.lessonOrder.filter((x) => !x.published && x.canBePublished).length > 0) {
                    console.log('setting publish all enabled')
                    setPublishAllEnabled(true)
                } else {
                    setPublishAllEnabled(false)
                }
            } else {
                console.log('display type error, should be published or unpublished')
                setCurrentLessonOrder([])
            }
        } else {
            setCurrentLessonOrder([])
        }
    }, [topic.lessonOrder])

    const handleToggleGateable = (customLessonId) => {
        onToggleGateable(customLessonId)
    }

    const handleDetachScenario = (customLessonId) => {
        onDetachScenario(customLessonId)
    }

    const handleEditScenario = (customLessonId) => {
        if (courseGroupId && topicId) {
            history.push(
                '/creator/course/group/' + courseGroupId + '/topic/' + topicId + '/lesson/edit/' + customLessonId
            )
        } else {
            history.push('/creator/topic/list/' + topicId + '/lessons/edit/' + customLessonId)
        }
    }

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
                alignItems: 'stretch',
            }}>
            {displayType === TopicLessonOrderDisplayType.Published && currentLessonOrder.length > 0 && (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        paddingLeft: '8px',
                        paddingRight: '8px',
                    }}>
                    <Typography
                        variant="h4"
                        style={{
                            display: 'inline-block',
                            color: CapeeshColors.DeepBlue,
                            marginTop: '20px',
                        }}>
                        <FormattedMessage
                            id={'course.topic.lesson.order.published.lessons'}
                            defaultMessage={'Published lessons'}
                        />
                    </Typography>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginBottom: '12px',
                            marginRight: '16px',
                            marginTop: '16px',
                            height: '30px',
                            justifySelf: 'center',
                        }}>
                        {showAdminToggle && (
                            <React.Fragment>
                                <Typography
                                    variant="body1"
                                    style={{ justifySelf: 'center', color: CapeeshColors.Gray900 }}>
                                    <FormattedMessage
                                        id={'course.topic.lesson.list.show.admin.data'}
                                        defaultMessage={'Display admin data'}
                                    />
                                </Typography>
                                <Switch
                                    checked={showAdminCard}
                                    onChange={() => {
                                        onToggleAdminSection()
                                    }}
                                    style={{ color: CapeeshColors.Gray900 }}
                                />
                            </React.Fragment>
                        )}
                    </div>
                </div>
            )}
            {displayType === TopicLessonOrderDisplayType.Unpublished && (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        paddingLeft: '8px',
                        paddingRight: '8px',
                    }}>
                    <Typography
                        variant="h4"
                        style={{
                            display: 'inline-block',
                            color: CapeeshColors.DeepBlue,
                            marginTop: '20px',
                        }}>
                        <FormattedMessage
                            id={'course.topic.lesson.order.draft.lessons'}
                            defaultMessage={'Lesson Drafts'}
                        />
                    </Typography>
                    <div>
                        <Button
                            className={classes.publishAllButton}
                            style={{
                                textTransform: 'none',
                                color: 'white',
                                background: CapeeshColors.Primary600,
                                opacity: publishAllEnabled ? 1 : 0.5,
                            }}
                            disabled={!publishAllEnabled}
                            variant={'contained'}
                            onClick={() => onPublishAllLessons()}>
                            <FormattedMessage
                                id="create.topic.lesson.order.button.publish.all.title"
                                defaultMessage={'Publish all'}
                            />
                        </Button>
                    </div>
                </div>
            )}

            <div style={{ marginTop: '10px', paddingLeft: '8px', paddingRight: '8px' }}>
                <Typography
                    style={{
                        fontFamily: 'Rubik',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '16px',
                        lineHeight: '24px',
                        color: '#8E8E93',
                    }}>
                    {displayType === TopicLessonOrderDisplayType.Published && currentLessonOrder.length > 0 && (
                        <FormattedMessage
                            id="create.topic.lessonorder.published.message"
                            defaultMessage={'These lessons are available in the app. Edit and republish changes.'}
                        />
                    )}

                    {displayType === TopicLessonOrderDisplayType.Unpublished && (
                        <FormattedMessage
                            id="create.topic.lessonorder.unpublished.message"
                            defaultMessage={'Edit and publish the drafts.'}
                        />
                    )}
                </Typography>
            </div>

            {currentLessonOrder.length === 0 && displayType === TopicLessonOrderDisplayType.Unpublished && (
                <TopicLessonOrderDraftsEmpty />
            )}
            {currentLessonOrder.length === 0 && displayType === TopicLessonOrderDisplayType.Published && (
                <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '32px' }}>
                    <TopicLessonOrderPublishedEmpty />
                </div>
            )}

            {currentLessonOrder.length > 0 && (
                <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}>
                                {currentLessonOrder.map((item, index) => (
                                    <Draggable
                                        className={classes.draggable}
                                        key={item.tempId}
                                        draggableId={item.tempId + ''}
                                        isDragDisabled={
                                            !(
                                                item.topicLessonType === TopicLessonType.Quiz ||
                                                item.topicLessonType === TopicLessonType.Scenario
                                            )
                                        }
                                        index={index}>
                                        {(provided, snapshot) =>
                                            (item.topicLessonTypeName === 'Scenario' ||
                                                item.topicLessonType === TopicLessonType.Scenario) && (
                                                <CustomLessonOrderCard
                                                    lessonOrderItem={item}
                                                    provided={provided}
                                                    index={index}
                                                    onEditScenario={handleEditScenario}
                                                    onPublishLesson={onPublishLesson}
                                                    onDetachScenario={handleDetachScenario}
                                                    onToggleGateable={handleToggleGateable}
                                                    onPreviewLesson={onPreviewLesson}
                                                    showAdminCard={showAdminCard}
                                                />
                                            )
                                        }
                                    </Draggable>
                                ))}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            )}
        </div>
    )
}

TopicLessonOrder.propTypes = {}

const mapStateToProps = (state) => {
    const { lesson, topic, user, organization } = state
    return {
        lesson,
        topic,
        organization,
        user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(TopicLessonOrder)
