import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import { getFlagAssetForLanguageId } from '../../../Logic/FlagUnicodeLogic'
import { ReactComponent as BackArrow } from '../../../assets/images/Back_Arrow.svg'
import { CapeeshColors } from '../../../assets/ColorPalette'
import placeholderImage from '../../../assets/images/placeholder_image.png'
import { ReactComponent as Stars } from '../../../assets/images/Progression_Stars.svg'
import { ReactComponent as ProgressBar } from '../../../assets/images/Course card- Progressbar.svg'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        overflow: 'hidden',
        position: 'relative',
        backgroundColor: CapeeshColors.OffWhite,
    },
    selectedLanguageContainer: {
        display: 'flex',
        width: 240,
        justifyContent: 'space-between',
        alignItems: 'center',
        top: 130,
        right: 80,
        position: 'absolute',
    },
    languageText: {
        fontFamily: 'Nunito',
        fontWeight: 'regular',
        fontSize: 16,
    },
    backArrow: {
        opacity: 0.5,
    },
    line: {
        borderBottom: '2px solid gray',
        opacity: 0.25,
    },
    headingAndDescriptionContainer: {
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        top: 200,
        left: 25,
    },
    heading: {
        marginBottom: 16,
        fontFamily: 'Nunito',
        fontWeight: 'medium',
        fontSize: 30,
        width: '100%',
    },
    description: {
        fontFamily: 'Nunito',
        fontWeight: 'regular',
        fontSize: 18,
        width: '100%',
    },
    courseText: {
        fontFamily: 'Nunito',
        fontWeight: 'regular',
        fontSize: 18,
        position: 'absolute',
        bottom: 300,
        left: 20,
    },
    headingAndDescriptionSubContainer: {
        width: 300,
        height: 200,
    },
    topicCardContainer: {
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        height: 250,
        width: 330,
        bottom: 35,
        left: 20,
        backgroundColor: 'white',
        borderRadius: 16,
        overflow: 'hidden',
    },
    topicCardImageContainer: {
        position: 'relative',
        width: '100%',
        height: '100px',
        justifyContent: 'center',
        overflow: 'hidden',
        WebkitMaskImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 1) 70%, rgba(0, 0, 0, 0))',
        maskImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 1) 70%, rgba(0, 0, 0, 0))',
        WebkitMaskComposite: 'destination-in',
        maskComposite: 'intersect',
    },
    topicCardImage: {
        width: '100%',
        height: 'auto',
        display: 'block',
        flexDirection: 'column',
    },
    topicCardName: {
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        fontSize: 20,
        padding: 8,
    },
    topicCardDescription: {
        fontFamily: 'Nunito',
        fontWeight: 'regular',
        fontSize: 16,
        padding: 8,
    },
    progressContainer: {
        width: '100%',
        position: 'absolute',
        bottom: 10,
        left: 2.5,
    },
    stars: {
        position: 'absolute',
        right: 5,
        zIndex: 100,
        top: 5,
    },
}))

const TopicCreationPreviewMockup = (props) => {
    const classes = useStyles()

    return (
        <div className={classes.mainContainer}>
            <BackArrow className={classes.backArrow} />
            <div className={classes.line} />
            <div className={classes.selectedLanguageContainer}>
                <div className={classes.languageText}>
                    <FormattedMessage id={'globalwords.course.lanugage'} defaultMessage={'Course language'} />:
                </div>
                <img src={getFlagAssetForLanguageId(props.language)} width="25" alt={'Language'} />
                <div className={classes.languageText}>
                    <FormattedMessage id={'globalwords.language'} defaultMessage={'Language'} />
                </div>
            </div>
            <div className={classes.headingAndDescriptionContainer}>
                <div className={classes.headingAndDescriptionSubContainer}>
                    <div className={classes.heading}>
                        {props.courseHeading ?? (
                            <FormattedMessage id={'globalwords.heading'} defaultMessage={'Heading'} />
                        )}
                    </div>
                    <div className={classes.description}>
                        {props.courseDescription ?? (
                            <FormattedMessage id={'globalwords.description.camel'} defaultMessage={'Description'} />
                        )}
                    </div>
                </div>
            </div>

            <div className={classes.courseText}>
                <FormattedMessage id={'globalwords.course'} defaultMessage={'Course'} />
            </div>
            <div className={classes.topicCardContainer}>
                <div className={classes.topicCardImageContainer}>
                    <Stars className={classes.stars} />
                    <img src={props.topicImage?.imageUrl ?? placeholderImage} className={classes.topicCardImage} />
                </div>

                <div className={classes.topicCardName}>{props.topicName}</div>
                <div className={classes.topicCardDescription}>{props.topicDescription}</div>
                <ProgressBar className={classes.progressContainer} />
            </div>
        </div>
    )
}

TopicCreationPreviewMockup.propTypes = {}

const mapStateToProps = (state) => {
    return {
        organization: state.organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(TopicCreationPreviewMockup)
