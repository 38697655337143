import React, { useState, useRef } from 'react'
import { connect } from 'react-redux'
import SvgIcon from '@mui/material/SvgIcon'
import { styled } from '@mui/system'
import { CircularProgress, Grid, makeStyles, TextField, Typography } from '@material-ui/core'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import { ReactComponent as StageRemove } from '.././Images/StageRemove.svg'
import StageDefinition from '.././Images/StageDefinition.png'
import StageRulecard from '.././Images/StageRulecard.png'
import StageTest from '.././Images/StageTest.png'
import StageAudiostory from '.././Images/StageAudiostory.png'
import StagePuzzle from '.././Images/StagePuzzle.png'
import StageQuiz from '.././Images/StageQuiz.png'
import StageInstruction from '.././Images/StageInstruction.png'
import StageDialogue from '.././Images/StageDialogue.png'
import ACCStageSettingsDialogue from './Cards/ACCStageSettingsDialogue'
import ACCStageSettingsTest from './Cards/ACCStageSettingsTest'
import {
    AutoCourseCreatorLessonTeachingStageTypes,
    AutoCourseCreatorStageFriendlyNames,
} from '../../../../Logic/AutoCourseCreationConstants'
import ACCStageSettingsRuleCard from './Cards/ACCStageSettingsRuleCard'
import ACCStageSettingsStory from './Cards/ACCStageSettingsStory'
import ACCStageSettingsQuiz from './Cards/ACCStageSettingsQuiz'
import ACCStageSettingsPuzzle from './Cards/ACCStageSettingsPuzzle'
import ACCStageSettingsInstruction from './Cards/ACCStageSettingsInstruction'
import ButtonBase from '@mui/material/ButtonBase'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import { FormattedMessage } from 'react-intl'

const CustomSVG = (props) => (
    <SvgIcon width="28" height="28" viewBox="-2 0 28 28" overflow="visible" {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none">
            <rect x="1.5" y="1" width="23" height="23" rx="7" stroke="#44A6EF" stroke-width="2" />
        </svg>
    </SvgIcon>
)

const CustomSVGChecked = (props) => (
    <SvgIcon width="28" height="28" viewBox="-2 0 28 28" overflow="visible" {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none">
            <rect x="1.5" y="1" width="23" height="23" rx="7" stroke="#44A6EF" stroke-width="2" />
            <polyline points="5 13 9 17 20 7" fill="none" stroke="#44A6EF" stroke-width="2" />
        </svg>
    </SvgIcon>
)

const MyCheckbox = styled(Checkbox)({
    color: 'blue',
    '&:hover': {
        transform: 'scale(1.1)',
        transition: 'transform 0.3s',
    },
})

const useStyles = makeStyles({
    checkbox: {
        color: '#000',
        '&.Mui-checked': {
            color: '#000',
        },
        '&.MuiCheckbox-root': {
            borderRadius: '8px',
            border: '1px solid blue',
        },
    },
    label: {
        fontFamily: "'Rubik', sans-serif",
    },
    imageStyle: {
        fontFamily: 'Rubik',
        fontWeight: 'normal',
        fontSize: '10px',
        lineHeight: '19px',
        color: 'black',
        letterSpacing: '0.31px',
        textAlign: 'center',
    },
    selectable: {
        cursor: 'pointer',
    },
    stageImage: {
        position: 'relative',
        width: '90px',
        marginRight: '20px',
    },
    stageImageInModal: {
        position: 'relative',
        width: '90px',
        marginRight: '20px',
    },
    stageRemove: {
        position: 'absolute',
        // width: '36px',
        zIndex: 1,
        top: '-10px',
        right: '10px',
    },
})

const AutoCourseSuggestLessonDetailsComplianceView = (props) => {
    const [openSelectStageModal, setOpenSelectStageModal] = useState(false)
    const [openStageSettings, setOpenStageSettings] = useState(false)

    const [newlySelectedStages, setNewlySelectedStages] = useState([])

    const displayCorrectMockup = (stageType) => {
        switch (stageType) {
            case AutoCourseCreatorLessonTeachingStageTypes.AudioStory:
                return StageAudiostory
            case AutoCourseCreatorLessonTeachingStageTypes.Definition:
                return StageDefinition
            case AutoCourseCreatorLessonTeachingStageTypes.Dialogue:
                return StageDialogue
            case AutoCourseCreatorLessonTeachingStageTypes.Instruction:
                return StageInstruction
            case AutoCourseCreatorLessonTeachingStageTypes.Puzzle:
                return StagePuzzle
            case AutoCourseCreatorLessonTeachingStageTypes.Test:
                return StageTest
            case AutoCourseCreatorLessonTeachingStageTypes.MultiChoice:
                return StageQuiz
            case AutoCourseCreatorLessonTeachingStageTypes.RuleCard:
                return StageRulecard
        }
        return StageInstruction
    }

    const selectStageModalOpen = () => {
        setOpenSelectStageModal(true)
    }

    const selectStageModaClose = () => {
        setOpenSelectStageModal(false)
        setNewlySelectedStages([])
        setOpenStageSettings(false)
        props.onChangeSelectedStage(null)
    }

    const handleAddNewStages = () => {
        setOpenSelectStageModal(false)
        setNewlySelectedStages([])
        props.onAddNewStages(newlySelectedStages, props.selectedLesson.lessonPlanId)
    }

    const addOrRemoveStagesInModal = (stageType) => {
        let newArray = [...newlySelectedStages]
        const index = newArray.indexOf(stageType)
        if (index === -1) {
            newArray.push(stageType)
        } else {
            newArray.splice(index, 1)
        }
        setNewlySelectedStages(newArray)
    }

    const removeStageFromLessonPlan = (stageType) => {
        if (props.selectedStage === stageType) {
            props.onChangeSelectedStage(null)
        }
        props.onUpdateStagePlotSelection(stageType, null, props.selectedLesson.lessonPlanId, true, false, false)
    }
    const handleToggleStagePlot = (
        stageType,
        plotType,
        shouldRemove,
        coversMultipleConcepts,
        interactionType = 'Reading'
    ) => {
        props.onUpdateStagePlotSelection(
            stageType,
            plotType,
            props.selectedLesson.lessonPlanId,
            shouldRemove,
            coversMultipleConcepts,
            interactionType
        )
    }

    const handleChangeFruitMachineSelection = (fruitMachineType, selection) => {
        props.onUpdateLessonSuggestionDto(props.selectedLesson.lessonPlanId, fruitMachineType, selection)
    }

    const getUniqueExercises = (exercises) => {
        if (typeof exercises === 'undefined' || exercises === null) return []
        return exercises.reduce((acc, current) => {
            const x = acc.find((item) => item.exerciseType === current.exerciseType)
            if (!x) {
                return acc.concat([current])
            } else {
                return acc
            }
        }, [])
    }
    const uniqueExercises = getUniqueExercises(props.selectedLesson.lessonPlanConfigurationDto.exercises)
    let object1 = {
        Definition: 'Definition',
        Test: 'Test',
        AudioStory: 'AudioStory',
        RuleCard: 'RuleCard',
        Dialogue: 'Dialogue',
        Puzzle: 'Puzzle',
        Multichoice: 'Multichoice',
        Instruction: 'Instruction',
    }

    let uniqueElements = Object.keys(object1).filter(
        (key) => !props.selectedLesson.lessonPlanConfigurationDto?.exercises?.some((obj) => obj.exerciseType === key)
    )
    const classes = useStyles()
    return (
        <Grid container>
            <Dialog maxWidth={'md'} fullWidth={false} open={openSelectStageModal} onClose={selectStageModaClose}>
                <DialogTitle>
                    <FormattedMessage
                        id={'create.knowledge.ai.lesson.plan.lesson.complience.add.another.stage'}
                        defaultMessage={'Add other types of stage'}
                    />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Grid container style={{ padding: '20px' }}>
                            {uniqueElements.map((exercise, index) => (
                                <Grid item style={{ position: 'relative', marginRight: '3px', marginLeft: '3px' }}>
                                    <Button
                                        style={{
                                            position: 'absolute',
                                            top: -10,
                                            right: 0,
                                            minWidth: 0, // to override MUI default
                                            width: '25px',
                                            height: '25px',
                                            borderRadius: '50%',
                                            backgroundColor: newlySelectedStages.includes(exercise)
                                                ? '#44A6EF'
                                                : '#ffffff',

                                            border: newlySelectedStages.includes(exercise)
                                                ? 'none'
                                                : '1px solid #44A6EF',
                                            zIndex: 1,
                                            fill: 'var(--White, #FFF)',
                                            strokeWidth: 1,
                                            stroke: 'var(--Blue, #44A6EF)',
                                            filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
                                        }}
                                        onClick={(e) => addOrRemoveStagesInModal(exercise)}></Button>
                                    <ButtonBase
                                        style={{ width: '100px' }}
                                        onClick={(e) => addOrRemoveStagesInModal(exercise)}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <img
                                                    alt="Add a Stage"
                                                    className={classes.stageImageInModal}
                                                    src={displayCorrectMockup(exercise)}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography className={classes.imageStyle} style={{}}>
                                                    <FormattedMessage
                                                        id={
                                                            'create.knowledge.ai.lesson.plan.lesson.complience.test.type'
                                                        }
                                                        defaultMessage={'{testType} stage'}
                                                        values={{
                                                            testType: AutoCourseCreatorStageFriendlyNames[exercise],
                                                        }}
                                                    />
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </ButtonBase>
                                </Grid>
                            ))}
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={selectStageModaClose} style={{ textTransform: 'none' }}>
                        <FormattedMessage id={'globalwords.cancel'} defaultMessage={'Cancel'} />
                    </Button>
                    <Button
                        onClick={handleAddNewStages}
                        disabled={newlySelectedStages.length === 0}
                        style={{ textTransform: 'none' }}>
                        <FormattedMessage
                            id={'create.knowledge.ai.lesson.plan.lesson.complience.add.button.text'}
                            defaultMessage={'Add stage'}
                        />
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog maxWidth={'lg'} fullWidth={true} open={props.selectedStage !== null} onClose={selectStageModaClose}>
                <DialogTitle>
                    Uses of {AutoCourseCreatorStageFriendlyNames[props.selectedStage]} in the lesson
                </DialogTitle>
                <DialogContent>
                    {props.selectedStage === AutoCourseCreatorLessonTeachingStageTypes.RuleCard && (
                        <ACCStageSettingsRuleCard
                            interactionType={
                                props.selectedLesson.lessonPlanConfigurationDto.exercises.find(
                                    (element) => element.exerciseType === 'RuleCard'
                                )?.interactionType
                            }
                            onToggleStagePlot={handleToggleStagePlot}
                            onUpdateLessonSuggestionDto={props.onUpdateLessonSuggestionDto}
                            selectedLesson={props.selectedLesson}
                        />
                    )}
                    {props.selectedStage === AutoCourseCreatorLessonTeachingStageTypes.MultiChoice && (
                        <ACCStageSettingsQuiz
                            onDeleteQuizQuestion={props.onDeleteQuizQuestion}
                            onToggleStagePlot={handleToggleStagePlot}
                            selectedLesson={props.selectedLesson}
                            onReorderQuiz={props.onReorderQuiz}
                            onSuggestQuiz={props.onSuggestQuiz}
                            isQuizGenerating={props.isQuizGenerating}
                            onWriteQuizQuestion={props.onWriteQuizQuestion}
                        />
                    )}

                    {props.selectedStage === AutoCourseCreatorLessonTeachingStageTypes.AudioStory && (
                        <ACCStageSettingsStory
                            interactionType={props.selectedLesson.lessonPlanConfigurationDto?.story?.storyMode}
                            onToggleStagePlot={handleToggleStagePlot}
                            onUpdateLessonSuggestionDto={props.onUpdateLessonSuggestionDto}
                            selectedLesson={props.selectedLesson}
                        />
                    )}

                    {props.selectedStage === AutoCourseCreatorLessonTeachingStageTypes.Dialogue && (
                        <ACCStageSettingsDialogue
                            onToggleStagePlot={handleToggleStagePlot}
                            isDialogueGenerating={props.isDialogueGenerating}
                            onUpdateLessonSuggestionDto={props.onUpdateLessonSuggestionDto}
                            onGetStageDetails={props.onGetStageDetails}
                            selectedLesson={props.selectedLesson}
                        />
                    )}
                    {props.selectedStage === AutoCourseCreatorLessonTeachingStageTypes.Test && (
                        <ACCStageSettingsTest
                            interactionType={
                                props.selectedLesson.lessonPlanConfigurationDto.exercises.find(
                                    (element) => element.exerciseType === 'Test'
                                ).interactionType
                            }
                            isDialogueGenerating={props.isDialogueGenerating}
                            onUpdateLessonSuggestionDto={props.onUpdateLessonSuggestionDto}
                            onGetStageDetails={props.onGetStageDetails}
                            selectedLesson={props.selectedLesson}
                        />
                    )}
                    {props.selectedStage === AutoCourseCreatorLessonTeachingStageTypes.Puzzle && (
                        <ACCStageSettingsPuzzle
                            interactionType={
                                props.selectedLesson.lessonPlanConfigurationDto.exercises.find(
                                    (element) => element.exerciseType === 'Puzzle'
                                )?.interactionType
                            }
                            onToggleStagePlot={handleToggleStagePlot}
                            isDialogueGenerating={props.isDialogueGenerating}
                            onUpdateLessonSuggestionDto={props.onUpdateLessonSuggestionDto}
                            onGetStageDetails={props.onGetStageDetails}
                            selectedLesson={props.selectedLesson}
                        />
                    )}
                    {props.selectedStage === AutoCourseCreatorLessonTeachingStageTypes.Instruction && (
                        <ACCStageSettingsInstruction
                            onToggleStagePlot={handleToggleStagePlot}
                            isDialogueGenerating={props.isDialogueGenerating}
                            onUpdateLessonSuggestionDto={props.onUpdateLessonSuggestionDto}
                            onGetStageDetails={props.onGetStageDetails}
                            selectedLesson={props.selectedLesson}
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={selectStageModaClose} style={{ textTransform: 'none' }}>
                        <FormattedMessage id={'globalwords.ok'} defaultMessage={'Ok'} />
                    </Button>
                </DialogActions>
            </Dialog>
            <Grid item xs={7} style={{ marginTop: '10px' }}>
                <Typography
                    style={{
                        marginTop: '10px',
                        fontFamily: 'Rubik',
                        fontWeight: 500,
                        fontSize: '16px',
                        lineHeight: '19px',
                        color: 'black',
                        letterSpacing: '0.41px',
                        textAlign: 'left',
                        marginBottom: '10px',
                    }}>
                    <FormattedMessage
                        id={'create.knowledge.ai.lesson.plan.lesson.what.type.of.lesson'}
                        defaultMessage={'What type of lesson would you like to create?'}
                    />
                </Typography>
                <Grid item xs={12}>
                    <Button
                        onClick={() =>
                            props.onUpdateLessonSuggestionDto(
                                props.selectedLesson.lessonPlanId,
                                'simulationType',
                                'ConversationWithSomeone',
                                false
                            )
                        }
                        style={{
                            color: props.selectedLesson.lessonSubType === 'Simulation' ? 'white' : '#35323C',
                            borderRadius: '5px',
                            background: props.selectedLesson.lessonSubType === 'Simulation' ? '#44A6EF' : 'white',
                            border:
                                props.selectedLesson?.lessonPlanConfigurationDto?.configurationType === 'Simulation'
                                    ? 0
                                    : '1px solid var(--gray-1, #8E8E93)',
                            display: 'inline',
                            height: '31px',
                            padding: '0 15px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '20px',
                            textTransform: 'none',
                            marginRight: '10px',
                        }}>
                        <FormattedMessage id={'globalwords.simulation'} defaultMessage={'Simulation'} />
                    </Button>
                    <Button
                        onClick={() =>
                            props.onUpdateLessonSuggestionDto(
                                props.selectedLesson.lessonPlanId,
                                'normalType',
                                'Traditional',
                                false
                            )
                        }
                        style={{
                            color:
                                props.selectedLesson.lessonSubType === 'Normal' &&
                                props.selectedLesson?.lessonPlanConfigurationDto?.configurationType === 'Traditional'
                                    ? 'white'
                                    : '#35323C',
                            borderRadius: '5px',
                            background:
                                props.selectedLesson.lessonSubType === 'Normal' &&
                                props.selectedLesson?.lessonPlanConfigurationDto?.configurationType === 'Traditional'
                                    ? '#44A6EF'
                                    : 'white',
                            border:
                                props.selectedLesson?.lessonPlanConfigurationDto?.configurationType === 'Simulation'
                                    ? 0
                                    : '1px solid var(--gray-1, #8E8E93)',
                            display: 'inline',
                            height: '31px',
                            padding: '0 15px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '20px',
                            textTransform: 'none',
                            marginRight: '10px',
                        }}>
                        <FormattedMessage id={'globalwords.complience'} defaultMessage={'Compliance'} />
                    </Button>
                    <Button
                        onClick={() =>
                            props.onUpdateLessonSuggestionDto(
                                props.selectedLesson.lessonPlanId,
                                'normalType',
                                'Quiz',
                                false
                            )
                        }
                        style={{
                            color:
                                props.selectedLesson.lessonSubType === 'Normal' &&
                                props.selectedLesson?.lessonPlanConfigurationDto?.configurationType === 'Quiz'
                                    ? 'white'
                                    : '#35323C',
                            borderRadius: '5px',
                            background:
                                props.selectedLesson.lessonSubType === 'Normal' &&
                                props.selectedLesson?.lessonPlanConfigurationDto?.configurationType === 'Quiz'
                                    ? '#44A6EF'
                                    : 'white',
                            border:
                                props.selectedLesson?.lessonPlanConfigurationDto?.configurationType === 'Simulation'
                                    ? 0
                                    : '1px solid var(--gray-1, #8E8E93)',
                            display: 'inline',
                            height: '31px',
                            padding: '0 15px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '20px',
                            textTransform: 'none',
                            marginRight: '10px',
                        }}>
                        <FormattedMessage id={'globalwords.quiz'} defaultMessage={'Quiz'} />
                    </Button>
                </Grid>
            </Grid>
            <Grid item xs={5} style={{ marginTop: '10px', paddingLeft: '20px' }}>
                <Typography
                    style={{
                        fontFamily: 'Rubik',
                        fontWeight: 500,
                        fontSize: '16px',
                        lineHeight: '19px',
                        color: 'black',
                        letterSpacing: '0.41px',
                        textAlign: 'left',
                        marginTop: '10px',
                        marginBottom: '10px',
                    }}>
                    <FormattedMessage
                        id={'create.knowledge.ai.lesson.plan.tone.of.lesson'}
                        defaultMessage={'Tone of lesson'}
                    />
                </Typography>
                <Grid item xs={12}>
                    <Button
                        onClick={() => handleChangeFruitMachineSelection('lessonToneOfVoiceType', 'Serious')}
                        style={{
                            color: props.selectedLesson.lessonToneOfVoiceType === 'Serious' ? 'white' : '#35323C',
                            borderRadius: '5px',
                            background: props.selectedLesson.lessonToneOfVoiceType === 'Serious' ? '#44A6EF' : 'white',
                            border:
                                props.selectedLesson.lessonToneOfVoiceType === 'Serious'
                                    ? 0
                                    : '1px solid var(--gray-1, #8E8E93)',
                            display: 'inline',
                            height: '31px',
                            padding: '0 15px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '20px',
                            textTransform: 'none',
                            marginRight: '10px',
                        }}>
                        <FormattedMessage id={'create.knowledge.ai.lesson.plan.serious'} defaultMessage={'Serious'} />
                    </Button>
                    <Button
                        onClick={() => handleChangeFruitMachineSelection('lessonToneOfVoiceType', 'Playful')}
                        style={{
                            color: props.selectedLesson.lessonToneOfVoiceType === 'Playful' ? 'white' : '#35323C',
                            borderRadius: '5px',
                            background: props.selectedLesson.lessonToneOfVoiceType === 'Playful' ? '#44A6EF' : 'white',
                            border:
                                props.selectedLesson.lessonToneOfVoiceType === 'Playful'
                                    ? 0
                                    : '1px solid var(--gray-1, #8E8E93)',
                            display: 'inline',
                            height: '31px',
                            padding: '0 15px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '20px',
                            textTransform: 'none',
                            marginRight: '10px',
                        }}>
                        <FormattedMessage id={'create.knowledge.ai.lesson.plan.playful'} defaultMessage={'Playful'} />
                    </Button>
                </Grid>
            </Grid>
            <Grid container style={{ background: 'var(--Off-white, #F8F8F8)', marginTop: '20px', padding: '20px' }}>
                <Grid item xs={12}>
                    <Typography
                        style={{
                            fontFamily: 'Rubik',
                            fontWeight: 500,
                            fontSize: '16px',
                            lineHeight: '19px',
                            color: 'black',
                            letterSpacing: '0.31px',
                            textAlign: 'left',
                            marginBottom: '10px',
                        }}>
                        <FormattedMessage
                            id={'create.knowledge.ai.lesson.plan.lesson.complience.stages.in.this.lesson'}
                            defaultMessage={'Stages in this lesson:'}
                        />
                    </Typography>
                    <Typography
                        style={{
                            fontFamily: 'Rubik',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '16px',
                            lineHeight: '24px',
                            color: '#8E8E93',
                            marginTop: '3px',
                            marginBottom: '20px',
                        }}>
                        <FormattedMessage
                            id={'create.knowledge.ai.lesson.plan.lesson.complience.stages.in.this.lesson.desc'}
                            defaultMessage={
                                'Here are the stages we suggest for this lesson. Click on a stage to edit how it is used or add other stages using the + button.'
                            }
                        />
                    </Typography>
                    <Grid container alignItems="left">
                        {uniqueExercises.map((exercise, index) => (
                            <Grid item style={{ position: 'relative' }}>
                                <Button
                                    style={{ position: 'absolute', top: 0, right: 0 }}
                                    onClick={(e) => removeStageFromLessonPlan(exercise.exerciseType)}>
                                    <StageRemove className={classes.stageRemove} />
                                </Button>
                                <ButtonBase
                                    style={{ width: '100px' }}
                                    onClick={(e) => props.onChangeSelectedStage(exercise.exerciseType)}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <img
                                                alt="Add a Stage"
                                                className={classes.stageImage}
                                                src={displayCorrectMockup(exercise.exerciseType)}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography className={classes.imageStyle} style={{}}>
                                                {AutoCourseCreatorStageFriendlyNames[exercise.exerciseType]} stages
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </ButtonBase>
                            </Grid>
                        ))}
                        <Grid>
                            <Grid item>
                                <ButtonBase
                                    onClick={selectStageModalOpen}
                                    style={{
                                        borderRadius: 10,
                                        background: '#D9D9D9',
                                        width: '90px',
                                        height: '160px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}>
                                    <Typography
                                        className={classes.imageStyle}
                                        style={{ fontSize: '46px', color: 'var(--Gray-1, #8E8E93)' }}>
                                        +
                                    </Typography>
                                </ButtonBase>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
AutoCourseSuggestLessonDetailsComplianceView.propTypes = {}

function mapStateToProps(state) {
    const { autoCourseCreation } = state
    return {
        autoCourseCreation,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(AutoCourseSuggestLessonDetailsComplianceView)
