import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import { history } from '../_state/helpers'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import studentPrivateRoutes from 'routes/studentPrivateRoutes'

const styles = (theme) => ({
    main: {
        //padding: '20px',
        margin: '60px 0px 0px 0px',
        [theme.breakpoints.down('sm')]: {
            margin: '60px 0px 0px 0px',
        },
    },
})

class StudentPrivateLayout extends React.Component {
    constructor(props) {
        super(props)
        if (!localStorage.getItem('authToken')) {
            console.log('StudentPrivateLayout does not have auth token available')
            history.push('/student/login')
            return
        }
        console.log('StudentPrivateLayout has authentication token and should render')

        history.listen((location, action) => {
            // clear alert on location change
            // dispatch(alertActions.clear());
        })
        if (!studentPrivateRoutes.find((x) => x.path === props.path)) {
            history.push('/student')
        }
    }

    componentDidMount() {
        const { user } = this.props
        // this.props.dispatch(userActions.getUserInfo(organization.selectedOrganization))
    }

    render() {
        const { component: Component, classes, location, history, dispatch, computedMatch } = this.props

        return (
            <div>
                <div className={classes.main}>
                    <Component
                        location={location}
                        computedMatch={computedMatch}
                        history={history}
                        dispatch={dispatch}
                    />
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { organization, user } = state
    return {
        user,
        organization,
    }
}

StudentPrivateLayout.propTypes = {
    classes: PropTypes.object.isRequired,
}

const enhance = compose(connect(mapStateToProps), withStyles(styles))

export default enhance(StudentPrivateLayout)
