import React from 'react'
import { history } from '../../_state/helpers'

import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { makeStyles } from '@material-ui/core'
import { CapeeshColors } from '../../assets/ColorPalette'
import { ReactComponent as CapeeshLogo } from 'assets/images/capeesh_logo.svg'
import { Button } from '@mui/material'

const useStyles = makeStyles((theme) => ({
    gridContainer: {
        padding: 30,
    },
    capeeshLogo: {
        fill: CapeeshColors.Primary900,
        display: 'inherit',
        margin: '20px auto',
    },
    centerMidDiv: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: '32px',
    },
}))

const NotFound = (props) => {
    const classes = useStyles()
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <div style={{ width: '80%', justifyContent: 'center' }}>
                <div className={classes.centerMidDiv}>
                    <CapeeshLogo
                        width={300}
                        height={100}
                        onClick={() => {
                            history.push('/')
                        }}
                        style={{ cursor: 'pointer' }}
                        className={classes.capeeshLogo}
                    />
                </div>
            </div>

            <div className={classes.centerMidDiv}>
                <Typography variant="h3" component="h2" align="center">
                    <FormattedMessage id="notfound.oops" defaultMessage="Oops!" />
                </Typography>

                <Typography variant="h5" component="h3" align="center">
                    <FormattedMessage
                        id="notfound.page"
                        defaultMessage="This page appears to not exist. please check and try again."
                    />
                </Typography>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    <Button style={{ width: '150px' }} variant="contained" onClick={() => history.goBack()}>
                        <FormattedMessage id="globalwords.back" defaultMessage="Back" />
                    </Button>
                </div>
            </div>
        </div>
    )
}

NotFound.propTypes = {}

function mapStateToProps(state) {
    return {}
}

const enhance = compose(connect(mapStateToProps))

export default enhance(NotFound)

// export default NotFound;
