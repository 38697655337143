import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import compose from 'recompose/compose'

import PropTypes from 'prop-types'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import ClassroomSidebar from './Components/ClassroomSidebar'
import { usergroupActions } from '_state/actions'

import { organizationActions } from '../../../_state/actions'
import DayOfTheWeekPieChart from '../../../Components/Graphs/DayOfTheWeekPieChart'
import HourOfDayGraph from '../../../Components/Graphs/HourOfDayGraph'
import DailyUsageGraph, { DailyUsageYLabelType } from '../../../Components/Graphs/DailyUsageGraph'
import DailySessionGraph from '../../../Components/Graphs/DailySessionsGraph'
import { FormattedMessage, useIntl } from 'react-intl'
import { i18nMessages } from '../../../Logic/CodeAccessible.i18n.Messages'

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '300px',
        marginLeft: 'calc(15% + 25px)',
        marginRight: '25px',
    },
    button: {
        margin: theme.spacing(1),
    },
    graph: {
        marginTop: 20,
    },
    leaderBoardIndex: {
        background: theme.palette.primary.main,
    },
}))

const ClassroomStatistics = (props) => {
    const intl = useIntl()
    const classes = useStyles()

    const { computedMatch, dispatch, organization } = props

    const [dayOfWeekUsageStats, setCDayOfWeekUsageStats] = useState([])

    const [showDayOfWeekUsageStats, setShowDayOfWeekUsageStats] = useState(false)

    const [dailyUsageAggregated, setDailyUsageAggregated] = useState([])
    const [showDailyUsageAggregated, setShowDailyUsageAggregated] = useState(false)

    const [dailyUsage, setDailyUsage] = useState([])
    const [showDailyUsage, setShowDailyUsage] = useState(false)

    const [aggregatedSessions, setAggregatedSessions] = useState([])
    const [sessions, setSessions] = useState([])

    const [showAggregatedSessions, setShowAggregatedSessions] = useState(false)
    const [showSessions, setShowSessions] = useState(false)
    const [showLessonRelatedGraphs, setShowLessonRelatedGraphs] = useState(false)

    const [graphDataNumberOfLessonsPlayed, setGraphDataNumberOfLessonsPlayed] = useState([])
    const [graphAggregatedNumberOfLessonsPlayed, setGraphAggregatedNumberOfLessonsPlayed] = useState([])
    const [graphDataNumberOfUniqueLessonsPlayed, setGraphDataNumberOfUniqueLessonsPlayed] = useState([])
    const [graphDataAggregatedNumberOfUniqueLessonsPlayed, setGraphDataAggregatedNumberOfUniqueLessonsPlayed] =
        useState([])
    const [graphDataNumberOfCompletedLessons, setGraphDataNumberOfCompletedLessons] = useState([])
    const [graphDataAggregatedNumberOfCompletedLessons, setGraphDataAggregatedNumberOfCompletedLessons] = useState([])
    const [graphDataNumberOfFailedLessons, setGraphDataNumberOfFailedLessons] = useState([])
    const [graphDataAggregatedNumberOfFailedLessons, setGraphDataAggregatedNumberOfFailedLessons] = useState([])

    const [graphDataNumberOfCorrectAnswersInLessons, setGraphDataNumberOfCorrectAnswersInLessons] = useState([])
    const [graphDataAggregatedNumberOfCorrectAnswersInLessons, setGraphDataAggregatedNumberOfCorrectAnswersInLessons] =
        useState([])
    const [graphDataNumberOfIncorrectAnswersInLessons, setGraphDataNumberOfIncorrectAnswersInLessons] = useState([])
    const [
        graphDataAggregatedNumberOfIncorrectAnswersInLessons,
        setGraphDataAggregatedNumberOfIncorrectAnswersInLessons,
    ] = useState([])

    const [graphDataNumberOfStars, setGraphDataNumberOfStars] = useState([])
    const [graphDataAggregatedNumberOfStars, setGraphDataAggregatedNumberOfStars] = useState([])

    const [showTimeOfDayStats, setShowTimeOfDayStats] = useState(false)

    const [timeOfDayStats, setTimeOfDayStats] = useState([])
    const [timeOfDayStatsIndex, setTimeOfDayStatsIndex] = useState({})

    useEffect(() => {
        props.dispatch(
            usergroupActions.getUserGroupStats(organization.selectedOrganization, computedMatch.params.classroomId)
        )
        props.dispatch(
            organizationActions.getGraphDataDayOfWeekUserGroup(
                organization.selectedOrganization,
                computedMatch.params.classroomId
            )
        )
        props.dispatch(
            organizationActions.getGraphDataHourlyUserGroupId(
                organization.selectedOrganization,
                computedMatch.params.classroomId
            )
        )
        props.dispatch(
            organizationActions.getGraphDataDailyUsageAggregatedUserGroup(
                organization.selectedOrganization,
                computedMatch.params.classroomId
            )
        )
        props.dispatch(
            organizationActions.getGraphDataDailyLessonAggregatedUserGroup(
                organization.selectedOrganization,
                computedMatch.params.classroomId
            )
        )

        updateGraphData()
    }, [])

    useEffect(() => {
        updateGraphData()
    }, [props.organization])

    const updateGraphData = () => {
        if (
            organization.graphDataDailyUsageAggregatedUserGroup &&
            organization.graphDataDailyUsageAggregatedUserGroup.userGroupId == computedMatch.params.classroomId &&
            organization.graphDataDailyUsageAggregatedUserGroup.canShowGraphData
        ) {
            const tempDailyUsageAggregated =
                organization.graphDataDailyUsageAggregatedUserGroup.graphStatsAggregateDays.map((x) => ({
                    name: x.day,
                    value: Math.ceil(x.aggregatedMinutes / 60),
                }))
            setDailyUsageAggregated(tempDailyUsageAggregated)
            setShowDailyUsageAggregated(true)

            const tempDailyUsage = organization.graphDataDailyUsageAggregatedUserGroup.graphStatsAggregateDays.map(
                (x) => ({
                    name: x.day,
                    value: Math.ceil(x.minutes / 60),
                })
            )
            setDailyUsage(tempDailyUsage)
            setShowDailyUsage(true)

            const tempAggregatedSessions =
                organization.graphDataDailyUsageAggregatedUserGroup.graphStatsAggregateDays.map((x) => ({
                    name: x.day,
                    value: x.aggregatedNumberOfSessions,
                }))
            setAggregatedSessions(tempAggregatedSessions)
            setShowAggregatedSessions(true)

            const tempSessions = organization.graphDataDailyUsageAggregatedUserGroup.graphStatsAggregateDays.map(
                (x) => ({
                    name: x.day,
                    value: x.numberOfSessions,
                })
            )
            setSessions(tempSessions)
            setShowSessions(true)
        }

        if (
            organization.graphDataDailyLessonAggregatedUserGroup &&
            organization.graphDataDailyLessonAggregatedUserGroup.userGroupId == computedMatch.params.classroomId &&
            organization.graphDataDailyLessonAggregatedUserGroup.aggregatedLessonDailyItemDtos?.length > 0 &&
            organization.graphDataDailyLessonAggregatedUserGroup.canShowGraphData
        ) {
            setShowLessonRelatedGraphs(true)

            const tempGraphDataNumberOfLessonsPlayed =
                organization.graphDataDailyLessonAggregatedUserGroup.aggregatedLessonDailyItemDtos.map((x) => ({
                    name: x.day,
                    value: x.numberOfLessonsPlayed,
                }))
            setGraphDataNumberOfLessonsPlayed(tempGraphDataNumberOfLessonsPlayed)

            const tempGraphAggregatedNumberOfLessonsPlayed =
                organization.graphDataDailyLessonAggregatedUserGroup.aggregatedLessonDailyItemDtos.map((x) => ({
                    name: x.day,
                    value: x.aggregatedNumberOfLessonsPlayed,
                }))
            setGraphAggregatedNumberOfLessonsPlayed(tempGraphAggregatedNumberOfLessonsPlayed)

            const tempGraphDataNumberOfUniqueLessonsPlayed =
                organization.graphDataDailyLessonAggregatedUserGroup.aggregatedLessonDailyItemDtos.map((x) => ({
                    name: x.day,
                    value: x.numberOfUniqueLessonsPlayed,
                }))
            setGraphDataNumberOfUniqueLessonsPlayed(tempGraphDataNumberOfUniqueLessonsPlayed)

            const tempGraphDataAggregatedNumberOfUniqueLessonsPlayed =
                organization.graphDataDailyLessonAggregatedUserGroup.aggregatedLessonDailyItemDtos.map((x) => ({
                    name: x.day,
                    value: x.aggregatedNumberOfUniqueLessonsPlayed,
                }))
            setGraphDataAggregatedNumberOfUniqueLessonsPlayed(tempGraphDataAggregatedNumberOfUniqueLessonsPlayed)

            const tempGraphDataNumberOfCompletedLessons =
                organization.graphDataDailyLessonAggregatedUserGroup.aggregatedLessonDailyItemDtos.map((x) => ({
                    name: x.day,
                    value: x.numberOfCompletedLessons,
                }))
            setGraphDataNumberOfCompletedLessons(tempGraphDataNumberOfCompletedLessons)

            const tempGraphDataAggregatedNumberOfCompletedLessons =
                organization.graphDataDailyLessonAggregatedUserGroup.aggregatedLessonDailyItemDtos.map((x) => ({
                    name: x.day,
                    value: x.aggregatedNumberOfCompletedLessons,
                }))
            setGraphDataAggregatedNumberOfCompletedLessons(tempGraphDataAggregatedNumberOfCompletedLessons)

            const tempGraphDataNumberOfFailedLessons =
                organization.graphDataDailyLessonAggregatedUserGroup.aggregatedLessonDailyItemDtos.map((x) => ({
                    name: x.day,
                    value: x.numberOfFailedLessons,
                }))
            setGraphDataNumberOfFailedLessons(tempGraphDataNumberOfFailedLessons)

            const tempGraphDataAggregatedNumberOfFailedLessons =
                organization.graphDataDailyLessonAggregatedUserGroup.aggregatedLessonDailyItemDtos.map((x) => ({
                    name: x.day,
                    value: x.aggregatedNumberOfFailedLessons,
                }))
            setGraphDataAggregatedNumberOfFailedLessons(tempGraphDataAggregatedNumberOfFailedLessons)

            const tempGraphDataNumberOfCorrectAnswersInLessons =
                organization.graphDataDailyLessonAggregatedUserGroup.aggregatedLessonDailyItemDtos.map((x) => ({
                    name: x.day,
                    value: x.numberOfCorrectAnswersInLessons,
                }))
            setGraphDataNumberOfCorrectAnswersInLessons(tempGraphDataNumberOfCorrectAnswersInLessons)

            const tempGraphDataAggregatedNumberOfCorrectAnswersInLessons =
                organization.graphDataDailyLessonAggregatedUserGroup.aggregatedLessonDailyItemDtos.map((x) => ({
                    name: x.day,
                    value: x.aggregatedNumberOfCorrectAnswersInLessons,
                }))
            setGraphDataAggregatedNumberOfCorrectAnswersInLessons(
                tempGraphDataAggregatedNumberOfCorrectAnswersInLessons
            )

            const tempGraphDataNumberOfIncorrectAnswersInLessons =
                organization.graphDataDailyLessonAggregatedUserGroup.aggregatedLessonDailyItemDtos.map((x) => ({
                    name: x.day,
                    value: x.numberOfIncorrectAnswersInLessons,
                }))
            setGraphDataNumberOfIncorrectAnswersInLessons(tempGraphDataNumberOfIncorrectAnswersInLessons)
            const tempGraphDataAggregatedNumberOfIncorrectAnswersInLessons =
                organization.graphDataDailyLessonAggregatedUserGroup.aggregatedLessonDailyItemDtos.map((x) => ({
                    name: x.day,
                    value: x.aggregatedNumberOfIncorrectAnswersInLessons,
                }))
            setGraphDataAggregatedNumberOfIncorrectAnswersInLessons(
                tempGraphDataAggregatedNumberOfIncorrectAnswersInLessons
            )

            const tempGraphDataNumberOfStars =
                organization.graphDataDailyLessonAggregatedUserGroup.aggregatedLessonDailyItemDtos.map((x) => ({
                    name: x.day,
                    value: x.numberOfStars,
                }))
            setGraphDataNumberOfStars(tempGraphDataNumberOfStars)

            const tempGraphDataAggregatedNumberOfStars =
                organization.graphDataDailyLessonAggregatedUserGroup.aggregatedLessonDailyItemDtos.map((x) => ({
                    name: x.day,
                    value: x.aggregatedNumberOfStars,
                }))
            setGraphDataAggregatedNumberOfStars(tempGraphDataAggregatedNumberOfStars)
        }

        if (
            organization.graphDataDayOfWeekUserGroup &&
            organization.graphDataDayOfWeekUserGroup.userGroupId === computedMatch.params.classroomId &&
            organization.graphDataDayOfWeekUserGroup.canShowGraphData
        ) {
            const tempDayOfWeekUsageStats = organization.graphDataDayOfWeekUserGroup.graphStatsDayOfWeekDtos.map(
                (pie) => ({
                    name: pie.dayOfWeekShort,
                    value: pie.secondsCombined,
                })
            )
            setCDayOfWeekUsageStats(tempDayOfWeekUsageStats)
            setShowDayOfWeekUsageStats(true)
        }

        if (
            organization.graphDataHourlyUserGroup &&
            organization.graphDataHourlyUserGroup.userGroupId === computedMatch.params.classroomId &&
            organization.graphDataHourlyUserGroup.canShowGraphData
        ) {
            const tempTimeOfDayStats = organization.graphDataHourlyUserGroup.graphStatsHourlyDtos
            let tempTimeOfDayStatsIndex = {}
            tempTimeOfDayStats.forEach((el, index) => (tempTimeOfDayStatsIndex[el.hourOfTheDay] = el.minutesCombined))
            setTimeOfDayStatsIndex(tempTimeOfDayStatsIndex)
            setTimeOfDayStats(tempTimeOfDayStats)
            setShowTimeOfDayStats(true)
        }
    }

    return (
        <div className={classes.root}>
            <ClassroomSidebar userGroupId={computedMatch.params.classroomId} path={computedMatch.path} />
            <Grid container spacing={5} style={{ marginTop: '25px' }}>
                <Grid item xs={12} sm={6}>
                    <DailyUsageGraph
                        defaultHeaderMessage={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.addmoreusers"
                                defaultMessage="Add more users to see graph"
                            />
                        }
                        headerMessage={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.dailyusage.desc"
                                defaultMessage="Shows how much time the users have spent in the app"
                            />
                        }
                        toolTipYLabelType={DailyUsageYLabelType.AggreatedUsageOverTime}
                        graphData={dailyUsageAggregated}
                        canShowChart={showDailyUsageAggregated}
                        showReferenceLine={false}
                        title={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.time.spent.in.app.title"
                                defaultMessage="Time spent in app aggregated"
                            />
                        }
                        yLabel={intl.formatMessage(i18nMessages.dashboardGraphsYLabelHours)}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <DayOfTheWeekPieChart
                        dayOfWeekChartData={dayOfWeekUsageStats}
                        canShowChart={showDayOfWeekUsageStats}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <HourOfDayGraph
                        graphData={timeOfDayStats}
                        graphDataIndex={timeOfDayStatsIndex}
                        canShowChart={showTimeOfDayStats}
                        showYAxisLabel={false}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <DailyUsageGraph
                        defaultHeaderMessage={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.addmoreusers"
                                defaultMessage="Add more users to see graph"
                            />
                        }
                        headerMessage={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.dailyusage.headermessage"
                                defaultMessage="Shows how much time is spent per day"
                            />
                        }
                        toolTipYLabelType={DailyUsageYLabelType.DailyUsage}
                        graphData={dailyUsage}
                        canShowChart={showDailyUsage}
                        showReferenceLine={false}
                        title={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.dailyusage.title"
                                defaultMessage="Daily usage"
                            />
                        }
                        yLabel={intl.formatMessage(i18nMessages.dashboardGraphsYLabelHours)}
                        isMinutes={false}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <DailySessionGraph
                        defaultHeaderMessage={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.addmoreusers"
                                defaultMessage="Add more users to see graph"
                            />
                        }
                        headerMessage={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.dailysesssions.header"
                                defaultMessage="Shows how many sessions users have in the app over time"
                            />
                        }
                        graphData={aggregatedSessions}
                        canShowChart={showAggregatedSessions}
                        showReferenceLine={false}
                        title={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.dailysesssions.title"
                                defaultMessage="Sessions"
                            />
                        }
                        yLabel={intl.formatMessage(i18nMessages.dashboardGraphsYLabelSessions)}
                        isMinutes={false}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <DailySessionGraph
                        defaultHeaderMessage={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.addmoreusers"
                                defaultMessage="Add more users to see graph"
                            />
                        }
                        toolTipPrefix={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.dailysessions"
                                defaultMessage="Daily sessions"
                            />
                        }
                        graphData={sessions}
                        canShowChart={showSessions}
                        showReferenceLine={true}
                        title={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.sessionperday"
                                defaultMessage="Session per day"
                            />
                        }
                        yLabel={intl.formatMessage(i18nMessages.dashboardGraphsYLabelSessions)}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <DailyUsageGraph
                        defaultHeaderMessage={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.addmoreusers"
                                defaultMessage="Add more users to see graph"
                            />
                        }
                        toolTipPrefix={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.numberofstars"
                                defaultMessage="Number of stars"
                            />
                        }
                        graphData={graphDataAggregatedNumberOfStars}
                        canShowChart={showLessonRelatedGraphs}
                        showReferenceLine={true}
                        title={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.numberofstarsaggregated"
                                defaultMessage="Number of stars per day aggregated"
                            />
                        }
                        yLabel={intl.formatMessage(i18nMessages.dashboardGraphsYLabelStars)}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <DailyUsageGraph
                        defaultHeaderMessage={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.addmoreusers"
                                defaultMessage="Add more users to see graph"
                            />
                        }
                        toolTipPrefix={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.numberofstars"
                                defaultMessage="Number of stars"
                            />
                        }
                        graphData={graphDataNumberOfStars}
                        canShowChart={showLessonRelatedGraphs}
                        showReferenceLine={true}
                        title={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.numberofstarsperday"
                                defaultMessage="Number of stars per day"
                            />
                        }
                        yLabel={intl.formatMessage(i18nMessages.dashboardGraphsYLabelStars)}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <DailyUsageGraph
                        defaultHeaderMessage={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.addmoreusers"
                                defaultMessage="Add more users to see graph"
                            />
                        }
                        toolTipPrefix={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.numberoflessons"
                                defaultMessage="Number of lessons"
                            />
                        }
                        graphData={graphDataNumberOfLessonsPlayed}
                        canShowChart={showLessonRelatedGraphs}
                        showReferenceLine={false}
                        title={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.lessonsplayedperday"
                                defaultMessage="Lessons played per day"
                            />
                        }
                        yLabel={intl.formatMessage(i18nMessages.dashboardGraphsYLabelLessons)}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <DailyUsageGraph
                        defaultHeaderMessage={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.addmoreusers"
                                defaultMessage="Add more users to see graph"
                            />
                        }
                        headerMessage={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.dailylessons.header"
                                defaultMessage="Shows lessons have been played over time"
                            />
                        }
                        toolTipYLabelType={DailyUsageYLabelType.NumberOfLessons}
                        graphData={graphAggregatedNumberOfLessonsPlayed}
                        canShowChart={showLessonRelatedGraphs}
                        showReferenceLine={false}
                        title={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.dailylessons.title"
                                defaultMessage="Lessons played"
                            />
                        }
                        yLabel={intl.formatMessage(i18nMessages.dashboardGraphsYLabelLessons)}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <DailyUsageGraph
                        defaultHeaderMessage={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.addmoreusers"
                                defaultMessage="Add more users to see graph"
                            />
                        }
                        toolTipPrefix={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.uniquelessons"
                                defaultMessage="Unique lessons"
                            />
                        }
                        graphData={graphDataNumberOfUniqueLessonsPlayed}
                        canShowChart={showLessonRelatedGraphs}
                        showReferenceLine={false}
                        title={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.uniquelessonsperdy"
                                defaultMessage="Unique lessons per day"
                            />
                        }
                        yLabel={intl.formatMessage(i18nMessages.dashboardGraphsYLabelLessons)}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <DailyUsageGraph
                        defaultHeaderMessage={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.addmoreusers"
                                defaultMessage="Add more users to see graph"
                            />
                        }
                        toolTipPrefix={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.uniquelessons"
                                defaultMessage="Unique lessons"
                            />
                        }
                        graphData={graphDataAggregatedNumberOfUniqueLessonsPlayed}
                        canShowChart={showLessonRelatedGraphs}
                        showReferenceLine={false}
                        title={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.uniquelessonsaggregated"
                                defaultMessage="Unique lessons per day aggregated"
                            />
                        }
                        yLabel={intl.formatMessage(i18nMessages.dashboardGraphsYLabelLessons)}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <DailyUsageGraph
                        defaultHeaderMessage={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.addmoreusers"
                                defaultMessage="Add more users to see graph"
                            />
                        }
                        toolTipPrefix={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.completedlessons"
                                defaultMessage="Completed lessons"
                            />
                        }
                        graphData={graphDataNumberOfCompletedLessons}
                        canShowChart={showLessonRelatedGraphs}
                        showReferenceLine={false}
                        title={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.numberofcompletedlessons"
                                defaultMessage="Number of completed lessons"
                            />
                        }
                        yLabel={intl.formatMessage(i18nMessages.dashboardGraphsYLabelLessons)}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <DailyUsageGraph
                        defaultHeaderMessage={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.addmoreusers"
                                defaultMessage="Add more users to see graph"
                            />
                        }
                        toolTipPrefix={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.completedlessons"
                                defaultMessage="Completed lessons"
                            />
                        }
                        graphData={graphDataAggregatedNumberOfCompletedLessons}
                        canShowChart={showLessonRelatedGraphs}
                        showReferenceLine={false}
                        title={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.numberofcompletedlessonsaggregated"
                                defaultMessage="Number of completed lesson aggregated"
                            />
                        }
                        yLabel={intl.formatMessage(i18nMessages.dashboardGraphsYLabelLessons)}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <DailyUsageGraph
                        defaultHeaderMessage={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.addmoreusers"
                                defaultMessage="Add more users to see graph"
                            />
                        }
                        toolTipPrefix={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.failedlessons"
                                defaultMessage="Failed lessons"
                            />
                        }
                        graphData={graphDataNumberOfFailedLessons}
                        canShowChart={showLessonRelatedGraphs}
                        showReferenceLine={false}
                        title={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.numberoffailedlessons"
                                defaultMessage="Number of failed lesson"
                            />
                        }
                        yLabel={intl.formatMessage(i18nMessages.dashboardGraphsYLabelLessons)}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <DailyUsageGraph
                        defaultHeaderMessage={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.addmoreusers"
                                defaultMessage="Add more users to see graph"
                            />
                        }
                        toolTipPrefix={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.failedlessons"
                                defaultMessage="Failed lessons"
                            />
                        }
                        graphData={graphDataAggregatedNumberOfFailedLessons}
                        canShowChart={showLessonRelatedGraphs}
                        showReferenceLine={false}
                        title={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.numberoffailedlessonsaggregated"
                                defaultMessage="Number of failed lesson aggregated"
                            />
                        }
                        yLabel={intl.formatMessage(i18nMessages.dashboardGraphsYLabelLessons)}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <DailyUsageGraph
                        defaultHeaderMessage={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.addmoreusers"
                                defaultMessage="Add more users to see graph"
                            />
                        }
                        toolTipPrefix={
                            <FormattedMessage id="dashboard.classroom.graphs.answers" defaultMessage="Answers" />
                        }
                        graphData={graphDataNumberOfCorrectAnswersInLessons}
                        canShowChart={showLessonRelatedGraphs}
                        showReferenceLine={false}
                        title={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.num.correct.answers"
                                defaultMessage="Number of correct answers"
                            />
                        }
                        yLabel={intl.formatMessage(i18nMessages.dashboardGraphsYLabelAnswers)}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <DailyUsageGraph
                        defaultHeaderMessage={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.addmoreusers"
                                defaultMessage="Add more users to see graph"
                            />
                        }
                        toolTipPrefix={
                            <FormattedMessage id="dashboard.classroom.graphs.answers" defaultMessage="Answers" />
                        }
                        graphData={graphDataAggregatedNumberOfCorrectAnswersInLessons}
                        canShowChart={showLessonRelatedGraphs}
                        showReferenceLine={false}
                        title={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.num.correct.answers.aggregated"
                                defaultMessage="Number of correct answers aggregated"
                            />
                        }
                        yLabel={intl.formatMessage(i18nMessages.dashboardGraphsYLabelAnswers)}
                    />
                </Grid>
            </Grid>
        </div>
    )
}

function mapStateToProps(state) {
    const { organization, usergroup, user } = state
    return {
        user,
        organization,
        usergroup,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}
export default connect(mapStateToProps, mapDispatchToProps)(ClassroomStatistics)
