import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core'
import UnityLessonPreview, { CapeeshWebState } from '../Scenarios/UnityLessonPreview/UnityLessonPreview'
import LessonPreview from '../../../Components/LessonPreview'

const useStyles = makeStyles((theme) => ({}))

const CapeeshWebView = ({ student, previewHeight, previewWidth, showBorder }) => {
    const classes = useStyles()

    const emptyGuid = '00000000-0000-0000-0000-000000000000'
    const AllSettings = [
        'Email',
        'UserName',
        'Password',
        'Language',
        'Music',
        'Sounds',
        'Vibration',
        'AudioAll',
        'Feedback',
        'PushNotification',
        'PrivacyPolicy',
        'GeneralAll',
        'TestingMode',
        'SignOut',
        'AppVersion',
    ]

    const SettingsFilter = { SettingsToFilter: ['Language', 'Vibration', 'PushNotification', 'SignOut'] }

    const CheckForUrlParams = () => {
        const urlParams = new URLSearchParams(window.location.search)

        console.log(urlParams)

        return {
            CourseGroupId: urlParams.get('coursegroup') ? urlParams.get('coursegroup') : emptyGuid,
            TopicId: urlParams.get('topic') ? urlParams.get('topic') : '',
            CustomLessonId: urlParams.get('lesson') ? urlParams.get('lesson') : emptyGuid,
            L1Id: student?.userDashboardLanguageId ? student?.userDashboardLanguageId : '',
        }
    }

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                alignSelf: 'center',
            }}>
            <LessonPreview
                buildName={'CapeeshWeb_Build_0.0.7'}
                basePath={'/build/unityFull/'}
                previewHeight={previewHeight}
                previewWidth={previewWidth}
                useBorder={showBorder}
                webType={CapeeshWebState.CapeeshWebStudent}
                dashboardLanguage={student?.userDashboardLanguageId}
                urlReloadCheck={CheckForUrlParams}
                settingsFilter={SettingsFilter}
            />
        </div>
    )
}

CapeeshWebView.propTypes = {}

const mapStateToProps = (state) => {
    return { student: state.student }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(CapeeshWebView)
