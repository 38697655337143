import { FormattedMessage } from 'react-intl'
import { Dashboard } from '@material-ui/icons'
import React from 'react'
import StudentDashboard from '../Views/StudentPrivate/StudentDashboard'

const studentPrivateRoutes = [
    {
        path: '/student',
        name: <FormattedMessage id="globalwords.dashboard" defaultMessage="Student Pag" />,
        id: 'Dashboard',
        icon: Dashboard,
        component: StudentDashboard,
        acceptRoles: ['STUDENT'],
    },
]

export default studentPrivateRoutes
