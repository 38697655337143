import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import { CapeeshColors } from '../../../../assets/ColorPalette'

const useStyles = makeStyles((theme) => ({
    baseContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: 32,
        backgroundColor: CapeeshColors.OffWhite,
        borderRadius: 16,
        gap: 16,
        height: 'auto',
        width: 'initial',
    },
    topText: {
        color: CapeeshColors.Primary900,
        fontFamily: 'Rubik',
        fontsize: 16,
        fontStyle: 'normal',
        fontWeight: 500,
    },
    topContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 16,
    },
}))

const DashboardGraphBox = ({ graphComponent, title, topRightButton }) => {
    const classes = useStyles()

    return (
        <div className={classes.baseContainer}>
            <div className={classes.topContainer}>
                <div className={classes.topText}>{title}</div>
                {topRightButton}
            </div>
            {graphComponent}
        </div>
    )
}

DashboardGraphBox.propTypes = {}

const mapStateToProps = (state) => {
    return {
        organization: state.organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardGraphBox)
