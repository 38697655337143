import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { CircularProgress, makeStyles, MenuItem, Select } from '@material-ui/core'
import Dialog from '@mui/material/Dialog'
import xImage from '../../../assets/images/Navigation.png'
import { CapeeshColors } from '../../../assets/ColorPalette'
import TextFieldWithLanguageSelector from './ScenarioComponents/TextFieldWithLanguageSelector'
import { LessonType, ScenarioL1L2SelectorOptions } from '../../../Logic/ScenarioConstants'
import Grid from '@mui/material/Grid'
import Typography from '@material-ui/core/Typography'
import Slider from '@mui/material/Slider'
import { Button } from '@mui/material'
import { e } from 'mathjs'

const useStyles = makeStyles((theme) => ({
    modalContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: 32,
        gap: 32,
        width: 467,
    },
    closeIcon: {
        width: 24,
        height: 24,
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        alignSelf: 'flex-end',
        marginRight: 16,
        marginTop: 16,
        display: 'flex',
        cursor: 'pointer',
        position: 'absolute',
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginBottom: 16,
    },
    titleText: {
        alignSelf: 'stretch',
        color: CapeeshColors.Gray900,
        textAlign: 'center',
        fontFamily: 'Rubik',
        fontSize: 16,
        fontStyle: 'normal',
        fontWeight: 400,
        letterSpacing: 0.64,
        textTransform: 'uppercase',
    },
    categoryContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
    },
    subCategoryContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    l1ChooserStyle: {
        marginLeft: '20px',
        paddingLeft: '10px',
        paddingRight: '10px',
        width: '60px',
        height: '60px',
        border: '1px solid #143349',
        boxSizing: 'border-box',
        borderRadius: '10px',
        maxWidth: '7px',
        maxHeight: '40px',
        minWidth: '78px',
        minHeight: '40px',
    },
    largeChooserStyle: {
        paddingLeft: '10px',
        paddingRight: '10px',
        width: '100%',
        height: '60px',
        border: '1px solid #143349',
        boxSizing: 'border-box',
        borderRadius: '10px',
        maxHeight: '40px',
        minWidth: '78px',
        minHeight: '40px',
    },
    bottomContainer: {
        display: 'flex',
        paddingTop: 16,
        justifyContent: 'flex-end',
        alignItems: 'center',
        gap: 8,
        alignSelf: 'stretch',
        borderTop: `1px solid ${CapeeshColors.Grey200}`,
    },
}))

const LessonOptionsModal = (
    {
        isOpen,
        onClose,
        onLanguageForName,
        onLanguageForShortDescription,
        selectedScenario,
        onChangeLessonType,
        subtitleTypes,
        onChangeExamPassRate,
        onSaveScenarioOptions,
        savingScenarioOptions,
        lesson,
        onChangeLessonPassRate,
        onSubtitleType,
        onScenarioNameChange,
        onUpdateScenarioShortDescriptionChange,
    },
    props
) => {
    const classes = useStyles()

    const examDefaultValue = 60
    const lessonDefaultValue = [50, 75, 95]

    const [name, setName] = useState(selectedScenario.name)
    const [description, setDescription] = useState(selectedScenario.shortDescription)
    const [lessonStars, setLessonStars] = useState([
        selectedScenario.lessonScoringDynamicConfigurationV1Dto.lessonStarThresholds[0] * 100,
        selectedScenario.lessonScoringDynamicConfigurationV1Dto.lessonStarThresholds[1] * 100,
        selectedScenario.lessonScoringDynamicConfigurationV1Dto.lessonStarThresholds[2] * 100,
    ])
    const [examStars, setExamStars] = useState(
        selectedScenario.lessonScoringDynamicConfigurationV1Dto.lessonStarThresholds[0] * 100
    )

    const handleSetExamValues = (e) => {
        setExamStars(e.target.value)
    }

    const handleSetLessonValues = (e) => {
        setLessonStars(e.target.value)
    }

    const handleSaveScenarioOptions = () => {
        if (selectedScenario.shortDescription !== description) {
            onUpdateScenarioShortDescriptionChange(description)
        }
        if (selectedScenario.name !== name) {
            onScenarioNameChange(name)
        }
        onSaveScenarioOptions()
    }

    const handleLanguageForShortDescription = (event) => {
        if (event.target.value === ScenarioL1L2SelectorOptions.L1) {
            onLanguageForShortDescription(selectedScenario.l1Id)
        }
        if (event.target.value === ScenarioL1L2SelectorOptions.L2) {
            onLanguageForShortDescription(selectedScenario.languageId)
        }
    }
    const handleLanguageForName = (event) => {
        if (event.target.value === ScenarioL1L2SelectorOptions.L1) {
            onLanguageForName(selectedScenario.l1Id)
        }
        if (event.target.value === ScenarioL1L2SelectorOptions.L2) {
            onLanguageForName(selectedScenario.languageId)
        }
    }

    return (
        <div>
            <Dialog open={isOpen} onClose={onClose}>
                <img onClick={() => onClose()} src={xImage} className={classes.closeIcon} />
                <div className={classes.modalContainer}>
                    <div className={classes.titleContainer}>
                        <div className={classes.titleText}>Edit Lesson Properties</div>
                    </div>
                    <div className={classes.categoryContainer}>
                        What is the lesson called? (required)
                        <div className={classes.subCategoryContainer}>
                            <TextFieldWithLanguageSelector
                                fullWidth={true}
                                text={name}
                                onChange={(e) => setName(e.target.value)}
                                style={{
                                    borderRadius: '10px',
                                    paddingRight: '5px',
                                }}
                                onChangeLanguageLanguage={(event) => handleLanguageForName(event)}
                                l2Id={selectedScenario.languageId}
                                l1Id={selectedScenario.l1Id}
                                canChangeLanguage={true}
                                displayL1OrL2={
                                    selectedScenario?.nameLanguageId === selectedScenario?.languageId
                                        ? ScenarioL1L2SelectorOptions.L2
                                        : ScenarioL1L2SelectorOptions.L1
                                }
                            />
                        </div>
                    </div>
                    <div className={classes.categoryContainer}>
                        Give a short description of the lesson (required)
                        <div className={classes.subCategoryContainer}>
                            <TextFieldWithLanguageSelector
                                fullWidth={true}
                                text={description}
                                onChange={(e) => setDescription(e.target.value)}
                                style={{
                                    borderRadius: '10px',
                                    paddingRight: '5px',
                                }}
                                onChangeLanguageLanguage={(event) => handleLanguageForShortDescription(event)}
                                l2Id={selectedScenario?.languageId}
                                l1Id={selectedScenario?.l1Id}
                                canChangeLanguage={true}
                                displayL1OrL2={
                                    selectedScenario?.shortDescriptionLanguageId === selectedScenario?.languageId
                                        ? ScenarioL1L2SelectorOptions.L2
                                        : ScenarioL1L2SelectorOptions.L1
                                }
                            />
                        </div>
                    </div>
                    <div className={classes.categoryContainer}>
                        Lesson Type (scoring)
                        <Select
                            className={classes.largeChooserStyle}
                            disableUnderline
                            value={selectedScenario.lessonType ? selectedScenario.lessonType : LessonType.NotDefined}
                            autoWidth
                            onChange={async (e) => {
                                if (selectedScenario.lessonType === LessonType.NotDefined) {
                                    setExamStars(examDefaultValue)
                                    onChangeExamPassRate(examDefaultValue)
                                } else if (selectedScenario.lessonType === LessonType.Exam) {
                                    setLessonStars(lessonDefaultValue)
                                    onChangeLessonPassRate(lessonDefaultValue)
                                }

                                onChangeLessonType(e.target.value)
                            }}
                            disabled={!lesson.canEditLesson}>
                            <MenuItem value={LessonType.NotDefined}>Normal</MenuItem>
                            <MenuItem value={LessonType.Roleplay}>RolePlay</MenuItem>
                            <MenuItem value={LessonType.Exam}>Exam</MenuItem>
                        </Select>
                        {selectedScenario.lessonType !== LessonType.Roleplay && (
                            <div>
                                <div className={classes.scenario}>
                                    <Typography
                                        variant="body1"
                                        className={classes.optionsTitle}
                                        style={{
                                            width: '300px',
                                        }}>
                                        {`${selectedScenario.lessonType === LessonType.Exam ? 'Exam' : 'Lesson'} pass rate (%)`}
                                    </Typography>
                                </div>
                                <div className={classes.scenario}>
                                    <Slider
                                        style={{ marginTop: 38 }}
                                        disabled={!lesson.canEditLesson}
                                        aria-label="Temperature"
                                        value={
                                            selectedScenario.lessonType === LessonType.Exam ? examStars : lessonStars
                                        }
                                        onChange={
                                            selectedScenario.lessonType === LessonType.Exam
                                                ? handleSetExamValues
                                                : handleSetLessonValues
                                        }
                                        valueLabelDisplay="on"
                                        step={5}
                                        marks
                                        min={0}
                                        max={100}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <div className={classes.categoryContainer}>
                        Subtitle settings for listen stages
                        <Select
                            className={classes.largeChooserStyle}
                            disableUnderline
                            value={!selectedScenario.subtitleType ? 0 : selectedScenario.subtitleType}
                            autoWidth
                            onChange={(e) => onSubtitleType(e.target.value)}
                            disabled={!lesson.canEditLesson}>
                            {subtitleTypes?.map((subtitleDescription, index) => {
                                switch (index) {
                                    case 1:
                                        var l1Language = props.metadata?.metadata?.languages?.find(
                                            (x) => x.id === selectedScenario?.l1Id
                                        )
                                        if (l1Language != null) {
                                            return (
                                                <MenuItem value={index}>
                                                    {subtitleDescription.replace('L1', l1Language.name)}
                                                </MenuItem>
                                            )
                                        }
                                        break

                                    case 2:
                                        var l2Language = props.metadata?.metadata?.languages?.find(
                                            (x) => x.id === selectedScenario?.languageId
                                        )
                                        if (l2Language != null) {
                                            return (
                                                <MenuItem value={index}>
                                                    {subtitleDescription.replace('L2', l2Language.name)}
                                                </MenuItem>
                                            )
                                        }
                                        break
                                }

                                return <MenuItem value={index}>{subtitleDescription}</MenuItem>
                            })}
                        </Select>
                    </div>
                    <div className={classes.bottomContainer}>
                        <Button size="large" onClick={() => onClose()}>
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            size="large"
                            onClick={async () => {
                                selectedScenario.lessonType === LessonType.Exam
                                    ? await onChangeExamPassRate(examStars)
                                    : await onChangeLessonPassRate(lessonStars)
                                handleSaveScenarioOptions()
                            }}
                            disabled={!lesson.canEditLesson}>
                            Save changes {savingScenarioOptions && <CircularProgress />}
                        </Button>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

LessonOptionsModal.propTypes = {}

const mapStateToProps = (state) => {
    return {
        organization: state.organization,
        metadata: state.metadata,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(LessonOptionsModal)
