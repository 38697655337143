import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import { CapeeshColors } from '../../../../assets/ColorPalette'
import { FormattedMessage } from 'react-intl'
import { Button } from '@mui/material'

const useStyles = makeStyles((theme) => ({
    baseContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: 32,
        backgroundColor: CapeeshColors.OffWhite,
        borderRadius: 16,
        gap: 16,
        width: '100%',
    },
    topText: {
        color: CapeeshColors.Primary900,
        fontFamily: 'Rubik',
        fontsize: 16,
        fontStyle: 'normal',
        fontWeight: 500,
    },
    shotCutsContainer: {
        gap: 8,
        display: 'flex',
        flexDirection: 'column',
    },
}))

const ShortcutBox = ({ shortcuts }) => {
    const classes = useStyles()

    const Shortcut = ({ text, icon, onClickFunction, type = 'Normal' }) => {
        const shortcutStyle = {
            width: '100%',
            height: 'fit-content',
            backgroundColor: CapeeshColors.Gray5,
            padding: '12px 16px 12px 12px',
            fontSize: 16,
            fontWeight: 400,
            fontFamily: 'Rubik',
            fontStyle: 'normal',
            textTransform: 'none',
            color: onClickFunction !== null ? CapeeshColors.Primary900 : CapeeshColors.Primary600,
            lineHeight: '140%',
            display: 'flex',
            justifyContent: 'flex-start',
        }

        return (
            <React.Fragment>
                {onClickFunction !== null ? (
                    <Button sx={shortcutStyle} onClick={() => onClickFunction()} startIcon={icon}>
                        {text}
                    </Button>
                ) : (
                    <Button disabled={true} sx={shortcutStyle} startIcon={icon}>
                        {text}
                    </Button>
                )}
            </React.Fragment>
        )
    }

    return (
        <div className={classes.baseContainer}>
            <div className={classes.topText}>
                <FormattedMessage id="dashboard.shortcutBox.topText" defaultMessage="Shortcuts" />
            </div>
            <div className={classes.shotCutsContainer}>
                {shortcuts.map((shortcut, index) => (
                    <Shortcut
                        key={`shortcut-${index}`}
                        text={shortcut.text}
                        icon={shortcut.icon}
                        onClickFunction={shortcut.onClickFunction}
                    />
                ))}
            </div>
        </div>
    )
}

ShortcutBox.propTypes = {}

const mapStateToProps = (state) => {
    return {
        organization: state.organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ShortcutBox)
