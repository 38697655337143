import { history } from '_state/helpers'

const protectedQueryParams = ['coursegroup', 'topic', 'lesson']

export const StudentMonoPageSubPages = {
    PlayCapeeshApp: 0,
    Profile: 1,
    AppOnboarding: 2,
}

export const switchToStudentSubPage = (subPageType) => {
    const location = window.location

    const rootPath = '/student'

    const previousSearchParams = new URLSearchParams(location.search)

    const currentSearchParams = []

    for (const protectedQueryParam of protectedQueryParams) {
        const queryParam = previousSearchParams.get(protectedQueryParam)
        if (queryParam) {
            currentSearchParams.push(`${protectedQueryParam}=${queryParam}`)
        }
    }
    switch (subPageType) {
        case StudentMonoPageSubPages.Profile:
            currentSearchParams.push('showStudentProfile=true')
            break
        case StudentMonoPageSubPages.AppOnboarding:
            currentSearchParams.push('showAppOnboarding=true')
    }
    if (currentSearchParams.length > 0) {
        const joinedSearchParams = currentSearchParams.join('&')
        history.push(`${rootPath}?${joinedSearchParams}`)
        return
    }

    history.push(rootPath)
}
