import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { usergroupActions, snackbarActions } from '_state/actions'
import MaterialTable from 'material-table'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled'
import Typography from '@material-ui/core/Typography'
import CardContent from '@material-ui/core/CardContent'
import Card from '@material-ui/core/Card'
import { makeStyles } from '@material-ui/core'
import CardHeader from '@material-ui/core/CardHeader'
import Grid from '@material-ui/core/Grid'
import EmailInvite from 'Components/EmailInvite'
import { organizationActions } from '../../../_state/actions'
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import MultipleStudentAddModal from '../../../Components/MultipleStudentAddModal/MultipleStudentAddModal'

const useStyles = makeStyles((theme) => ({
    inviteNewStudentsButton: {
        width: '600px',
        height: '120px',
        margin: '0px auto',
        marginTop: '15px',
        padding: '15px',
        display: 'grid',
        alignContent: 'center',
    },
    inviteButton: {
        float: 'center',
        clear: 'both',
        width: '180px',
        margin: '0px auto',
        display: 'grid',
        alignContent: 'center',
    },
}))

const ClassroomInvite = (props) => {
    const classes = useStyles()
    const { organization, usergroup, metadata, computedMatch, usergroupId, user } = props
    const [inviteEmailsOpen, setInviteEmailsOpen] = useState(false)
    const [usersToInvite, setUsersToInvite] = useState([])

    const handleTableSelectionUpdated = (userIds) => {
        setUsersToInvite(userIds)
    }

    const handleInviteEmailsOpen = () => {
        setInviteEmailsOpen(true)
    }

    const handleInviteEmailsClose = () => {
        setInviteEmailsOpen(false)
    }

    const handleInviteClicked = (id) => {
        let classroomId = computedMatch.params.classroomId
        props.addUserGroupUsers(organization.selectedOrganization, classroomId, [id])
    }

    let classRoomId = computedMatch.params.classroomId

    let availableUsers = organization.organizationUsers

    if (user.organizationRole !== 'admin') {
        availableUsers = availableUsers.filter(
            (x) => !x.email.includes('@itsoundsgood.no') && !x.email.includes('@capeesh.com')
        )
    }

    if (usergroup.userGroupUsers && !props.usergroupId) {
        // do not perform search if classroom is being created
        availableUsers = availableUsers.filter((e) => !usergroup.userGroupUsers.find((x) => x.id === e.id))
    }

    let currentOrganization

    if (organization.myOrganizations && organization.selectedOrganization)
        currentOrganization = organization.myOrganizations.find((x) => x.id === organization.selectedOrganization)

    let users = []
    availableUsers &&
        availableUsers.map((user, index) => {
            users.push({
                id: user.id,
                email: user.email,
                name: user.name,
                l1: metadata.metadata?.languages?.find((x) => x.id === user.l1Id).name,
                l2: metadata.metadata?.languages?.find((x) => x.id === user.l2Id).name,
                roles: user.roles.join(', '),
                isStudent: user.roles.includes('Student'),
                isAdmin: user.roles.includes('Admin'),
                isTranslator: user.roles.includes('Translator'),
            })
        })
    let currentUserGroup = usergroup.usergroups.find((x) => x.id === computedMatch.params.classroomId)

    var canAddMoreLicenses = true
    if (!currentOrganization.canAddMoreLicenses) {
        canAddMoreLicenses = false
    } else if (currentUserGroup.maxLicenses !== 0 && currentUserGroup.numberOfUsers >= currentUserGroup.maxLicenses) {
        canAddMoreLicenses = false
    }

    return (
        <div style={{ padding: '20px' }}>
            {/*<EmailInvite*/}
            {/*    inviteEmailsOpen={inviteEmailsOpen}*/}
            {/*    handleInviteEmailsClose={handleInviteEmailsClose}*/}
            {/*    userGroupId={classRoomId}*/}
            {/*    title={*/}
            {/*        <FormattedMessage*/}
            {/*            id="dashboard.classroom.students.invitemodal.title"*/}
            {/*            defaultMessage="Invite new students"*/}
            {/*        />*/}
            {/*    }*/}
            {/*    description={*/}
            {/*        <FormattedMessage*/}
            {/*            id="dashboard.classroom.students.invitemodal.description"*/}
            {/*            defaultMessage="Enter emails for user to invite. Use comma to separate and add more than one."*/}
            {/*        />*/}
            {/*    }*/}
            {/*    availableRoles={['Student']}*/}
            {/*/>*/}
            <MultipleStudentAddModal
                inviteEmailsOpen={inviteEmailsOpen}
                onInviteStudentsToSchool={(addStudentRows, role) => console.log('inviting', addStudentRows, role)}
                title={
                    <FormattedMessage
                        id="dashboard.classroom.students.invitemodal.title"
                        defaultMessage="Invite new students"
                    />
                }
                description={
                    <FormattedMessage
                        id="dashboard.classroom.students.invitemodal.description"
                        defaultMessage="Enter emails for user to invite. Use tab key to navigate to the next field. You can paste in space or comma separated lists (eg. from a Excel column). The name field will only be shown in the dashboard and not shared with the user."
                    />
                }
                role={'Student'}
            />

            <Grid container justify="center" spacing={3}>
                <Grid item xs={12}>
                    {!canAddMoreLicenses && (
                        <div className={classes.inviteNewStudentsButton}>
                            <PersonAddDisabledIcon
                                style={{
                                    marginLeft: '255',
                                    marginTop: '10px',
                                    marginBottom: '15px',
                                    height: '70px',
                                    width: '70px',
                                }}
                            />

                            <Typography style={{ fontSize: '18px' }} align="center">
                                <FormattedMessage
                                    id="dashboard.classroom.students.warning.nolicenses"
                                    defaultMessage="You have used up all your licenses"
                                />
                            </Typography>
                            <Typography style={{ fontSize: '16px' }} align="center">
                                <FormattedMessage
                                    id="dashboard.classroom.students.warning.getmorelicenses"
                                    defaultMessage="Contact us at sales@capeesh.com or call sales at +47 92409439 if you want to increase your license subscription"
                                />
                            </Typography>
                        </div>
                    )}
                    {canAddMoreLicenses && (
                        <div className={classes.inviteNewStudentsButton}>
                            <PersonAddIcon
                                style={{
                                    marginLeft: '255',
                                    marginTop: '15px',
                                    marginBottom: '15px',
                                    height: '70px',
                                    width: '70px',
                                }}
                            />
                            <Typography style={{ fontSize: '18px' }} align="center">
                                <FormattedMessage
                                    id="dashboard.classroom.students.invitenewstudents.email"
                                    defaultMessage="Invite new students by email"
                                />
                            </Typography>
                            <Typography style={{ fontSize: '16px' }} align="center">
                                <FormattedMessage
                                    id="dashboard.classroom.students.invitenewstudents.description"
                                    defaultMessage="They will receive an email once is done, with instructions on how to get started."
                                />
                            </Typography>
                        </div>
                    )}
                </Grid>
                {canAddMoreLicenses && (
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            className={classes.inviteButton}
                            onClick={handleInviteEmailsOpen}
                            color={'primary'}>
                            <FormattedMessage
                                id="dashboard.classroom.students.invitenewstudents.button"
                                defaultMessage="Invite students"
                            />
                        </Button>
                    </Grid>
                )}

                {canAddMoreLicenses && user.organizationRole === 'admin' && (
                    <Grid item xs={12}>
                        <Card>
                            <CardHeader
                                title={
                                    <FormattedMessage
                                        id="dashboard.classroom.students.list.addfromorganization.title"
                                        defaultMessage="Add from organization"
                                    />
                                }
                                subheader={
                                    <FormattedMessage
                                        id="dashboard.classroom.students.list.addfromorganization.description"
                                        defaultMessage="These are users that not currently in the classroom."
                                    />
                                }
                            />
                            <CardContent>
                                <MaterialTable
                                    title=""
                                    columns={[
                                        { title: 'Name', field: 'name', defaultSort: 'asc' },
                                        { title: 'Email', field: 'email' },
                                        { title: 'Native Language', field: 'l1' },
                                        { title: 'Learning Language', field: 'l2' },
                                        { title: 'Roles', field: 'roles' },
                                    ]}
                                    data={users}
                                    options={{}}
                                    actions={[
                                        {
                                            icon: PersonAddIcon,
                                            tooltip: 'Add student',
                                            onClick: (event, rowData) => handleInviteClicked(rowData.id),
                                        },
                                    ]}
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                )}
            </Grid>
        </div>
    )
}

ClassroomInvite.propTypes = {}

const mapoProps = (state) => {
    return {
        organization: state.organization,
        usergroup: state.usergroup,
        metadata: state.metadata,
        user: state.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        enqueueSnackbar: (notification) => dispatch(snackbarActions.enqueueSnackbar(notification)),
        addUserGroupUsers: (orgId, usergroupId, userIds) =>
            dispatch(usergroupActions.addUserGroupUsers(orgId, usergroupId, userIds)),
    }
}

export default connect(mapoProps, mapDispatchToProps)(ClassroomInvite)
