import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Grid, Typography, makeStyles } from '@material-ui/core'
import { organizationActions } from '_state/actions'
import { UserStatisticsVisibility } from '../CapeeshConstants'
import OrganizationOverviewSidebar from './Components/OrganizationOverviewSidebar'
import OrganizationClassroomList from './Components/OrganizationClassroomList'
import SimpleFactBox from '../../../Components/Factbox/SimpleFactBox'
import LicenseBox from './Components/LicenseBox'
import OrganizationCourseUsageList from './Components/OrganizationCourseUsageList'
import { CapeeshColors, HexToColorWithAlpha } from '../../../assets/ColorPalette'
import { FormattedMessage, useIntl } from 'react-intl'
import ShortcutBox from './Components/ShortcutBox'
import { ReactComponent as PlusIcon } from '../../../assets/images/BluePlusIcon.svg'
import { ReactComponent as PaperPlaneIcon } from '../../../assets/images/PaperPlaneIcon.svg'
import DashboardGraphBox from './Components/DashboardGraphBox'
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import moment from 'moment/moment'
import { Button } from '@mui/material'
import { ReactComponent as RightArrowIcon } from '../../../assets/images/RightArrowIcon.svg'
import { history } from '../../../_state/helpers'
import AddStudentsToClassroomModal from './Components/AddStudentsToClassroomModal'
import CreateNewClassroomDialog from '../../../Components/CreateNewClassroomDialog/CreateNewClassroomDialog'
import { i18nMessages } from '../../../Logic/CodeAccessible.i18n.Messages'

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '300px',
        marginLeft: 'calc(15% + 25px)',
        marginRight: '25px',
    },
    main: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
    },
    mainSection: {
        marginBottom: '20px',
    },
    mainContainer: {
        display: 'flex',
        gap: 16,
        flexDirection: 'column',
    },
    rowContainer: {
        display: 'flex',
        flexDirection: 'row',
        gap: 16,
    },
}))

const OrganizationDashboard = (props) => {
    const classes = useStyles()
    const intl = useIntl()
    const [showAddStudentsOption, setShowAddStudentsOption] = useState(false)
    const [canCreateNewClassroom, setCanCreateNewClassroom] = useState(false)

    const [currentOrganization, setCurrentOrganization] = useState(null)

    const [dayOfWeekUsageStats, setDayOfWeekUsageStats] = useState([])
    const [showDayOfWeekUsageStats, setShowDayOfWeekUsageStats] = useState(false)

    const [showTimeOfDayStats, setShowTimeOfDayStats] = useState(false)
    const [timeOfDayStats, setTimeOfDayStats] = useState([])
    const [timeOfDayStatsIndex, setTimeOfDayStatsIndex] = useState({})

    const [numberOfHoursPlayedText, setNumberOfHoursPlayedText] = useState('')
    const [numberOfHoursPlayedChangedText, setNumberOfHoursPlayedChangedText] = useState('')

    const [numberOfLessonsPassedText, setNumberOfLessonsPassed] = useState('')
    const [numberOfLessonsPassedChangedText, setNumberOfLessonsPassedChangedText] = useState('')

    const [numberOfOnboardedUsersText, setNumberOfOnboardedUsersText] = useState('')
    const [numberOfOnboardedUsersChangedText, setNumberOfOnboardedUsersChangedText] = useState('0')

    const [dailyUsage, setDailyUsage] = useState(null)
    const [completedLessonsAggregated, setCompletedLessonsAggregated] = useState(null)

    const [openInviteStudentModal, setOpenInviteStudentModal] = useState(false)

    // create new classroom
    const [openCreateNewClassroom, setOpenCreateNewClassroom] = useState(false)

    useEffect(() => {
        props.dispatch(organizationActions.getOrganizationFactBoxStats(props.organization.selectedOrganization))
        props.dispatch(
            organizationActions.getGraphDataDailyUsageAggregatedOrganization(props.organization.selectedOrganization)
        )
        props.dispatch(
            organizationActions.getGraphDataDailyLessonAggregatedOrganization(props.organization.selectedOrganization)
        )
        recalculateViewData()
    }, [])

    useEffect(() => {
        recalculateViewData()
    }, [props.organization])

    const handleGetUsageData = () => {
        const tempDailyUsage = props.organization.graphDataDailyUsageAggregatedOrg?.graphStatsAggregateDays.map(
            (x) => ({
                name: x.day,
                value: Math.ceil(x.aggregatedMinutes / 60),
            })
        )

        setDailyUsage(tempDailyUsage)
    }

    const handleGetCompletedLessonsData = () => {
        const tempGraphDataAggregatedNumberOfCompletedLessons =
            props.organization.graphDataDailyLessonAggregatedOrg?.aggregatedLessonDailyItemDtos.map((x) => ({
                name: x.day,
                value: x.aggregatedNumberOfCompletedLessons,
            }))

        setCompletedLessonsAggregated(tempGraphDataAggregatedNumberOfCompletedLessons)
    }

    const recalculateViewData = () => {
        let tempCurrentOrganization

        if (props.organization.myOrganizations && props.organization.selectedOrganization)
            tempCurrentOrganization = props.organization.myOrganizations.find(
                (x) => x.id === props.organization.selectedOrganization
            )

        setCurrentOrganization(tempCurrentOrganization)

        let tempDayOfWeekUsageStats = []
        let tempShowDayOfWeekUsageStats = false
        if (
            props.organization.graphDataDayOfWeekOrganization &&
            props.organization.graphDataDayOfWeekOrganization.organizationId ===
                props.organization.selectedOrganization &&
            props.organization.graphDataDayOfWeekOrganization.canShowGraphData
        ) {
            tempDayOfWeekUsageStats = props.organization.graphDataDayOfWeekOrganization.graphStatsDayOfWeekDtos.map(
                (pie) => ({
                    name: pie.dayOfWeekShort,
                    value: pie.secondsCombined,
                })
            )
            tempShowDayOfWeekUsageStats = true
        }
        setShowDayOfWeekUsageStats(tempShowDayOfWeekUsageStats)
        setDayOfWeekUsageStats(tempDayOfWeekUsageStats)

        handleGetUsageData()
        handleGetCompletedLessonsData()

        let tempShowTimeOfDayStats = false
        let tempTimeOfDayStats = []
        let tempTimeOfDayStatsIndex = {}
        if (
            props.organization.graphDataHourlyOrganization &&
            props.organization.graphDataHourlyOrganization.organizationId === props.organization.selectedOrganization &&
            props.organization.graphDataHourlyOrganization.canShowGraphData
        ) {
            tempTimeOfDayStats = props.organization.graphDataHourlyOrganization.graphStatsHourlyDtos
            tempTimeOfDayStats.forEach((el, index) => (timeOfDayStatsIndex[el.hourOfTheDay] = el.minutesCombined))
            tempShowTimeOfDayStats = true
        }
        setShowTimeOfDayStats(tempShowTimeOfDayStats)
        setTimeOfDayStats(tempTimeOfDayStats)
        setTimeOfDayStatsIndex(tempTimeOfDayStatsIndex)

        let tempShowAddStudentsOption = false
        if (props.user.organizationRole === 'admin' || props.user.organizationRole === 'teacher') {
            tempShowAddStudentsOption = true
        } else if (props.user.organizationRole === 'reporting') {
            if (
                !props.user.classroomOnlyReportingUser &&
                tempCurrentOrganization?.userStatisticsVisibility === UserStatisticsVisibility.SchoolMode
            ) {
                tempShowAddStudentsOption = true
            } else if (props.user.classroomOnlyReportingUser) {
                tempShowAddStudentsOption = true
            }
        }
        setShowAddStudentsOption(tempShowAddStudentsOption)

        let tempCanCreateNewClassroom = false
        if (
            props.user.organizationRole === 'admin' ||
            (props.user.organizationRole === 'teacher' && tempCurrentOrganization?.customerCreateNewClassroom)
        ) {
            tempCanCreateNewClassroom = true
        } else if (
            tempCurrentOrganization?.customerCreateNewClassroom &&
            props.user.organizationRole === 'reporting' &&
            !props.user.classroomOnlyReportingUser
        ) {
            tempCanCreateNewClassroom = true
        }
        setCanCreateNewClassroom(tempCanCreateNewClassroom)

        const organizationId = props.organization.selectedOrganization
        if (props.organization.organizationIdToFactBoxStats?.hasOwnProperty(organizationId)) {
            const factBoxData = props.organization.organizationIdToFactBoxStats[props.organization.selectedOrganization]

            if (factBoxData.sessionMinutes > 60000) {
                const kHoursPlayed = factBoxData.sessionMinutes / 60 / 1000
                setNumberOfHoursPlayedText(kHoursPlayed.toFixed(0) + 'K')
            } else {
                const hoursPlayed = factBoxData.sessionMinutes / 60
                setNumberOfHoursPlayedText(hoursPlayed.toFixed(0))
            }
            var numbHoursPlayedChanged = factBoxData.newSessionMinutesLastThirtyDays / 60
            setNumberOfHoursPlayedChangedText(numbHoursPlayedChanged.toFixed(1))

            if (factBoxData.numberOfLessonsPassed > 2000) {
                setNumberOfLessonsPassed(factBoxData.numberOfLessonsPassed / 1000 + 'K')
            } else {
                setNumberOfLessonsPassed(factBoxData.numberOfLessonsPassed.toString())
            }
            setNumberOfLessonsPassedChangedText(factBoxData.newLessonsPassedLastThirtyDays)

            setNumberOfOnboardedUsersText(factBoxData.numberOfOnboardedUsers.toString())
            setNumberOfOnboardedUsersChangedText(factBoxData.newNumberOfOnboardUsersLastThirtyDays.toString())
        }
    }

    const onCreateNewCourse = () => {
        history.push('/creator/')
    }

    const onCreateNewClassroom = () => {
        setOpenCreateNewClassroom(true)
        // history.push('/dashboard/new-classroom/')
    }

    const onInviteStudent = () => {
        setOpenInviteStudentModal(true)
    }

    const handleShowAllStatistics = () => {
        history.push('/dashboard/statistics/')
    }

    const handleAddStudentsToClassroom = (userDtos, classroomId) => {
        console.log('selectedClassroom', classroomId)
        console.log('userDtos', userDtos)

        props.dispatch(
            organizationActions.inviteStudentUserDtoToSchool(
                props.organization.selectedOrganization,
                userDtos,
                ['Student'],
                classroomId
            )
        )
        setOpenInviteStudentModal(false)
    }

    const getCurrentShortCutOptions = () => {
        const shortcuts = [
            {
                text: (
                    <FormattedMessage
                        id="dashboard.organization.shortcuts.create.new.course"
                        defaultMessage="Create new course"
                    />
                ),
                icon: <PlusIcon style={{ color: CapeeshColors.Primary600 }} />,
                onClickFunction: onCreateNewCourse,
            },
            ,
        ]
        let canCreateClassroom = false
        if (props.organization.myOrganizations && props.organization.selectedOrganization) {
            const tempCurrentOrganization = props.organization.myOrganizations.find(
                (x) => x.id === props.organization.selectedOrganization
            )
            if (tempCurrentOrganization && tempCurrentOrganization.customerCreateNewClassroom) {
                canCreateClassroom = true
            }
        }
        if (canCreateClassroom) {
            shortcuts.push({
                text: (
                    <FormattedMessage
                        id="dashboard.organization.shortcuts.create.new.classroom"
                        defaultMessage="Create a new classroom"
                    />
                ),
                icon: <PlusIcon style={{ color: CapeeshColors.Primary600 }} />,
                onClickFunction: onCreateNewClassroom,
            })
        } else {
            shortcuts.push({
                text: (
                    <FormattedMessage
                        id="dashboard.organization.shortcuts.contact.support.create.new.classroom"
                        defaultMessage="Contact support to create classroom"
                    />
                ),
                icon: <PlusIcon style={{ color: CapeeshColors.DisabledColor }} />,
                onClickFunction: null,
            })
        }

        const hasClassrooms = props.usergroup.usergroups?.length > 0
        if (hasClassrooms) {
            shortcuts.push({
                text: (
                    <FormattedMessage
                        id="dashboard.organization.shortcuts.invite.student"
                        defaultMessage="Invite student"
                    />
                ),
                icon: <PaperPlaneIcon style={{ color: CapeeshColors.Primary600 }} />,
                onClickFunction: onInviteStudent,
            })
        } else {
            shortcuts.push({
                text: (
                    <FormattedMessage
                        id="dashboard.organization.create.classrooms.to.intivte.students"
                        defaultMessage="Create classrooms to invite students"
                    />
                ),
                icon: <PaperPlaneIcon style={{ color: CapeeshColors.DisabledColor }} />,
                onClickFunction: null,
            })
        }
        return shortcuts
    }

    const DashboardGraph = ({ data, label }) => {
        return (
            <ResponsiveContainer height={200} width={300}>
                <AreaChart data={data} margin={{ left: 10 }}>
                    <defs>
                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={CapeeshColors.Green} stopOpacity={0.8} />
                            <stop offset="95%" stopColor={CapeeshColors.Green} stopOpacity={0} />
                        </linearGradient>
                    </defs>
                    <XAxis
                        dataKey="name"
                        tickFormatter={(tick) => moment(tick).format('MMM Do')}
                        angle={-10}
                        dy={5}></XAxis>
                    <YAxis dataKey="value" label={{ value: label, angle: -90, position: 'left' }}></YAxis>
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip />
                    <Area
                        type="monotone"
                        dataKey="value"
                        stackId="1"
                        strokeWidth={2}
                        stroke={CapeeshColors.Green}
                        fill={'url(#colorUv)'}
                    />
                </AreaChart>
            </ResponsiveContainer>
        )
    }

    return (
        <div className={classes.root} style={{ marginTop: '25px' }}>
            {openInviteStudentModal && (
                <AddStudentsToClassroomModal
                    onClose={() => setOpenInviteStudentModal(false)}
                    open={openInviteStudentModal}
                    classrooms={props.usergroup.usergroups}
                    onAddStudentsToClassroom={handleAddStudentsToClassroom}
                />
            )}
            {openCreateNewClassroom && (
                <CreateNewClassroomDialog
                    open={openCreateNewClassroom}
                    dispatch={props.dispatch}
                    onCloseDialog={() => setOpenCreateNewClassroom(false)}
                />
            )}
            <OrganizationOverviewSidebar path={props.computedMatch?.path} />
            <div>
                <div>
                    <div className={classes.mainContainer}>
                        <Grid
                            items
                            xs={12}
                            style={{
                                marginBottom: '0px',
                                marginLeft: '20px',
                                marginTop: '25px',
                            }}>
                            <Grid item xs={12}>
                                <Typography variant="h4">
                                    <FormattedMessage
                                        id={'globalwords.dahboard'}
                                        defaultMessage={'Dashboard'}></FormattedMessage>
                                </Typography>
                            </Grid>
                        </Grid>

                        <div className={classes.rowContainer} style={{ height: 150 }}>
                            <SimpleFactBox
                                title={
                                    <FormattedMessage
                                        id="globalwords.hoursspentintheapp"
                                        defaultMessage="Hours spent in the app"
                                    />
                                }
                                statsText={numberOfHoursPlayedText}
                                changeText={numberOfHoursPlayedChangedText}
                                changeDescription={
                                    <FormattedMessage id="globalwords.last30days" defaultMessage="Last 30 days" />
                                }
                                backgroundColor={CapeeshColors.Primary600}
                                textColor={'white'}
                                trendColor={'white'}
                                trendColorBackground={CapeeshColors.Primary400}
                            />
                            <SimpleFactBox
                                title={
                                    <FormattedMessage id="globalwords.passedlessons" defaultMessage="Passed lessons" />
                                }
                                statsText={numberOfLessonsPassedText}
                                changeText={numberOfLessonsPassedChangedText}
                                changeDescription={
                                    <FormattedMessage id="globalwords.last30days" defaultMessage="Last 30 days" />
                                }
                                backgroundColor={CapeeshColors.OffWhite}
                                trendColorBackground={HexToColorWithAlpha(CapeeshColors.Green, 0.2)}
                            />

                            <LicenseBox />
                        </div>

                        <div className={classes.rowContainer}>
                            <DashboardGraphBox
                                title={
                                    <FormattedMessage
                                        id="dashboard.dashboarGraphBox.topText"
                                        defaultMessage="Hours spent in the app"
                                    />
                                }
                                topRightButton={
                                    <Button
                                        onClick={handleShowAllStatistics}
                                        variant="text"
                                        endIcon={<RightArrowIcon />}>
                                        <FormattedMessage
                                            id="dashboard.dashboarGraphBox.statisticsButton"
                                            defaultMessage="View all statistics"
                                        />
                                    </Button>
                                }
                                graphComponent={
                                    <DashboardGraph
                                        data={dailyUsage}
                                        label={intl.formatMessage(i18nMessages.dashboardGraphsYLabelHours)}
                                    />
                                }
                            />
                            <DashboardGraphBox
                                title={
                                    <FormattedMessage
                                        id="dashboard.dashboarGraphBox.aggregated.lessons.completed"
                                        defaultMessage="Number of completed lesson aggregated"
                                    />
                                }
                                graphComponent={
                                    <DashboardGraph
                                        data={completedLessonsAggregated}
                                        label={intl.formatMessage(i18nMessages.dashboardGraphsYLabelLessons)}
                                    />
                                }
                            />
                            <ShortcutBox shortcuts={getCurrentShortCutOptions()} />
                        </div>

                        <div className={classes.rowContainer} style={{ marginBottom: 16 }}>
                            <OrganizationCourseUsageList dispatch={props.dispatch}></OrganizationCourseUsageList>
                            <OrganizationClassroomList
                                showAddStudentsOption={showAddStudentsOption}
                                dispatch={props.dispatch}
                                canCreateNewClassroom={canCreateNewClassroom}
                                minimizedView={true}
                                showGoToAllClassrooms={true}
                                showHeader={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    const { user, organization, usergroup, translator, metadata, lesson, topic } = state
    return {
        user,
        organization,
        usergroup,
        translator,
        metadata,
        lesson,
        topic,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationDashboard)
