import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { Button, makeStyles, Typography } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import { usergroupActions } from '../../../../_state/actions'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { CapeeshColors } from '../../../../assets/ColorPalette'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { history } from '_state/helpers'

const useStyles = makeStyles((theme) => ({
    ccard: {
        marginBottom: '5px',
    },
    marginEmptyText: {
        margin: '20px',
    },

    classroomCard: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '16px',
        padding: '24px',
        alignSelf: 'stretch',
        borderRadius: '16px',
        backgroundColor: CapeeshColors.OffWhite,
        width: '100%',
    },

    cardHeader: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },

    cardTitle: {
        display: 'flex',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '500',
        // letterSpacing: '0.1em',
        color: CapeeshColors.DarkText,
        // textAlign: 'left',
        fontFamily: 'Rubik',
        textTransform: 'none',
        justifyContent: 'flex-start',
        lineHeight: '24px',
    },
    cardAction: {
        display: 'flex',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '500',
        fontFamily: 'Rubik',
        color: CapeeshColors.Primary600,
        // textAlign: 'right',

        textTransform: 'none',
        justifyContent: 'flex-end',
    },

    tableHeaderCell: {
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        fontFamily: 'Rubik',
    },

    tableRow: {
        display: 'flex',
        paddingBottom: '8px',
        alignItems: 'flex-start',
        gap: '8px',
        alignSelf: 'stretch',
    },

    tableRowCellName: {
        color: CapeeshColors.Primary600,
        fontSize: '14px',
        fontStyle: 'normal',
        fontFamily: 'Rubik',
        fontWeight: 500,
        textTransform: 'none',
    },
}))

const OrganizationClassroomList = (props) => {
    // expected props:
    // props.showAddStudentsOption
    // props.dispatch
    // props.canCreateNewClassroom
    // props.minimizedView
    // props.showGoToAllClassrooms
    // props.showHeader
    const classes = useStyles()

    const [userGroups, setUserGroups] = useState([])
    const [numberOfUserGroupsNotShown, setNumberOfUserGroupsNotShown] = useState(0)
    const formatSecondsForClassroom = (seconds) => {
        if (seconds === 0) {
            return <FormattedMessage id="dashboard.classroom.card.noactivity" defaultMessage="No activity" />
        }
        const min = seconds / 60
        const hours = min / 60
        const rhours = Math.floor(hours)
        const minutes = (hours - rhours) * 60
        const rminutes = Math.round(minutes)

        let secondString = seconds + ' ' + 'sec'

        if (seconds < 60) return secondString

        let minutesString = rminutes + ' min'

        if (rhours === 0) {
            return minutesString
        }

        let minutesString2 = rminutes + ' min'

        let rhoursString = rhours + ' h'

        return rhoursString + ' ' + minutesString2
    }

    useEffect(() => {
        props.dispatch(usergroupActions.getAllUserGroupsForOrganisation(props.organization.selectedOrganization))
    }, [])

    useEffect(() => {
        if (props.usergroup.usergroups?.length > 0) {
            const allUserGroups = props.usergroup.usergroups.sort((a, b) => a.name.localeCompare(b.name))
            if (props.minimizedView && allUserGroups.length > 5) {
                const tempMinimizedUserGroups = allUserGroups.slice(0, 5)
                setUserGroups(tempMinimizedUserGroups)
                let userGroupsHidden = allUserGroups.length - 5
                if (userGroupsHidden < 0) {
                    userGroupsHidden = 0
                }
                setNumberOfUserGroupsNotShown(userGroupsHidden)
            } else {
                setUserGroups(allUserGroups)
                setNumberOfUserGroupsNotShown(0)
            }
        } else {
            setUserGroups([])
        }
    }, [props.usergroup])

    const handleGoToAllClassrooms = () => {
        history.push('/dashboard/classrooms/')
    }
    const handleGoToSingleClassroom = (userGroupId) => {
        history.push('/dashboard/classroom/' + userGroupId)
    }

    return (
        <div className={classes.classroomCard}>
            {props.showHeader && (
                <div className={classes.cardHeader}>
                    <Typography className={classes.cardTitle}>
                        <FormattedMessage id="dashboard.classroom.title" defaultMessage="Classrooms" />
                    </Typography>

                    {props.showGoToAllClassrooms && (
                        <Button
                            onClick={() => handleGoToAllClassrooms()}
                            variant={'text'}
                            className={classes.cardAction}>
                            <FormattedMessage
                                id="dashboard.classroomlist.viewallclassrooms"
                                defaultMessage="View all classrooms"
                            />
                            <ArrowForwardIosIcon
                                sx={{
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    marginTop: '6px',
                                    marginLeft: '8px',
                                    color: CapeeshColors.Primary600,
                                }}
                            />
                        </Button>
                    )}
                </div>
            )}

            <TableContainer>
                <Table
                    sx={{
                        minWidth: 400,
                        backgroundColor: CapeeshColors.OffWhite,
                    }}
                    size="small"
                    aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell width="40%">
                                <Typography className={classes.tableHeaderCell}>
                                    <FormattedMessage
                                        id="dashboard.classroom.table.classroom"
                                        defaultMessage="Classroom"
                                    />
                                </Typography>
                            </TableCell>
                            <TableCell width="20%" align="right">
                                <Typography className={classes.tableHeaderCell}>
                                    <FormattedMessage
                                        id="dashboard.classroom.table.students"
                                        defaultMessage="Students"
                                    />
                                </Typography>
                            </TableCell>
                            <TableCell width="20%" align="right">
                                <Typography className={classes.tableHeaderCell}>
                                    <FormattedMessage
                                        id="dashboard.classroom.table.apptime"
                                        defaultMessage="App time"
                                    />
                                </Typography>
                            </TableCell>
                            <TableCell width="20%" align="right">
                                <Typography className={classes.tableHeaderCell}>
                                    <FormattedMessage id="dashboard.classroom.table.courses" defaultMessage="Courses" />
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {userGroups.map((userGroup, index) => (
                            <TableRow
                                key={userGroup.id}
                                onClick={() => handleGoToSingleClassroom(userGroup.id)}
                                sx={{
                                    '&:last-child td, &:last-child th': {
                                        border: 0,
                                    },
                                    backgroundColor: index % 2 === 0 ? CapeeshColors.Gray5 : CapeeshColors.OffWhite,
                                    height: '50px',
                                    cursor: 'pointer',
                                }}>
                                <TableCell width="40%" component="th" scope="row">
                                    <Typography className={classes.tableRowCellName}>{userGroup.name}</Typography>
                                </TableCell>
                                <TableCell width="20%" align="right">
                                    {userGroup.numberOfUsers}
                                </TableCell>
                                <TableCell width="20%" align="right">
                                    {formatSecondsForClassroom(userGroup.appUsageSeconds)}
                                </TableCell>
                                <TableCell width="20%" align="right">
                                    {userGroup.numberOfCourseGroups}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <div
                style={{
                    display: 'flex',
                    alignItems: 'left',
                    width: '100%',
                }}>
                {numberOfUserGroupsNotShown > 0 && (
                    <Typography
                        style={{
                            fontFamily: 'Rubik',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            color: CapeeshColors.Gray1,
                            textAlign: 'left',
                            justifyContent: 'flex-start',
                            flexGrow: 1,
                            minWidth: '200px',
                            marginLeft: '20px',
                        }}>
                        + {numberOfUserGroupsNotShown}{' '}
                        <FormattedMessage
                            id="dashboard.classroom.table.xmorecourses"
                            defaultMessage="more classrooms"
                        />
                    </Typography>
                )}
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    const { organization, usergroup, user } = state
    return {
        organization,
        usergroup,
        user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationClassroomList)
