import React, { useEffect, useState } from 'react'
import { Link, useLocation, useRouteMatch } from 'react-router-dom'

import {
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Menu,
    MenuItem,
    Button,
    Avatar,
    makeStyles,
    Grid,
} from '@material-ui/core'
import { AccountCircle } from '@material-ui/icons'

import { ReactComponent as CapeeshLogo } from 'assets/images/capeesh_logo.svg'
import { ReactComponent as CapeeshIcon } from 'assets/images/capeesh_icon.svg'

import { connect } from 'react-redux'

import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/styles'
import Select from '@material-ui/core/Select'
import { studentActions } from '../../../_state/actions'

import { CapeeshColors } from '../../../assets/ColorPalette'

import { StudentMonoPageSubPages, switchToStudentSubPage } from 'Logic/Student/StudentQueryParamParser'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        // height: '98px',
        top: 0,
        position: 'fixed',
        zIndex: 100,
    },
    appBar: {
        position: 'relative',
        padding: '0px 20px 0px 20px',
        //boxShadow: "0px 8px 16px 0px rgba(0, 0, 0, 0.08)",
    },
    toolBar: {
        padding: '0px',
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    title: {
        display: 'none',
        justifyContent: 'center',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    capeeshLogo: {
        position: 'relative',
        height: '64px',
        width: '150px',
        cursor: 'pointer',
        transition: 'all 150ms',
        boxShadow: 'none',
        fill: theme.palette.primary.contrastText,
    },
    capeeshIcon: {
        position: 'relative',
        height: '42px',
        width: '42px',
        cursor: 'pointer',
        transition: 'all 150ms',
        boxShadow: 'none',
        overflow: 'visible',
        fill: theme.palette.primary.contrastText,
    },
    logoButton: {
        height: '64px',
        padding: '0',
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0)',
        },
    },
    username: {
        margin: 'auto 5px',
        color: theme.palette.primary.contrastText,
    },

    dropdownMenu: {
        marginTop: 35,
    },
    profileButton: {
        borderRadius: '5px',
    },
    breadcrumbs: {
        position: 'relative',
        padding: '5px 20px 5px 20px',
    },
    breadcrumb: {
        letterSpacing: '0.41px',
        textTransform: 'none',
        color: 'inherit',
        alignItems: 'center',
        display: 'flex',
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
    languageSelectorItem: {
        display: 'flex',
        alignItems: 'center',
    },
}))

const MenuOptionType = {
    Logout: 0,
    Link: 1,
    QueryParam: 2,
}

const menuOptionsStudents = [
    { type: MenuOptionType.QueryParam, subPageType: StudentMonoPageSubPages.PlayCapeeshApp, text: 'Play Capeesh App' },
    { type: MenuOptionType.QueryParam, subPageType: StudentMonoPageSubPages.AppOnboarding, text: 'App onboarding' },
    { type: MenuOptionType.QueryParam, subPageType: StudentMonoPageSubPages.Profile, text: 'Profile' },
    { type: MenuOptionType.Logout, link: '/student/login', text: 'Sign out' },
]

const StudentTopBar = (props) => {
    const { user } = props
    const classes = useStyles()
    const location = useLocation()

    let studentMainPageMatch = useRouteMatch({
        path: '/student',
        strict: true,
        sensitive: true,
    })

    const [anchorEl, setAnchorEl] = useState(null)
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null)

    const theme = useTheme()
    const smDownMatch = useMediaQuery(theme.breakpoints.down('sm'))

    const pathNames = location.pathname.split('/').filter((x) => x)
    const isMenuOpen = Boolean(anchorEl)
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const [isScrolled, setIsScrolled] = useState(false)

    const handleScroll = () => {
        // Set isScrolled true if scroll position is more than 0
        const scrolled = window.scrollY > 20
        setIsScrolled(scrolled)
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)

        // Cleanup the listener when component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    const handleMenuClose = () => {
        setAnchorEl(null)
        handleMobileMenuClose()
    }

    const handleMenuItemClicked = (item) => {
        setAnchorEl(null)
        handleMobileMenuClose()

        if (item.type === MenuOptionType.QueryParam) {
            // maybe need too keep around some of the query params here
            switchToStudentSubPage(item.subPageType)
        }
    }

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget)
    }

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null)
    }

    let menuOption = menuOptionsStudents

    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
            className={classes.dropdownMenu}>
            {menuOption.map((option, index) => (
                <MenuItem
                    key={index}
                    id={index}
                    onClick={() => handleMenuItemClicked(option)}
                    component={option.link ? Link : ''}
                    to={option.link}>
                    {option.text}
                </MenuItem>
            ))}
        </Menu>
    )

    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
            className={classes.dropdownMenu}>
            {menuOption.map((option, index) => (
                <MenuItem
                    key={index}
                    id={index}
                    onClick={() => handleMenuItemClicked(option)}
                    component={option.link ? Link : ''}
                    to={option.link}>
                    {option.text}
                </MenuItem>
            ))}
        </Menu>
    )
    const changeLocalizedLanguage = (e) => {
        props.dispatch(studentActions.setStudentDashboardLanguage(e.target.value))
    }
    return (
        <div style={{ height: '60px' }} className={classes.root}>
            <AppBar
                className={classes.appBar}
                style={{
                    background: CapeeshColors.Primary900,
                    boxShadow: 'none',
                }}>
                <Toolbar className={classes.toolBar}>
                    <Button
                        component={Link}
                        to="/student"
                        disableFocusRipple={true}
                        disableRipple={true}
                        className={classes.logoButton}>
                        {smDownMatch ? (
                            <CapeeshIcon width={42} height={42} className={classes.capeeshIcon} />
                        ) : (
                            <CapeeshLogo width={150} height={64} className={classes.capeeshLogo} />
                        )}
                    </Button>

                    <div className={classes.grow} />
                    <Select
                        value={props.student?.userDashboardLanguageId ? props.student?.userDashboardLanguageId : 1}
                        style={{
                            width: '180px',
                            height: '40px',
                            backgroundColor: 'white',
                            borderRadius: theme.shape.borderRadius,
                        }}
                        // autoWidth
                        onChange={changeLocalizedLanguage}>
                        <MenuItem value={1} key={1}>
                            <Grid container direction="row" className={classes.languageSelectorItem}>
                                <Grid item xs={4}>
                                    <Typography style={{ fontSize: '32px', marginLeft: '5px' }}>
                                        <img width="30" src="https://flagcdn.com/64x48/gb.png"></img>
                                    </Typography>
                                </Grid>
                                <Grid item xs={8} style={{}}>
                                    <Typography style={{ width: '120px', textAlign: 'left', marginLeft: '1px' }}>
                                        English
                                    </Typography>
                                </Grid>
                            </Grid>
                        </MenuItem>

                        <MenuItem value={3} key={3}>
                            <Grid container direction="row" className={classes.languageSelectorItem}>
                                <Grid item xs={4}>
                                    <Typography style={{ fontSize: '32px', marginLeft: '5px' }}>
                                        <img width="30" src="https://flagcdn.com/64x48/no.png" />
                                    </Typography>
                                </Grid>
                                <Grid item xs={8} style={{}}>
                                    <Typography style={{ width: '120px', textAlign: 'left', marginLeft: '1px' }}>
                                        Norsk bokmål
                                    </Typography>
                                </Grid>
                            </Grid>
                        </MenuItem>

                        <MenuItem value={6} key={6}>
                            <Grid container direction="row" className={classes.languageSelectorItem}>
                                <Grid item xs={4}>
                                    <Typography style={{ fontSize: '32px', marginLeft: '5px' }}>
                                        <img width="30" src="https://flagcdn.com/64x48/de.png" />
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography style={{ width: '120px', textAlign: 'left', marginLeft: '1px' }}>
                                        Deutsch
                                    </Typography>
                                </Grid>
                            </Grid>
                        </MenuItem>
                    </Select>

                    <div className={classes.sectionDesktop}>
                        <IconButton
                            aria-owns={isMenuOpen ? 'material-appbar' : null}
                            aria-haspopup="true"
                            className={classes.profileButton}
                            onClick={handleProfileMenuOpen}
                            color="inherit"
                            disableRipple>
                            <Typography className={classes.username} color="primary">
                                {props?.userInfo?.name}
                            </Typography>
                            <AccountCircle />
                        </IconButton>
                    </div>
                    <div className={classes.sectionMobile}>
                        <IconButton aria-haspopup="true" onClick={handleMobileMenuOpen} color="inherit">
                            {props.student?.userinfo?.profilePictureUrl ? (
                                <Avatar
                                    alt={props.student?.userInfo?.name}
                                    src={props.student?.userInfo?.profilePictureUrl}
                                />
                            ) : (
                                <AccountCircle />
                            )}
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            {renderMenu}
            {renderMobileMenu}
        </div>
    )
}

StudentTopBar.propTypes = {}

const mapStateToProps = (state) => {
    const { student, studentAuth } = state
    return {
        student: student,
        studentAuth: studentAuth,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(StudentTopBar)
