import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'

import { makeStyles } from '@material-ui/core'
import { CapeeshColors } from '../../../../assets/ColorPalette'
import { FormattedMessage } from 'react-intl'

import { Button } from '@mui/material'
import { ReactComponent as CourseGroupTopicsEmptyGraphic } from './CourseGroupTopicsEmptyGraphic.svg'
import { AddOutlined } from '@material-ui/icons'
import { CapeeshSizes as CapeesSizes } from '../../../../assets/SizePresets'

const useStyles = makeStyles((theme) => ({
    main: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        paddingTop: '32px',
        paddingBottom: '32px',
        gap: '64px',
        borderRadius: '16px',
        background: 'white',
        marginLeft: CapeesSizes.CreatorSideBar,
        marginRight: CapeesSizes.CreatorSideBar,
        width: 'calc(100% - 400px)',
        [theme.breakpoints.down('sm')]: {
            marginLeft: '0px',
            width: '100%',
        },
    },
    mainTitle: {
        color: CapeeshColors.Gray900,
        fontFamily: 'Rubik',
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: '500',
    },
    subTitle: {
        color: CapeeshColors.Gray900,
        fontFamily: 'Rubik',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: '500',
        textAlign: 'center',
    },
    description: {
        color: CapeeshColors.Gray900,
        fontFamily: 'Rubik',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '400',
        textAlign: 'center',
    },
    button: {
        color: 'white',
        background: CapeeshColors.Primary600,
        height: '40px',
        marginTop: '16px',
    },
    emptyGraphic: {
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        display: 'flex',
    },
}))

const CourseGroupTopicsEmpty = ({ onCreateTopic }) => {
    const classes = useStyles()

    return (
        <div className={classes.main}>
            <div className={classes.emptyGraphic}>
                <CourseGroupTopicsEmptyGraphic style={{ marginBottom: 16, alignSelf: 'center' }} />
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px',
                    justifyContent: 'center',
                    alignItems: 'left',
                }}>
                <Typography className={classes.mainTitle}>
                    <FormattedMessage
                        id="create.course.group.topics.empty.title"
                        defaultMessage={'Create your topic'}
                    />
                </Typography>
                <Typography className={classes.subTitle}>
                    <FormattedMessage
                        id="create.course.group.topics.empty.subtitle"
                        defaultMessage={'No topic created yet in this course.'}
                    />
                </Typography>

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'left' }}>
                    <Button
                        className={classes.button}
                        style={{
                            textTransform: 'none',
                            maxWidth: '200px',
                        }}
                        variant={'contained'}
                        onClick={() => onCreateTopic()}>
                        <AddOutlined
                            sx={{
                                marginRight: '15px',
                                fontSize: '15px',
                                color: 'white',
                            }}
                        />
                        <FormattedMessage
                            id="create.topic.lesson.order.button.new.topic.title"
                            defaultMessage={'Create new topic'}
                        />
                    </Button>
                </div>
            </div>
        </div>
    )
}

CourseGroupTopicsEmpty.propTypes = {}

const mapStateToProps = (state) => {
    return {
        organization: state.organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseGroupTopicsEmpty)
