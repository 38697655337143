// @material-ui/icons
import LibraryBooks from '@material-ui/icons/LibraryBooks'
import BubbleChart from '@material-ui/icons/BubbleChart'
import LocationOn from '@material-ui/icons/LocationOn'

// Public views
import LoginView from 'Views/Public/LoginPage'
import StudentForgotPassword from 'Views/Public/StudentForgotPassword'
import ForgotView from 'Views/Public/ForgotPassword'
import NotFoundView from 'Views/Public/NotFound'

import StudentLoginPage from 'Views/Public/StudentLoginPage'

const publicRoutes = [
    {
        path: '/login',
        name: 'Login',
        icon: LibraryBooks,
        component: LoginView,
    },
    {
        path: '/register',
        name: 'Register',
        icon: BubbleChart,
        component: LoginView,
    },
    {
        path: '/forgot',
        name: 'Forgot',
        icon: LibraryBooks,
        component: ForgotView,
    },
    {
        path: '/404',
        name: 'Icons',
        icon: LocationOn,
        component: NotFoundView,
    },
    {
        path: '/student/login',
        name: 'Student Login',
        icon: LibraryBooks,
        component: StudentLoginPage,
    },
    {
        path: '/student/register',
        name: 'Student Register',
        icon: BubbleChart,
        component: StudentLoginPage,
    },
    {
        path: '/student/forgot',
        name: 'Forgot',
        icon: LibraryBooks,
        component: StudentForgotPassword,
    },
    {
        path: '/student/404',
        name: 'Icons',
        icon: LocationOn,
        component: NotFoundView,
    },
]

export default publicRoutes
