import { call, put, takeLatest } from 'redux-saga/effects'
import { usergroupConstants, topicConstants, organizationConstants } from '../actions'
import { usergroupService, topicService, organizationService } from '../services'
import { history } from '../helpers'
import { snackbarActions } from '../actions/snackbar.actions'

export function* createUserGroup(data) {
    try {
        const response = yield call(usergroupService.createUserGroup, data.payload)
        yield put({ type: usergroupConstants.GET_ALL_USERGROUPS.REQUEST, payload: data.payload })
        yield put({ type: usergroupConstants.CREATE_USERGROUP.SUCCESS, data: response.data })

        try {
            const newUserGroup = response.data
            if (newUserGroup && newUserGroup.id) {
                history.push('/dashboard/classroom/' + newUserGroup.id + '/students')
            } else {
                history.push('/dashboard/classrooms')
            }
        } catch (error) {
            console.log('failed to forward to new classroom', error)
        }
    } catch (e) {
        yield put(
            snackbarActions.enqueueSnackbar({
                message: 'We were unable to create the classroom. Please try again or contact support.',
                options: {
                    variant: 'warning',
                },
            })
        )
        yield put({ type: usergroupConstants.CREATE_USERGROUP.FAILURE, message: e.message })
    }
}

export function* editUserGroup(data) {
    try {
        const response = yield call(usergroupService.editUserGroup, data.payload)
        //TODO. MORTEN fix history push
        history.push('/cohorts')
        yield put({ type: usergroupConstants.EDIT_USERGROUP.SUCCESS, data: response.data })
        yield put({ type: usergroupConstants.GET_ALL_USERGROUPS.REQUEST, payload: data.payload })
    } catch (e) {
        yield put(
            snackbarActions.enqueueSnackbar({
                message: 'We were unable to edit the cohort. Please try again or contact support.',
                options: {
                    variant: 'warning',
                },
            })
        )
        yield put({ type: usergroupConstants.CREATE_USERGROUP.FAILURE, message: e.message })
    }
}

export function* deleteUserGroup(data) {
    try {
        const response = yield call(usergroupService.deleteUserGroup, data.payload.userGroupId)
        history.push('/dashboard/')
        yield put({ type: usergroupConstants.DELETE_USERGROUP.SUCCESS, data: response.data })
        yield put({ type: usergroupConstants.GET_ALL_USERGROUPS.REQUEST, payload: data.payload })
        yield put(
            snackbarActions.enqueueSnackbar({
                message: 'The classroom has been deleted.',
                options: {
                    variant: 'success',
                },
            })
        )
    } catch (e) {
        yield put(
            snackbarActions.enqueueSnackbar({
                message: 'We were unable to delete the classroom. Please try again or contact support.',
                options: {
                    variant: 'warning',
                },
            })
        )
        yield put({ type: usergroupConstants.DELETE_USERGROUP.FAILURE, message: e.message })
    }
}

export function* getUserGroupUsers(data) {
    try {
        const response = yield call(usergroupService.getUserGroupUsers, data.payload.userGroupId)
        yield put({ type: usergroupConstants.GET_USERGROUP_USERS.SUCCESS, data: response.data.users })
    } catch (e) {
        yield put(
            snackbarActions.enqueueSnackbar({
                message: 'We were unable to load classroom users. Please try again or contact support.',
                options: {
                    variant: 'warning',
                },
            })
        )
        yield put({ type: usergroupConstants.GET_USERGROUP_USERS.FAILURE, message: e.message })
    }
}

export function* getUserGroupTopics(data) {
    try {
        const response = yield call(usergroupService.getUserGroupTopics, data.payload.userGroupId)
        yield put({ type: usergroupConstants.GET_USERGROUP_TOPICS.SUCCESS, data: response.data.topics })
    } catch (e) {
        yield put(
            snackbarActions.enqueueSnackbar({
                message: 'We were unable to load the cohort topics. Please try again or contact support.',
                options: {
                    variant: 'warning',
                },
            })
        )
        yield put({ type: usergroupConstants.GET_USERGROUP_TOPICS.FAILURE, message: e.message })
    }
}

export function* addUserGroupUsers(data) {
    try {
        const response = yield call(usergroupService.addUserGroupUsers, data.payload)

        const responseStats = yield call(usergroupService.getUserGroupStats, data.payload)
        yield put({ type: usergroupConstants.GET_USERGROUP_STATS.SUCCESS, data: responseStats.data })

        yield put({ type: usergroupConstants.ADD_USERGROUP_USERS.SUCCESS, data: response.data })
        yield put({ type: usergroupConstants.GET_USERGROUP_USERS.REQUEST, payload: data.payload })
        if (!data?.payload?.skipAddMessage) {
            if (data.payload?.userIds?.length > 1) {
                yield put(
                    snackbarActions.enqueueSnackbar({
                        message: 'Users has been added to classroom',
                        options: {
                            variant: 'success',
                        },
                    })
                )
            } else {
                yield put(
                    snackbarActions.enqueueSnackbar({
                        message: 'User has been added to classroom',
                        options: {
                            variant: 'success',
                        },
                    })
                )
            }
        }
    } catch (e) {
        yield put(
            snackbarActions.enqueueSnackbar({
                message: 'We were unable to add the user to the classroom. Please try again or contact support.',
                options: {
                    variant: 'warning',
                },
            })
        )
        yield put({ type: usergroupConstants.ADD_USERGROUP_USERS.FAILURE, message: e.message })
    }
}

export function* deleteUserGroupUsers(data) {
    try {
        const response = yield call(usergroupService.deleteUserGroupUsers, data.payload)
        yield put({ type: usergroupConstants.DELETE_USERGROUP_USERS.SUCCESS, data: response.data })
        const responseUsers = yield call(usergroupService.getUserGroupUsers, data.payload.userGroupId)
        yield put({ type: usergroupConstants.GET_USERGROUP_USERS.SUCCESS, data: responseUsers.data.users })
        const responseStats = yield call(usergroupService.getUserGroupStats, data.payload)
        yield put({ type: usergroupConstants.GET_USERGROUP_STATS.SUCCESS, data: responseStats.data })
        yield put(
            snackbarActions.enqueueSnackbar({
                message: 'The user has been removed from the classroom(s).',
                options: {
                    variant: 'success',
                },
            })
        )
    } catch (e) {
        yield put(
            snackbarActions.enqueueSnackbar({
                message: 'We were unable to remove user from the classroom. Please try again or contact support.',
                options: {
                    variant: 'warning',
                },
            })
        )
        yield put({ type: usergroupConstants.DELETE_USERGROUP_USERS.FAILURE, message: e.message })
    }
}

export function* addUserGroupTopic(data) {
    try {
        const response = yield call(usergroupService.addUserGroupTopic, data.payload)
        yield put({ type: usergroupConstants.ADD_USERGROUP_TOPIC.SUCCESS, data: response.data })
        if (data.payload.shouldReprocess)
            yield put({ type: topicConstants.GET_ALL_USERGROUPS_FOR_TOPIC.REQUEST, payload: data.payload })
        const responseShared = yield call(topicService.getSharableCourses, null)
        yield put({ type: topicConstants.GET_SHARABLE_COURSES.SUCCESS, data: responseShared.data })
        const responseNewCourses = yield call(usergroupService.getUserGroupTopics, data.payload.userGroupId)
        yield put({ type: usergroupConstants.GET_USERGROUP_TOPICS.SUCCESS, data: responseNewCourses.data.topics })
        yield put(
            snackbarActions.enqueueSnackbar({
                message: 'The course has been added to the classroom(s).',
                options: {
                    variant: 'success',
                },
            })
        )
    } catch (e) {
        let usefulInformation = ''
        usefulInformation += e.response.data
        yield put(
            snackbarActions.enqueueSnackbar({
                message: 'We were unable to add the course to the classroom. ' + usefulInformation,
                options: {
                    variant: 'warning',
                },
            })
        )
        yield put({ type: usergroupConstants.ADD_USERGROUP_TOPIC.FAILURE, message: e.message })
    }
}

export function* deleteUserGroupTopic(data) {
    try {
        const response = yield call(usergroupService.deleteUserGroupTopic, data.payload)
        yield put({ type: usergroupConstants.DELETE_USERGROUP_TOPIC.SUCCESS, data: response.data })
        yield put({ type: usergroupConstants.GET_USERGROUP_TOPICS.REQUEST, payload: data.payload })
        if (data.payload.reload)
            yield put({ type: topicConstants.GET_ALL_USERGROUPS_FOR_TOPIC.REQUEST, payload: data.payload })
        yield put(
            snackbarActions.enqueueSnackbar({
                message: 'The course has been removed from the classroom(s).',
                options: {
                    variant: 'success',
                },
            })
        )
        const responseNewCourses = yield call(usergroupService.getUserGroupTopics, data.payload.userGroupId)
        if (data.payload.shouldReprocess)
            yield put({ type: topicConstants.GET_ALL_USERGROUPS_FOR_TOPIC.REQUEST, payload: data.payload })
        yield put({ type: usergroupConstants.GET_USERGROUP_TOPICS.SUCCESS, data: responseNewCourses.data.topics })
    } catch (e) {
        yield put(
            snackbarActions.enqueueSnackbar({
                message: 'We were unable to remove the course from the classroom. Please try again or contact support.',
                options: {
                    variant: 'warning',
                },
            })
        )
        yield put({ type: usergroupConstants.DELETE_USERGROUP_TOPIC.FAILURE, message: e.message })
    }
}

export function* getAllUserGroups(data) {
    try {
        const response = yield call(usergroupService.getAllUserGroups, data.payload.organizationId)
        yield put({ type: usergroupConstants.GET_ALL_USERGROUPS.SUCCESS, data: response.data })
    } catch (e) {
        yield put(
            snackbarActions.enqueueSnackbar({
                message: 'We were unable to load the classroom. Please try again or contact support.',
                options: {
                    variant: 'warning',
                },
            })
        )
        yield put({ type: usergroupConstants.GET_ALL_USERGROUPS.FAILURE, message: e.message })
    }
}

export function* updateUserGroup(data) {
    try {
        const response = yield call(usergroupService.updateUserGroup, data.payload)
        yield put({ type: usergroupConstants.GET_ALL_USERGROUPS.REQUEST, payload: data.payload })
        yield put({ type: usergroupConstants.UPDATE_USERGROUP.SUCCESS, data: response.data })
    } catch (e) {
        yield put(
            snackbarActions.enqueueSnackbar({
                message: 'We were unable to update the classroom. Please try again or contact support.',
                options: {
                    variant: 'warning',
                },
            })
        )
        yield put({ type: usergroupConstants.UPDATE_USERGROUP.FAILURE, message: e.message })
    }
}

export function* getUserGroupStats(data) {
    try {
        const response = yield call(usergroupService.getUserGroupStats, data.payload)
        yield put({ type: usergroupConstants.GET_USERGROUP_STATS.SUCCESS, data: response.data })
    } catch (e) {
        yield put(
            snackbarActions.enqueueSnackbar({
                message: 'We were unable to get usergroup stats. Please try again or contact support.',
                options: {
                    variant: 'warning',
                },
            })
        )
        yield put({ type: usergroupConstants.GET_USERGROUP_STATS.FAILURE, message: e.message })
    }
}

export function* getCourseGroupsForUserGroup(data) {
    try {
        const response = yield call(usergroupService.getCourseGroupsForUserGroup, data.payload)
        yield put({ type: usergroupConstants.GET_COURSE_GROUPS_FOR_USER_GROUP.SUCCESS, data: response.data })
    } catch (e) {
        let usefulInformation = '' // in case response data is empty.
        usefulInformation += e.response.data
        yield put(
            snackbarActions.enqueueSnackbar({
                message: 'Could not get course groups for classroom. ' + usefulInformation,
                options: {
                    variant: 'warning',
                },
            })
        )
        yield put({ type: usergroupConstants.GET_COURSE_GROUPS_FOR_USER_GROUP.FAILURE, message: e.message })
    }
}

export function* updateUserGroupsForCourseGroupId(data) {
    let failed = false

    if (data.payload.addToUserGroupIds?.length > 0) {
        for (const userGroupId of data.payload.addToUserGroupIds) {
            const addPayload = {
                userGroupId: userGroupId,
                courseGroupId: data.payload.courseGroupId,
            }
            try {
                const response = yield call(usergroupService.addCourseGroupToUserGroup, addPayload)
            } catch (e) {
                let usefulInformation = '' // in case response data is empty.
                usefulInformation += e.response.data
                yield put(
                    snackbarActions.enqueueSnackbar({
                        message: 'Could not add course groups to classroom. ' + usefulInformation,
                        options: {
                            variant: 'warning',
                        },
                    })
                )
                failed = true
            }
        }
    }
    if (data.payload.deleteFromUserGroupIds?.length > 0) {
        for (const userGroupId of data.payload.deleteFromUserGroupIds) {
            const removePayload = {
                userGroupId: userGroupId,
                courseGroupId: data.payload.courseGroupId,
            }
            try {
                const response = yield call(usergroupService.removeCourseGroupToUserGroup, removePayload)
            } catch (e) {
                let usefulInformation = '' // in case response data is empty.
                usefulInformation += e.response.data
                yield put(
                    snackbarActions.enqueueSnackbar({
                        message: 'Could not add course groups to classroom. ' + usefulInformation,
                        options: {
                            variant: 'warning',
                        },
                    })
                )
                failed = true
            }
        }
    }
    if (failed) {
        yield put({ type: usergroupConstants.UPDATE_USER_GROUPS_FOR_COURSE_GROUP_ID.FAILURE, message: e.message })
    } else {
        const responseUserGroups = yield call(usergroupService.getAllUserGroups, data.payload.organizationId)
        yield put({
            type: usergroupConstants.UPDATE_USER_GROUPS_FOR_COURSE_GROUP_ID.SUCCESS,
            data: responseUserGroups.data,
        })
    }
}

export function* addCourseGroupToUserGroup(data) {
    try {
        const response = yield call(usergroupService.addCourseGroupToUserGroup, data.payload)
        yield put({ type: usergroupConstants.ADD_COURSE_GROUP_TO_USER_GROUP.SUCCESS, data: response.data })
    } catch (e) {
        let usefulInformation = '' // in case response data is empty.
        usefulInformation += e.response.data
        yield put(
            snackbarActions.enqueueSnackbar({
                message: 'Could not add course groups to classroom. ' + usefulInformation,
                options: {
                    variant: 'warning',
                },
            })
        )
        yield put({ type: usergroupConstants.ADD_COURSE_GROUP_TO_USER_GROUP.FAILURE, message: e.message })
    }
}

export function* removeCourseGroupToUserGroup(data) {
    try {
        const response = yield call(usergroupService.removeCourseGroupToUserGroup, data.payload)
        yield put({ type: usergroupConstants.DELETE_COURSE_GROUP_FROM_USER_GROUP.SUCCESS, data: response.data })
    } catch (e) {
        let usefulInformation = '' // in case response data is empty.
        usefulInformation += e.response.data
        yield put(
            snackbarActions.enqueueSnackbar({
                message: 'Could not remove course groups from classroom. ' + usefulInformation,
                options: {
                    variant: 'warning',
                },
            })
        )
        yield put({ type: usergroupConstants.DELETE_COURSE_GROUP_FROM_USER_GROUP.FAILURE, message: e.message })
    }
}

export function* addUserGroupReportingUser(data) {
    try {
        const response = yield call(usergroupService.addUserGroupReportingUser, data.payload)
        yield put({ type: usergroupConstants.ADD_USER_GROUP_REPORTING_USER.SUCCESS })

        const myOrgUsers = yield call(organizationService.getOrganizationUsers, data.payload.organizationId)
        yield put({ type: organizationConstants.GET_ORGANIZATION_USERS.SUCCESS, data: myOrgUsers.data.users })

        const responseUserGroups = yield call(usergroupService.getAllUserGroups, data.payload.organizationId)
        yield put({ type: usergroupConstants.GET_ALL_USERGROUPS.SUCCESS, data: responseUserGroups.data })
    } catch (e) {
        let usefulInformation = '' // in case response data is empty.
        usefulInformation += e.response.data
        yield put(
            snackbarActions.enqueueSnackbar({
                message: 'Could not add classroom reporting users. ' + usefulInformation,
                options: {
                    variant: 'warning',
                },
            })
        )
        yield put({ type: usergroupConstants.ADD_USER_GROUP_REPORTING_USER.FAILURE, message: e.message })
    }
}

export function* removeUserGroupReportingUser(data) {
    try {
        const response = yield call(usergroupService.removeUserGroupReportingUser, data.payload)
        yield put({ type: usergroupConstants.REMOVE_USER_GROUP_REPORTING_USER.SUCCESS })

        const myOrgUsers = yield call(organizationService.getOrganizationUsers, data.payload.organizationId)
        yield put({ type: organizationConstants.GET_ORGANIZATION_USERS.SUCCESS, data: myOrgUsers.data.users })

        const responseUserGroups = yield call(usergroupService.getAllUserGroups, data.payload.organizationId)
        yield put({ type: usergroupConstants.GET_ALL_USERGROUPS.SUCCESS, data: responseUserGroups.data })
    } catch (e) {
        let usefulInformation = '' // in case response data is empty.
        usefulInformation += e.response.data
        yield put(
            snackbarActions.enqueueSnackbar({
                message: 'Could not add classroom reporting users. ' + usefulInformation,
                options: {
                    variant: 'warning',
                },
            })
        )
        yield put({ type: usergroupConstants.REMOVE_USER_GROUP_REPORTING_USER.FAILURE, message: e.message })
    }
}

//Listeners
export function* usergroupSagas() {
    yield takeLatest(usergroupConstants.CREATE_USERGROUP.REQUEST, createUserGroup)
    yield takeLatest(usergroupConstants.EDIT_USERGROUP.REQUEST, editUserGroup)
    yield takeLatest(usergroupConstants.DELETE_USERGROUP.REQUEST, deleteUserGroup)
    yield takeLatest(usergroupConstants.GET_USERGROUP_USERS.REQUEST, getUserGroupUsers)
    yield takeLatest(usergroupConstants.GET_USERGROUP_TOPICS.REQUEST, getUserGroupTopics)
    yield takeLatest(usergroupConstants.ADD_USERGROUP_USERS.REQUEST, addUserGroupUsers)
    yield takeLatest(usergroupConstants.DELETE_USERGROUP_USERS.REQUEST, deleteUserGroupUsers)
    yield takeLatest(usergroupConstants.ADD_USERGROUP_TOPIC.REQUEST, addUserGroupTopic)
    yield takeLatest(usergroupConstants.DELETE_USERGROUP_TOPIC.REQUEST, deleteUserGroupTopic)
    yield takeLatest(usergroupConstants.GET_ALL_USERGROUPS.REQUEST, getAllUserGroups)
    yield takeLatest(usergroupConstants.UPDATE_USERGROUP.REQUEST, updateUserGroup)
    yield takeLatest(usergroupConstants.GET_USERGROUP_STATS.REQUEST, getUserGroupStats)
    yield takeLatest(usergroupConstants.GET_COURSE_GROUPS_FOR_USER_GROUP.REQUEST, getCourseGroupsForUserGroup)
    yield takeLatest(usergroupConstants.ADD_COURSE_GROUP_TO_USER_GROUP.REQUEST, addCourseGroupToUserGroup)
    yield takeLatest(usergroupConstants.DELETE_COURSE_GROUP_FROM_USER_GROUP.REQUEST, removeCourseGroupToUserGroup)
    yield takeLatest(usergroupConstants.ADD_USER_GROUP_REPORTING_USER.REQUEST, addUserGroupReportingUser)
    yield takeLatest(usergroupConstants.REMOVE_USER_GROUP_REPORTING_USER.REQUEST, removeUserGroupReportingUser)
    yield takeLatest(
        usergroupConstants.UPDATE_USER_GROUPS_FOR_COURSE_GROUP_ID.REQUEST,
        updateUserGroupsForCourseGroupId
    )
}
