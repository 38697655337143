import { call, put, takeLatest } from 'redux-saga/effects'
import { studentAuthConstants, snackbarActions, authConstants, studentConstants } from '../actions'
import { studentAuthService } from '../services'
import { history } from '../helpers'
import { studentService } from '../services'

export function* studentShouldRegister(action) {
    try {
        yield put(snackbarActions.clearSnackbar())
        const authResponse = yield call(studentAuthService.studentShouldRegister, action.payload)
        yield put({ type: studentAuthConstants.STUDENT_SHOULD_REGISTER.SUCCESS, data: authResponse.data })
    } catch (e) {
        yield put({ type: studentAuthConstants.STUDENT_SHOULD_REGISTER.FAILURE, message: e.message })
        yield put(
            snackbarActions.enqueueSnackbar({
                message: 'It was not possible to check if the email should register or log in.',
                options: {
                    variant: 'warning',
                },
            })
        )
    }
}

export function* loginStudentUser(action) {
    try {
        yield put(snackbarActions.clearSnackbar())
        const authResponse = yield call(studentAuthService.studentLogin, action.payload)
        localStorage.setItem('authToken', JSON.stringify(authResponse.data))
        const userResponse = yield call(studentService.getUserInfo)
        yield put({ type: studentConstants.STUDENT_GET_USER_INFO.SUCCESS, data: userResponse.data })

        yield put({ type: studentAuthConstants.STUDENT_LOGIN.SUCCESS, data: authResponse.data })

        history.push('/student')
    } catch (e) {
        yield put({ type: studentAuthConstants.STUDENT_LOGIN.FAILURE, message: e.message })
        yield put(
            snackbarActions.enqueueSnackbar({
                message: 'It was not possible to login. Please check your email and password and try again.',
                options: {
                    variant: 'warning',
                },
            })
        )
    }
}

export function* registerStudentUser(action) {
    try {
        const authResponse = yield call(studentAuthService.studentRegister, action.payload)
        localStorage.setItem('authToken', JSON.stringify(authResponse.data))

        const userResponse = yield call(studentService.getUserInfo())
        yield put({ type: studentConstants.STUDENT_GET_USER_INFO.SUCCESS, data: userResponse.data })

        yield put({ type: studentAuthConstants.STUDENT_REGISTER.SUCCESS, data: authResponse.data })

        history.push('/student')
    } catch (e) {
        let usefulInformation = ''
        if (e.response.data != 'Server error') {
            usefulInformation = e.response.data
        }
        yield put(
            snackbarActions.enqueueSnackbar({
                message: 'We were unable to register the user. ' + usefulInformation,
                options: {
                    variant: 'warning',
                },
            })
        )
        yield put({ type: studentAuthConstants.STUDENT_REGISTER.FAILURE, message: e.message })
    }
}

export function* logoutStudentUser() {
    try {
        localStorage.removeItem('authToken')
        yield put({ type: studentAuthConstants.STUDENT_LOGOUT.SUCCESS })
        history.push('/student/login')
    } catch (e) {
        yield put({ type: studentAuthConstants.STUDENT_LOGOUT.FAILURE, message: e.message })
    }
}

export function* studentForgotPasswordPin(action) {
    try {
        const response = yield call(studentAuthService.studentForgotPasswordPin, action.payload)
        yield put({ type: studentAuthConstants.STUDENT_FORGOT_PASSWORD_PIN.SUCCESS, data: response.data })
    } catch (e) {
        yield put({ type: studentAuthConstants.STUDENT_FORGOT_PASSWORD_PIN.FAILURE, message: e.message })
    }
}

export function* studentUpdateForgotPassword(action) {
    try {
        const response = yield call(studentAuthService.studentUpdateForgotPassword, action.payload)
        yield put({ type: studentAuthConstants.STUDENT_UPDATE_FORGOT_PASSWORD.SUCCESS, data: response.data })
    } catch (e) {
        yield put({ type: studentAuthConstants.STUDENT_FORGOT_PASSWORD_PIN.FAILURE, message: e.message })
    }
}

//Listeners
export function* studentAuthSagas() {
    yield takeLatest(studentAuthConstants.STUDENT_LOGIN.REQUEST, loginStudentUser)
    yield takeLatest(studentAuthConstants.STUDENT_REGISTER.REQUEST, registerStudentUser)
    yield takeLatest(studentAuthConstants.STUDENT_LOGOUT.REQUEST, logoutStudentUser)
    yield takeLatest(studentAuthConstants.STUDENT_FORGOT_PASSWORD_PIN.REQUEST, studentForgotPasswordPin)
    yield takeLatest(studentAuthConstants.STUDENT_UPDATE_FORGOT_PASSWORD.REQUEST, studentUpdateForgotPassword)
    yield takeLatest(studentAuthConstants.STUDENT_SHOULD_REGISTER.REQUEST, studentShouldRegister)
}
