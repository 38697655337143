import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { makeStyles, withStyles } from '@material-ui/core/styles'
import { Button, Grid, Typography } from '@material-ui/core'
import { organizationActions } from '../../../_state/actions'
import DayOfTheWeekPieChart from '../../../Components/Graphs/DayOfTheWeekPieChart'
import HourOfDayGraph from '../../../Components/Graphs/HourOfDayGraph'
import OrganizationOverviewSidebar from './Components/OrganizationOverviewSidebar'
import DailyUsageGraph, { DailyUsageYLabelType } from '../../../Components/Graphs/DailyUsageGraph'
import { FormattedMessage, useIntl } from 'react-intl'
import DailySessionGraph from '../../../Components/Graphs/DailySessionsGraph'
import moment from 'moment'
import CircularProgress from '@material-ui/core/CircularProgress'
import { CapeeshColors } from '../../../assets/ColorPalette'
import { i18nMessages } from '../../../Logic/CodeAccessible.i18n.Messages'

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '300px',
        marginLeft: 'calc(15% + 25px)',
        marginRight: '25px',
    },
    button: {
        margin: theme.spacing(1),
    },
    graph: {
        marginTop: 20,
    },
    leaderBoardIndex: {
        background: theme.palette.primary.main,
    },
    reportingSection: {
        marginTop: '10px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
}))

const OrganizationStatistics = (props) => {
    const intl = useIntl()

    const [dayOfWeekUsageStats, setDayOfWeekUsageStats] = useState(null)
    const [hourOfDayUsageStats, setHourOfDayUsageStats] = useState(null)
    const [hourOfDayUsageStatsIndex, setHourOfDayUsageStatsIndex] = useState(null)
    const [showHourOfDayUsageStats, setShowHourOfDayUsageStats] = useState(false)

    const [dailyUsageAggregated, setDailyUsageAggregated] = useState([])
    const [showDailyUsageAggregated, setShowDailyUsageAggregated] = useState(false)

    const [dailyUsage, setDailyUsage] = useState([])
    const [showDailyUsage, setShowDailyUsage] = useState(false)

    const [aggregatedSessions, setAggregatedSessions] = useState([])
    const [showAggregatedSessions, setShowAggregatedSessions] = useState(false)

    const [sessions, setSessions] = useState([])
    const [showSessions, setShowSessions] = useState(false)

    // lesson graph data
    const [graphDataNumberOfLessonsPlayed, setGraphDataNumberOfLessonsPlayed] = useState([])
    const [graphAggregatedNumberOfLessonsPlayed, setGraphAggregatedNumberOfLessonsPlayed] = useState([])
    const [graphDataNumberOfUniqueLessonsPlayed, setGraphDataNumberOfUniqueLessonsPlayed] = useState([])
    const [graphDataAggregatedNumberOfUniqueLessonsPlayed, setGraphDataAggregatedNumberOfUniqueLessonsPlayed] =
        useState([])
    const [graphDataNumberOfCompletedLessons, setGraphDataNumberOfCompletedLessons] = useState([])
    const [graphDataAggregatedNumberOfCompletedLessons, setGraphDataAggregatedNumberOfCompletedLessons] = useState([])
    const [graphDataNumberOfFailedLessons, setGraphDataNumberOfFailedLessons] = useState([])
    const [graphDataAggregatedNumberOfFailedLessons, setGraphDataAggregatedNumberOfFailedLessons] = useState([])
    const [graphDataNumberOfCorrectAnswersInLessons, setGraphDataNumberOfCorrectAnswersInLessons] = useState([])
    const [graphDataAggregatedNumberOfCorrectAnswersInLessons, setGraphDataAggregatedNumberOfCorrectAnswersInLessons] =
        useState([])
    const [graphDataNumberOfIncorrectAnswersInLessons, setGraphDataNumberOfIncorrectAnswersInLessons] = useState([])
    const [
        graphDataAggregatedNumberOfIncorrectAnswersInLessons,
        setGraphDataAggregatedNumberOfIncorrectAnswersInLessons,
    ] = useState([])
    const [graphDataNumberOfStars, setGraphDataNumberOfStars] = useState([])
    const [graphDataAggregatedNumberOfStars, setGraphDataAggregatedNumberOfStars] = useState([])
    const [showLessonRelatedGraphs, setShowLessonRelatedGraphs] = useState(false)

    const classes = useStyles()

    useEffect(() => {
        props.dispatch(organizationActions.getGraphDataDayOfWeekOrganization(props.organization.selectedOrganization))
        props.dispatch(
            organizationActions.getGraphDataDailyUsageAggregatedOrganization(props.organization.selectedOrganization)
        )

        props.dispatch(
            organizationActions.getGraphDataDailyLessonAggregatedOrganization(props.organization.selectedOrganization)
        )
        props.dispatch(organizationActions.getGraphDataHourlyOrganization(props.organization.selectedOrganization))
    }, [])

    const updateDayOfWeekGraphData = () => {
        if (
            props.organization.graphDataDayOfWeekOrganization &&
            props.organization.graphDataDayOfWeekOrganization.canShowGraphData
        ) {
            let tempDayOfWeekUsageStats = props.organization.graphDataDayOfWeekOrganization.graphStatsDayOfWeekDtos.map(
                (pie) => ({ name: pie.dayOfWeekShort, value: pie.secondsCombined })
            )
            setDayOfWeekUsageStats(tempDayOfWeekUsageStats)
        }
    }

    useEffect(() => {
        updateAggregateGraphData()
        updateDayOfWeekGraphData()
        updateTimeOfDayGraphData()
        updateLessonPerformenceDataGraphs()
    }, [props.organization])

    const updateAggregateGraphData = () => {
        let tempDailyUsageAggregated = []
        let tempShowDailyUsageAggregated = false

        let tempDailyUsage = []
        let tempShowDailyUsage = false

        let tempAggregatedSessions = []
        let tempShowAggregatedSessions = false

        let tempSessions = []
        let tempShowSessions = false

        if (
            props.organization.graphDataDailyUsageAggregatedOrg &&
            props.organization.graphDataDailyUsageAggregatedOrg.organizationId ===
                props.organization.selectedOrganization &&
            props.organization.graphDataDailyUsageAggregatedOrg.canShowGraphData
        ) {
            tempDailyUsageAggregated = props.organization.graphDataDailyUsageAggregatedOrg.graphStatsAggregateDays.map(
                (x) => ({ name: x.day, value: Math.ceil(x.aggregatedMinutes / 60) })
            )
            tempShowDailyUsageAggregated = true

            tempDailyUsage = props.organization.graphDataDailyUsageAggregatedOrg.graphStatsAggregateDays.map((x) => ({
                name: x.day,
                value: Math.ceil(x.minutes / 60),
            }))
            tempShowDailyUsage = true

            tempAggregatedSessions = props.organization.graphDataDailyUsageAggregatedOrg.graphStatsAggregateDays.map(
                (x) => ({ name: x.day, value: x.aggregatedNumberOfSessions })
            )
            tempShowAggregatedSessions = true

            tempSessions = props.organization.graphDataDailyUsageAggregatedOrg.graphStatsAggregateDays.map((x) => ({
                name: x.day,
                value: x.numberOfSessions,
            }))
            tempShowSessions = true
        }

        setDailyUsageAggregated(tempDailyUsageAggregated)
        setShowDailyUsageAggregated(tempShowDailyUsageAggregated)

        setDailyUsage(tempDailyUsage)
        setShowDailyUsage(tempShowDailyUsage)

        setAggregatedSessions(tempAggregatedSessions)
        setShowAggregatedSessions(tempShowAggregatedSessions)

        setSessions(tempSessions)
        setShowSessions(tempShowSessions)
    }

    const getOrgExcelReportFromTemplate = () => {
        const currentOrganization = props.organization.myOrganizations.find(
            (x) => x.id === props.organization.selectedOrganization
        )

        let date = moment(moment.now()).format('YYYY-MM-DD')
        let filename = 'Organization Report for ' + currentOrganization.name + ' ' + date + '.xlsx'
        props.dispatch(organizationActions.exportExcelFromTemplateOrganizationStats(currentOrganization.id, filename))
    }

    const updateTimeOfDayGraphData = () => {
        let showTimeOfDayStats = false
        let timeOfDayStats = []
        let timeOfDayStatsIndex = {}
        if (
            props.organization.graphDataHourlyOrganization &&
            props.organization.graphDataHourlyOrganization.organizationId === props.organization.selectedOrganization &&
            props.organization.graphDataHourlyOrganization.canShowGraphData
        ) {
            timeOfDayStats = props.organization.graphDataHourlyOrganization.graphStatsHourlyDtos
            timeOfDayStats.forEach((el, index) => (timeOfDayStatsIndex[el.hourOfTheDay] = el.minutesCombined))
            showTimeOfDayStats = true
        }

        setHourOfDayUsageStats(timeOfDayStats)
        setHourOfDayUsageStatsIndex(timeOfDayStatsIndex)
        setShowHourOfDayUsageStats(showTimeOfDayStats)
    }

    const updateLessonPerformenceDataGraphs = () => {
        let tempShowLessonRelatedGraphs = false

        let tempGraphDataNumberOfLessonsPlayed = []
        let tempGraphAggregatedNumberOfLessonsPlayed = []
        let tempGraphDataNumberOfUniqueLessonsPlayed = []
        let tempGraphDataAggregatedNumberOfUniqueLessonsPlayed = []
        let tempGraphDataNumberOfCompletedLessons = []
        let tempGraphDataAggregatedNumberOfCompletedLessons = []
        let tempGraphDataNumberOfFailedLessons = []
        let tempGraphDataAggregatedNumberOfFailedLessons = []
        let tempGraphDataNumberOfCorrectAnswersInLessons = []
        let tempGraphDataAggregatedNumberOfCorrectAnswersInLessons = []
        let tempGraphDataNumberOfIncorrectAnswersInLessons = []
        let tempGraphDataAggregatedNumberOfIncorrectAnswersInLessons = []
        let tempGraphDataNumberOfStars = []
        let tempGraphDataAggregatedNumberOfStars = []

        if (
            props.organization.graphDataDailyLessonAggregatedOrg &&
            props.organization.graphDataDailyLessonAggregatedOrg.organizationId ===
                props.organization.selectedOrganization &&
            props.organization.graphDataDailyLessonAggregatedOrg.aggregatedLessonDailyItemDtos?.length > 0 &&
            props.organization.graphDataDailyLessonAggregatedOrg.canShowGraphData
        ) {
            tempShowLessonRelatedGraphs = true

            tempGraphDataNumberOfLessonsPlayed =
                props.organization.graphDataDailyLessonAggregatedOrg.aggregatedLessonDailyItemDtos.map((x) => ({
                    name: x.day,
                    value: x.numberOfLessonsPlayed,
                }))

            tempGraphAggregatedNumberOfLessonsPlayed =
                props.organization.graphDataDailyLessonAggregatedOrg.aggregatedLessonDailyItemDtos.map((x) => ({
                    name: x.day,
                    value: x.aggregatedNumberOfLessonsPlayed,
                }))

            tempGraphDataNumberOfUniqueLessonsPlayed =
                props.organization.graphDataDailyLessonAggregatedOrg.aggregatedLessonDailyItemDtos.map((x) => ({
                    name: x.day,
                    value: x.numberOfUniqueLessonsPlayed,
                }))

            tempGraphDataAggregatedNumberOfUniqueLessonsPlayed =
                props.organization.graphDataDailyLessonAggregatedOrg.aggregatedLessonDailyItemDtos.map((x) => ({
                    name: x.day,
                    value: x.aggregatedNumberOfUniqueLessonsPlayed,
                }))

            tempGraphDataNumberOfCompletedLessons =
                props.organization.graphDataDailyLessonAggregatedOrg.aggregatedLessonDailyItemDtos.map((x) => ({
                    name: x.day,
                    value: x.numberOfCompletedLessons,
                }))

            tempGraphDataAggregatedNumberOfCompletedLessons =
                props.organization.graphDataDailyLessonAggregatedOrg.aggregatedLessonDailyItemDtos.map((x) => ({
                    name: x.day,
                    value: x.aggregatedNumberOfCompletedLessons,
                }))

            tempGraphDataNumberOfFailedLessons =
                props.organization.graphDataDailyLessonAggregatedOrg.aggregatedLessonDailyItemDtos.map((x) => ({
                    name: x.day,
                    value: x.numberOfFailedLessons,
                }))

            tempGraphDataAggregatedNumberOfFailedLessons =
                props.organization.graphDataDailyLessonAggregatedOrg.aggregatedLessonDailyItemDtos.map((x) => ({
                    name: x.day,
                    value: x.aggregatedNumberOfFailedLessons,
                }))

            tempGraphDataNumberOfCorrectAnswersInLessons =
                props.organization.graphDataDailyLessonAggregatedOrg.aggregatedLessonDailyItemDtos.map((x) => ({
                    name: x.day,
                    value: x.numberOfCorrectAnswersInLessons,
                }))

            tempGraphDataAggregatedNumberOfCorrectAnswersInLessons =
                props.organization.graphDataDailyLessonAggregatedOrg.aggregatedLessonDailyItemDtos.map((x) => ({
                    name: x.day,
                    value: x.aggregatedNumberOfCorrectAnswersInLessons,
                }))
            tempGraphDataNumberOfIncorrectAnswersInLessons =
                props.organization.graphDataDailyLessonAggregatedOrg.aggregatedLessonDailyItemDtos.map((x) => ({
                    name: x.day,
                    value: x.numberOfIncorrectAnswersInLessons,
                }))

            tempGraphDataAggregatedNumberOfIncorrectAnswersInLessons =
                props.organization.graphDataDailyLessonAggregatedOrg.aggregatedLessonDailyItemDtos.map((x) => ({
                    name: x.day,
                    value: x.aggregatedNumberOfIncorrectAnswersInLessons,
                }))
            tempGraphDataNumberOfStars =
                props.organization.graphDataDailyLessonAggregatedOrg.aggregatedLessonDailyItemDtos.map((x) => ({
                    name: x.day,
                    value: x.numberOfStars,
                }))

            tempGraphDataAggregatedNumberOfStars =
                props.organization.graphDataDailyLessonAggregatedOrg.aggregatedLessonDailyItemDtos.map((x) => ({
                    name: x.day,
                    value: x.aggregatedNumberOfStars,
                }))
        }

        setShowLessonRelatedGraphs(tempShowLessonRelatedGraphs)

        setGraphDataNumberOfLessonsPlayed(tempGraphDataNumberOfLessonsPlayed)
        setGraphAggregatedNumberOfLessonsPlayed(tempGraphAggregatedNumberOfLessonsPlayed)
        setGraphDataNumberOfUniqueLessonsPlayed(tempGraphDataNumberOfUniqueLessonsPlayed)
        setGraphDataAggregatedNumberOfUniqueLessonsPlayed(tempGraphDataAggregatedNumberOfUniqueLessonsPlayed)
        setGraphDataNumberOfCompletedLessons(tempGraphDataNumberOfCompletedLessons)
        setGraphDataAggregatedNumberOfCompletedLessons(tempGraphDataAggregatedNumberOfCompletedLessons)
        setGraphDataNumberOfFailedLessons(tempGraphDataNumberOfFailedLessons)
        setGraphDataAggregatedNumberOfFailedLessons(tempGraphDataAggregatedNumberOfFailedLessons)
        setGraphDataNumberOfCorrectAnswersInLessons(tempGraphDataNumberOfCorrectAnswersInLessons)
        setGraphDataAggregatedNumberOfCorrectAnswersInLessons(tempGraphDataAggregatedNumberOfCorrectAnswersInLessons)
        setGraphDataNumberOfIncorrectAnswersInLessons(tempGraphDataNumberOfIncorrectAnswersInLessons)
        setGraphDataAggregatedNumberOfIncorrectAnswersInLessons(
            tempGraphDataAggregatedNumberOfIncorrectAnswersInLessons
        )
        setGraphDataNumberOfStars(tempGraphDataNumberOfStars)
        setGraphDataAggregatedNumberOfStars(tempGraphDataAggregatedNumberOfStars)
    }

    return (
        <div className={classes.root}>
            <OrganizationOverviewSidebar path={props.computedMatch?.path} />
            <Grid container spacing={5} style={{ marginTop: '25px' }}>
                <Grid item xs={12} sm={12}>
                    <div className={classes.reportingSection}>
                        <Typography
                            variant="h6"
                            style={{
                                marginRight: '20px',
                                float: 'right',
                                justifyContent: 'flex-start',
                                textAlign: 'left',
                                fontSize: '18px',
                                fontStyle: 'normal',
                                fontWeight: 500,
                            }}>
                            <FormattedMessage
                                id="organization.statistics.downloadreportmsg"
                                defaultMessage="Export a excel sheet for all students, containing statistics for all users in all classrooms."
                            />
                        </Typography>
                        <Button
                            variant={'contained'}
                            color={'primary'}
                            onClick={() => getOrgExcelReportFromTemplate()}
                            style={{ textAlign: 'center', height: '40px', width: '220px' }}>
                            {!props.organization.isExportingOrgExcelReport && (
                                <FormattedMessage
                                    id="organization.statistics.downloadreport"
                                    defaultMessage="Download report"
                                />
                            )}
                            {props.organization.isExportingOrgExcelReport && <CircularProgress size={24} />}
                        </Button>
                    </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <DailyUsageGraph
                        defaultHeaderMessage={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.addmoreusers"
                                defaultMessage="Add more users to see graph"
                            />
                        }
                        headerMessage={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.dailyusage.desc"
                                defaultMessage="Shows how much time the users have spent in the app"
                            />
                        }
                        toolTipYLabelType={DailyUsageYLabelType.AggreatedUsageOverTime}
                        graphData={dailyUsageAggregated}
                        canShowChart={showDailyUsageAggregated}
                        showReferenceLine={false}
                        title={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.time.spent.in.app.aggregated.title"
                                defaultMessage="Time spent in app aggregated"
                            />
                        }
                        yLabel={intl.formatMessage(i18nMessages.dashboardGraphsYLabelHours)}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <DayOfTheWeekPieChart dayOfWeekChartData={dayOfWeekUsageStats} canShowChart={dayOfWeekUsageStats} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <HourOfDayGraph
                        graphData={hourOfDayUsageStats}
                        graphDataIndex={hourOfDayUsageStatsIndex}
                        canShowChart={showHourOfDayUsageStats}
                        showYAxisLabel={false}
                    />
                </Grid>

                {/*<Grid item xs={12} sm={6}>*/}
                {/*    <HourOfDayGraph*/}
                {/*        graphData={timeOfDayStats}*/}
                {/*        graphDataIndex={timeOfDayStatsIndex}*/}
                {/*        canShowChart={showTimeOfDayStats}*/}
                {/*        showYAxisLabel={false}*/}
                {/*    />*/}
                {/*</Grid>*/}

                <Grid item xs={12} sm={6}>
                    <DailyUsageGraph
                        defaultHeaderMessage={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.addmoreusers"
                                defaultMessage="Add more users to see graph"
                            />
                        }
                        headerMessage={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.dailyusage.headermessage"
                                defaultMessage="Shows how much time is spent per day"
                            />
                        }
                        toolTipYLabelType={DailyUsageYLabelType.DailyUsage}
                        graphData={dailyUsage}
                        canShowChart={showDailyUsage}
                        showReferenceLine={false}
                        title={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.dailyusage.title"
                                defaultMessage="Daily usage"
                            />
                        }
                        yLabel={intl.formatMessage(i18nMessages.dashboardGraphsYLabelHours)}
                        isMinutes={false}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <DailySessionGraph
                        defaultHeaderMessage={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.addmoreusers"
                                defaultMessage="Add more users to see graph"
                            />
                        }
                        headerMessage={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.dailysesssions.header"
                                defaultMessage="Shows how many sessions users have in the app over time"
                            />
                        }
                        graphData={aggregatedSessions}
                        canShowChart={showAggregatedSessions}
                        showReferenceLine={false}
                        title={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.dailysesssions.title"
                                defaultMessage="Sessions"
                            />
                        }
                        yLabel={intl.formatMessage(i18nMessages.dashboardGraphsYLabelSessions)}
                        isMinutes={false}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <DailySessionGraph
                        defaultHeaderMessage={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.addmoreusers"
                                defaultMessage="Add more users to see graph"
                            />
                        }
                        toolTipPrefix={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.dailysessions"
                                defaultMessage="Daily sessions"
                            />
                        }
                        graphData={sessions}
                        canShowChart={showSessions}
                        showReferenceLine={true}
                        title={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.sessionperday"
                                defaultMessage="Session per day"
                            />
                        }
                        yLabel={intl.formatMessage(i18nMessages.dashboardGraphsYLabelSessions)}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <DailyUsageGraph
                        defaultHeaderMessage={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.addmoreusers"
                                defaultMessage="Add more users to see graph"
                            />
                        }
                        toolTipPrefix={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.numberofstars"
                                defaultMessage="Number of stars"
                            />
                        }
                        graphData={graphDataAggregatedNumberOfStars}
                        canShowChart={showLessonRelatedGraphs}
                        showReferenceLine={true}
                        title={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.numberofstarsaggregated"
                                defaultMessage="Number of stars per day aggregated"
                            />
                        }
                        yLabel={intl.formatMessage(i18nMessages.dashboardGraphsYLabelStars)}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <DailyUsageGraph
                        defaultHeaderMessage={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.addmoreusers"
                                defaultMessage="Add more users to see graph"
                            />
                        }
                        toolTipPrefix={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.numberofstars"
                                defaultMessage="Number of stars"
                            />
                        }
                        graphData={graphDataNumberOfStars}
                        canShowChart={showLessonRelatedGraphs}
                        showReferenceLine={true}
                        title={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.numberofstarsperday"
                                defaultMessage="Number of stars per day"
                            />
                        }
                        yLabel={intl.formatMessage(i18nMessages.dashboardGraphsYLabelStars)}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <DailyUsageGraph
                        defaultHeaderMessage={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.addmoreusers"
                                defaultMessage="Add more users to see graph"
                            />
                        }
                        toolTipPrefix={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.numberoflessons"
                                defaultMessage="Number of lessons"
                            />
                        }
                        graphData={graphDataNumberOfLessonsPlayed}
                        canShowChart={showLessonRelatedGraphs}
                        showReferenceLine={false}
                        title={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.lessonsplayedperday"
                                defaultMessage="Lessons played per day"
                            />
                        }
                        yLabel={intl.formatMessage(i18nMessages.dashboardGraphsYLabelLessons)}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <DailyUsageGraph
                        defaultHeaderMessage={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.addmoreusers"
                                defaultMessage="Add more users to see graph"
                            />
                        }
                        headerMessage={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.dailylessons.header"
                                defaultMessage="Shows lessons have been played over time"
                            />
                        }
                        toolTipYLabelType={DailyUsageYLabelType.NumberOfLessons}
                        graphData={graphAggregatedNumberOfLessonsPlayed}
                        canShowChart={showLessonRelatedGraphs}
                        showReferenceLine={false}
                        title={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.dailylessons.title"
                                defaultMessage="Lessons played"
                            />
                        }
                        yLabel={intl.formatMessage(i18nMessages.dashboardGraphsYLabelLessons)}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <DailyUsageGraph
                        defaultHeaderMessage={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.addmoreusers"
                                defaultMessage="Add more users to see graph"
                            />
                        }
                        toolTipPrefix={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.uniquelessons"
                                defaultMessage="Unique lessons"
                            />
                        }
                        graphData={graphDataNumberOfUniqueLessonsPlayed}
                        canShowChart={showLessonRelatedGraphs}
                        showReferenceLine={false}
                        title={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.uniquelessonsperdy"
                                defaultMessage="Unique lessons per day"
                            />
                        }
                        yLabel={intl.formatMessage(i18nMessages.dashboardGraphsYLabelLessons)}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <DailyUsageGraph
                        defaultHeaderMessage={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.addmoreusers"
                                defaultMessage="Add more users to see graph"
                            />
                        }
                        toolTipPrefix={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.uniquelessons"
                                defaultMessage="Unique lessons"
                            />
                        }
                        graphData={graphDataAggregatedNumberOfUniqueLessonsPlayed}
                        canShowChart={showLessonRelatedGraphs}
                        showReferenceLine={false}
                        title={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.uniquelessonsaggregated"
                                defaultMessage="Unique lessons per day aggregated"
                            />
                        }
                        yLabel={intl.formatMessage(i18nMessages.dashboardGraphsYLabelLessons)}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <DailyUsageGraph
                        defaultHeaderMessage={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.addmoreusers"
                                defaultMessage="Add more users to see graph"
                            />
                        }
                        toolTipPrefix={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.completedlessons"
                                defaultMessage="Completed lessons"
                            />
                        }
                        graphData={graphDataNumberOfCompletedLessons}
                        canShowChart={showLessonRelatedGraphs}
                        showReferenceLine={false}
                        title={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.numberofcompletedlessons"
                                defaultMessage="Number of completed lessons"
                            />
                        }
                        yLabel={intl.formatMessage(i18nMessages.dashboardGraphsYLabelLessons)}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <DailyUsageGraph
                        defaultHeaderMessage={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.addmoreusers"
                                defaultMessage="Add more users to see graph"
                            />
                        }
                        toolTipPrefix={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.completedlessons"
                                defaultMessage="Completed lessons"
                            />
                        }
                        graphData={graphDataAggregatedNumberOfCompletedLessons}
                        canShowChart={showLessonRelatedGraphs}
                        showReferenceLine={false}
                        title={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.numberofcompletedlessonsaggregated"
                                defaultMessage="Number of completed lesson aggregated"
                            />
                        }
                        yLabel={intl.formatMessage(i18nMessages.dashboardGraphsYLabelLessons)}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <DailyUsageGraph
                        defaultHeaderMessage={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.addmoreusers"
                                defaultMessage="Add more users to see graph"
                            />
                        }
                        toolTipPrefix={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.failedlessons"
                                defaultMessage="Failed lessons"
                            />
                        }
                        graphData={graphDataNumberOfFailedLessons}
                        canShowChart={showLessonRelatedGraphs}
                        showReferenceLine={false}
                        title={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.numberoffailedlessons"
                                defaultMessage="Number of failed lesson"
                            />
                        }
                        yLabel={intl.formatMessage(i18nMessages.dashboardGraphsYLabelLessons)}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <DailyUsageGraph
                        defaultHeaderMessage={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.addmoreusers"
                                defaultMessage="Add more users to see graph"
                            />
                        }
                        toolTipPrefix={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.failedlessons"
                                defaultMessage="Failed lessons"
                            />
                        }
                        graphData={graphDataAggregatedNumberOfFailedLessons}
                        canShowChart={showLessonRelatedGraphs}
                        showReferenceLine={false}
                        title={
                            <FormattedMessage
                                id="dashboard.classroom.graphs.numberoffailedlessonsaggregated"
                                defaultMessage="Number of failed lesson aggregated"
                            />
                        }
                        yLabel={intl.formatMessage(i18nMessages.dashboardGraphsYLabelLessons)}
                    />
                </Grid>

                {/*<Grid item xs={12} sm={6}>*/}
                {/*  <DailyUsageGraph*/}
                {/*    defaultHeaderMessage={"Add more users to see graph"}*/}
                {/*    toolTipPrefix={"Answers"}*/}
                {/*    graphData={graphDataNumberOfCorrectAnswersInLessons}*/}
                {/*    canShowChart={showLessonRelatedGraphs}*/}
                {/*    showReferenceLine={false}*/}
                {/*    title={"Number of correct answers"}*/}
                {/*    yLabel={"answers"}*/}
                {/*  />*/}
                {/*</Grid>*/}

                {/*<Grid item xs={12} sm={6}>*/}
                {/*  <DailyUsageGraph*/}
                {/*    defaultHeaderMessage={"Add more users to see graph"}*/}
                {/*    toolTipPrefix={"Answers"}*/}
                {/*    graphData={graphDataAggregatedNumberOfCorrectAnswersInLessons}*/}
                {/*    canShowChart={showLessonRelatedGraphs}*/}
                {/*    showReferenceLine={false}*/}
                {/*    title={"Number of correct answers aggregated"}*/}
                {/*    yLabel={"answers"}*/}
                {/*  />*/}
                {/*</Grid>*/}

                {/*<Grid item xs={12} sm={6}>*/}
                {/*  <DailyUsageGraph*/}
                {/*    defaultHeaderMessage={"Add more users to see graph"}*/}
                {/*    toolTipPrefix={"Answers"}*/}
                {/*    graphData={graphDataNumberOfIncorrectAnswersInLessons}*/}
                {/*    canShowChart={showLessonRelatedGraphs}*/}
                {/*    showReferenceLine={false}*/}
                {/*    title={"Number of incorrect answers"}*/}
                {/*    yLabel={"answers"}*/}
                {/*  />*/}
                {/*</Grid>*/}

                {/*<Grid item xs={12} sm={6}>*/}
                {/*  <DailyUsageGraph*/}
                {/*    defaultHeaderMessage={"Add more users to see graph"}*/}
                {/*    toolTipPrefix={"Answers"}*/}
                {/*    graphData={graphDataAggregatedNumberOfIncorrectAnswersInLessons}*/}
                {/*    canShowChart={showLessonRelatedGraphs}*/}
                {/*    showReferenceLine={false}*/}
                {/*    title={"Number of incorrect answers aggregated"}*/}
                {/*    yLabel={"answers"}*/}
                {/*  />*/}
                {/*</Grid>*/}
            </Grid>
        </div>
    )
}

function mapStateToProps(state) {
    const { organization } = state
    return {
        organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationStatistics)
