import { Unity, useUnityContext } from 'react-unity-webgl'
import React, { Fragment, useEffect, useState, forwardRef, useImperativeHandle, useCallback, useRef } from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import { API_ROOT } from '../../../../_state/helpers'
import { connect } from 'react-redux'
import Dictaphone from './UnityLessonPreviewVoice'
import { GlobalEvents } from '../../../../assets/GlobalEvents'
import { lessonActions } from '../../../../_state/actions'
import { CapeeshColors, HexToColorWithAlpha } from '../../../../assets/ColorPalette'
import json from '@iconify-icons/mdi/json'

const useStyles = makeStyles((theme) => ({
    unitySizeStyle: {
        height: '75vh',
        width: 'calc(75vh * 0.62)',
    },
}))

export const CapeeshWebState = {
    LessonPreview: 'LessonPreview',
    CapeeshWebStudent: 'CapeeshWebStudent',
}

const cacheControl = (url) => {
    // Caching enabled for .data and .bundle files.
    // Revalidate if file is up to date before loading from cache
    if (url.match(/\.data/) || url.match(/\.bundle/) || url.match(/\.wasm/)) {
        return 'must-revalidate'
    }

    // Caching enabled for .mp4 and .custom files
    // Load file from cache without revalidation.
    if (url.match(/\.mp3/) || url.match(/\.jpeg/) || url.match(/\.png/) || url.match(/\.jpg/)) {
        return 'immutable'
    }

    // Disable explicit caching for all other files.
    // Note: the default browser cache may cache them anyway.
    return 'no-store'
}

// Expected props
// props.customLessonId
// props.clickableSkipButton
// props.closeDialog
// props.disableMic

// Optional props
// props.onNodeIdChanged
// props.nodeId

const UnityLessonPreview = forwardRef((props, ref) => {
    const classes = useStyles()
    const [initUnity, setInitUnity] = useState(false)
    const [unityIsLoaded, setUnityIsLoaded] = useState(false)

    const emptyGuid = '00000000-0000-0000-0000-000000000000'

    const path = props.basePath
    const buildName = props.buildName
    const unityConfig = {
        loaderUrl: path + buildName + '.loader.js',
        dataUrl: path + buildName + '.data',
        frameworkUrl: path + buildName + '.framework.js',
        codeUrl: path + buildName + '.wasm',
        productName: 'Capeesh',
        productVersion: '3.1.1',
        companyName: 'Capeesh',
        cacheControl: cacheControl,
    }
    const { unityProvider, isLoaded, loadingProgression, sendMessage, unload, addEventListener, removeEventListener } =
        useUnityContext(unityConfig)

    const unityRef = useRef(null)

    const unloadUnity = async () => {
        setUnityIsLoaded(false)

        OnQuitApp()

        try {
            unload().finally(() => {
                document.dispatchEvent(GlobalEvents.UnityLessonPreviewDialogClose)
            })
        } catch (e) {
            document.dispatchEvent(GlobalEvents.UnityLessonPreviewDialogClose)
        }
    }

    const InitUnityLessonPreview = () => {
        if (!isLoaded) {
            return
        }

        if (!initUnity) {
            setInitUnity(true)
            setUnityIsLoaded(true)
        }
    }

    const InitCapeeshWeb = () => {
        let authTokenJson = localStorage.getItem('authToken')
        let authTokenObject = JSON.parse(authTokenJson)
        let authToken = authTokenObject?.token
        let validAuthToken = authToken != null && authToken !== ''
        if (validAuthToken === false) return

        const capeeshWebDto = {
            environment: API_ROOT,
            authToken: authToken,
        }

        sendMessage('CapeeshWebController', 'Init', JSON.stringify(capeeshWebDto).toString())
        sendMessage(
            'CapeeshWebGLSettings',
            'SetAppLanguage',
            props.dashboardLanguage ? props.dashboardLanguage?.toString() : ''.toString()
        )
    }

    const LoadCapeeshWebToRightPlace = () => {
        const loadDto = props.urlReloadCheck()
        if (!loadDto) return

        console.log('Load CapeeshWebToRightPlace', loadDto)

        sendMessage('CapeeshWebGLSettings', 'LoadCapeeshFromParameters', JSON.stringify(loadDto).toString())
    }

    const InitUnityLessonPreviewForCustomLessonId = (customLessonId, l1Id, nodeId, clickableSkipButton) => {
        let authTokenJson = localStorage.getItem('authToken')
        let authTokenObject = JSON.parse(authTokenJson)
        let authToken = authTokenObject?.token
        let validAuthToken = authToken != null && authToken !== ''
        if (validAuthToken === false) return

        let previewLessonDto = {
            environment: API_ROOT,
            authToken: authToken,
            customLessonId: customLessonId,
            l1Id: l1Id,
            nodeId: nodeId,
            clickableSkipButton: clickableSkipButton,
        }
        sendMessage('LessonPreviewController', 'Init', JSON.stringify(previewLessonDto).toString())
    }

    const AddToUrl = (parameterObject) => {
        const url = new URL(window.location.href)
        if (url.searchParams.has(parameterObject.parameterName)) return

        url.searchParams.set(parameterObject.parameterName, parameterObject.value)
        window.history.pushState({}, '', url)
    }

    const RemoveFromUrl = (parameterObject) => {
        const url = new URL(window.location.href)
        if (url.searchParams.has(parameterObject)) {
            url.searchParams.delete(parameterObject)
        }
        window.history.pushState({}, '', url)
    }

    const FilterAppSettings = () => {
        if (props.settingsFilter) {
            sendMessage('CapeeshWebGLSettings', 'SetFilterNames', JSON.stringify(props.settingsFilter).toString())
        }
    }

    const OnUnfocus = () => {
        console.log('Happens unfocus')
        //sendMessage('VirtualKeyboard(Clone)', 'DisableKeyboardFromExternal');

        sendMessage('LessonPreviewController', 'OnUnFocusExternal')
    }

    const OnFocus = () => {
        //sendMessage('VirtualKeyboard(Clone)', 'EnableKeyboardFromExternal');
        console.log('Happens focus')

        sendMessage('LessonPreviewController', 'OnFocusExternal')
    }

    const OnQuitApp = () => {
        sendMessage('LessonPreviewController', 'CloseApp')
    }

    const InitUnityLessonFromParams = (customLessonId, l1Id, nodeId, clickableSkipButton, l2Id) => {
        props.dispatch(lessonActions.setSpeechRecognitionL2(l2Id))
        InitUnityLessonPreviewForCustomLessonId(customLessonId, l1Id, nodeId, clickableSkipButton)
    }

    const handleCloseDialog = () => {
        unloadUnity()
    }

    const handleReceiveNodeId = (nodeId) => {
        if (props.onNodeIdChanged != null) {
            props.onNodeIdChanged(nodeId)
        }
    }

    const handleGetKeyboard = () => {
        sendMessage('CapeeshWebGLSettings', 'FocusKeyboard')
    }

    const handleRemoveKeyboard = () => {
        sendMessage('CapeeshWebGLSettings', 'UnFocusKeyboard')
    }

    useEffect(() => {
        if (props.webType !== CapeeshWebState.CapeeshWebStudent) return

        console.log('Happens do', props.dashboardLanguage)

        sendMessage(
            'CapeeshWebGLSettings',
            'SetAppLanguage',
            props.dashboardLanguage ? props.dashboardLanguage?.toString() : ''.toString()
        )
    }, [props.dashboardLanguage])

    useEffect(() => {
        if (isLoaded && !initUnity) {
            InitUnityLessonPreview()
        }
    }, [isLoaded])

    useEffect(() => {
        if (initUnity) {
            if (props.webType === CapeeshWebState.CapeeshWebStudent) {
                InitCapeeshWeb()
            } else if (props.webType === CapeeshWebState.LessonPreview) {
                document.dispatchEvent(GlobalEvents.UnityLessonPreviewOnLoad)
            }
        }
    }, [initUnity])

    useEffect(() => {
        if (unityIsLoaded === false) {
            removeEventListener('ReceiveNodeId', handleReceiveNodeId)
            removeEventListener('AddToUrl', AddToUrl)
            removeEventListener('MainMenuFinishLoad', LoadCapeeshWebToRightPlace)
            removeEventListener('RemoveFromUrl', RemoveFromUrl)
            removeEventListener('SettingsFinishedLoading', FilterAppSettings)
        } else {
            addEventListener('ReceiveNodeId', handleReceiveNodeId)
            addEventListener('AddToUrl', AddToUrl)
            addEventListener('MainMenuFinishLoad', LoadCapeeshWebToRightPlace)
            addEventListener('RemoveFromUrl', RemoveFromUrl)
            addEventListener('SettingsFinishedLoading', FilterAppSettings)
        }
    }, [unityIsLoaded])

    useEffect(() => {
        if (unityIsLoaded === false) return

        document.addEventListener('click', function (event) {
            const targetDiv = document.getElementById('unity-div')
            if (!targetDiv.contains(event.target)) {
                handleRemoveKeyboard()
            }
        })

        return () => {
            document.removeEventListener('click', function (event) {
                const targetDiv = document.getElementById('unity-div')
                if (!targetDiv.contains(event.target)) {
                    handleRemoveKeyboard()
                }
            })
        }
    }, [])

    useImperativeHandle(ref, () => {
        return {
            unloadUnity: unloadUnity,
            InitUnityLessonFromParams: InitUnityLessonFromParams,
            OnFocus: OnFocus,
            OnUnfocus: OnUnfocus,
        }
    })

    return (
        <div onClick={() => handleGetKeyboard()} id="unity-div">
            <Unity
                unityProvider={unityProvider}
                className={
                    props.previewWidth && props.previewHeight
                        ? { width: props.previewWidth, height: props.previewHeight }
                        : classes.unitySizeStyle
                }
                style={{
                    visibility: isLoaded ? 'visible' : 'hidden',
                    height: props.previewHeight ? props.previewHeight : 748,
                    width: props.previewWidth ? props.previewWidth : 400,
                    borderRadius: props.useBorder ? 16 : '',
                    border: props.useBorder ? `12px solid ${HexToColorWithAlpha(CapeeshColors.DeepBlue, 0.5)}` : '',
                    minWidth: 300,
                    minHeight: 600,
                }}
                ref={unityRef}
            />

            {!props.disableMic && (
                <Dictaphone
                    addEventListener={addEventListener}
                    removeEventListener={removeEventListener}
                    sendMessage={sendMessage}
                    unityIsLoaded={unityIsLoaded}></Dictaphone>
            )}

            {!isLoaded && <Typography>Loading Application... {Math.round(loadingProgression * 100)}%</Typography>}
        </div>
    )
})

function mapStateToProps(state) {
    const { lesson } = state
    return {
        lesson,
    }
}

export default connect(mapStateToProps, null, null, { forwardRef: true })(UnityLessonPreview)
