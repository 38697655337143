const formatSeconds = (seconds) => {
    const min = seconds / 60
    const hours = min / 60
    const rhours = Math.floor(hours)
    const minutes = (hours - rhours) * 60
    const rminutes = Math.round(minutes)

    let secondString = seconds + ' seconds'
    if (seconds === 1) {
        secondString = seconds + ' seconds'
    }

    if (seconds < 60) return secondString + '.'

    if (rhours === 0 && rminutes === 0) return 'No activity'

    let minutesString = rminutes + ' minutes'
    if (rminutes === 1) {
        minutesString = rminutes + ' minute'
    }
    if (rhours === 0) {
        return minutesString + '.'
    }

    let rhoursString = rhours + ' hours'
    if (rhours === 1) {
        rhoursString = rhours + ' hour'
    }

    return rhoursString + ' and ' + minutesString + '.'
}

export const formatSecondsShort = (seconds) => {
    const min = seconds / 60
    const hours = min / 60
    const flooredHours = Math.floor(hours)
    const minutes = (hours - flooredHours) * 60
    const roundedMinutes = Math.round(minutes)

    if (flooredHours === 0 && roundedMinutes === 0) return ''

    let minutesString = roundedMinutes + ' m'

    if (flooredHours === 0) {
        return minutesString + '.'
    }

    let rhoursString = flooredHours + ' h'

    return rhoursString + ' ' + minutesString + '.'
}
