import { call, put, takeLatest, takeEvery } from 'redux-saga/effects'
import { organizationConstants, usergroupConstants, lessonConstants, topicConstants, userConstants } from '../actions'
import { organizationService, userService, usergroupService, lessonService } from '../services'
import { history } from '../helpers'
import { snackbarActions } from '../actions/snackbar.actions'
import fileDownload from 'js-file-download'
import { OrganizationFileAssetTypes } from '../../Logic/OrganizationAssetConstants'
import { convertDataURIToBinary } from '../helpers/base64Logic'

export function* getOrganizationInfo(data) {
    try {
        const response = yield call(organizationService.createSchool, data.payload.organizationId)
        yield put({ type: organizationConstants.GET_ORGANIZATION_INFO.SUCCESS, data: response.data })
    } catch (e) {}
}

export function* setOrganizationInfo(data) {
    try {
        const response = yield call(organizationService.setOrganizationInfo, data.payload)
        yield put({ type: organizationConstants.SET_ORGANIZATION_INFO.SUCCESS, data: response.data })
        yield put({ type: organizationConstants.GET_MY_ORGANIZATIONS.REQUEST, data: data.payload })
    } catch (e) {
        yield put(
            snackbarActions.enqueueSnackbar({
                message: 'Sorry, we were unable to save the organization details. Please try again.',
                options: {
                    variant: 'warning',
                },
            })
        )
        yield put({ type: organizationConstants.SET_ORGANIZATION_INFO.FAILURE, message: e.message })
    }
}

export function* createOrganization(data) {
    try {
        const response = yield call(organizationService.createSchool, data.payload)

        const getMyOrganizationsResponse = yield call(organizationService.getMyOrganizations)
        yield put({ type: organizationConstants.GET_MY_ORGANIZATIONS.SUCCESS, data: getMyOrganizationsResponse.data })

        yield put({ type: organizationConstants.SET_CURRENT_ORGANIZATION.SUCCESS, data: response.data.id })
        yield put({ type: usergroupConstants.RESET_CURRENT_ORGANIZATION.REQUEST, data: response.data.id })
        yield put({ type: lessonConstants.RESET_CURRENT_ORGANIZATION_LESSONS.REQUEST, data: response.data.id })

        let tempPayload = {
            organizationId: response.data.id,
        }

        yield put({ type: organizationConstants.CREATE_SCHOOL.SUCCESS, data: response.data })
        const userResponse = yield call(userService.getUserInfo)
        yield put({ type: userConstants.GETUSERINFO.SUCCESS, data: userResponse.data })

        history.push({
            pathname: '/',
            state: {
                showsnackbar: true,
                snackbarmessage: 'Organization created',
            },
        })
    } catch (e) {
        yield put(
            snackbarActions.enqueueSnackbar({
                message:
                    'Sorry, we were unable to create the organization. Please try again and check the organization does not exist.',
                options: {
                    variant: 'warning',
                },
            })
        )
        yield put({ type: organizationConstants.CREATE_SCHOOL.FAILURE, message: e.message })
    }
}

export function* getMyOrganizations() {
    try {
        const response = yield call(organizationService.getMyOrganizations)
        yield put({ type: organizationConstants.GET_MY_ORGANIZATIONS.SUCCESS, data: response.data })
    } catch (e) {
        yield put(
            snackbarActions.enqueueSnackbar({
                message: 'Sorry, we were unable to load your organizations. We are looking into this.',
                options: {
                    variant: 'warning',
                },
            })
        )
        yield put({ type: organizationConstants.GET_MY_ORGANIZATIONS.FAILURE, message: e.message })
    }
}

export function* setCurrentOrganization(data) {
    try {
        yield put({ type: organizationConstants.SET_CURRENT_ORGANIZATION.SUCCESS, data: data.payload.organizationId })
        yield put({ type: usergroupConstants.RESET_CURRENT_ORGANIZATION.REQUEST, data: data.payload.organizationId })
        yield put({
            type: lessonConstants.RESET_CURRENT_ORGANIZATION_LESSONS.REQUEST,
            data: data.payload.organizationId,
        })
        yield put({ type: organizationConstants.GET_ORGANIZATION_USERS.REQUEST, payload: data.payload })
        yield put({ type: organizationConstants.GET_INVITE_ROLES_AVAILABLE.REQUEST, payload: data.payload })
        yield put({ type: organizationConstants.GET_ALL_USERGROUPS.REQUEST, payload: data.payload })
        yield put({ type: topicConstants.GET_ALL_TOPICS.REQUEST, payload: data.payload })
        yield put({ type: organizationConstants.GET_AVAILABLE_COURSE_GROUPS.REQUEST, payload: data.payload })

        const customLessons = yield call(lessonService.getOrganizationLessons, data.payload.organizationId)
        yield put({ type: lessonConstants.GET_ORGANIZATION_LESSONS.SUCCESS, data: customLessons.data })
        if (data.payload.goToDashboardAfterChange) {
            history.push('/dashboard/')
        }
    } catch (e) {
        yield put(
            snackbarActions.enqueueSnackbar({
                message: 'Sorry, we were unable to change the organization',
                options: {
                    variant: 'warning',
                },
            })
        )
    }
}

export function* getOrganizationUsers(data) {
    console.log('getOrganizationUsers', data)
    try {
        const response = yield call(organizationService.getOrganizationUsers, data.payload.organizationId)
        yield put({ type: organizationConstants.GET_ORGANIZATION_USERS.SUCCESS, data: response.data.users })
    } catch (e) {
        yield put(
            snackbarActions.enqueueSnackbar({
                message: 'Sorry, we were unable to load the organization users. Please try again.',
                options: {
                    variant: 'warning',
                },
            })
        )
        yield put({ type: organizationConstants.GET_ORGANIZATION_USERS.FAILURE, message: e.message })
    }
}

export function* inviteStudentsToSchool(data) {
    try {
        const response = yield call(organizationService.inviteStudentsToSchool, data.payload)

        yield put(
            snackbarActions.enqueueSnackbar({
                message:
                    'The user has been added to the organization. If the user is new a invite email has been sent.',
                options: {
                    variant: 'success',
                },
            })
        )
        if (data.payload.userGroupId) {
            yield put({
                type: usergroupConstants.ADD_USERGROUP_USERS.REQUEST,
                payload: {
                    organizationId: data.payload.organizationId,
                    userGroupId: data.payload.userGroupId,
                    userIds: response.data,
                    skipAddMessage: true,
                },
            })
        }
        const responseOrgUsers = yield call(organizationService.getOrganizationUsers, data.payload.organizationId)
        yield put({
            type: organizationConstants.GET_ORGANIZATION_USERS.SUCCESS,
            payload: responseOrgUsers.data.users,
        })
        yield put({ type: organizationConstants.INVITE_STUDENTS_SCHOOL.SUCCESS, data: response.data })
    } catch (e) {
        yield put(
            snackbarActions.enqueueSnackbar({
                message: 'Sorry, we were unable to send out the invites. Please try again.',
                options: {
                    variant: 'warning',
                },
            })
        )
        yield put({ type: organizationConstants.INVITE_STUDENTS_SCHOOL.FAILURE, message: e.message })
    }
}

export function* inviteStudentUserDtosToOrganization(data) {
    try {
        const userDtos = data.payload.userDtos

        const userEmails = userDtos
            .map((userDto) => {
                if (userDto.email && userDto.validEmail) {
                    return userDto.email
                }
            })
            .filter((x) => x)
        const inviteToOrganizationPayload = {
            organizationId: data.payload.organizationId,
            userEmails: userEmails,
            schoolRoles: data.payload.schoolRoles,
            userGroupId: data.payload.userGroupId,
        }

        const response = yield call(organizationService.inviteStudentsToSchool, inviteToOrganizationPayload)

        // fetch all org users again
        let failedToSetNickNameCounter = 0
        const responseOrgUsers = yield call(organizationService.getOrganizationUsers, data.payload.organizationId)
        var newOrganizationUsers = responseOrgUsers.data?.users
        for (const userDto of userDtos) {
            if (userDto.name) {
                const orgUser = newOrganizationUsers.find((x) => x.email === userDto.email)
                if (orgUser) {
                    const userUpdateSetNickNamePayload = {
                        organizationId: data.payload.organizationId,
                        userId: orgUser.id,
                        nickName: userDto.name,
                    }
                    try {
                        const response = yield call(userService.adminUpdateUser(userUpdateSetNickNamePayload))
                    } catch (e) {
                        failedToSetNickNameCounter++
                    }
                }
            }
        }

        if (data.payload.userGroupId) {
            yield put({
                type: usergroupConstants.ADD_USERGROUP_USERS.REQUEST,
                payload: {
                    organizationId: data.payload.organizationId,
                    userGroupId: data.payload.userGroupId,
                    userIds: response.data,
                },
            })
        }
        const responseOrgUsers2 = yield call(organizationService.getOrganizationUsers, data.payload.organizationId)
        if (responseOrgUsers2?.data?.users) {
            yield put({
                type: organizationConstants.GET_ORGANIZATION_USERS.SUCCESS,
                payload: responseOrgUsers2.data.users,
            })
        }

        yield put(
            snackbarActions.enqueueSnackbar({
                message:
                    'The user(s) has been added to the organization. If the user is new a invite email has been sent.',
                options: {
                    variant: 'success',
                },
            })
        )

        yield put({ type: organizationConstants.INVITE_STUDENTS_SCHOOL_USER_DTO.SUCCESS, data: response.data })
    } catch (e) {
        yield put(
            snackbarActions.enqueueSnackbar({
                message: 'Sorry, we were unable to send out the invites. Please try again.',
                options: {
                    variant: 'warning',
                },
            })
        )
        yield put({ type: organizationConstants.INVITE_STUDENTS_SCHOOL.FAILURE, message: e.message })
    }
}

export function* getInviteRolesAvailable(data) {
    try {
        const response = yield call(organizationService.getInviteRolesAvailable, data.payload)
        yield put({ type: organizationConstants.GET_INVITE_ROLES_AVAILABLE.SUCCESS, data: response.data })
    } catch (e) {
        yield put({ type: organizationConstants.GET_INVITE_ROLES_AVAILABLE.FAILURE, message: e.message })
    }
}

export function* deleteOrganizationUser(data) {
    try {
        const response = yield call(organizationService.deleteOrganizationUser, data.payload)
        yield put({ type: organizationConstants.DELETE_ORGANIZATION_USER.SUCCESS, data: response.data })
        yield put({ type: organizationConstants.GET_ORGANIZATION_USERS.REQUEST, payload: data.payload })
        if (data.payload.userGroupId) {
            const responseUsers = yield call(usergroupService.getUserGroupUsers, data.payload.userGroupId)
            yield put({ type: usergroupConstants.GET_USERGROUP_USERS.SUCCESS, data: responseUsers.data.users })
            const responseStats = yield call(usergroupService.getUserGroupStats, data.payload)
            yield put({ type: usergroupConstants.GET_USERGROUP_STATS.SUCCESS, data: responseStats.data })
        }
        yield put(
            snackbarActions.enqueueSnackbar({
                message: 'The student(s) have been removed from the organization.',
                options: {
                    variant: 'success',
                },
            })
        )
    } catch (e) {
        yield put(
            snackbarActions.enqueueSnackbar({
                message:
                    'We were unable to remove the students from the organization. Please try again or contact support.',
                options: {
                    variant: 'warning',
                },
            })
        )
        yield put({ type: organizationConstants.DELETE_ORGANIZATION_USER.FAILURE, message: e.message })
    }
}

export function* deleteOrganizationAsset(action) {
    try {
        const deleteOrganizationLogoResponse = yield call(
            organizationService.deleteOrganizationFileAsset,
            action.payload
        )
        yield put({
            type: organizationConstants.DELETE_ORGANIZATION_ASSET.SUCCESS,
            data: deleteOrganizationLogoResponse.data,
        })
        yield put(
            snackbarActions.enqueueSnackbar({
                message:
                    'Your organization ' +
                    Object.keys(OrganizationFileAssetTypes)[action.payload.organizationFileAssetType] +
                    ' file asset has been deleted.',
                options: {
                    variant: 'success',
                },
            })
        )
    } catch (e) {
        let usefulInformation = '' // in case response data is empty.
        usefulInformation += e.response.data

        yield put(
            snackbarActions.enqueueSnackbar({
                message: 'We were not able to delete the organization asset. ' + usefulInformation,
                options: {
                    variant: 'warning',
                },
            })
        )

        yield put({ type: organizationConstants.DELETE_ORGANIZATION_ASSET.FAILURE, message: e.message })
    }
}

export function* getOrganizationAsset(action) {
    try {
        const getOrganizationAssetResponse = yield call(organizationService.getOrganizationFileAsset, action.payload)
        yield put({
            type: organizationConstants.GET_ORGANIZATION_ASSETS.SUCCESS,
            data: getOrganizationAssetResponse.data,
        })
    } catch (e) {
        let usefulInformation = '' // in case response data is empty.
        usefulInformation += e.response.data

        yield put(
            snackbarActions.enqueueSnackbar({
                message: 'We were not able to get the organization assets. ' + usefulInformation,
                options: {
                    variant: 'warning',
                },
            })
        )

        yield put({ type: organizationConstants.GET_ORGANIZATION_ASSETS.FAILURE, message: e.message })
    }
}

export function* updateOrganizationAsset(action) {
    try {
        const updateOrganizationLogoResponse = yield call(
            organizationService.updateOrganizationFileAsset,
            action.payload
        )
        yield put({
            type: organizationConstants.UPDATE_ORGANIZATION_ASSET.SUCCESS,
            data: updateOrganizationLogoResponse.data,
        })
        yield put(
            snackbarActions.enqueueSnackbar({
                message:
                    'Your organization ' +
                    Object.keys(OrganizationFileAssetTypes)[action.payload.organizationFileAssetTypes[0]] +
                    ' has been uploaded.',
                options: {
                    variant: 'success',
                },
            })
        )
    } catch (e) {
        let usefulInformation = '' // in case response data is empty.
        usefulInformation += e.response.data

        yield put(
            snackbarActions.enqueueSnackbar({
                message: 'We were not able to update the organization asset. ' + usefulInformation,
                options: {
                    variant: 'warning',
                },
            })
        )

        yield put({ type: organizationConstants.UPDATE_ORGANIZATION_ASSET.FAILURE, message: e.message })
    }
}

export function* updateOrganizaitonInformation(action) {
    try {
        const updateOrganizationInformationResponse = yield call(
            organizationService.updateOrganizationInformation,
            action.payload
        )
        const getMyOrganizationsResponse = yield call(organizationService.getMyOrganizations)
        yield put({ type: organizationConstants.GET_MY_ORGANIZATIONS.SUCCESS, data: getMyOrganizationsResponse.data })
        yield put({ type: organizationConstants.UPDATE_ORGANIZATION_INFO.SUCCESS, data: null })
    } catch (e) {
        yield put(
            snackbarActions.enqueueSnackbar({
                message: 'Failed to save organization information',
                options: {
                    variant: 'warning',
                },
            })
        )

        yield put({ type: organizationConstants.UPDATE_ORGANIZATION_INFO.FAILURE, message: e.message })
    }
}

export function* exportExcelFromTemplateOrgStats(data) {
    try {
        const response = yield call(organizationService.excelExportFromTemplateOrgStats, data.payload)

        var binary = convertDataURIToBinary(response.data)
        var blob = new Blob([binary], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })

        fileDownload(blob, data.payload.filename)

        yield put({ type: organizationConstants.EXPORT_EXCEL_FROM_TEMPLATE_ORG_STATS.SUCCESS, data: response.data })
    } catch (e) {
        let usefulInformation = '' // in case response data is empty.
        usefulInformation += e.response.data
        yield put(
            snackbarActions.enqueueSnackbar({
                message: 'Could not export excel file for organization. ' + usefulInformation,
                options: {
                    variant: 'warning',
                },
            })
        )
        yield put({ type: organizationConstants.EXPORT_EXCEL_FROM_TEMPLATE_ORG_STATS.FAILURE, message: e.message })
    }
}

export function* exportExcelFromTemplateClassroomStats(data) {
    try {
        const response = yield call(organizationService.excelExportFromTemplateClasssoomStats, data.payload)
        var binary = convertDataURIToBinary(response.data)
        var blob = new Blob([binary], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })

        fileDownload(blob, data.payload.filename)
        yield put({
            type: organizationConstants.EXPORT_EXCEL_FROM_TEMPLATE_CLASSROOM_STATS.SUCCESS,
            data: response.data,
        })
    } catch (e) {
        let usefulInformation = '' // in case response data is empty.
        usefulInformation += e.response.data
        yield put(
            snackbarActions.enqueueSnackbar({
                message: 'Could not export excel file for classroom. ' + usefulInformation,
                options: {
                    variant: 'warning',
                },
            })
        )
        yield put({
            type: organizationConstants.EXPORT_EXCEL_FROM_TEMPLATE_CLASSROOM_STATS.FAILURE,
            message: e.message,
        })
    }
}

export function* getAvailableCourseGroupsForOrganization(data) {
    try {
        const response = yield call(organizationService.getAvailableCourseGroups, data.payload.organizationId)
        yield put({ type: organizationConstants.GET_AVAILABLE_COURSE_GROUPS.SUCCESS, data: response.data })
    } catch (e) {
        let usefulInformation = '' // in case response data is empty.
        usefulInformation += e.response.data
        yield put(
            snackbarActions.enqueueSnackbar({
                message: 'Could not fetch available course groups for organization. ' + usefulInformation,
                options: {
                    variant: 'warning',
                },
            })
        )
        yield put({ type: organizationConstants.GET_AVAILABLE_COURSE_GROUPS.FAILURE, message: e.message })
    }
}

export function* getGraphDataHourlyForOrganization(data) {
    try {
        const response = yield call(organizationService.getGraphDataHourlyOrganization, data.payload)
        yield put({ type: organizationConstants.GET_GRAPH_DATA_HOURLY_ORG.SUCCESS, data: response.data })
    } catch (e) {
        yield put({ type: organizationConstants.GET_GRAPH_DATA_HOURLY_ORG.FAILURE, message: e.message })
    }
}

export function* getGraphDataHourlyForUserGroup(data) {
    try {
        const response = yield call(organizationService.getGraphDataHourlyUserGroup, data.payload)
        yield put({ type: organizationConstants.GET_GRAPH_DATA_HOURLY_USER_GROUP.SUCCESS, data: response.data })
    } catch (e) {
        yield put({ type: organizationConstants.GET_GRAPH_DATA_HOURLY_USER_GROUP.FAILURE, message: e.message })
    }
}

export function* getGraphDataDayOfWeekForOrganization(data) {
    try {
        const response = yield call(organizationService.getGraphDataDayOfWeekOrganization, data.payload)
        yield put({ type: organizationConstants.GET_GRAPH_DATA_DAY_OF_WEEK_ORG.SUCCESS, data: response.data })
    } catch (e) {
        yield put({ type: organizationConstants.GET_GRAPH_DATA_DAY_OF_WEEK_ORG.FAILURE, message: e.message })
    }
}

export function* getGraphDataDayOfWeekForUserGroup(data) {
    try {
        const response = yield call(organizationService.getGraphDataDayOfWeekUserGroup, data.payload)
        yield put({ type: organizationConstants.GET_GRAPH_DATA_DAY_OF_WEEK_USER_GROUP.SUCCESS, data: response.data })
    } catch (e) {
        yield put({ type: organizationConstants.GET_GRAPH_DATA_DAY_OF_WEEK_USER_GROUP.FAILURE, message: e.message })
    }
}

export function* getGraphDataDailyUsageAggregatedForOrganization(data) {
    try {
        const response = yield call(organizationService.getGraphDataDailyUsageAggregatedOrganization, data.payload)
        yield put({
            type: organizationConstants.GET_GRAPH_DATA_DAILY_USAGE_AGGREGATED_ORG.SUCCESS,
            data: response.data,
        })
    } catch (e) {
        yield put({ type: organizationConstants.GET_GRAPH_DATA_DAILY_USAGE_AGGREGATED_ORG.FAILURE, message: e.message })
    }
}

export function* getGraphDataDailyUsageAggregatedForUserGroup(data) {
    try {
        const response = yield call(organizationService.getGraphDataDailyUsageAggregatedUserGroup, data.payload)
        yield put({
            type: organizationConstants.GET_GRAPH_DATA_DAILY_USAGE_AGGREGATED_USER_GROUP.SUCCESS,
            data: response.data,
        })
    } catch (e) {
        yield put({
            type: organizationConstants.GET_GRAPH_DATA_DAILY_USAGE_AGGREGATED_USER_GROUP.FAILURE,
            message: e.message,
        })
    }
}

export function* getGraphDataDailyLessonAggregatedForOrganization(data) {
    try {
        const response = yield call(organizationService.getGraphDataDailyLessonAggregatedOrganization, data.payload)
        yield put({
            type: organizationConstants.GET_GRAPH_DATA_DAILY_LESSON_AGGREGATED_ORG.SUCCESS,
            data: response.data,
        })
    } catch (e) {
        yield put({
            type: organizationConstants.GET_GRAPH_DATA_DAILY_LESSON_AGGREGATED_ORG.FAILURE,
            message: e.message,
        })
    }
}

export function* getGraphDataDailyLessonAggregatedForUserGroup(data) {
    try {
        const response = yield call(organizationService.getGraphDataDailyLessonAggregatedUserGroup, data.payload)
        yield put({
            type: organizationConstants.GET_GRAPH_DATA_DAILY_LESSON_AGGREGATED_USER_GROUP.SUCCESS,
            data: response.data,
        })
    } catch (e) {
        yield put({
            type: organizationConstants.GET_GRAPH_DATA_DAILY_LESSON_AGGREGATED_USER_GROUP.FAILURE,
            message: e.message,
        })
    }
}

export function* getOrganizationFactBoxStats(data) {
    try {
        const response = yield call(organizationService.getOrganizationFactBoxStats, data.payload)
        yield put({ type: organizationConstants.GET_ORGANIZATION_FACT_BOX_STATS.SUCCESS, data: response.data })
    } catch (e) {
        yield put({ type: organizationConstants.GET_ORGANIZATION_FACT_BOX_STATS.FAILURE, message: e.message })
    }
}

export function* getOrganizationCourseGroupUsage(data) {
    try {
        const response = yield call(organizationService.getOrganizationCourseGroupUsage, data.payload)
        yield put({ type: organizationConstants.GET_ORGANIZATION_COURSE_GROUP_USAGE.SUCCESS, data: response.data })
    } catch (e) {
        yield put({ type: organizationConstants.GET_ORGANIZATION_COURSE_GROUP_USAGE.FAILURE, message: e.message })
    }
}

export function* getOrganizationTimeZoneLocations(data) {
    try {
        const response = yield call(organizationService.getOrganizationTimeZoneLocations, data.payload)
        yield put({ type: organizationConstants.GET_ORGANIZATION_TIME_ZONE_LOCATIONS.SUCCESS, data: response.data })
    } catch (e) {
        let usefulInformation = '' + e.response.data
        yield put(
            snackbarActions.enqueueSnackbar({
                message: 'Could to get time zone location for organization. ' + usefulInformation,
                options: {
                    variant: 'warning',
                },
            })
        )
        yield put({ type: organizationConstants.GET_ORGANIZATION_TIME_ZONE_LOCATIONS.FAILURE, message: e.message })
    }
}

export function* setOrganizationTimeZoneLocation(data) {
    try {
        const response = yield call(organizationService.setOrganizationTimeZoneLocations, data.payload)

        // fetch the new organization data
        const responseForRefetchingOrganization = yield call(organizationService.getMyOrganizations)
        yield put({
            type: organizationConstants.GET_MY_ORGANIZATIONS.SUCCESS,
            data: responseForRefetchingOrganization.data,
        })

        yield put({ type: organizationConstants.SET_ORGANIZATION_TIME_ZONE_LOCATION.SUCCESS, data: response.data })
    } catch (e) {
        let usefulInformation = '' + e.response.data
        yield put(
            snackbarActions.enqueueSnackbar({
                message: 'Could to set time zone location for organization. ' + usefulInformation,
                options: {
                    variant: 'warning',
                },
            })
        )
        yield put({ type: organizationConstants.SET_ORGANIZATION_TIME_ZONE_LOCATION.FAILURE, message: e.message })
    }
}

export function* getOrganizationCourseGroups(data) {
    try {
        const response = yield call(organizationService.getOrganizationCourseGroups, data.payload)
        yield put({ type: organizationConstants.GET_ORGANIZATION_COURSE_GROUPS.SUCCESS, data: response.data })
    } catch (e) {
        let usefulInformation = '' + e.response.data
        yield put(
            snackbarActions.enqueueSnackbar({
                message: 'Could to get course groups for organization. ' + usefulInformation,
                options: {
                    variant: 'warning',
                },
            })
        )
        yield put({ type: organizationConstants.GET_ORGANIZATION_COURSE_GROUPS.FAILURE, message: e.message })
    }
}

export function* addCourseGroupToOrganization(data) {
    try {
        const response = yield call(organizationService.addCourseGroupToOrganization, data.payload)
        yield put({ type: organizationConstants.ADD_COURSE_GROUP_TO_ORGANIZATION.SUCCESS, data: response.data })
    } catch (e) {
        let usefulInformation = '' + e.response.data
        yield put(
            snackbarActions.enqueueSnackbar({
                message: 'Failed to add course groups to organization. ' + usefulInformation,
                options: {
                    variant: 'warning',
                },
            })
        )
        yield put({ type: organizationConstants.ADD_COURSE_GROUP_TO_ORGANIZATION.FAILURE, message: e.message })
    }
}

export function* removeCourseGroupFromOrganization(data) {
    try {
        const response = yield call(organizationService.removeCourseGroupFromOrganization, data.payload)
        yield put({ type: organizationConstants.REMOVE_COURSE_GROUP_FROM_ORGANIZATION.SUCCESS, data: response.data })
    } catch (e) {
        let usefulInformation = '' + e.response.data
        yield put(
            snackbarActions.enqueueSnackbar({
                message: 'Failed to remove course group from organization. ' + usefulInformation,
                options: {
                    variant: 'warning',
                },
            })
        )
        yield put({ type: organizationConstants.REMOVE_COURSE_GROUP_FROM_ORGANIZATION.FAILURE, message: e.message })
    }
}

export function* getSkillsCoursesForOrganization(data) {
    try {
        const response = yield call(organizationService.getSkillsCourseGroups, data.payload)
        yield put({
            type: organizationConstants.SKILLS_COURSES_FOR_ORG.SUCCESS,
            data: response.data,
        })
    } catch (e) {
        let usefulInformation = '' + e.response.data
        yield put(
            snackbarActions.enqueueSnackbar({
                message: 'Failed to get skills courses for organization. ' + usefulInformation,
                options: {
                    variant: 'warning',
                },
            })
        )
        yield put({ type: organizationConstants.SKILLS_COURSES_FOR_ORG.FAILURE, message: e.message })
    }
}

//Listeners
export function* organizationSagas() {
    yield takeLatest(organizationConstants.GET_ORGANIZATION_INFO.REQUEST, getOrganizationInfo)
    yield takeLatest(organizationConstants.SET_ORGANIZATION_INFO.REQUEST, setOrganizationInfo)
    yield takeLatest(organizationConstants.CREATE_SCHOOL.REQUEST, createOrganization)
    yield takeLatest(organizationConstants.GET_MY_ORGANIZATIONS.REQUEST, getMyOrganizations)
    yield takeLatest(organizationConstants.SET_CURRENT_ORGANIZATION.REQUEST, setCurrentOrganization)
    yield takeLatest(organizationConstants.GET_ORGANIZATION_USERS.REQUEST, getOrganizationUsers)
    yield takeLatest(organizationConstants.INVITE_STUDENTS_SCHOOL.REQUEST, inviteStudentsToSchool)
    yield takeLatest(organizationConstants.GET_INVITE_ROLES_AVAILABLE.REQUEST, getInviteRolesAvailable)
    yield takeLatest(organizationConstants.DELETE_ORGANIZATION_USER.REQUEST, deleteOrganizationUser)
    yield takeLatest(organizationConstants.GET_ORGANIZATION_ASSETS.REQUEST, getOrganizationAsset)
    yield takeLatest(organizationConstants.UPDATE_ORGANIZATION_ASSET.REQUEST, updateOrganizationAsset)
    yield takeLatest(organizationConstants.DELETE_ORGANIZATION_ASSET.REQUEST, deleteOrganizationAsset)
    yield takeLatest(
        organizationConstants.EXPORT_EXCEL_FROM_TEMPLATE_ORG_STATS.REQUEST,
        exportExcelFromTemplateOrgStats
    )
    yield takeLatest(
        organizationConstants.EXPORT_EXCEL_FROM_TEMPLATE_CLASSROOM_STATS.REQUEST,
        exportExcelFromTemplateClassroomStats
    )
    yield takeLatest(organizationConstants.GET_AVAILABLE_COURSE_GROUPS.REQUEST, getAvailableCourseGroupsForOrganization)
    yield takeLatest(organizationConstants.GET_GRAPH_DATA_HOURLY_ORG.REQUEST, getGraphDataHourlyForOrganization)
    yield takeLatest(organizationConstants.GET_GRAPH_DATA_HOURLY_USER_GROUP.REQUEST, getGraphDataHourlyForUserGroup)
    yield takeLatest(organizationConstants.GET_GRAPH_DATA_DAY_OF_WEEK_ORG.REQUEST, getGraphDataDayOfWeekForOrganization)
    yield takeLatest(
        organizationConstants.GET_GRAPH_DATA_DAY_OF_WEEK_USER_GROUP.REQUEST,
        getGraphDataDayOfWeekForUserGroup
    )
    yield takeLatest(
        organizationConstants.GET_ORGANIZATION_TIME_ZONE_LOCATIONS.REQUEST,
        getOrganizationTimeZoneLocations
    )
    yield takeLatest(organizationConstants.SET_ORGANIZATION_TIME_ZONE_LOCATION.REQUEST, setOrganizationTimeZoneLocation)
    yield takeLatest(
        organizationConstants.GET_GRAPH_DATA_DAILY_USAGE_AGGREGATED_ORG.REQUEST,
        getGraphDataDailyUsageAggregatedForOrganization
    )
    yield takeLatest(
        organizationConstants.GET_GRAPH_DATA_DAILY_USAGE_AGGREGATED_USER_GROUP.REQUEST,
        getGraphDataDailyUsageAggregatedForUserGroup
    )

    yield takeLatest(
        organizationConstants.GET_GRAPH_DATA_DAILY_LESSON_AGGREGATED_ORG.REQUEST,
        getGraphDataDailyLessonAggregatedForOrganization
    )
    yield takeLatest(
        organizationConstants.GET_GRAPH_DATA_DAILY_LESSON_AGGREGATED_USER_GROUP.REQUEST,
        getGraphDataDailyLessonAggregatedForUserGroup
    )

    yield takeLatest(organizationConstants.GET_ORGANIZATION_COURSE_GROUPS.REQUEST, getOrganizationCourseGroups)
    yield takeLatest(organizationConstants.ADD_COURSE_GROUP_TO_ORGANIZATION.REQUEST, addCourseGroupToOrganization)
    yield takeLatest(
        organizationConstants.REMOVE_COURSE_GROUP_FROM_ORGANIZATION.REQUEST,
        removeCourseGroupFromOrganization
    )
    yield takeLatest(organizationConstants.UPDATE_ORGANIZATION_INFO.REQUEST, updateOrganizaitonInformation)
    yield takeEvery(organizationConstants.GET_ORGANIZATION_FACT_BOX_STATS.REQUEST, getOrganizationFactBoxStats)
    yield takeLatest(organizationConstants.GET_ORGANIZATION_COURSE_GROUP_USAGE.REQUEST, getOrganizationCourseGroupUsage)
    yield takeLatest(organizationConstants.SKILLS_COURSES_FOR_ORG.REQUEST, getSkillsCoursesForOrganization)
    yield takeLatest(organizationConstants.INVITE_STUDENTS_SCHOOL_USER_DTO.REQUEST, inviteStudentUserDtosToOrganization)
}
