import React, { useEffect, useState } from 'react'
import { Card, Button, Grid, TextField, CardContent, Typography } from '@material-ui/core'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { createApi } from 'unsplash-js'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { AdminUserCredentialType } from '../../../../Logic/CapeeshConstants'
import TextFieldWithLanguageSelector from '../ScenarioComponents/TextFieldWithLanguageSelector'

import { CapeeshLanguage } from '../../../../Logic/CapeeshConstants'
import { Search } from '@material-ui/icons'
import { CapeeshColors } from '../../../../assets/ColorPalette'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles((theme) => ({
    chip: {
        marginRight: '5px',
        marginBottom: '5px',
    },
    scenario: {
        paddingTop: '30px',
    },
    textFields: {
        marginBottom: '5px',
    },
}))

const ImageSearch = (props) => {
    const classes = useStyles()
    const [images, setImages] = useState([])
    const [keywords, setKeywords] = useState('')
    const [open, setOpen] = useState(false)
    const [file, setFile] = useState('')

    const [unsplash, setUnsplash] = useState(null)

    useEffect(() => {
        const unsplashCert = props.user.info?.credentialsDtos?.find(
            (x) => x.adminUserCredentialType === AdminUserCredentialType.Unsplash
        )
        if (unsplashCert && !unsplash) {
            const tempUnsplash = new createApi({
                accessKey: unsplashCert.apiKey,
            })
            setUnsplash(tempUnsplash)
        }
    }, [])

    const setSearchTerms = (term) => {
        var cleanedString = term.replace('\n', '')
        setKeywords(cleanedString)
    }

    const keyPress = (e) => {
        if (e.keyCode == 13) {
            searchPhotos(null)
        }
    }

    const searchPhotos = async (e) => {
        if (!unsplash) {
            return
        }
        let searchTerms = null
        if (e === null) {
            searchTerms = keywords
        } else {
            return
        }

        unsplash.search.getPhotos({ query: searchTerms, page: 1, perPage: 50 }).then((searchResult) => {
            if (searchResult.type === 'success') {
                setImages(searchResult.response.results)
                setOpen(true)
            } else {
                console.log(searchResult.message)
            }
        })
    }

    const handleImageChosen = (image, e) => {
        download(image)
    }

    const download = async (url) => {
        const { organization } = props
        const response = await fetch(url)
        if (response.status === 200) {
            const blob = await response.blob()
            const url = URL.createObjectURL(blob)
            var finalisedFile = new File([blob], 'tname', {
                type: 'image/jpeg',
                lastModified: new Date(),
            })
            setFile(finalisedFile)
            props.onSelectImage(blob, keywords)
            setOpen(false)
            return { success: true }
        }
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={(event) => {
                    setOpen(false)
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                style={{
                    paddingLeft: '100px',
                    paddingRight: '100px',
                    cursor: 'pointer',
                }}
                maxWidth="lg"
                fullWidth>
                <DialogTitle id="alert-dialog-title">
                    <FormattedMessage id={'globalwords.select.an.image'} defaultMessage={'Select an image'} />
                </DialogTitle>
                <DialogContent>
                    <Card>
                        <CardContent className={classes.card}>
                            <Grid container>
                                <Grid item xs={12} sm={12} lg={12} className={classes.gridItem}>
                                    <div className={classes.root}>
                                        {images?.length > 0 ? (
                                            images.map((image, index) => (
                                                <img
                                                    key={image.urls.small}
                                                    src={image.urls.small + ''}
                                                    alt={'an image'}
                                                    style={{
                                                        maxHeight: '200px',
                                                    }}
                                                    onClick={(e) => handleImageChosen(image.urls.regular, e)}
                                                />
                                            ))
                                        ) : (
                                            <Typography variant="body1" component="p">
                                                <FormattedMessage
                                                    id="create.course.images.search.no.results"
                                                    defaultMessage="No results"
                                                />
                                            </Typography>
                                        )}
                                    </div>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={(event) => {
                            setOpen(false)
                        }}
                        color="primary"
                        autoFocus>
                        <FormattedMessage id="globalwords.cancel" defaultMessage="Cancel" />
                    </Button>
                </DialogActions>
            </Dialog>
            <Grid container spacing={0}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        gap: '8px',
                        width: '95%',
                    }}>
                    <TextFieldWithLanguageSelector
                        text={keywords}
                        onChange={(event) => setSearchTerms(event.target.value)}
                        placeholder={'Image search keywords in English'}
                        style={{
                            borderRadius: '10px',
                            paddingRight: '5px',
                            width: 'calc(100% - 172px)',
                        }}
                        soloLanguageId={CapeeshLanguage.EnglishUK}
                        canChangeLanguage={false}
                    />
                    <Button
                        size="small"
                        variant="outlined"
                        style={{ color: 'white', background: CapeeshColors.Primary600, height: '40px', width: '160px' }}
                        onClick={(event) => searchPhotos(null)}>
                        <Search />
                        <FormattedMessage id="globalwords.search" defaultMessage="Search" />
                    </Button>
                </div>
            </Grid>
        </div>
    )
}

ImageSearch.propTypes = {}

const mapToProps = (state) => {
    return {
        metadata: state.metadata,
        organization: state.organization,
        user: state.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapToProps, mapDispatchToProps)(ImageSearch)
