import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import { Grid, makeStyles } from '@material-ui/core'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import { FormattedMessage } from 'react-intl'
import Dialog from '@mui/material/Dialog'
import { TextField } from '@mui/material'

const useStyles = makeStyles((theme) => ({}))

const EditUserAttributeModal = ({
    title,
    description,
    editUserAttributeModalOpen,
    onEditUserAttributeModalClose,
    editUser,
    onUpdateUserNickName,
}) => {
    const classes = useStyles()

    const [editUserNickName, setEditUserNickName] = useState('')

    useEffect(() => {
        setEditUserNickName(editUser.nickName)
    }, [])

    return (
        <div>
            <Dialog
                open={editUserAttributeModalOpen}
                onClose={onEditUserAttributeModalClose}
                aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{description}</DialogContentText>
                </DialogContent>
                <div style={{ width: '480px', height: '40px', paddingLeft: '20px', paddingBottom: '40px' }}>
                    <TextField
                        autoFocus
                        value={editUserNickName}
                        onChange={(event) => setEditUserNickName(event.target.value)}
                        label={<FormattedMessage id="globalwords.name" defaultMessage="Name" />}
                        style={{ width: '400px', height: '60px', borderRadius: '10px' }}
                        InputProps={{ disableUnderline: true }}
                        variant="filled"
                    />
                </div>
                <DialogActions>
                    <Button onClick={onEditUserAttributeModalClose} color="primary">
                        <FormattedMessage id="globalwords.cancel" defaultMessage="Cancel" />
                    </Button>
                    <Button
                        onClick={() => onUpdateUserNickName(editUser.id, editUserNickName)}
                        variant={'contained'}
                        disabled={editUserNickName === editUser.nickName}
                        color="primary">
                        <FormattedMessage id="globalwords.edit" defaultMessage="Edit" />
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

EditUserAttributeModal.propTypes = {}

const mapStateToProps = (state) => {
    return {
        organization: state.organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(EditUserAttributeModal)
