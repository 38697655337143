import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import OrganizationOverviewSidebar from './Components/OrganizationOverviewSidebar'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Card, CardActions, CardContent, CardHeader, Grid } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import EditOrganizationInformation from './Components/EditOrgranizationInformation'
import CircularProgress from '@material-ui/core/CircularProgress'
import { organizationActions } from '../../../_state/actions'

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '300px',
        marginLeft: 'calc(15% + 25px)',
        marginRight: '25px',
    },
}))

const OrganizationSettings = (props) => {
    const classes = useStyles()

    const [currentOrganizationDto, setCurrentOrganizationDto] = useState(null)

    useEffect(() => {
        props.dispatch(organizationActions.getMyOrganizations())

        if (props.organization.myOrganizations && props.organization.selectedOrganization) {
            let tempCurrentOrganization = props.organization.myOrganizations.find(
                (x) => x.id === props.organization.selectedOrganization
            )
            if (tempCurrentOrganization) {
                setCurrentOrganizationDto(tempCurrentOrganization)
            }
        }
    }, [])

    useEffect(() => {
        if (props.organization.myOrganizations && props.organization.selectedOrganization) {
            let tempCurrentOrganization = props.organization.myOrganizations.find(
                (x) => x.id === props.organization.selectedOrganization
            )
            if (tempCurrentOrganization) {
                setCurrentOrganizationDto(tempCurrentOrganization)
            }
        }
    }, [props.organization.selectedOrganization, props.organization.myOrganizations])

    const handleSaveBillingInformation = (
        organizationNumber,
        companyContactEmail,
        companyContactName,
        street,
        countyCode,
        countyName,
        country
    ) => {
        const updateOrganizationInformationDto = {
            organizationId: props.organization.selectedOrganization,
            organizationNumber: organizationNumber,
            contactEmail: companyContactEmail,
            contactPersonName: companyContactName,
            billingAddressStreet: street,
            billingAddressCountyCode: countyCode,
            billingAddressCountyName: countyName,
            billingAddressCountry: country,
        }
        props.dispatch(
            organizationActions.updateOrgnaizationInfo(
                props.organization.selectedOrganization,
                updateOrganizationInformationDto
            )
        )
    }

    return (
        <div className={classes.root}>
            <OrganizationOverviewSidebar path={props.computedMatch?.path} />

            <Grid container>
                {!currentOrganizationDto && (
                    <React.Fragment>
                        <CircularProgress></CircularProgress>
                        Loading organization...
                    </React.Fragment>
                )}

                {currentOrganizationDto && (
                    <Card style={{ marginTop: '20px' }}>
                        <CardHeader
                            title={
                                <FormattedMessage
                                    id="admin.edit.org.billing.information"
                                    defaultMessage="Billing information"
                                />
                            }
                        />
                        <CardContent>
                            <EditOrganizationInformation
                                organizationDto={currentOrganizationDto}
                                saveBillingInformation={handleSaveBillingInformation}
                            />
                        </CardContent>
                        <CardActions></CardActions>
                    </Card>
                )}
            </Grid>
        </div>
    )
}

function mapStateToProps(state) {
    const { organization, user } = state
    return {
        organization,
        user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationSettings)
